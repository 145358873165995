import styled from 'styled-components';
import { pxToRem } from '@styles';

export const PrefixWrapper = styled.div`
  position: relative;
  .input input {
    padding-left: ${pxToRem(25)};
  }
`;

interface IPrefixProps {
  char: string;
}

export const Prefix = styled.span<IPrefixProps>`
  position: absolute;
  left: 9px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.colors.dustyGray};
  &::before {
    content: '${props => props.char}';
    display: block;
  }
`;
