import { get } from '@common/fetch';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { IAccount } from '@models';
import {
  IReceiveKoaAccountsAction,
  IRequestKoaAccountsAction,
  ISetFavoriteKoaAccountsAction,
  ISetSelectedKoaAccountsAction,
} from '@store/koa/contracts/Actions';
import { KnownNotificationAction } from '@store/notification';

export { default as IKoaState } from './contracts/IKoaState';

export type KnownKoaAction =
  | IRequestKoaAccountsAction
  | IReceiveKoaAccountsAction
  | ISetSelectedKoaAccountsAction
  | ISetFavoriteKoaAccountsAction
  | KnownNotificationAction;

export const koaActions = {
  getAccounts: (): IAppThunkAction<KnownKoaAction> => async (dispatch, getState) => {
    const { koa, user } = getState();
    const { path: statePath } = koa;
    const path = `accounts?administrationNumber=${user.activeAdministration.number}&types=koa`;

    if (path !== statePath) {
      dispatch({ type: 'REQUEST_KOA_ACCOUNTS' });

      const response = await get(path);
      if (response.ok) {
        dispatch({
          type: 'RECEIVE_KOA_ACCOUNTS',
          accounts: await response.json(),
          path,
        });
      }
    }
  },
  setSelectedAccounts: (accounts: IAccount[]): IAppThunkAction<KnownKoaAction> => async dispatch => {
    dispatch({ type: 'SET_SELECTED_KOA_ACCOUNTS', accounts });
  },
  setFavoriteAccounts: (accounts: string[]): IAppThunkAction<KnownKoaAction> => async dispatch => {
    dispatch({ type: 'SET_FAVORITE_KOA_ACCOUNTS', accounts });
  },
};
