import styled from 'styled-components';
import { breakpoint, pxToRem } from '@styles';
import Link from '@components/shared/Link';

export const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  max-height: ${pxToRem(300)};
  background: ${props => props.theme.colors.white};
  &.no-max {
    max-height: 70vh;
    .carousel {
      max-width: calc(100vw - 5%);
      width: ${pxToRem(605)};
      ${breakpoint('l')`
        max-width: calc(100vw - 10%);
        width: ${pxToRem(914)};
      `}
    }
  }
  img {
    object-fit: contain;
  }
  .icon {
    width: ${pxToRem(22)};
    position: absolute;
    right: ${pxToRem(10)};
  }
`;
