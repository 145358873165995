import React, { useEffect } from 'react';
import { Wrapper } from './styles';
import HeaderBar from '@containers/app/HeaderBar';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, IApplicationState } from '@store/index';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import { useBreakpoint } from '@containers/withBreakpoint';
import { toggleBodyScroll } from '@styles/index';
import classNames from 'classnames';

const LAYOUT_CLASSNAME = 'column-layout';
export const LEFT_COLUMN_OPEN_CLASSNAME = 'left-column-open';

type IColumnLayoutProps = {
  left: () => JSX.Element;
  right: () => JSX.Element;
};

export default function ColumnLayout({ left, right }: IColumnLayoutProps) {
  const { isBreakpointSmall, isBreakpointLarge } = useBreakpoint();
  const { isOpen, userChanged } = useSelector(({ app }: IApplicationState) => app.sideBar);
  const wrapperClass = classNames({ 'with-transition': userChanged });
  const trapFocus = isBreakpointSmall && isOpen;
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add(LAYOUT_CLASSNAME);

    if (isOpen) {
      document.body.classList.add(LEFT_COLUMN_OPEN_CLASSNAME);
    } else {
      document.body.classList.remove(LEFT_COLUMN_OPEN_CLASSNAME);
    }

    toggleBodyScroll(!isOpen || !isBreakpointSmall);

    return () => {
      document.body.classList.remove(LAYOUT_CLASSNAME);
      toggleBodyScroll(true);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!userChanged) {
      toggle(isBreakpointLarge, false);
    }
  }, []);

  return (
    <Wrapper className={wrapperClass}>
      <HeaderBar />
      <LeftColumn isOpen={isOpen} trapFocus={trapFocus} onClose={() => toggle()}>
        {left()}
      </LeftColumn>
      <RightColumn>{right()}</RightColumn>
    </Wrapper>
  );

  function toggle(open: boolean = false, changedByUser: boolean = true) {
    dispatch(appActions.setSideBarVisibility(open, changedByUser));
  }
}
