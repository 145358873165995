import { Action, Reducer } from 'redux';
import INotificationState from './contracts/INotificationState';
import { NotificationStatus } from '@enums';
import NotificationLevel from '@enums/NotificationLevel';
import NotificationType from '@enums/NotificationType';
import { IHubNotification } from '@models/index';
import { isGeneralWarningNotification } from '@common/notification';
import { KnownNotificationAction } from '@store/notification/index';

const unloadedState: INotificationState = {
  notifications: [],
  pushNotifications: [],
};

export const notificationReducer: Reducer<INotificationState> = (state: INotificationState, incomingAction: Action) => {
  const action = incomingAction as KnownNotificationAction;

  switch (action.type) {
    case 'RECEIVE_NOTIFICATION': {
      const { notification } = action;
      let newNotifications = [...state.notifications];

      if (isGeneralWarningNotification(notification)) {
        newNotifications = getNotificationsWithoutGeneralWarnings(newNotifications);
      }

      newNotifications.push(notification);

      return {
        ...state,
        notifications: newNotifications,
      };
    }
    case 'RECEIVE_PUSH_NOTIFICATION':
      return {
        ...state,
        pushNotifications: state.pushNotifications.concat(action.notification),
      };
    case 'REMOVE_PUSH_NOTIFICATION':
      return {
        ...state,
        pushNotifications: state.pushNotifications.filter(i => i !== action.notification),
      };
    case 'SET_NOTIFICATION_STATUS':
      return {
        ...state,
        notifications: state.notifications
          .map(notification => {
            const updatedNotification = { ...notification };
            if (action.notificationIds.includes(notification.id)) {
              updatedNotification.status = action.status;
            }

            return updatedNotification;
          })
          .filter(n => n.status !== NotificationStatus.Deleted),
      };
  }

  return state || unloadedState;
};

function getNotificationsWithoutGeneralWarnings(notifications: IHubNotification[]) {
  return notifications.filter(n => n.type !== NotificationType.General && n.level !== NotificationLevel.Warning);
}
