import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IconType } from '@enums';
import Button, { IButtonProps } from './Button';

export type ICloseButtonComponentProps = IButtonProps & WithTranslation;

export function CloseButton({ t, className, to, noHover, onClick, testHook }: ICloseButtonComponentProps) {
  const buttonClass = classNames('button-close', className);

  return (
    <Button
      title={t('general:close')}
      icon={IconType.Cross}
      to={to && { pathname: to, search: true }}
      onClick={onClick}
      className={buttonClass}
      noHover={noHover}
      excludeAutoFocus
      testHook={testHook}
    />
  );
}

export default withTranslation(['general'])(CloseButton);
