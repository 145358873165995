import React from 'react';
import { HtmlOutputStyle } from './styles';

interface IHtmlOutputProps extends React.HTMLProps<HTMLDivElement> {
  html: string;
}

export default function HtmlOutput({ html, className }: IHtmlOutputProps) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return <HtmlOutputStyle dangerouslySetInnerHTML={{ __html: html }} className={className} />;
}
