import styled from 'styled-components';
import { breakpoint, pxToRem } from '@styles';
import Link from '@components/shared/Link';

export const Content = styled.div`
  width: 100%;
  img {
    object-fit: contain;
  }
`;

export const ImageWrapper = styled.div`
  height: 100%;
  background: ${props => props.theme.colors.silver};
  .image {
    display: flex;
    justify-content: center;
    ${breakpoint('m')`
      width: auto;
      height: 100%;
    `}
  }
  .with-thumbnails & {
    height: calc(100% - ${pxToRem(95)});
  }
`;

export const ThumbnailBar = styled.ul`
  margin-top: ${pxToRem(20)};
  height: ${pxToRem(75)};
`;

export const Thumbnail = styled.li`
  display: inline-flex;
  float: left;
  width: ${pxToRem(100)};
  background-color: ${props => props.theme.colors.silver};
  height: 100%;
  align-items: baseline;
  margin: 0 ${pxToRem(15)} ${pxToRem(15)} 0;
`;

export const ThumbnailLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  &.active {
    border: ${pxToRem(1)} solid ${props => props.theme.colors.lipstick};
  }
`;
