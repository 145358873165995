import React, { useState } from 'react';
import { IconType } from '@enums';
import Icon from '@components/shared/Icon';
import { Body, Header } from './styles';

interface IAccordionProps extends React.HTMLProps<any> {
  children?: any;
  title?: string;
  isOpen?: boolean;
  testHook?: string;
  renderTitle?: () => JSX.Element;
}

export default function Accordion({ children, title, isOpen: initialOpen, testHook, renderTitle }: IAccordionProps) {
  const [isOpen, setIsOpen] = useState(initialOpen);

  return (
    <div className="accordion" data-test-hook={testHook}>
      <Header type="button" onClick={() => setIsOpen(!isOpen)}>
        <Icon type={IconType.Arrow} rotate={isOpen ? 0 : -90} />
        {!renderTitle && <span className="text-link ellipsify">{title}</span>}
        {renderTitle && renderTitle()}
      </Header>

      {isOpen && <Body className="body">{children}</Body>}
    </div>
  );
}
