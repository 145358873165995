import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  Content,
  HamburgerActivityIndicator,
  InnerWrapper,
  Logo,
  MenuWrapper,
  NotificationButtonWrapper,
  ToggleButtonsWrapper,
  UserNavigationWrapper,
} from './styles';
import PrimaryNavigation from '@components/app/PrimaryNavigation';
import UserMenu from '@components/app/UserMenu';
import { IconType, NotificationLevel, NotificationStatus, NotificationType } from '@enums';
import { Tooltip } from '@components/shared/Tooltip';
import NotificationMenu from '@components/app/NotificationMenu';
import { IHubNotification } from '@models';
import CountLabel from '@components/app/CountLabel';
import { Button, CloseButton } from '@components/shared/Button';
import TrapFocus from '@components/shared/TrapFocus';
import { isGlobalNotification, isNewOrderNotification, isErrorNotification } from '@common/notification';
import { ActiveUser } from '../ActiveUser';

type IHeaderBarProps = {
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onUserMenuVisibilityChanged?: (visible: boolean) => void;
  onClearNotifications?: () => void;
  isBreakpointLarge?: boolean;
  notifications: IHubNotification[];
};

function setNotifications(notifications: IHubNotification[]) {
  const visibleNotifications = notifications.filter(n => isGlobalNotification(n));
  const newNotifications = visibleNotifications.filter(n => n.status === NotificationStatus.New);
  const newOrderNotifications = notifications.filter(n => isNewOrderNotification(n) && !isErrorNotification(n));
  const generalWarnings = visibleNotifications.filter(n => n.type === NotificationType.General && n.level === NotificationLevel.Warning);

  return { visibleNotifications, newNotifications, newOrderNotifications, generalWarnings };
}

function HeaderBar(props: IHeaderBarProps) {
  const { onClearNotifications, isOpen, onOpen, onClose, isBreakpointLarge, notifications } = props;
  const { t } = useTranslation('general');

  const [showNotificationMenu, setShowNotificationMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const { visibleNotifications, newNotifications, newOrderNotifications, generalWarnings } = useMemo(() => setNotifications(notifications), [
    notifications,
  ]);
  const newOrderCount = newOrderNotifications.length;
  const hasActivity = newOrderCount > 0;

  const wrapperClass = classNames({
    open: isOpen,
  });
  const notificationButton = classNames('icon-button', {
    'has-new-notifications': newNotifications.length > 0,
    'has-general-warning': generalWarnings.length > 0,
  });

  useEffect(() => {
    const { onUserMenuVisibilityChanged } = props;
    onUserMenuVisibilityChanged && onUserMenuVisibilityChanged(showNotificationMenu);
  }, [showNotificationMenu]);

  return (
    <TrapFocus disabled={!isOpen}>
      {setRef => (
        <MenuWrapper role="menu" className={wrapperClass} ref={setRef}>
          <InnerWrapper>
            <Bar>
              <ToggleButtonsWrapper className="hidden-l">
                {!isOpen && (
                  <HamburgerActivityIndicator show={hasActivity}>
                    <Button
                      title={t('open')}
                      icon={IconType.Hamburger}
                      onClick={onOpen}
                      className="icon-button"
                      noHover={!isBreakpointLarge}
                      testHook="btn-mobile-menu"
                    />
                  </HamburgerActivityIndicator>
                )}

                {isOpen && <CloseButton onClick={onClose} noHover={!isBreakpointLarge} />}
              </ToggleButtonsWrapper>

              {!isOpen && (
                <UserNavigationWrapper>
                  <NotificationButtonWrapper>
                    <CountLabel count={newNotifications.length} />
                    <Button
                      title={t('menu.notifications')}
                      icon={IconType.Bell}
                      className={notificationButton}
                      noHover={!isBreakpointLarge}
                      onClick={() => setShowNotificationMenu(true)}
                      testHook="btn-notification-menu"
                    />
                  </NotificationButtonWrapper>
                  <Button
                    title={t('menu.userMenu')}
                    icon={IconType.Figure}
                    className="icon-button"
                    noHover={!isBreakpointLarge}
                    onClick={() => setShowUserMenu(true)}
                    testHook="btn-user-menu"
                  />
                  {isBreakpointLarge && <ActiveUser onClick={() => setShowUserMenu(true)} />}
                  {showNotificationMenu && (
                    <Tooltip inline noPadding className="tooltip-menu notification" onClose={() => setShowNotificationMenu(false)}>
                      <NotificationMenu
                        notifications={visibleNotifications}
                        onClear={() => {
                          onClearNotifications && onClearNotifications();
                        }}
                      />
                    </Tooltip>
                  )}

                  {showUserMenu && (
                    <Tooltip inline noPadding className="tooltip-menu user" onClose={() => setShowUserMenu(false)}>
                      <UserMenu />
                    </Tooltip>
                  )}
                </UserNavigationWrapper>
              )}
            </Bar>

            <Content>
              <PrimaryNavigation newOrderCount={newOrderCount} />
            </Content>

            <Logo />
          </InnerWrapper>
        </MenuWrapper>
      )}
    </TrapFocus>
  );
}

export default React.memo(HeaderBar);
