import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IconType } from '@enums';
import { OutlineButton } from './';

interface IBackButtonProps extends WithTranslation {
  to?: any;
}

export function BackButton({ t, to }: IBackButtonProps) {
  return (
    <OutlineButton className="back-button" to={to} label={t('general:back')} icon={{ type: IconType.Arrow, rotation: 90 }} testHook="btn-back" />
  );
}

export default withTranslation(['general'])(BackButton);
