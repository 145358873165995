import React, { useState } from 'react';
import { Code, Block, InputLabel, Wrapper, InnerWrapper } from './styles';
import Heading from '@components/shared/Heading';
import AdministrationSelect from '@containers/app/AdministrationSelect';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubMenu, SubMenuItem } from '@components/shared/SubMenu';
import { withAuthorization } from '@containers/withAuthorization';
import LanguageSelect from '@containers/app/LanguageSelect';
import { UserConnect } from '@containers/shared/Connect';
import { FillButton } from '@components/shared/Button';
import ConfirmationModal from '@components/shared/ConfirmationModal';
import { useOktaAuth } from '@okta/okta-react';
import config from '@common/config';

export function UserMenu() {
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const { t } = useTranslation('general');
  const { oktaAuth } = useOktaAuth();
  const { okta } = config;

  const onContinueLogOut = () => {
    oktaAuth.signOut({
      postLogoutRedirectUri: okta.postLogoutRedirectUri,
    });
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <Block>
          <UserConnect>
            {({ user }) => (
              <>
                <Heading as="h5" size="s">
                  {user.name}
                </Heading>
                <Code>{user.id}</Code>
              </>
            )}
          </UserConnect>

          <InputLabel>
            {t('menu.administration')}:
            <AdministrationSelect />
          </InputLabel>

          <InputLabel>
            {t('menu.language')}:
            <LanguageSelect />
          </InputLabel>
        </Block>

        <Block noPadding>
          <SubMenu>
            <UserConnect>
              {({ user }) => (
                <>
                  {user.authorization.account.read ? (
                    <SubMenuItem>
                      <NavLink exact to="/settings">
                        {t('menu.settings')}
                      </NavLink>
                    </SubMenuItem>
                  ) : (
                    <SubMenuItem className="disabled">{t('menu.settings')}</SubMenuItem>
                  )}
                  {user.authorization.linkedAccounts.read ? (
                    <SubMenuItem>
                      <NavLink to="/settings/accounts">{t('menu.accounts')}</NavLink>
                    </SubMenuItem>
                  ) : (
                    <SubMenuItem className="disabled">{t('menu.accounts')}</SubMenuItem>
                  )}
                </>
              )}
            </UserConnect>
          </SubMenu>
        </Block>

        <Block>
          <FillButton
            className="log-out"
            onClick={() => {
              setShowLogoutConfirmation(true);
            }}
            label={t('menu.logout')}
          />
        </Block>
      </InnerWrapper>

      {showLogoutConfirmation && (
        <ConfirmationModal
          title={`${t('menu.logout')}?`}
          onReady={onContinueLogOut}
          onBoth={() => {
            setShowLogoutConfirmation(false);
          }}
        >
          {t('menu.logoutConfirmation')}
        </ConfirmationModal>
      )}
    </Wrapper>
  );
}

export default withAuthorization(React.memo(UserMenu));
