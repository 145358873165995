import React from 'react';
import classNames from 'classnames';
import { IconType } from '@enums';
import Icon from '@components/shared/Icon';
import Link, { ILocationDescriptor } from '@components/shared/Link';

type IIconProp =
  | IconType
  | {
      type: IconType;
      position?: 'left' | 'right';
      rotation?: number;
    };

export interface IBaseButtonProps extends React.HTMLProps<HTMLElement> {
  label?: string;
  icon?: IIconProp;
  to?: ILocationDescriptor;
  href?: string;
  target?: string;
  disabled?: boolean;
  excludeAutoFocus?: boolean;
  noHover?: boolean;
  active?: boolean;
  testHook?: string;
}

export default function BaseButton(props: IBaseButtonProps) {
  const { href, to, excludeAutoFocus, disabled, title, onClick, testHook, type = 'button' } = props;

  const sharedProps = {
    disabled,
    title,
    onClick,
    className: getClass(props),
    children: getBody(props),
  };

  if (to) {
    return <Link {...sharedProps} excludeAutoFocus={excludeAutoFocus} testHook={testHook} disabled={disabled} to={to} />;
  }

  if (href) {
    const { target } = props;

    return <a {...sharedProps} href={!disabled ? href : null} target={target} data-exclude-auto-focus={excludeAutoFocus} data-test-hook={testHook} />;
  }

  return <button {...sharedProps} type={type as any} data-exclude-auto-focus={excludeAutoFocus} data-test-hook={testHook} />;
}

function getBody(props: IBaseButtonProps) {
  const { icon } = props as IBaseButtonProps;
  const { label } = props;
  const iconPosition = (typeof icon === 'object' ? icon.position : null) || 'left';

  return (
    <>
      {iconPosition === 'left' && <IconWrapper icon={icon} />}
      {label && <span className="label">{label}</span>}
      {iconPosition === 'right' && <IconWrapper icon={icon} />}
    </>
  );
}

function IconWrapper({ icon }: { icon: IIconProp }) {
  const iconType = typeof icon === 'object' ? icon.type : icon;
  const iconRotation = (typeof icon === 'object' ? icon.rotation : null) || 0;
  const hasIcon = iconType !== null && typeof iconType !== 'undefined';

  if (hasIcon) {
    return (
      <div className="icon-wrapper">
        <Icon type={iconType} rotate={iconRotation} />
      </div>
    );
  }

  return null;
}

export function getClass(props: IBaseButtonProps) {
  const { label, icon, noHover, active, disabled, className } = props;

  return classNames('button', className, {
    active,
    disabled,
    'has-icon': !!icon,
    'has-label': !!label,
    'no-label': !label,
    'no-hover': noHover,
  });
}
