import React, { RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ClearButton, SearchButton, SearchWrapper } from './searchStyles';
import Icon from '@components/shared/Icon';
import { IconType } from '@enums';
import { ButtonsWrapper, StyledInput } from './styles';

export interface ISearchComponentProps {
  onChange?: (value: string) => void;
  value: string;
  required?: boolean;
  placeholder?: string;
}

interface ISearchProps extends ISearchComponentProps, WithTranslation {}

export class Search extends React.PureComponent<ISearchProps> {
  searchElement: RefObject<HTMLElement> = React.createRef();

  constructor(props: ISearchProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onClearClicked = this.onClearClicked.bind(this);
  }

  render() {
    const { t, required, placeholder, value } = this.props;
    const hasValue = value && value.length > 0;
    const searchClass = classNames('input-search', {
      'has-value': hasValue,
    });

    return (
      <SearchWrapper className={searchClass}>
        <StyledInput
          type="search"
          placeholder={placeholder || t('input.search')}
          ref={this.searchElement as any}
          onChange={this.onChange}
          required={required}
          value={value}
        />
        <ButtonsWrapper>
          {hasValue && (
            <ClearButton type="button" onClick={this.onClearClicked}>
              <span className="visually-hidden">{t('input.empty')}</span>
              <canvas width="1" height="1" />
              <Icon type={IconType.Cross} />
            </ClearButton>
          )}
          <SearchButton type="submit">
            <span className="visually-hidden">{t('input.search')}</span>
            <canvas width="1" height="1" />
            <Icon type={IconType.Magnifier} />
          </SearchButton>
        </ButtonsWrapper>
      </SearchWrapper>
    );
  }

  private onChange(event: React.FormEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    const { onChange } = this.props;

    onChange(value);
  }

  private onClearClicked(event: any) {
    event.preventDefault();

    const { onChange } = this.props;

    onChange('');
  }
}

export default withTranslation()(Search);
