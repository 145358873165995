import styled from 'styled-components';
import { breakpoint, pxToRem } from '@styles';

export const Wrapper = styled.div`
  ${breakpoint('m')`
    display: flex;
  `}
`;

export const InputWrapper = styled.div`
  margin-top: ${pxToRem(10)};
`;

export const Fieldset = styled.fieldset`
  display: inline-block;
  width: 100%;
  margin-bottom: ${pxToRem(15)};
  legend {
    width: 100%;
    border-bottom: ${pxToRem(1)} solid ${props => props.theme.colors.alto};
    padding-bottom: ${pxToRem(5)};
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${breakpoint('m')`
    margin-bottom: 0;
    &:not(:first-child) {
      > * {
        padding-left: ${pxToRem(30)};
      }
    }
  `}
`;
