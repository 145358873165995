import { connect } from 'react-redux';
import { IApplicationState, IOrderFilterState, IOrderState, ISupplyFilterState, ISupplyState, IUserState } from '@store';

export const SupplyConnect = getConnect<ISupplyState>(({ supply }: IApplicationState) => supply);
export const SupplyFilterConnect = getConnect<ISupplyFilterState>(({ supplyFilter }: IApplicationState) => supplyFilter);
export const OrderConnect = getConnect<IOrderState>(({ order }: IApplicationState) => order);
export const OrderFilterConnect = getConnect<IOrderFilterState>(({ orderFilter }: IApplicationState) => orderFilter);

interface IAdditionalUserProps {
  authorizedToBuy: boolean;
  authorizedToMark: boolean;
  authorizedToRequestClockPresale: boolean;
  authorizedToWriteLinkedAccounts: boolean;
}

export const UserConnect = getConnect<IUserState, IAdditionalUserProps>(
  ({ user }: IApplicationState) => user,
  ({ user: { authorization } }) => ({
    authorizedToBuy: authorization.order.write,
    authorizedToMark: authorization.mark.write,
    authorizedToRequestClockPresale: authorization.clockPreSaleRequest.write,
    authorizedToWriteLinkedAccounts: authorization.linkedAccounts.write,
  }),
);

interface IConnectedProps<TState, TAdditional = Record<string, any>> {
  children: (props: TState, additional?: TAdditional) => JSX.Element;
  state: TState;
}

function getConnect<TState, TAdditional = Record<string, any>>(mapStateToProps: any, additionalProps?: (state: TState) => TAdditional) {
  return connect(
    (state: IApplicationState) => ({
      state: mapStateToProps(state),
    }),
    null,
  )(({ children, state }: IConnectedProps<TState, TAdditional>) => children(state, additionalProps && additionalProps(state)));
}
