import localStorage from 'store';
import { get, patch } from '@common/fetch';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { IReceiveUserAction, IRequestUserAction, ISetActiveAdministrationAction } from './contracts/Actions';
import { IAdministration } from '@models';
import { store } from '@root/configureStore';
import { getUserAdministrationStorageKey } from './reducer';
import IUserState from './contracts/IUserState';
import { Language } from '@enums';

export { default as IUserState } from './contracts/IUserState';

export type KnownUserAction = IRequestUserAction | IReceiveUserAction | ISetActiveAdministrationAction;

export const userActions = {
  getUser: (): IAppThunkAction<KnownUserAction> => dispatch => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: 'REQUEST_USER' });

      const response = await get('users/current');

      if (response.ok) {
        dispatch({
          type: 'RECEIVE_USER',
          user: await response.json(),
        });

        resolve();
      } else {
        reject();
      }
    });
  },
  setActiveAdministration: (administrationNumber: number): IAppThunkAction<KnownUserAction> => async (dispatch, getState) => {
    const { user } = getState().user;
    const administration = {
      number: administrationNumber,
      isMain: false,
      name: '',
    };
    if (!user.authorization.useAnyAdministration.write && user.administrations.length > 0) {
      if (!user.administrations.some(administrationItem => administrationItem.number === administrationNumber)) {
        administration.number = getDefaultAdministration(user.administrations).number;
      }
    }
    dispatch({ administration, type: 'SET_ACTIVE_ADMINISTRATION' });
  },
  setLanguage: (language: Language): IAppThunkAction<KnownUserAction> => async () => {
    const response = await patch('users/current', [
      {
        op: 'replace',
        path: '/language',
        value: language,
      },
    ]);

    if (response.ok) {
      document.location.reload();
    }
  },
};

export function getActiveAdministrationNumber(): number {
  const { activeAdministration } = store.getState().user;

  return activeAdministration ? activeAdministration.number : null;
}

export async function setAdministrationFromStorage({ user }: IUserState) {
  if (user) {
    let activeAdministration = localStorage.get(getUserAdministrationStorageKey(user.id));
    if (!activeAdministration) {
      activeAdministration = getDefaultAdministration(user.administrations);
    }
    await userActions.setActiveAdministration(activeAdministration.number)(store.dispatch, store.getState);
  }
}

function getDefaultAdministration(administrations: IAdministration[]): IAdministration {
  const mainAdministration = administrations.filter((a: IAdministration) => a.isMain)[0];

  return mainAdministration || administrations[0] || { number: 0, isMain: false, name: '' };
}
