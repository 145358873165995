import styled from 'styled-components';
import { breakpoint, BREAKPOINTS_IN_PX, pxToRem, setFont } from '@styles';
import { LeftColumn as GenericLeftColumn, RightColumn as GenericRightColumn } from '@containers/layout/CenterLayout/styles';

export const LEFT_COLUMN_WIDTH = 660;

export const LeftColumn = styled(GenericLeftColumn)`
  ${breakpoint('l')`
    width: ${pxToRem(LEFT_COLUMN_WIDTH)};
  `}
`;

export const RightColumn = styled(GenericRightColumn)`
  ${breakpoint('l')`
    width: ${pxToRem(BREAKPOINTS_IN_PX.l - LEFT_COLUMN_WIDTH)};
  `}
`;

export const Article = styled.article`
  .news-title {
    color: ${props => props.theme.colors.lipstick};
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint('l')`
    flex-direction: row;
  `}
`;

export const GridItem = styled.div`
  margin-bottom: ${pxToRem(30)};
  ${breakpoint('l')`
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

export const GridColumn = styled.div`
  width: 100%;
  &:not(:first-child) {
    margin: 0;
    ${breakpoint('l')`
      margin-left: ${pxToRem(20)};
    `}
  }
  &:last-child ${GridItem}:last-child {
    margin-bottom: 0;
  }
  ${breakpoint('l')`
    width: 50%;
  `}
`;

export const ContentBlock = styled.div`
  position: relative;
  margin-bottom: ${pxToRem(30)};
`;

export const FollowLink = styled.a`
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #00aced;
  color: ${props => props.theme.colors.white};
  font-size: ${pxToRem(12)};
  ${setFont('bold')}
  padding: ${pxToRem(5)} ${pxToRem(10)};
  border-radius: ${pxToRem(5)};
  align-items: center;
  .icon {
    height: ${pxToRem(15)};
    margin: auto ${pxToRem(10)} auto 0;
  }
`;

export const StatisticsBar = styled.div`
  height: ${pxToRem(130)};
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DateTimeContainer = styled.div`
  display: block;
  color: ${props => props.theme.colors.white};
  .clock-icon {
    vertical-align: sub;
    margin-left: ${pxToRem(10)};
    margin-right: ${pxToRem(10)};
  }
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const ContactBlock = styled.div`
  width: ${pxToRem(300)};
  .title {
    margin-bottom: ${pxToRem(20)};
  }
  .button {
    margin-top: ${pxToRem(20)};
  }
`;

export const Text = styled.div`
  line-height: 160%;
  > a {
    color: ${props => props.theme.colors.lipstick};
  }
`;

export const TitleWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-bottom: ${pxToRem(20)};
  .button.bordered.no-label {
    color: ${props => props.theme.colors.white};
    margin-left: ${pxToRem(15)};
    &:hover {
      color: ${props => props.theme.colors.white};
      border-color: ${props => props.theme.colors.white};
    }
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  ${breakpoint('m')`
    flex-direction: row;
  `}
`;
