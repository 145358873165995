import { translateEnum } from '@common/enum';

enum TradeCategory {
  Flowers = 'Flowers',
  Plants = 'Plants',
}

export function translateTradeCategory(key: TradeCategory, name: string = 'TradeCategory') {
  return translateEnum(name, key);
}

export default TradeCategory;
