import React from 'react';
import classNames from 'classnames';
import { Indicator, Label, StyledRadioInput, Wrapper } from './radioStyles';

export interface IRadioComponentsProps {
  label?: string;
  disabled?: boolean;
}

type IRadioProps = IRadioComponentsProps & React.HTMLProps<HTMLInputElement>;

export default function Radio({ label, disabled, ref, as, ...restProps }: IRadioProps) {
  const wrapperClass = classNames({ disabled });

  return (
    <Wrapper className={wrapperClass}>
      <StyledRadioInput type="radio" disabled={disabled} {...restProps} className="visually-hidden" />
      <Indicator className="indicator" />
      <Label className="ellipsify">{label}</Label>
    </Wrapper>
  );
}
