import React, { Suspense } from 'react';
import { initialize as initializeWebsocket } from '@websocket';
import { initializeI18n } from './i18n';
import { store } from './configureStore';
import ErrorDialog from '@containers/shared/ErrorDialog';
import { IApplicationState } from './store';
import { IApiError, IUser } from '@models';
import { setAdministrationFromStorage, userActions } from '@store/user';
import NotAuthorized from '@components/app/NotAuthorized';
import Loader from '@components/shared/Loader';
import Routes from '@containers/app/Routes';
import { SUPPLY_COLUMN_LAYOUT_DEFAULT } from '@common/columnLayout';
import AppLayout from '@containers/layout/AppLayout';
import NotAllowedBrowser from './components/app/NotAllowedBrowser';
import { withOktaAuth } from '@okta/okta-react';
import { setCookie } from './common/cookie';

interface IBootStrapperState {
  loading: boolean;
  error: IApiError;
  user: IUser;
  browserIsCompatible: boolean;
}

export default withOktaAuth(
  class BootStrapper extends React.PureComponent<Record<string, any>, IBootStrapperState> {
    state: IBootStrapperState = {
      loading: true,
      error: null,
      user: null,
      browserIsCompatible: !window.navigator.userAgent.match(/MSIE|Trident/),
    };

    constructor(props: Readonly<Record<string, any>>) {
      super(props);
    }

    private async setOkta() {
      try {
        const token = await this.props.authState.accessToken.accessToken;
        setCookie('oktaToken', token);
      } catch (x) {
        // console.log('setOkta exception ', x);
      }
    }

    private async initializeState(): Promise<IApplicationState> {
      return new Promise(async (resolve, reject) => {
        try {
          await this.setOkta();
          await userActions.getUser()(store.dispatch, store.getState);

          const state = store.getState();
          const {
            user: { user },
          } = state;

          if (user.authorization.mark.write) {
            SUPPLY_COLUMN_LAYOUT_DEFAULT[0].visible = true;
          }

          await setAdministrationFromStorage(state.user);

          resolve(state);
        } catch {
          reject();
        }
      });
    }

    componentDidMount() {
      this.state.browserIsCompatible &&
        this.initializeState().then(async ({ user: userState }) => {
          await initializeWebsocket(userState.user);
          initializeI18n(userState.user);

          const { error } = store.getState().api;
          this.setState({
            loading: false,
            user: userState.user,
            error,
          });
        });
    }

    render() {
      const { loading, user, error } = this.state;

      if (!this.state.browserIsCompatible) {
        initializeI18n(null);

        return (
          <Suspense fallback={null}>
            <NotAllowedBrowser />
          </Suspense>
        );
      }

      return (
        <Suspense fallback={null}>
          {(() => {
            if (loading) {
              return (
                <div className="bootstrapper-loader">
                  <Loader iconSize="l" />
                  When the page does not load, refresh the page
                </div>
              );
            }

            if (error) {
              return <ErrorDialog showButtons={false} />;
            }

            if (!user.authorization.isAuthorized) {
              return <NotAuthorized />;
            }

            return (
              <AppLayout>
                <Routes />
              </AppLayout>
            );
          })()}
        </Suspense>
      );
    }
  },
);
