import { connection } from '@websocket';
import { IHubNotification } from '@models/index';
import { notificationActions } from '@store/notification';
import connect from '@websocket/connect';

export default function initialize() {
  const { receiveNotification } = connect<typeof notificationActions>(notificationActions);

  connection.on('notification:receive', (notifications: IHubNotification[]) => {
    notifications.forEach(notification => receiveNotification(notification));
  });

  connection.on('notification:receiveNew', (notifications: IHubNotification[]) => {
    notifications.forEach(notification => receiveNotification(notification, true));
  });
}
