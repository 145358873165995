import { Action, Reducer } from 'redux';
import includes from 'lodash.includes';
import store from 'store';
import { KnownKoaAction } from './';
import { IAccount } from '@models';
import IKoaState from '@store/koa/contracts/IKoaState';

const STORAGE_KEY_PREFIX = 'koa:';
const STORAGE_KEYS = {
  selectedKoaAccounts: `${STORAGE_KEY_PREFIX}selected-koa-accounts`,
  favoriteKoaAccounts: `${STORAGE_KEY_PREFIX}favorite-koa-accounts`,
};

const unloadedState: IKoaState = {
  accounts: [],
  loading: false,
  path: null,
  selection: store.get(STORAGE_KEYS.selectedKoaAccounts) || [],
  favorites: store.get(STORAGE_KEYS.favoriteKoaAccounts) || [],
};

export const koaReducer: Reducer<IKoaState> = (state: IKoaState, incomingAction: Action) => {
  const action = incomingAction as KnownKoaAction;

  switch (action.type) {
    case 'REQUEST_KOA_ACCOUNTS':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_KOA_ACCOUNTS':
      const filteredSelection = state.selection.filter(s =>
        includes(
          action.accounts.map(a => a.id),
          s.id,
        ),
      );

      storeSelectedKoaAccounts(filteredSelection);

      return {
        ...state,
        loading: false,
        accounts: action.accounts,
        selection: filteredSelection,
        path: action.path,
      };
    case 'SET_SELECTED_KOA_ACCOUNTS':
      storeSelectedKoaAccounts(action.accounts);

      return {
        ...state,
        selection: action.accounts,
      };
    case 'SET_FAVORITE_KOA_ACCOUNTS':
      store.set(STORAGE_KEYS.favoriteKoaAccounts, action.accounts);

      return {
        ...state,
        favorites: action.accounts,
      };
  }

  return state || unloadedState;
};

function storeSelectedKoaAccounts(accounts: IAccount[]) {
  store.set(STORAGE_KEYS.selectedKoaAccounts, accounts);
}
