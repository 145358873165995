enum SupplyFilterType {
  TradeInstrument = 'TradeInstrument',
  CropGroup = 'CropGroup',
  ProductGroup = 'ProductGroup',
  Product = 'Product',
  IsSpecific = 'IsSpecific',
  Supplier = 'Supplier',
  CountryOfOrigin = 'CountryOfOrigin',
  Certification = 'Certification',
  Quality = 'Quality',
  MaturityStage = 'MaturityStage',
  MinimumStemsPerPlant = 'MinimumStemsPerPlant',
  PlantShape = 'PlantShape',
  PotMaterial = 'PotMaterial',
  Colour = 'Colour',
  Package = 'Package',
  AuctionClock = 'AuctionClock',
  Price = 'Price',
  ProducerGroup = 'ProducerGroup',
  PotSize = 'PotSize',
  PlantHeight = 'PlantHeight',
  LengthOfFlowerStem = 'LengthOfFlowerStem',
  Weight = 'Weight',
  DeliveryFormBarcode = 'DeliveryFormBarcode',
  StagingArea = 'StagingArea',
}

export default SupplyFilterType;
