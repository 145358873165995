import React from 'react';
import Modal, { IModalComponentProps } from '@components/shared/Modal';
import { WithTranslation, withTranslation } from 'react-i18next';

export type IConfirmationModalComponentProps = IModalComponentProps;

type IConfirmationDialogProps = IConfirmationModalComponentProps & WithTranslation;

export function ConfirmationModal({ t, ...restProps }: IConfirmationDialogProps) {
  return (
    <Modal
      {...restProps}
      size="s"
      headingSize="m"
      isForm
      showCloseButton={false}
      showCancelButton
      reverseButtons
      readyLabel={t('general:yes')}
      cancelLabel={t('general:no')}
    />
  );
}

export default withTranslation(['general'])(ConfirmationModal);
