import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TOAST_ANIMATION_DURATION } from './styles';

interface IToastGroupProps {
  children: React.ReactNode;
}

export default function ToastGroup({ children }: IToastGroupProps) {
  return (
    <div>
      <TransitionGroup>
        {React.Children.map(children, child => (
          <CSSTransition timeout={TOAST_ANIMATION_DURATION} classNames="toast">
            {child}
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
}
