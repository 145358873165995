import { store } from '@root/configureStore';

/* Connects the provided store actions to the store */
export default function connect<T extends { [key: string]: any }>(actions: T): T {
  const result: { [key: string]: any } = {};

  Object.keys(actions).forEach((key: string) => {
    result[key] = (...args: any) => actions[key](...args)(store.dispatch, store.getState);
  });

  return result as T;
}
