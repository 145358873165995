import React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import Modal from '@components/shared/Modal';
import { IOrderLine, ISupplyLine } from '@models';
import Image from '@components/shared/Image';
import { Content, ImageWrapper, Thumbnail, ThumbnailBar, ThumbnailLink } from './styles';
import Loader from '@components/shared/Loader';
import { History } from 'history';
import LocationDescriptor = History.LocationDescriptor;

type IModelDetailPhotosDialogProps = {
  type: 'supplyLine' | 'orderLine';
  model: ISupplyLine | IOrderLine;
  closeTo?: LocationDescriptor;
} & RouteComponentProps<{ index: string }> &
  WithTranslation;

export class ModelDetailPhotosDialog extends React.PureComponent<IModelDetailPhotosDialogProps> {
  render() {
    const { t, closeTo } = this.props;

    return (
      <Modal size="l" fixedContent title={t('photos.title')} closeTo={closeTo} autoClose>
        {this.getContent()}
      </Modal>
    );
  }

  private getContent() {
    const {
      model,
      match: { params },
    } = this.props;

    if (model) {
      const { tradeItem } = model;
      const currentIndex = parseInt(params.index, 10) || 0;
      const showThumbnails = tradeItem.assets.length > 1;

      const contentClass = classNames({
        'with-thumbnails': showThumbnails,
      });

      return (
        <Content className={contentClass}>
          <ImageWrapper>
            <Image
              src={tradeItem.assets[currentIndex] && tradeItem.assets[currentIndex].url}
              alt={tradeItem.growerProduct.name}
              title={tradeItem.growerProduct.name}
              sources={[{ media: { 'max-width': 768 }, width: 768, step: 100, from: 400 }]}
            />
          </ImageWrapper>
          {showThumbnails && (
            <ThumbnailBar>
              {tradeItem.assets.map((asset, i) => {
                const urlClass = classNames({ active: currentIndex === i });

                return (
                  <Thumbnail key={i}>
                    <ThumbnailLink to={{ pathname: `photos/${i}`, search: true, replace: '/photos' }} className={urlClass}>
                      <Image src={asset.url} fit width={100} />
                    </ThumbnailLink>
                  </Thumbnail>
                );
              })}
            </ThumbnailBar>
          )}
        </Content>
      );
    }

    return (
      <Content>
        <Loader />
      </Content>
    );
  }
}

export default withRouter(withTranslation()(ModelDetailPhotosDialog));
