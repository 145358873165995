import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { OutlineButton } from '@components/shared/Button';
import { UserConnect } from '@containers/shared/Connect';
import { ISupplyLine } from '@models';
import { IApplicationState } from '@store';
import includes from 'lodash.includes';

type ISupplyLineClockPresalesRequestButtonProps = {
  to: string;
  className?: string;
  supplyLine: ISupplyLine;
  onClick: (supplyLine: ISupplyLine) => void;
};

function SupplyLineClockPresalesRequestButton(props: ISupplyLineClockPresalesRequestButtonProps) {
  const { className, supplyLine, to } = props;
  const { t } = useTranslation('general');
  const history = useHistory();
  const location = useLocation();
  const requestedClockPresales = useSelector(({ supply }: IApplicationState) => supply.requestedClockPresales);

  const onClick = () => {
    const disableClockPresalesRequestDialog = localStorage.getItem('disable-clock-presales-request-dialog');
    if (!disableClockPresalesRequestDialog) {
      history.push({
        pathname: to,
        search: location.search,
        state: { supplyLine },
      });
    } else {
      props.onClick(supplyLine);
    }
  };

  return (
    !includes(requestedClockPresales, supplyLine.id) && (
      <UserConnect>
        {(_userState, { authorizedToRequestClockPresale }) => (
          <OutlineButton
            label={t('request')}
            important
            className={className}
            onClick={authorizedToRequestClockPresale ? onClick : undefined}
            disabled={!authorizedToRequestClockPresale}
          />
        )}
      </UserConnect>
    )
  );
}

export default React.memo(SupplyLineClockPresalesRequestButton);
