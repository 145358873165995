import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash.groupby';
import { IDeliveryOption } from '@models';
import { Radio } from '@components/shared/Input';
import { Fieldset, InputWrapper, Wrapper } from './styles';
import { formatPrice } from '@components/shared/PriceOutput';
import { formatDate } from '@components/shared/DateOutput';

type IDeliveryGridProps = {
  options: IDeliveryOption[];
  value?: number;
  onChange?: (delivery: IDeliveryOption) => void;
};

function DeliveryGrid({ options, value, onChange }: IDeliveryGridProps) {
  const valueOption = value && options.filter(o => o.id === value)[0];
  const [selectedLocationId, setSelectedLocation] = useState(valueOption && valueOption.delivery.location.id.toString());
  const [selectedMoment, setSelectedMoment] = useState(valueOption && valueOption.delivery.moment);
  const { t } = useTranslation('marketPlace');

  const deliveryLocations = groupBy(options, (d: IDeliveryOption) => d.delivery.location.id);
  const deliveryMoments = groupBy(options, (d: IDeliveryOption) => d.delivery.moment);

  const isMomentAllowed = (momentValue: string) => {
    const selectedLocation = deliveryLocations[selectedLocationId];

    if (selectedLocation) {
      return selectedLocation.filter(l => l.delivery.moment === momentValue).length > 0;
    }

    return true;
  };

  const onOptionChange = () => {
    const selectedOption = options.filter(
      (o: IDeliveryOption) => o.delivery.location.id === parseInt(selectedLocationId, 10) && o.delivery.moment === selectedMoment,
    )[0];

    onChange && onChange(selectedOption);
  };

  useEffect(() => {
    const isSelectedMomentAllowed = isMomentAllowed(selectedMoment);

    if (!isSelectedMomentAllowed) {
      setSelectedMoment(deliveryLocations[selectedLocationId][0].delivery.moment);
    } else {
      onOptionChange();
    }
  }, [selectedLocationId]);

  useEffect(() => {
    onOptionChange();
  }, [selectedMoment]);

  return (
    <Wrapper>
      <Fieldset>
        <legend>{t('order.deliveryLocation')}</legend>
        {Object.keys(deliveryLocations).map(key => {
          const {
            priceSupplementPerItem,
            delivery: { location },
          } = deliveryLocations[key][0];
          const priceSupplementPrefix = priceSupplementPerItem && priceSupplementPerItem > 0 ? '+' : '';
          const priceSupplementLabel = priceSupplementPerItem ? `${priceSupplementPrefix} ${formatPrice(priceSupplementPerItem)}` : '';

          return (
            <InputWrapper key={key}>
              <Radio
                name="location"
                label={`${location.name} ${priceSupplementLabel}`}
                value={key}
                checked={selectedLocationId === key}
                onChange={(event: React.FormEvent<HTMLInputElement>) => setSelectedLocation(event.currentTarget.value)}
              />
            </InputWrapper>
          );
        })}
      </Fieldset>
      <Fieldset>
        <legend>{t('order.deliveryMoment')}</legend>
        {Object.keys(deliveryMoments).map(key => (
          <InputWrapper key={key}>
            <Radio
              name="moment"
              label={formatDate(key, 'dd HH:mm')}
              value={key}
              checked={selectedMoment === key}
              disabled={!isMomentAllowed(key)}
              onChange={(event: React.FormEvent<HTMLInputElement>) => setSelectedMoment(event.currentTarget.value)}
            />
          </InputWrapper>
        ))}
      </Fieldset>
    </Wrapper>
  );
}

export default React.memo(DeliveryGrid);
