import styled from 'styled-components';
import { breakpoint, pxToRem, rgbToRgba } from '@styles';

export const MOUSE_OFFSET = 10;
const MIN_WIDTH = pxToRem(100);
const ARROW_WIDTH = 15;

export const Wrapper = styled.div`
  position: fixed;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(20)} 0 ${props => rgbToRgba(props.theme.colors.black, 0.15)};
  width: 100vw;
  z-index: 400;
  animation: .5s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
  border-radius: ${pxToRem(5)};
  display: none;
  ${breakpoint('m')`
    display: block;
    min-width: ${MIN_WIDTH};
    width: auto;
  `}
  &::before {
    display: none;
    content: '';
    width: ${pxToRem(ARROW_WIDTH)};
    height: ${pxToRem(ARROW_WIDTH)};
    transform: rotate(45deg);
    position: absolute;
    top: -${pxToRem(ARROW_WIDTH / 2)};
    left: ${pxToRem(15)};
    background: inherit;
    box-shadow: inherit;
  }
  &::after {
    display: none;
    content: '';
    background: inherit;
    width: calc(100% - ${pxToRem(20)});
    height: ${pxToRem(ARROW_WIDTH)};
    position: absolute;
    top: 0;
    left: 0;
  }
  &.inline {
    display: block;
    position: absolute;
    animation: none;
    visibility: visible;
  }
  &:not(.inline) {
    pointer-events: none;
  }
  &.with-arrow {
    &::before, &::after {
      display: block;
    }
  }
  .button-close {
    position: absolute;
    top: ${pxToRem(5)};
    right: ${pxToRem(5)};
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
}
`;

export const Body = styled.div`
  padding: ${pxToRem(30)};
  .no-padding & {
    padding: 0;
  }
`;
