import React, { Suspense, useEffect } from 'react';
import {
  Content,
  FixedWidthWrapper,
  StyledCenterLayout,
  StyledFooter,
  StyledHeader,
  StyledSection,
  StyledTitle,
} from '@containers/layout/CenterLayout/styles';
import HeaderBar from '@containers/app/HeaderBar';

const LAYOUT_CLASSNAME = 'center-layout';

export { InlineFooter } from './styles';

export function CenterLayout({ children }: React.HTMLProps<HTMLDivElement>) {
  useEffect(() => {
    document.body.classList.add(LAYOUT_CLASSNAME);

    return () => document.body.classList.remove(LAYOUT_CLASSNAME);
  });

  return (
    <StyledCenterLayout>
      <HeaderBar />

      <Content>
        <Suspense fallback={null}>{children}</Suspense>
      </Content>
    </StyledCenterLayout>
  );
}

export function Section({ children }: React.HTMLProps<HTMLDivElement>) {
  return (
    <StyledSection>
      <FixedWidthWrapper>{children}</FixedWidthWrapper>
    </StyledSection>
  );
}

export function Header({ children }: React.HTMLProps<HTMLDivElement>) {
  return (
    <StyledHeader>
      <FixedWidthWrapper>{children}</FixedWidthWrapper>
    </StyledHeader>
  );
}

export function SubHeader({ children }: React.HTMLProps<HTMLDivElement>) {
  return (
    <StyledSection withBackground>
      <FixedWidthWrapper>{children}</FixedWidthWrapper>
    </StyledSection>
  );
}

export function Footer({ children }: React.HTMLProps<HTMLDivElement>) {
  return (
    <StyledFooter as="footer">
      <FixedWidthWrapper>{children}</FixedWidthWrapper>
    </StyledFooter>
  );
}

interface ITitleProps extends React.HTMLProps<HTMLDivElement> {
  text: string;
}

export function Title({ children, text }: ITitleProps) {
  return (
    <StyledTitle>
      <span>FloraMondo </span>
      {text}
      {children}
    </StyledTitle>
  );
}
