import React from 'react';
import { Route } from 'react-router-dom';
import { CenterLayout } from '@containers/layout/CenterLayout';
import Home from '@containers/home/Home';
import NotAuthorizedDialog from '@components/app/NotAuthorizedDialog';
import { NOT_AUTHORIZED_ROUTE, SUPPLY_LINE_DETAIL_ROUTE_RECOMMENDATION } from '@root/routes';
import ClockStatusDialog from '@containers/home/ClockStatusDialog';
import SupplyLineDetailsDialog from '@containers/home/SupplyLineDetailsDialog';
import SupplyLinePhotos from '@containers/supply/SupplyLinePhotos';
import OrderDialog from '@containers/orders/OrderDialog';

const NotFound = React.lazy(() => import(/* webpackChunkName: "not-found" */ '@pages/NotFound'));

export const HOME_SUB_PATHS: { [key: string]: any } = {
  [SUPPLY_LINE_DETAIL_ROUTE_RECOMMENDATION]: () => (
    <SupplyLineDetailsDialog
      photoPath={'/supply-recommendation/:id/details/photos'}
      buyPath={'/supply-recommendation/:id/details/buy'}
      clockPresalesRequestPath={'/marketplace/:id/details/supply-clock-presales-request'}
    />
  ),
  [`${SUPPLY_LINE_DETAIL_ROUTE_RECOMMENDATION}/photos/:index?`]: () => <SupplyLinePhotos closeTo={SUPPLY_LINE_DETAIL_ROUTE_RECOMMENDATION} />,
  '/supply-recommendation/:id/photos/:index?': () => <SupplyLinePhotos closeTo="/" />,
  [`${SUPPLY_LINE_DETAIL_ROUTE_RECOMMENDATION}/buy`]: () => <OrderDialog closeTo={SUPPLY_LINE_DETAIL_ROUTE_RECOMMENDATION} />,
  '/clock-status': ClockStatusDialog,
  [NOT_AUTHORIZED_ROUTE]: NotAuthorizedDialog,
  '/404': () => <NotFound />,
};

export default function HomePage() {
  return (
    <CenterLayout>
      <Home />
      {Object.keys(HOME_SUB_PATHS).map(path => (
        <Route key={path} path={path} component={HOME_SUB_PATHS[path]} />
      ))}
    </CenterLayout>
  );
}
