import React, { RefObject } from 'react';
import classNames from 'classnames';
import { Body, MOUSE_OFFSET, Wrapper } from './styles';
import { IWithBreakpointProps, withBreakpoint } from '@containers/withBreakpoint';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type ITooltipProps = {
  children?: any;
  inline?: boolean;
  noPadding?: boolean;
  className?: string;
  onClose?: () => void;
  withArrow?: boolean;
} & IWithBreakpointProps &
  RouteComponentProps;

interface ITooltipState {
  mouseX: number;
  mouseY: number;
}

class TooltipComponent extends React.PureComponent<ITooltipProps, ITooltipState> {
  element: RefObject<HTMLElement> = React.createRef();

  state: ITooltipState = {
    mouseX: 0,
    mouseY: 0,
  };

  constructor(props: ITooltipProps) {
    super(props);

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onWindowClicked = this.onWindowClicked.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    const { inline } = this.props;

    if (!inline) {
      addEventListener('mousemove', this.onMouseMove);
      addEventListener('mouseenter', this.onMouseMove);
    } else {
      // Timeout needed to prevent closing the modal immediately after opening
      setTimeout(() => {
        addEventListener('click', this.onWindowClicked);
      });
    }
  }

  componentWillUnmount() {
    removeEventListener('mousemove', this.onMouseMove);
    removeEventListener('mouseenter', this.onMouseMove);
    removeEventListener('click', this.onWindowClicked);
  }

  componentDidUpdate(prevProps: ITooltipProps) {
    if (this.props.location !== prevProps.location) {
      this.close();
    }
  }

  render() {
    const { children, className, noPadding, inline, withArrow = true } = this.props;
    const { mouseX, mouseY } = this.state;
    const tooltipClass = classNames(className, {
      'no-padding': noPadding,
      'with-arrow': withArrow,
      inline,
    });

    const style = !inline ? this.getPosition() : null;

    if (inline || (mouseX > 0 && mouseY > 0)) {
      return (
        <Wrapper className={tooltipClass} style={style} role="dialog" ref={this.element as any}>
          <Body className="body">{children}</Body>
        </Wrapper>
      );
    }

    return null;
  }

  private getPosition() {
    if (!this.element || !this.element.current) {
      return null;
    }

    const { isBreakpointSmall } = this.props;
    const { mouseX, mouseY } = this.state;

    const leftPosition = mouseX + MOUSE_OFFSET;
    const topPosition = mouseY + MOUSE_OFFSET;
    const elementBoundingBox = this.element.current.getBoundingClientRect();
    const documentBoundingBox = document.body.getBoundingClientRect();
    const rightPosition = leftPosition + elementBoundingBox.width;
    const bottomPosition = topPosition + elementBoundingBox.height;

    const isExceedingRight = rightPosition > documentBoundingBox.right;
    const isExceedingBottom = bottomPosition > documentBoundingBox.bottom;

    return {
      left: isBreakpointSmall ? 0 : !isExceedingRight ? `${mouseX + MOUSE_OFFSET}px` : 'auto',
      right: isExceedingRight ? 0 : 'auto',
      top: !isExceedingBottom ? `${mouseY + MOUSE_OFFSET}px` : 'auto',
      bottom: isExceedingBottom ? 0 : 'auto',
    };
  }

  private onMouseMove(event: MouseEvent) {
    this.setState({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
  }

  private onWindowClicked(event: any) {
    if (this.element && this.element.current && !this.element.current.contains(event.target)) {
      this.close();
    }
  }

  private close() {
    const { onClose } = this.props;

    onClose && onClose();
  }
}

export default withRouter(withBreakpoint(TooltipComponent));
