import styled from 'styled-components';
import React from 'react';
import { outlineButtonStyles } from './styles';
import BaseButton, { IBaseButtonProps } from './BaseButton';

export type IOutlineButtonProps = {
  color?: 'black' | 'white';
} & IBaseButtonProps;

const OutlineButton = styled((props: any) => <BaseButton {...props} />)`
  ${outlineButtonStyles}
`;

export default OutlineButton;
