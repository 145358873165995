import IAppThunkAction from '@store/contracts/IAppThunkAction';
import {
  ISideBarVisibilityChangedAction,
  ILastCookieRefreshMomentChangedAction,
  IIsOnlineChangedAction,
  IIsSuppressErrorChangedAction,
} from './contracts/Actions';
import { Moment } from 'moment';
import reset from '../../common/reset';

export { default as IAppState } from './contracts/IAppState';

export type KnownAppAction =
  | IIsOnlineChangedAction
  | IIsSuppressErrorChangedAction
  | ISideBarVisibilityChangedAction
  | ILastCookieRefreshMomentChangedAction;

export const appActions = {
  setOnline: (isOnline: boolean): IAppThunkAction<KnownAppAction> => dispatch => {
    if (isOnline) {
      if (!window.location.toString().includes('scan')) {
        window.location.reload();
      }
    } else if (!window.location.toString().includes('marketplace') && !window.location.toString().includes('scan')) {
      reset(); // redirected/relocation from fmoCookie class. (question: why is this reset function called??)
    }
    dispatch({ isOnline, type: 'IS_ONLINE_CHANGED' });
  },
  setSuppressError: (isSuppressError: boolean): IAppThunkAction<KnownAppAction> => dispatch => {
    dispatch({ isSuppressError, type: 'IS_SUPPRESS_ERROR_CHANGED' });
  },
  setSideBarVisibility: (isOpen: boolean, userChanged: boolean = true): IAppThunkAction<KnownAppAction> => dispatch => {
    dispatch({ isOpen, userChanged, type: 'SIDE_BAR_VISIBILITY_CHANGED' });
  },
  setLastCookieRefreshMoment: (lastCookieRefreshMoment: Moment): IAppThunkAction<KnownAppAction> => dispatch => {
    dispatch({ lastCookieRefreshMoment, type: 'LAST_COOKIE_REFRESH_MOMENT_CHANGED' });
  },
};
