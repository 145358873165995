import { Action, Reducer } from 'redux';
import { KnownSupplyRecommendationEventAction } from '.';
import ISupplyRecommendationsState from './contracts/ISupplyRecommendationsState';

const unloadedState: ISupplyRecommendationsState = {
  loading: false,
  items: [],
};

export const supplyRecommendationReducer: Reducer<ISupplyRecommendationsState> = (state: ISupplyRecommendationsState, incomingAction: Action) => {
  const action = incomingAction as KnownSupplyRecommendationEventAction;

  switch (action.type) {
    case 'REQUEST_SUPPLY_RECOMMENDATIONS':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_SUPPLY_RECOMMENDATIONS':
      return {
        ...state,
        items: action.items,
        loading: false,
      };
    case 'RECOMMENDATION_EVENT':
      const items = state.items.map(item => {
        const recommendation = { ...item };
        if (recommendation.supplyLine.id === action.supplyRecommendation.supplyLine.id) {
          recommendation.viewed = true;
        }

        return recommendation;
      });

      return {
        ...state,
        items,
      };
  }

  return state || unloadedState;
};
