import { pxToRem, setFont } from '@styles/index';
import styled from 'styled-components';

export const SelectLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${pxToRem(15)};
  > span {
    ${setFont('bold')};
    margin-right: ${pxToRem(15)};
  }
`;

export const Table = styled.table`
  width: 100%;
  th,
  td {
    line-height: ${pxToRem(35)};
    padding-left: ${pxToRem(15)};
  }
  th {
    ${setFont('bold')};
    background-color: ${props => props.theme.colors.alto};
  }
  td {
    border-bottom: ${pxToRem(1)} solid ${props => props.theme.colors.alto};
  }
`;
