import { translateEnum } from '@common/enum';

enum TradeInstrument {
  DirectSales = 'DirectSales',
  ClockPresales = 'ClockPresales',
  Clock = 'Clock',
  ManualOrder = 'ManualOrder',
}

export function translateTradeInstrument(key: TradeInstrument, name: string = 'TradeInstrument') {
  return translateEnum(name, key);
}

export default TradeInstrument;
