import styled from 'styled-components';

export const DateTimeWrapper = styled.div`
  &.with-time {
    .input {
      &.date {
        width: 65%;
      }
      &.time {
        width: 35%;
      }
    }
  }
`;
