import styled from 'styled-components';
import { breakpoint, pxToRem, rgbToRgba } from '@styles';

export const BACKDROP_Z_INDEX = 200;

export const BackDrop = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: ${BACKDROP_Z_INDEX};
  position: fixed;
  top: 0;
  left: 0;
  ${props => `
    ${breakpoint('m')`
      display: flex;
      flex-direction: column;
      align-items: center;
      background: ${rgbToRgba(props.theme.colors.black, 0.3)};
      &::before, &::after {
        display: block;
        width: 100%;
        height: 5%;
        max-height: ${pxToRem(50)};
        content: "";
      }
    `}
    ${breakpoint('l')`
      &::before, &::after {
        height: 10%;
        max-height: ${pxToRem(100)};
      }
    `}
  `}
`;

export const Dialog = styled.aside`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 ${pxToRem(20)} 0 ${props => rgbToRgba(props.theme.colors.black, 0.2)};
  width: 100%;
  height: 100%;
  padding: ${pxToRem(15)};
  ${breakpoint('m')`
    overflow: hidden;
    width: ${pxToRem(605)};
    padding: ${pxToRem(30)};
    max-width: calc(100vw - 5%);
    height: auto;
    max-height: 100%;
    .size-s & {
      width: ${pxToRem(500)};
    }
    .size-l & {
      width: ${pxToRem(914)};
    }
  `}
  ${breakpoint('l')`
    max-width: calc(100vw - 10%);
  `}
  .button-close {
    position: absolute;
    top: ${pxToRem(5)};
    right: ${pxToRem(5)};
    z-index: 1;
  }
  .body-header {
    margin-bottom: ${pxToRem(20)};
  }
`;

export const Header = styled.header`
  ${breakpoint('m')`
    margin-bottom: ${pxToRem(25)};
  `}
  .title {
    margin-bottom: ${pxToRem(15)};
  }
`;

export const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: ${pxToRem(15)};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%; /* Fixes IE11 issue with flexbox where overflow was not handled correctly in the modal */
`;

export const Body = styled.div`
  overflow-y: auto;
  margin-bottom: ${pxToRem(15)};
  &.overflowed {
    padding-right: ${pxToRem(20)};
  }
  ${breakpoint('m')`
    margin-bottom: ${pxToRem(20)};
    .fixed-content & {
      display: flex;
      overflow: hidden;
    }
  `}
`;

export const Footer = styled.footer`
  border-top: ${pxToRem(1)} solid ${props => props.theme.colors.alto};
  padding-top: ${pxToRem(15)};
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  .button {
    margin: 0;
    &:only-child {
      margin-left: auto;
    }
  }
  ${breakpoint('m')`
     padding-top: ${pxToRem(20)};
  `}
`;
