import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@components/shared/Modal';
import Icon from '@components/shared/Icon';
import { IconType } from '@enums';
import { Content, Strong, Wrapper } from './styles';

export default function NotAuthorizedDialog() {
  const { t } = useTranslation('general');

  return (
    <Modal autoClose closeTo={{ pathname: '/' }} size="l" title={t('authorization.dialogTitle')}>
      <Wrapper>
        <Icon type={IconType.Info} rotate={180} />
        <Content>
          <p>{t('authorization.dialogContent')}</p>
          <Strong>{location.href}</Strong>
        </Content>
      </Wrapper>
    </Modal>
  );
}
