import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { HOME_ROUTE, ORDER_ROUTE, SETTINGS_ROUTE, SUPPLY_ROUTE, HELP_ROUTE, SCAN_ROUTE } from '@root/routes';
import AuthenticatedRoute from '@containers/app/AuthenticatedRoute';
import { useBreakpoint } from '@containers/withBreakpoint';
import { HOME_SUB_PATHS } from '@pages/Home';

const HomePage = React.lazy(() => import(/* webpackChunkName: "home" */ '@pages/Home'));
const MarketPlace = React.lazy(() => import(/* webpackChunkName: "marketplace" */ '@pages/MarketPlace'));
const Transactions = React.lazy(() => import(/* webpackChunkName: "transactions" */ '@pages/Transactions'));
const Help = React.lazy(() => import(/* webpackChunkName: "help" */ '@pages/Help'));
const Settings = React.lazy(() => import(/* webpackChunkName: "settings" */ '@pages/Settings'));
const Scan = React.lazy(() => import(/* webpackChunkName: "scan" */ '@pages/Scan'));

export default function Routes() {
  const { isBreakpointLarge } = useBreakpoint();

  return (
    <Switch>
      <AuthenticatedRoute path={[HOME_ROUTE, ...Object.keys(HOME_SUB_PATHS)]} exact component={HomePage} />
      <AuthenticatedRoute path={SUPPLY_ROUTE} component={MarketPlace} />
      <AuthenticatedRoute path={ORDER_ROUTE} component={Transactions} />
      <AuthenticatedRoute path={SETTINGS_ROUTE} component={Settings} />
      <AuthenticatedRoute path={`${HELP_ROUTE}/:slug?`} component={Help} />
      {!isBreakpointLarge && <AuthenticatedRoute path={`${SCAN_ROUTE}`} component={Scan} />}

      <Route path="*" render={() => <Redirect to={`/404?location=${location.href}`} />} />
    </Switch>
  );
}
