import React, { RefObject } from 'react';
import { Picture, Img } from './styles';
import classNames from 'classnames';

const DEFAULT_IMAGE = '/images/fallback-image.png';
const OBSERVER_SUPPORTED = 'IntersectionObserver' in window;
const FLORIDAY_IMAGE_PATTERN = 'floriday.(io|com)/images/';

interface IImageProps extends React.HTMLProps<HTMLImageElement> {
  fit?: boolean;
  rounded?: boolean;
  sources?: any[];
}

interface IImageState {
  isError: boolean;
  inView: boolean;
}

export default class Image extends React.PureComponent<IImageProps, IImageState> {
  observer: IntersectionObserver;

  element: RefObject<HTMLElement> = React.createRef();

  state: IImageState = {
    isError: !this.props.src,
    inView: !OBSERVER_SUPPORTED,
  };

  constructor(props: IImageProps) {
    super(props);

    this.onError = this.onError.bind(this);
    this.onIntersectionChanged = this.onIntersectionChanged.bind(this);
  }

  static getDerivedStateFromProps(nextProps: IImageProps, currentState: IImageState): IImageState {
    return {
      ...currentState,
      isError: !nextProps.src,
    };
  }

  componentDidMount() {
    if (OBSERVER_SUPPORTED) {
      this.observer = new IntersectionObserver(this.onIntersectionChanged, { threshold: 0.1 });
      this.observer.observe(this.element.current);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.unobserve(this.element.current);
      this.observer = null;
    }
  }

  render() {
    const { alt, title, className, fit, rounded, onClick } = this.props;
    const pictureClass = classNames('image', className);
    const imgClass = classNames({ fit, rounded });
    const url = this.getImageUrl();
    const isFloridayImage = this.isFloridayImage(url);

    return (
      <Picture ref={this.element as any} className={pictureClass}>
        {isFloridayImage && this.getSources(url)}
        <Img src={this.getSource(url, isFloridayImage)} alt={alt} title={title} className={imgClass} onError={this.onError} onClick={onClick} />
      </Picture>
    );
  }

  private getSource(url: string, isFloridayImage: boolean) {
    const { width, height } = this.props;

    if (isFloridayImage) {
      return this.addDimensions(url, width as number, height as number);
    }

    return url;
  }

  private getSources(url: string) {
    const { sources } = this.props;
    const result: any[] = [];

    const addSource = (source: any) => {
      Object.keys(source.media).forEach(key => {
        const mediaValue = source.media[key];
        result.push(getSourceComponent(key, mediaValue, source.width));
      });
    };

    const getSourceComponent = (query: string, value: string, width: number) => {
      const media = `(${query}: ${value}px)`;

      return <source key={media + width} media={media} srcSet={this.addDimensions(url, width)} />;
    };

    sources &&
      sources.map(source => {
        if (source.from) {
          for (let i = source.from, y = source.width; i <= y; i += source.step) {
            Object.keys(source.media).forEach(key => {
              result.push(getSourceComponent(key, i, i));
            });
          }
        }

        addSource(source);
      });

    return result;
  }

  private getImageUrl() {
    const { src } = this.props;
    const { isError, inView } = this.state;

    return !isError && inView ? src : DEFAULT_IMAGE;
  }

  private isFloridayImage(url: string) {
    const regex = RegExp(FLORIDAY_IMAGE_PATTERN);

    return regex.test(url);
  }

  private addDimensions(source: string, width: number, height: number = null) {
    if (width || height) {
      const params = ['crop=fill'];
      const containsSearchParams = source.indexOf('?') !== -1;
      const searchParamToken = containsSearchParams ? '&' : '?';

      if (width) {
        params.push(`width=${width}`);
      }

      if (height) {
        params.push(`height=${height}`);
      }

      return `${source}${searchParamToken}${params.join(';')}`;
    }

    return source;
  }

  private onIntersectionChanged(entries: IntersectionObserverEntry[]) {
    if (entries.some(entry => entry.intersectionRatio > 0)) {
      this.setState({
        inView: true,
      });
    }
  }

  private onError() {
    this.setState({
      isError: true,
    });
  }
}
