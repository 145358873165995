import React, { useEffect } from 'react';
import ErrorDialog from '@containers/shared/ErrorDialog';
import NotificationToastGroup from '@containers/shared/NotificationToastGroup';
import ActionToastGroup from '@containers/shared/ActionToastGroup';

const USING_KEYBOARD_CLASSNAME = 'using-keyboard';

export default function AppLayout({ children }: React.HTMLProps<HTMLElement>) {
  useEffect(() => {
    const { addEventListener, removeEventListener } = window;

    document.body.classList.add(USING_KEYBOARD_CLASSNAME);

    addEventListener('mousedown', onMouseDown);
    addEventListener('keydown', onKeyDown);

    return () => {
      removeEventListener('mousedown', onMouseDown);
      removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <>
      {children}

      <ActionToastGroup />

      <NotificationToastGroup />

      <ErrorDialog />
    </>
  );
}

function onMouseDown() {
  document.body.classList.remove(USING_KEYBOARD_CLASSNAME);
}

function onKeyDown() {
  document.body.classList.add(USING_KEYBOARD_CLASSNAME);
}
