import React from 'react';
import { UserConnect } from '@containers/shared/Connect';
import config from '@common/config';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AccountType, IconType } from '@enums/index';
import { Button } from '@components/shared/Button';

interface IHelpLinkProps extends React.HTMLProps<HTMLAnchorElement>, WithTranslation {
  configKey: string;
  accountType?: AccountType;
  label: string;
  showIcon?: boolean;
}

export function HelpLink({ t, configKey, accountType, label, target, showIcon, className }: IHelpLinkProps) {
  const { pages } = config.help;

  return (
    <UserConnect>
      {({ user }) =>
        (!accountType || user.accountType === accountType) && (
          <Button
            to={pages[configKey][user.language.toLowerCase()]}
            title={t('general:goToHelp')}
            label={label}
            className={`help-link ${className}`}
            target={target}
            icon={showIcon && { type: IconType.Arrow, rotation: -90 }}
          />
        )
      }
    </UserConnect>
  );
}

export default withTranslation(['general'])(HelpLink);
