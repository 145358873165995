import styled from 'styled-components';
import { breakpoint, BREAKPOINTS_IN_PX, HEADER_HEIGHT, MAX_VIEWPORT_WIDTH, pxToRem, setFont } from '@styles';
import { LAYOUT_ANIMATION_DURATION } from '@containers/layout/ColumnLayout/styles';
import { PageHeading } from '@components/shared/Heading';

const DEFAULT_LEFT_COLUMN_WIDTH = 300;

export const StyledCenterLayout = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: inline-block;
  height: inherit;
  position: relative;
  vertical-align: top;
  width: 100%;
  margin-top: ${pxToRem(HEADER_HEIGHT.s + 10)};
  ${breakpoint('m')`
    overflow-y: auto;
    margin-top: ${pxToRem(HEADER_HEIGHT.m + 10)};
  `}
  ${breakpoint('l')`
    margin-top: ${pxToRem(HEADER_HEIGHT.l + 20)};
    overflow-y: visible;
  `}
`;

export const Column = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
  transition: width ${LAYOUT_ANIMATION_DURATION}ms;
  ${breakpoint('l')`
    padding: ${pxToRem(20)} ${pxToRem(20)} 0 0;
  `}
`;

export const LeftColumn = styled(Column)`
  margin-bottom: ${pxToRem(30)};
  ${breakpoint('l')`
    width: ${pxToRem(DEFAULT_LEFT_COLUMN_WIDTH)};
    position: relative;
    left:auto;
    margin-bottom: 0;
  `}
`;

export const RightColumn = styled(Column)`
  position: relative;
  ${breakpoint('l')`
    width: ${pxToRem(BREAKPOINTS_IN_PX.l - DEFAULT_LEFT_COLUMN_WIDTH)};
  `}
`;

export const FixedWidthWrapper = styled.div`
  max-width: ${pxToRem(MAX_VIEWPORT_WIDTH)};
  margin: auto;
  ${breakpoint('l')`
    width: ${pxToRem(BREAKPOINTS_IN_PX.l)};
  `}
`;

interface IStyledSectionProps {
  withBackground?: boolean;
}

export const StyledSection = styled.section<IStyledSectionProps>`
  display: block;
  padding: ${pxToRem(20)};
  ${props =>
    props.withBackground
      ? `
    background-color: ${props.theme.colors.wildSand};
  `
      : null}
  section {
    margin-bottom: ${pxToRem(30)};
    header {
      margin-bottom: ${pxToRem(15)};
    }
  }
`;

export const StyledHeader = styled(StyledSection)`
  background: url('/images/main-background.jpg') no-repeat center center fixed;
  background-size: cover;
`;

export const StyledFooter = styled(StyledSection)`
  border-top: ${pxToRem(40)} solid ${props => props.theme.colors.redViolet};
  background-color: ${props => props.theme.colors.wildSand};
  .image {
    width: 100%;
    max-width: ${pxToRem(200)};
    margin-top: ${pxToRem(30)};
    ${breakpoint('m')`
      margin-top: 0;
    `}
  }
`;

export const StyledTitle = styled(PageHeading)`
  display: inline-block;
  ${setFont('thin')};
  letter-spacing: -${pxToRem(1)};
  font-size: ${pxToRem(38)};
  font-weight: lighter;
  color: ${props => props.theme.colors.white};
  line-height: ${pxToRem(46)};
  margin-bottom: ${pxToRem(5)};
  ${breakpoint('m')`
    font-size: ${pxToRem(64)};
    line-height: ${pxToRem(64)};
  `}
  > span {
    ${setFont('bold')};
    font-weight: 900;
  }
`;

export const InlineFooter = styled.div`
  padding-top: ${pxToRem(30)};
  border-top: ${pxToRem(1)} solid ${props => props.theme.colors.alto};
  margin: ${pxToRem(15)} 0 ${pxToRem(30)} 0;
`;
