import React from 'react';
import { matchPath } from 'react-router';
import { HOME_ROUTE, ORDER_ROUTE, SCAN_ROUTE, SETTINGS_ROUTE, SUPPLY_ROUTE } from '@root/routes';
import { IUser } from '@models';
import { UserConnect } from '@containers/shared/Connect';

type IPath = string | string[];

export interface IWithAuthorizationProps {
  authorizedForPath: (path: IPath) => boolean;
}

type WithoutPrefilled<T extends IWithAuthorizationProps> = Pick<T, Exclude<keyof T, keyof IWithAuthorizationProps>>;

export function withAuthorization<P extends IWithAuthorizationProps>(
  UnwrappedComponent: React.ComponentType<P>,
): React.ComponentType<WithoutPrefilled<P>> {
  return function WithAuthorization(props: WithoutPrefilled<P>) {
    return (
      <UserConnect>
        {({ user }) => <UnwrappedComponent {...(props as any)} authorizedForPath={authorizedForPath.bind(authorizedForPath, user)} />}
      </UserConnect>
    );
  };
}

function authorizedForPath(user: IUser, path: IPath) {
  const { authorization } = user;

  const props = { path };
  const p = typeof path === 'string' ? path : path[0];

  if (path === HOME_ROUTE) {
    return true;
  }

  if (matchPath(SUPPLY_ROUTE, props)) {
    return authorization.supply.read;
  }

  const scanMatch = matchPath(`${SUPPLY_ROUTE}/scan`, props);
  if (scanMatch && scanMatch.isExact) {
    return authorization.scan.read;
  }

  if (matchPath(SCAN_ROUTE, props)) {
    return authorization.scan.read;
  }

  if (matchPath(ORDER_ROUTE, props)) {
    return authorization.order.read;
  }

  if (matchPath(SETTINGS_ROUTE, props)) {
    return authorization.account.read;
  }

  if (matchPath(`${SETTINGS_ROUTE}/accounts`, props)) {
    return authorization.linkedAccounts.read;
  }

  if (matchPath(`${SETTINGS_ROUTE}/account/*`, props)) {
    return authorization.linkedAccounts.read;
  }

  if (matchPath(p, { path: '*buy' })) {
    return authorization.order.write;
  }

  return authorization.isAuthorized;
}
