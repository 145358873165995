import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IOrderLine, ISupplyLine } from '@models';
import Image from '@components/shared/Image';
import ImageCarousel from '@components/shared/ImageCarousel';
import { Wrapper } from './styles';

interface IModelDetailMainPhotoProps extends React.HTMLProps<HTMLDivElement>, WithTranslation {
  model: ISupplyLine | IOrderLine;
  width?: number;
  height?: number;
  sources?: any[];
  className?: string;
  photoPath: string;
  noCarousel?: boolean;
  onClick?: () => void;
}

export function ModelDetailMainPhoto(props: IModelDetailMainPhotoProps) {
  const {
    t,
    model: { tradeItem },
    width,
    height,
    sources,
    className,
    photoPath,
    onClick,
    noCarousel,
  } = props;
  const images = tradeItem.assets.map(asset => {
    return {
      url: asset.url,
      title: tradeItem.growerProduct.name,
      alt: tradeItem.growerProduct.name,
    };
  });

  return (
    <Wrapper to={{ pathname: photoPath, search: true }} className={className}>
      {noCarousel ? (
        <Image
          src={images[0] && images[0].url}
          width={width}
          height={height}
          sources={sources}
          alt={images[0] && images[0].alt}
          title={images[0] && images[0].title}
          onClick={event => {
            if (onClick) {
              event.preventDefault();
              onClick();
            }
          }}
        />
      ) : (
        <ImageCarousel onClick={onClick} className="carousel" images={images} width={width} height={height} sources={sources} />
      )}
      <span className="visually-hidden">{t('photos.enlargeImage')}</span>
    </Wrapper>
  );
}

export default withTranslation()(ModelDetailMainPhoto);
