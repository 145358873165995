import { translateEnum } from '@common/enum';

enum SupplyColumnType {
  MarkSelected = 'MarkSelected',
  ProductName = 'ProductName',
  GrowerProductName = 'GrowerProductName',
  SelectDelivery = 'SelectDelivery',
  Supplier = 'Supplier',
  Price = 'Price',
  Characteristics = 'Characteristics',
  Deliveries = 'Deliveries',
  AvailablePackages = 'AvailablePackages',
  Load = 'Load',
  DeliveryForm = 'DeliveryForm',
  InstantBuy = 'InstantBuy',
  CreationDate = 'CreationDate',
  ClockPresales = 'ClockPresales',
  TrolleyLocations = 'TrolleyLocations',
}

export function translateSupplyColumnType(key: SupplyColumnType, name: string = 'SupplyColumnType') {
  return translateEnum(name, key);
}

export default SupplyColumnType;
