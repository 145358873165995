import { Action, Reducer } from 'redux';
import { KnownAppAction } from './';
import IAppState from './contracts/IAppState';

const unloadedState: IAppState = {
  isOnline: true,
  suppressError: false,
  sideBar: {
    isOpen: false,
    userChanged: false,
  },
  lastCookieRefreshMoment: null,
};

export const appReducer: Reducer<IAppState> = (state: IAppState, incomingAction: Action) => {
  const action = incomingAction as KnownAppAction;

  switch (action.type) {
    case 'IS_ONLINE_CHANGED':
      return {
        ...state,
        isOnline: action.isOnline,
      };
    case 'IS_SUPPRESS_ERROR_CHANGED':
      return {
        ...state,
        suppressError: action.isSuppressError,
      };
    case 'SIDE_BAR_VISIBILITY_CHANGED':
      return {
        ...state,
        sideBar: {
          ...state.sideBar,
          ...action,
        },
      };
    case 'LAST_COOKIE_REFRESH_MOMENT_CHANGED':
      return {
        ...state,
        lastCookieRefreshMoment: action.lastCookieRefreshMoment,
      };
  }

  return state || unloadedState;
};
