import qs from 'query-string';
import { OrderFilterType, SortDirection } from '@enums';
import ISelectedOrderFilter from '@store/order-filter/contracts/ISelectedOrderFilter';
import IOrderFilterState from '@store/order-filter/contracts/IOrderFilterState';

const FILTER_TYPE_TO_QUERY_PROP: { [key: string]: string } = {
  [OrderFilterType.Account]: 'account',
  [OrderFilterType.DeliveryLocation]: 'deliveryLocationId',
  [OrderFilterType.TradeInstrument]: 'tradeInstruments',
  [OrderFilterType.PeriodStart]: 'start',
  [OrderFilterType.PeriodEnd]: 'end',
};

interface IOrderQueryProps {
  [key: string]: any;
}

export default function getOrderUrl(path: string, filterState: IOrderFilterState = null, activeAdministrationNumber: number) {
  const queryProps: IOrderQueryProps = {};

  if (filterState) {
    const {
      selected: {
        settings: { mainGroups },
        filters,
        grouping,
      },
    } = filterState;

    if (mainGroups) {
      queryProps.mainGroups = mainGroups;
    }

    if (filters) {
      filters.forEach((filter: ISelectedOrderFilter) => {
        queryProps[FILTER_TYPE_TO_QUERY_PROP[filter.type]] = filter.value;
      });
    }

    if (grouping) {
      const direction = grouping.direction === SortDirection.Ascending ? '+' : '-';

      queryProps.sort = `${direction}${grouping.field}`;
    }
  }

  const queryPropsAsString = qs.stringify(queryProps);

  return `${path}?administrationNumber=${activeAdministrationNumber}${queryPropsAsString.length > 0 ? `&${queryPropsAsString}` : ''}`;
}
