import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { store } from './configureStore';
import { theme } from '@styles';
import GlobalStyles from './styles/Global';
import config from '@common/config';
import BootStrapper from './Bootstrapper';
import 'core-js';
import initializeWindowEventListners from './common/window';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { setCookie } from './common/cookie';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const { okta } = config;

const oktaAuth = new OktaAuth(okta);
oktaAuth.tokenManager.on('renewed', async (key: string, newToken: any) => {
  if (key === 'accessToken') {
    setCookie('oktaToken', newToken.accessToken);
  }
});

async function restoreOriginalUri(_: any, originalUri: any) {
  history.replace(toRelativeUrl(originalUri, window.location.origin));
  location.reload();
}

console.info(
  // eslint-disable-next-line
  "______ _                ___  ___                _\n|  ___| |               |  \\/  |               | |\n| |_  | | ___  _ __ __ _| .  . | ___  _ __   __| | ___\n|  _| | |/ _ \\| '__/ _` | |\\/| |/ _ \\| '_ \\ / _` |/ _ \\\n| |   | | (_) | | | (_| | |  | | (_) | | | | (_| | (_) |\n\\_|   |_|\\___/|_|  \\__,_\\_|  |_/\\___/|_| |_|\\__,_|\\___/",
);

// log loglocation
console.info(`${config.env} - version: ${config.version} (${document.location.origin}/changelog.md)`);
initializeWindowEventListners();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Switch>
            <Route path="/implicit/callback" component={LoginCallback} />
            <SecureRoute path="/" component={BootStrapper} />
          </Switch>
        </Security>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('app'),
);
