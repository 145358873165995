import moment from 'moment';
import { store } from '@root/configureStore';

export function filterDeliveryOptions<T>(options: T[], callback: (option: T) => any) {
  const { supplyFilter } = store.getState();
  const { delivery } = supplyFilter.selected.settings;
  const hasTime = !moment(delivery.moment).startOf('day').isSame(delivery.moment);

  return options.filter(o => !hasTime || moment(callback(o)).isSameOrBefore(delivery.moment));
}
