import styled from 'styled-components';
import { ActionToast } from '@components/app/Toast';

export const ToastWrapper = styled(ActionToast)`
  &.is-side-bar-open-with-breakpoint-small {
    right: 0px;
    width: 50%;
    .button {
      width: 100%;
    }
  }
`;
