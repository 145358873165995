import { get } from '@common/fetch';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { IReceiveEvents, IRequestEvents } from './contracts/Actions';
import moment from 'moment';

export { default as IEventState } from './contracts/IEventState';

export type KnownEventAction = IRequestEvents | IReceiveEvents;

export const eventActions = {
  getToday: (): IAppThunkAction<KnownEventAction> => async (dispatch, getState) => {
    const requestPath = `events?offset=0&limit=7&from=${moment().format('YYYY-MM-DD')}`;
    const { path: statePath } = getState().event;

    if (requestPath !== statePath) {
      dispatch({ type: 'REQUEST_EVENTS', path: requestPath });

      const response = await get(requestPath);

      if (response.ok) {
        dispatch({
          items: await response.json(),
          type: 'RECEIVE_EVENTS',
        });
      }
    }
  },
};
