enum OrderSortingField {
  ProductName = 'tradeItem.growerProduct.vbnProduct.shortName',
  GrowerProductName = 'tradeItem.growerProduct.name',
  TransactionMoment = 'createdAt',
  DeliveryMoment = 'delivery.moment',
  DeliveryLocation = 'delivery.location.name',
  Supplier = 'tradeItem.supplier.name',
  Account = 'buyerOrder.buyer.name',
  PricePerItem = 'pricePerItem',
  Price = 'price',
  CustomerPricePerItem = 'customerPricePerItem',
  CustomerPrice = 'customerPrice',
  OrderFee = 'orderFee',
  PackageCode = 'tradeItem.packing.package.code',
}

export default OrderSortingField;
