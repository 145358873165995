import { Action, Reducer } from 'redux';
import IBannerState from './contracts/IBannerState';
import { KnownBannerAction } from './';

const unloadedState: IBannerState = {
  item: null,
  path: null,
};

export const bannerReducer: Reducer<IBannerState> = (state: IBannerState, incomingAction: Action) => {
  const action = incomingAction as KnownBannerAction;

  switch (action.type) {
    case 'REQUEST_BANNER':
      return {
        ...state,
      };
    case 'RECEIVE_BANNER':
      return {
        ...state,
        item: action.item,
      };
  }

  return state || unloadedState;
};
