import React, { useEffect } from 'react';
import { Component, RecommendationItem, BottomWrapper, Footer, Info, InfoLine, Name } from './styles';
import TradeInstrumentIndicator from '@components/shared/TradeInstrumentIndicator';
import ColorIndicator from '@components/shared/ColorIndicator';
import Icon from '@components/shared/Icon';
import { IconType } from '@enums/index';
import { useTranslation } from 'react-i18next';
import PriceOutput from '@components/shared/PriceOutput';
import TradeInstrument from '@enums/TradeInstrument';
import SupplyLineTrading from '@containers/shared/SupplyLineTrading';
import ModelDetailMainPhoto from '@components/shared/ModelDetailMainPhoto';
import Link from '@components/shared/Link';
import ISupplyRecommendation from '@models/ISupplyRecommendation';
import Carousel from '@components/shared/Carousel';
import { supplyRecommendationActions } from '@store';
import { useDispatch } from 'react-redux';
import { useBreakpoint } from '@containers/withBreakpoint';

interface IRecommendationListProps {
  supplyRecommendations: ISupplyRecommendation[];
}

export default function RecommendationList({ supplyRecommendations }: IRecommendationListProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['home', 'general']);
  const { isBreakpointSmall } = useBreakpoint();
  const visibleItems = isBreakpointSmall ? 1 : 2;

  useEffect(() => {
    if (supplyRecommendations.length > 0) {
      onIndexChange(0);
    }
  }, []);

  return (
    <Component>
      {supplyRecommendations.length <= 0 && <span>{t('recommendations.notFound')}</span>}
      <Carousel visibleItems={visibleItems} onIndexChange={onIndexChange}>
        {getRecommendationElements(supplyRecommendations)}
      </Carousel>
    </Component>
  );

  function onIndexChange(value: number) {
    const viewedItems = supplyRecommendations.length > visibleItems ? visibleItems : supplyRecommendations.length;
    Array.apply(null, Array(viewedItems)).map((_x: any, i: number) => {
      dispatch(supplyRecommendationActions.createRecommendationEvent('viewed', supplyRecommendations[value + i].supplyLine.id));
    });
  }
}

function getRecommendationElements(supplyRecommendations: ISupplyRecommendation[]) {
  const { t } = useTranslation(['home', 'general']);

  return supplyRecommendations.map(recommendation => {
    const { id, tradeInstrument, tradeItem, fulfillment } = recommendation.supplyLine;

    return (
      <RecommendationItem key={id}>
        <Info>
          <InfoLine>
            <TradeInstrumentIndicator tradeInstrument={tradeInstrument} />
            <ColorIndicator color={tradeItem.growerProduct.vbnProduct.mainColor.rgb} />
            <Name>
              <span className="ellipsify">{tradeItem.growerProduct.vbnProduct.shortName}</span>
              <span>&nbsp;({tradeItem.growerProduct.vbnProduct.code})</span>
            </Name>
          </InfoLine>
          <InfoLine>
            <span className="ellipsify">{tradeItem.supplier.name}</span>
            {fulfillment.isSelectDelivery && <Icon type={IconType.Truck} title={t('general:selectDelivery.title')} />}
          </InfoLine>
        </Info>

        <ModelDetailMainPhoto
          model={recommendation.supplyLine}
          className="main-photo"
          photoPath={`/supply-recommendation/${recommendation.supplyLine.id}/photos`}
          sources={[
            { media: { 'max-width': 710 }, width: 710, step: 100, from: 400 },
            { media: { 'min-width': 710 }, width: 470 },
          ]}
          noCarousel
        />

        <Footer>
          <BottomWrapper>
            <SupplyLineTrading supplyLine={recommendation.supplyLine}>
              {({ price }) => <PriceOutput price={price} alternate={recommendation.supplyLine.tradeInstrument === TradeInstrument.Clock} />}
            </SupplyLineTrading>
            <Link to={{ pathname: `/supply-recommendation/${id}/details`, search: true }} themed>
              {t('recommendations.productDetails')}
            </Link>
          </BottomWrapper>
        </Footer>
      </RecommendationItem>
    );
  });
}
