import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState, INotificationState, notificationActions } from '@store';
import { IPushNotification } from '@models';
import { NotificationToast, ToastGroup } from '@components/app/Toast';

type INotificationToastGroupProps = INotificationState & typeof notificationActions;

export class NotificationToastGroup extends React.PureComponent<INotificationToastGroupProps> {
  constructor(props: INotificationToastGroupProps) {
    super(props);

    this.onToastClosed = this.onToastClosed.bind(this);
  }

  render() {
    const { pushNotifications } = this.props;

    return (
      <ToastGroup>
        {pushNotifications.map((notification, index) => (
          <NotificationToast key={`${notification.title}-${index}`} notification={notification} onClose={this.onToastClosed} />
        ))}
      </ToastGroup>
    );
  }

  private onToastClosed(notification: IPushNotification) {
    const { removePushNotification } = this.props;

    removePushNotification(notification);
  }
}

export default connect((state: IApplicationState) => state.notifications, notificationActions)(NotificationToastGroup);
