import { Action, Reducer } from 'redux';
import { KnownNewsAction } from './';
import INewsState from './contracts/INewsState';

const unloadedState: INewsState = { items: [], loading: false, moreNewsUrl: '' };

export const newsReducer: Reducer<INewsState> = (state: INewsState, incomingAction: Action) => {
  const action = incomingAction as KnownNewsAction;

  switch (action.type) {
    case 'REQUEST_NEWS':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_NEWS':
      return {
        ...state,
        items: action.items,
        loading: false,
        moreNewsUrl: action.moreNewsUrl,
      };
  }

  return state || unloadedState;
};
