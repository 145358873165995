import { get, post } from '@common/fetch';
import parseLink from 'parse-link-header';
import qs from 'query-string';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import {
  IClearSelectedSupplyLinesAction,
  IReceiveSupplyAction,
  IReceiveSupplyLineAction,
  IReceiveSupplyLineFailedAction,
  IRequestFreshSupplyAction,
  IRequestSupplyAction,
  IRequestSupplyLineAction,
  ISetSupplyColumnLayoutAction,
  IToggleSelectedSupplyLineAction,
  IRequestClockPresalesAction,
  IRequestClockPresalesFailedAction,
  IRequestDeliveryFormSupply,
  IReceiveDeliveryFormSupply,
  IReceiveDeliveryFormSupplyFailed,
  IResetDeliveryFormSupply,
} from './contracts/Actions';
import { IReceiveOrderConditionsFailed } from '../order/contracts/Actions';
import getSupplyUrl, { ISupplyQueryProps } from './getSupplyUrl';
import getSupplyLine from './getSupplyLine';
import { ISupplyLine } from '@models';
import { KnownNotificationAction } from '@store/notification';
import { IColumnLayoutItem } from '@contracts';
import { getDefaultDeliveryMoment } from '@common/deliveryMoment';
import { getPathName } from '@common/url';
import { getActiveAdministrationNumber } from '@store/user';

export { default as ISupplyState } from './contracts/ISupplyState';

export type KnownSupplyAction =
  | IRequestSupplyAction
  | IReceiveSupplyAction
  | IRequestFreshSupplyAction
  | IRequestSupplyLineAction
  | IReceiveSupplyLineAction
  | IReceiveSupplyLineFailedAction
  | ISetSupplyColumnLayoutAction
  | IToggleSelectedSupplyLineAction
  | IClearSelectedSupplyLinesAction
  | KnownNotificationAction
  | IReceiveOrderConditionsFailed
  | IRequestClockPresalesAction
  | IRequestClockPresalesFailedAction
  | IRequestDeliveryFormSupply
  | IReceiveDeliveryFormSupply
  | IReceiveDeliveryFormSupplyFailed
  | IResetDeliveryFormSupply;

export const supplyActions = {
  getNextSupply: (): IAppThunkAction<KnownSupplyAction> => async (dispatch, getState) => {
    const {
      all: { nextLink },
    } = getState().supply;

    if (nextLink) {
      const pathname = getPathName(nextLink);
      const path = nextLink.replace(pathname, 'supply');

      getSupply(path, true)(dispatch, getState);
    }
  },
  getSupply: (fresh: boolean = false, limit: number = 50): IAppThunkAction<KnownSupplyAction> => async (dispatch, getState) => {
    const path = getSupplyUrl('supply', getState, { limit });

    if (fresh) {
      dispatch({ type: 'REQUEST_FRESH_SUPPLY' });
    }

    getSupply(path)(dispatch, getState);
  },
  getSupplyLine: (id: string): IAppThunkAction<KnownSupplyAction> => async (dispatch, getState) => {
    const {
      detail: { lines },
    } = getState().supply;
    const existingLine = lines[id];

    if (!existingLine) {
      dispatch({ type: 'REQUEST_SUPPLY_LINE' });

      const supplyLine = await getSupplyLine(id);
      if (supplyLine) {
        dispatch({
          type: 'RECEIVE_SUPPLY_LINE',
          supplyLine,
        });
      } else {
        dispatch({
          type: 'RECEIVE_SUPPLY_LINE_FAILED',
        });
      }
    }
  },
  setColumnLayout: (columnLayout: IColumnLayoutItem[]): IAppThunkAction<KnownSupplyAction> => async dispatch => {
    dispatch({ columnLayout, type: 'SET_SUPPLY_COLUMN_LAYOUT' });
  },
  toggleSelectedSupplyLine: (supplyLine: ISupplyLine, selected: boolean): IAppThunkAction<KnownSupplyAction> => async dispatch => {
    dispatch({ type: 'TOGGLE_SELECTED_SUPPLY_LINE', supplyLine, selected });
  },
  clearSelectedSupplyLines: (): IAppThunkAction<KnownSupplyAction> => async dispatch => {
    dispatch({ type: 'CLEAR_SELECTED_SUPPLY_LINES' });
  },
  requestClockPresalesSupply: (supplyLine?: ISupplyLine): IAppThunkAction<KnownSupplyAction> => async (dispatch, getState) => {
    const { user } = getState();
    const path = `supply/clock-presale-requests?administrationNumber=${user.activeAdministration.number}`;

    dispatch({ type: 'REQUEST_CLOCK_PRESALES', supplyLineId: supplyLine.id });

    const response = await post(path, supplyLine);

    if (!response.ok) {
      dispatch({ type: 'REQUEST_CLOCK_PRESALES_FAILED', supplyLineId: supplyLine.id });
    }
  },
  getDeliveryFormSupply: (barcode: string): IAppThunkAction<KnownSupplyAction> => async (dispatch, getState) => {
    const queryProps: ISupplyQueryProps = {
      deliveryFormBarcodes: barcode,
      deliveryMoment: getDefaultDeliveryMoment(),
      mainGroups: [1, 2, 3],
      hasBarcodeScanOrigin: window.location.href.includes('/scan'),
    };
    const path = `supply?administrationNumber=${getActiveAdministrationNumber()}&${qs.stringify(queryProps)}`;
    getDeliveryFormSupply(path)(dispatch, getState);
  },
  resetDeliveryFormSupply: (): IAppThunkAction<KnownSupplyAction> => async dispatch => {
    dispatch({ type: 'RESET_DELIVERY_FORM_SUPPLY' });
  },
};

function getSupply(path: string, isNext: boolean = false): IAppThunkAction<KnownSupplyAction> {
  return async (dispatch, getState) => {
    const {
      all: { path: statePath },
    } = getState().supply;

    if (path !== statePath) {
      dispatch({ type: 'REQUEST_SUPPLY', path, isNext });

      const response = await get(path);
      if (response.ok) {
        const linkHeader = parseLink(response.headers.get('Link'));

        dispatch({
          type: 'RECEIVE_SUPPLY',
          supplyAmount: parseInt(response.headers.get('X-Total-Count'), 10),
          supplyLink: linkHeader,
          supply: await response.json(),
          isNext,
        });
      }
    }
  };
}

function getDeliveryFormSupply(path: string): IAppThunkAction<KnownSupplyAction> {
  return async dispatch => {
    dispatch({ type: 'REQUEST_DELIVERY_FORM_SUPPLY' });

    const response = await get(path);
    if (response.ok) {
      dispatch({
        type: 'RECEIVE_DELIVERY_FORM_SUPPLY',
        supply: await response.json(),
      });
    } else {
      dispatch({ type: 'RECEIVE_DELIVERY_FORM_SUPPLY_FAILED' });
    }
  };
}
