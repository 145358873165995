import styled from 'styled-components';
import { IInputComponentProps } from './Input';
import { breakpoint, pxToRem, webkitOnly } from '@styles';

const INPUT_HEIGHT = 30;

export const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  &.validated {
    min-height: ${pxToRem(INPUT_HEIGHT + 20)};
  }
`;

export const StyledInput = styled.input<IInputComponentProps>`
  width: 100%;
  height: 100%;
  min-height: ${pxToRem(INPUT_HEIGHT)};
  padding: 0 ${pxToRem(10)};
  border: ${pxToRem(1)} solid ${props => props.theme.colors.silver};
  border-radius: ${pxToRem(5)};
  &::placeholder {
    ${props => props.theme.colors.dustyGray};
  }
  &:focus {
    border-color: ${props => props.theme.colors.dustyGray};
  }
  .error & {
    border-bottom: ${pxToRem(2)} solid ${props => props.theme.colors.red};
  }
  &[type='number'],
  &[type='date'],
  &[type='time'] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
    }
  }
  .no-decimals & {
    ${breakpoint('m')`
      &[type="number"] {
        -moz-appearance: number-input;
        &::-webkit-inner-spin-button {
          display: block;
          -webkit-appearance: inner-spin-button;
        }
      }
    `}
  }
  &[type='date'] {
    ${webkitOnly()`
      &::-webkit-calendar-picker-indicator {
        position: absolute;
        padding: 0;
        right: 0;
        width: ${pxToRem(INPUT_HEIGHT)};
        height: ${pxToRem(INPUT_HEIGHT)};
      }
    `}
  }
  &[type='search'] {
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
  }
`;

interface IButtonsWrapperProps {
  clickThrough?: boolean;
}

export const ButtonsWrapper = styled.div<IButtonsWrapperProps>`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  ${props =>
    props.clickThrough &&
    `
    pointer-events: none;
  `}
`;

export const Message = styled.span`
  color: ${props => props.theme.colors.red};
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: ${pxToRem(10)};
  font-size: ${pxToRem(13)};
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  position: relative;
  &:focus-within {
    input,
    select,
    button {
      border-color: ${props => props.theme.colors.dustyGray};
    }
  }
`;

interface IInputButtonProps {
  clickThrough?: boolean;
  webkitOnly?: boolean;
}

export const InputButton = styled.button<IInputButtonProps>`
  display: flex;
  height: 100%;
  position: relative;
  background: ${props => props.theme.colors.white};
  border: ${pxToRem(1)} solid ${props => props.theme.colors.silver};
  border-radius: 0 ${pxToRem(5)} ${pxToRem(5)} 0;
  min-width: ${pxToRem(INPUT_HEIGHT + 1)};
  color: ${props => props.theme.colors.dustyGray};
  ${props =>
    props.clickThrough &&
    `
     pointer-events: none;
  `}
  ${props =>
    props.webkitOnly &&
    `
    display: none;
    ${webkitOnly()`
      display: flex;
    `}
  `}
  > canvas {
    height: 100%;
  }
  .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    margin-top: 25%;
    margin-left: 25%;
  }
`;
