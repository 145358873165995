import React from 'react';
import { IWithBreakpointProps, withBreakpoint } from '@containers/withBreakpoint';

type IRenderOnBreakpointProps = {
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  render: () => JSX.Element;
} & React.HTMLProps<HTMLElement> &
  IWithBreakpointProps;

export function RenderOnBreakpoint({
  isBreakpointSmall,
  isBreakpointMedium,
  isBreakpointLarge,
  small,
  medium,
  large,
  render,
}: IRenderOnBreakpointProps) {
  const shouldRender = (small && isBreakpointSmall) || (medium && isBreakpointMedium) || (large && isBreakpointLarge);

  return shouldRender ? render() : null;
}

export default withBreakpoint(RenderOnBreakpoint);
