import { Action, Reducer } from 'redux';
import { KnownClocksAction } from './';
import IClocksState from './contracts/IClocksState';

const unloadedState: IClocksState = {
  items: [],
  loading: false,
};

export const clocksReducer: Reducer<IClocksState> = (state: IClocksState, incomingAction: Action) => {
  const action = incomingAction as KnownClocksAction;

  switch (action.type) {
    case 'REQUEST_CLOCKS':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_CLOCKS':
      return {
        ...state,
        items: action.items,
        loading: false,
      };
  }

  return state || unloadedState;
};
