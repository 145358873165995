import React from 'react';
import { Prefix, PrefixWrapper } from './prefixStyles';
import InputNumber from './InputNumber';

type IPercentageProps = {
  onPercentageChange?: (percentage: string) => void;
  decimals?: number;
} & React.HTMLProps<HTMLInputElement>;

export default class Percentage extends React.PureComponent<IPercentageProps> {
  render() {
    const { className, value, min, max, step, disabled, onPercentageChange, decimals } = this.props;

    return (
      <PrefixWrapper className={className}>
        <InputNumber value={value} min={min} max={max} step={step} disabled={disabled} decimals={decimals} onNumberChange={onPercentageChange} />
        <Prefix char="%" />
      </PrefixWrapper>
    );
  }
}
