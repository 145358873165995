import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoint } from '@containers/withBreakpoint';
import { IApplicationState, notificationActions } from '@store';
import HeaderBar from '@components/app/HeaderBar';

function HeaderBarContainer() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const notifications = useSelector((state: IApplicationState) => state.notifications.notifications);
  const location = useLocation();
  const { isBreakpointLarge } = useBreakpoint();
  const { clearAll, markAllAsRead } = notificationActions;

  const onUserMenuVisibilityChanged = (visible: boolean) => {
    if (visible) {
      dispatch(markAllAsRead());
    }
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <HeaderBar
      isOpen={!isBreakpointLarge && isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onUserMenuVisibilityChanged={onUserMenuVisibilityChanged}
      onClearNotifications={() => dispatch(clearAll())}
      isBreakpointLarge={isBreakpointLarge}
      notifications={notifications}
    />
  );
}

export default React.memo(HeaderBarContainer);
