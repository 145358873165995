import { applyMiddleware, combineReducers, compose, createStore, ReducersMapObject, Store } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, routerReducer, RouterState } from 'react-router-redux';
import { createBrowserHistory, History } from 'history';
import { IApplicationState, reducers } from './store';

function configureStore(history: History, initialState?: IApplicationState) {
  const createStoreWithMiddleware = compose(applyMiddleware(thunk, routerMiddleware(history)))(createStore);

  const allReducers = buildRootReducer(reducers);

  return createStoreWithMiddleware(allReducers, initialState) as Store<IApplicationState>;
}

function buildRootReducer(allReducers: ReducersMapObject<IApplicationState>) {
  return combineReducers<IApplicationState & { routing: RouterState }>({
    ...allReducers,
    routing: routerReducer,
  });
}

const browserHistory = createBrowserHistory();

export const store = configureStore(browserHistory);
