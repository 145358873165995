import qs from 'query-string';
import { get, patch, post } from '@common/fetch';
import { PRECONDITION_REQUIRED } from 'http-status-codes';
import { getActiveAdministrationNumber } from '@store/user';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import {
  IOrderCancellationResult,
  IReceiveOrderConditions,
  IReceiveOrderConditionsFailed,
  IReceiveOrderLineAction,
  IReceiveOrders,
  IReceiveRecentOrders,
  IRequestFreshOrdersAction,
  IRequestOrderCancellation,
  IRequestOrderConditions,
  IRequestOrderLineAction,
  IRequestOrders,
  IRequestRecentOrders,
  IResetOrderConditions,
  ISetOrderColumnLayoutAction,
} from './contracts/Actions';
import { IOrder, IOrderConditions, IOrderLine } from '@models';
import { KnownSupplyAction, supplyActions } from '@store/supply';
import getOrderUrl from './getOrderUrl';
import getOrderLine from './getOrderLine';
import { CancellationState } from '@enums';
import moment from 'moment';
import { IColumnLayoutItem } from '@contracts';
import i18next from 'i18next';

export { default as IOrderState } from './contracts/IOrderState';

export type KnownOrderAction =
  | KnownSupplyAction
  | IRequestOrders
  | IRequestFreshOrdersAction
  | IReceiveOrders
  | IRequestOrderLineAction
  | IReceiveOrderLineAction
  | IRequestOrderConditions
  | IReceiveOrderConditions
  | IReceiveOrderConditionsFailed
  | IResetOrderConditions
  | IRequestOrderCancellation
  | IOrderCancellationResult
  | IRequestRecentOrders
  | IReceiveRecentOrders
  | ISetOrderColumnLayoutAction;

export const orderActions = {
  getAll: (fresh: boolean = false): IAppThunkAction<KnownOrderAction> => async (dispatch, getState) => {
    const activeAdministrationNumber = getActiveAdministrationNumber();

    if (activeAdministrationNumber) {
      const requestPath = getOrderUrl('orders', getState().orderFilter, activeAdministrationNumber);

      if (fresh) {
        dispatch({ type: 'REQUEST_FRESH_ORDERS' });
      }

      const { path: statePath } = getState().order.all;

      if (requestPath !== statePath) {
        dispatch({ type: 'REQUEST_ORDERS', path: requestPath });

        const response = await get(requestPath);

        if (response.ok) {
          dispatch({
            type: 'RECEIVE_ORDERS',
            orderLines: await response.json(),
          });
        }
      }
    }
  },
  getRecent: (): IAppThunkAction<KnownOrderAction> => async dispatch => {
    const activeAdministrationNumber = getActiveAdministrationNumber();

    if (activeAdministrationNumber) {
      dispatch({ type: 'REQUEST_RECENT_ORDERS' });

      const query = {
        offset: 0,
        limit: 5,
        sort: '-createdAt',
        cancellationStates: [CancellationState.None, CancellationState.Partial],
        mainGroups: [1, 2, 3],
      };
      const response = await get(`${getOrderUrl('orders', null, activeAdministrationNumber)}&${qs.stringify(query)}`);

      if (response.ok) {
        const orderLines: IOrderLine[] = await response.json();

        dispatch({
          type: 'RECEIVE_RECENT_ORDERS',
          orderLines: orderLines.filter(line => moment(line.createdAt).isSame(orderLines[0].createdAt, 'day')),
        });
      }
    }
  },
  getOrderLine: (id: string): IAppThunkAction<KnownOrderAction> => async (dispatch, getState) => {
    const {
      detail: { lines },
    } = getState().order;
    const existingLine = lines[id];

    if (!existingLine) {
      dispatch({ type: 'REQUEST_ORDER_LINE' });

      dispatch({
        type: 'RECEIVE_ORDER_LINE',
        orderLine: await getOrderLine(id),
      });
    }
  },
  cancelOrder: (id: number): IAppThunkAction<KnownOrderAction> => async (dispatch, getState) => {
    const queryProps = { administrationNumber: getActiveAdministrationNumber() };

    if (queryProps.administrationNumber) {
      dispatch({
        type: 'REQUEST_ORDER_CANCELLATION',
        orderId: id,
      });

      await patch(`orders?${qs.stringify(queryProps)}`, [{ id }], {
        headers: {
          'x-action': 'cancel',
        },
      });

      // regardless the response of the cancellation action, always refresh order line and remove from pending cancellations
      const response = await get(getOrderUrl(`orders/${id}`, getState().orderFilter, queryProps.administrationNumber));

      dispatch({
        type: 'ORDER_CANCELLATION_RESULT',
        orderId: id,
        orderLine: await response.json(),
      });
    }
  },
  getConditions: (supplyLineId: string): IAppThunkAction<KnownOrderAction> => async (dispatch, getState) => {
    let supplyLine = getSupplyLineFromState();
    let error = i18next.t('marketPlace:order.notFound');

    dispatch({ type: 'REQUEST_ORDER_CONDITIONS' });

    if (!supplyLine) {
      await supplyActions.getSupplyLine(supplyLineId)(dispatch, getState);
      supplyLine = getSupplyLineFromState();
    }

    if (supplyLine) {
      const { id } = supplyLine;
      const path = 'orders/conditions';
      const queryProps = {
        administrationNumber: getActiveAdministrationNumber(),
        supplyLineId: id,
      };

      const response = await get(`${path}?${qs.stringify(queryProps)}`, {
        customErrorHandlers: [PRECONDITION_REQUIRED],
      });

      if (response.ok) {
        dispatch({
          type: 'RECEIVE_ORDER_CONDITIONS',
          conditions: (await response.json()) as IOrderConditions,
          supplyLine,
        });

        return;
      }

      error = (await response.json())[0];
    }

    dispatch({
      type: 'RECEIVE_ORDER_CONDITIONS_FAILED',
      error,
      supplyLineId,
    });

    function getSupplyLineFromState() {
      return getState().supply.detail.lines[supplyLineId];
    }
  },
  createOrder: (order: IOrder): IAppThunkAction<KnownOrderAction> => async () => {
    const newOrder = { ...order, administrationNumber: getActiveAdministrationNumber() };

    await post('orders', newOrder);
  },
  setColumnLayout: (columnLayout: IColumnLayoutItem[]): IAppThunkAction<KnownOrderAction> => async dispatch => {
    dispatch({ columnLayout, type: 'SET_ORDER_COLUMN_LAYOUT' });
  },
  resetOrderConditions: (): IAppThunkAction<KnownOrderAction> => async dispatch => {
    dispatch({ type: 'RESET_ORDER_CONDITIONS' });
  },
};
