import styled from 'styled-components';
import { breakpoint, BREAKPOINT_GUTTER, HEADER_HEIGHT, pxToRem, rgbToRgba } from '@styles';
import { FLOATING_BUTTON_HEIGHT } from '@components/shared/Button/styles';
import { LEFT_COLUMN_OPEN_CLASSNAME } from './';

export const LAYOUT_ANIMATION_DURATION = 300;
export const LEFT_COLUMN_WIDTH = 350;
export const CLOSE_BUTTON_SIZE = 44;

export const Wrapper = styled.div`
  ${breakpoint('m')`
    height: 100vh;
  `}
`;

const Column = styled.div`
  display: block;
  min-height: inherit;
  position: relative;
  vertical-align: top;
  padding-top: ${pxToRem(HEADER_HEIGHT.s)};
  ${breakpoint('m')`
    padding-top: ${pxToRem(HEADER_HEIGHT.m)};
  `}
  ${breakpoint('l')`
    padding-top: ${pxToRem(HEADER_HEIGHT.l)};
  `}
  .with-transition & {
    transition: ${LAYOUT_ANIMATION_DURATION}ms;
  }
`;

export const LeftColumnWrapper = styled(Column)`
  ${props => `
    position: fixed;
    width: 100vw;
    height: 100%;
    left: -100vw; /* left panel needs to move away on mobile to prevent overlapping content when x-scrolling right panel */
    background: ${props.theme.colors.alabaster};
    overflow-y: hidden;
    ${breakpoint('m')`
      width: ${pxToRem(LEFT_COLUMN_WIDTH)};
      left: 0;
    `}
    .${LEFT_COLUMN_OPEN_CLASSNAME} & {
      left: 0;
    }
    .close-button {
      position: absolute;
      top: ${pxToRem(5)};
      right: ${pxToRem(5)};
    }
    .has-supply-selection-with-breakpoint-small{
      width:50%;
    }
  `}
`;

export const LeftColumnContent = styled.div`
  position: relative;
  height: calc(100% - ${pxToRem(FLOATING_BUTTON_HEIGHT)});
  overflow-y: scroll;
  padding: ${pxToRem(CLOSE_BUTTON_SIZE)} ${pxToRem(BREAKPOINT_GUTTER.s)} ${pxToRem(BREAKPOINT_GUTTER.s)};
  ${breakpoint('m')`
    height: 100%;
    padding: ${pxToRem(BREAKPOINT_GUTTER.m)};
  `}
`;

export const RightColumnWrapper = styled(Column)`
  ${props => `
    background: ${props.theme.colors.white};
    ${breakpoint('m')`
      height: 100%;
      box-shadow: 0 0 ${pxToRem(15)} 0 ${rgbToRgba(props.theme.colors.dustyGray, 0.5)};
    `}
    .${LEFT_COLUMN_OPEN_CLASSNAME} & {
      margin-left: 100vw;
      ${breakpoint('m')`
        margin-left: ${pxToRem(LEFT_COLUMN_WIDTH)};
      `}
    }
  `}
`;

export const Main = styled.main`
  ${breakpoint('m')`
    height: 100%;
    overflow-y: auto;
  `}
`;
