import styled from 'styled-components';
import { pxToRem, rgbToRgba, setFont } from '@styles';
import { NavLink } from 'react-router-dom';

export const Block = styled(NavLink)`
  border: ${pxToRem(2)} solid ${props => rgbToRgba(props.theme.colors.white, 0.25)};
  height: inherit;
  overflow: hidden;
  padding: 0 ${pxToRem(20)};
  position: relative;
  &:hover {
    border-color: ${props => rgbToRgba(props.theme.colors.white, 0.75)};
  }
  &.disabled {
    cursor: default;
    &:hover {
      border-color: ${props => rgbToRgba(props.theme.colors.white, 0.25)};
    }
  }
  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 50px;
  }
`;

export const Data = styled.span`
  ${setFont('thin')}
  font-variant-numeric: normal;
  display: block;
  font-size: ${pxToRem(50)};
  margin-top: ${pxToRem(28)};
  .price {
    font-variant-numeric: inherit;
  }
`;

export const Title = styled.span`
  display: block;
  font-weight: bold;
`;
