import { IFilterOption } from '@contracts';
import { SupplyFilterGroup, SupplyFilterType } from '@enums/index';
import includes from 'lodash.includes';
import { ISupplyFilterState } from '@store/supply-filter';

const FILTER_GROUPS: { [key: string]: SupplyFilterType[] } = {
  [SupplyFilterGroup.General]: [
    SupplyFilterType.TradeInstrument,
    SupplyFilterType.CropGroup,
    SupplyFilterType.ProductGroup,
    SupplyFilterType.Product,
    SupplyFilterType.IsSpecific,
    SupplyFilterType.Supplier,
    SupplyFilterType.CountryOfOrigin,
    SupplyFilterType.Certification,
    SupplyFilterType.Quality,
    SupplyFilterType.MaturityStage,
    SupplyFilterType.MinimumStemsPerPlant,
    SupplyFilterType.PlantShape,
    SupplyFilterType.PotMaterial,
    SupplyFilterType.Colour,
    SupplyFilterType.Package,
    SupplyFilterType.AuctionClock,
    SupplyFilterType.Price,
    SupplyFilterType.ProducerGroup,
    SupplyFilterType.PotSize,
    SupplyFilterType.PlantHeight,
    SupplyFilterType.LengthOfFlowerStem,
    SupplyFilterType.Weight,
    SupplyFilterType.StagingArea,
  ],
  [SupplyFilterGroup.Koa]: [SupplyFilterType.DeliveryFormBarcode],
};

export function toGenericFilterOption(options: IFilterOption[]) {
  return options.map((option: IFilterOption) => ({ label: option.name, value: option.id, count: option.count }));
}

export function getSelectedFiltersForGroup(state: ISupplyFilterState, group: SupplyFilterGroup, inverse: boolean = false) {
  const {
    selected: { filters },
  } = state;

  return filters.filter(f => {
    const isIncluded = includes(FILTER_GROUPS[group], f.type);

    return !inverse ? isIncluded : !isIncluded;
  });
}
