import styled from 'styled-components';
import { pxToRem, setFont } from '@styles';

const PADDING = 30;

export const SubMenu = styled.nav`
  padding: ${pxToRem(PADDING / 2)} 0;
`;

export const SubMenuItem = styled.li`
  line-height: ${pxToRem(50)};
  font-size: ${pxToRem(18)};
  overflow: hidden;
  text-overflow: ellipsis;
  margin: ${pxToRem(3)} 0;
  > a {
    border-left: ${pxToRem(5)} solid transparent;
    padding: 0 ${pxToRem(PADDING - 5)};
    &.active {
      ${setFont('bold')}
      color: ${props => props.theme.colors.lipstick};
      border-left-color: ${props => props.theme.colors.lipstick};
    }
    &:hover {
      border-left-color: ${props => props.theme.colors.lipstick};
    }
  }
  &.disabled {
    border-left: ${pxToRem(5)} solid transparent;
    padding: 0 ${pxToRem(PADDING - 5)};
    color: ${props => props.theme.colors.silver};
  }
`;
