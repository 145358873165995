import React, { useState } from 'react';
import Image from '@components/shared/Image';
import Icon from '@components/shared/Icon';
import { IconType } from '@enums';
import classNames from 'classnames';
import { NavigationButton } from '../NavigationButton/styles';
import { Carousel } from '../ImageCarousel/styles';

interface IImage {
  url: string;
  title: string;
  alt: string;
}

interface IImageCarouselProps extends React.HTMLProps<HTMLDivElement> {
  images: IImage[];
  width?: number;
  height?: number;
  sources?: any[];
  onClick?: () => void;
}

export default function ImageCarousel(props: IImageCarouselProps) {
  const { images, width, height, sources, className, onClick } = props;
  const [index, setIndex] = useState(0);

  return (
    <Carousel className={className}>
      {index > 0 && (
        <NavigationButton
          className="previous"
          onClick={event => {
            event.preventDefault();
            setIndex(index - 1);
          }}
        >
          <Icon type={IconType.LessThan} />
        </NavigationButton>
      )}
      <Image
        onClick={event => {
          if (onClick) {
            event.preventDefault();
            onClick();
          }
        }}
        src={images[index] && images[index].url}
        width={width}
        height={height}
        sources={sources}
        alt={images[index] && images[index].alt}
        title={images[index] && images[index].title}
      />
      {index < images.length - 1 && (
        <NavigationButton
          className="next"
          onClick={event => {
            event.preventDefault();
            setIndex(index + 1);
          }}
        >
          <Icon type={IconType.LessThan} />
        </NavigationButton>
      )}

      {images.length > 1 && (
        <div className="dots">
          {Array.apply(null, Array(images.length)).map((_x: any, i: number) => (
            <span
              key={i}
              className={classNames('dot', { active: index === i })}
              onClick={event => {
                event.preventDefault();
                setIndex(i);
              }}
            />
          ))}
        </div>
      )}
    </Carousel>
  );
}
