import React from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import { breakpoint, pxToRem, setFont } from '@styles';

interface IHeadingProps {
  size?: IHeadingSize;
  themed?: boolean;
  alignTop?: boolean;
}

const headingStyles: any = {
  xxl: css<IHeadingProps>`
    ${props => `
      font-size: ${pxToRem(36)};
      line-height: ${pxToRem(45)};
      letter-spacing: -${pxToRem(0.5)};
      ${
        props.alignTop &&
        `
        margin-top: -${pxToRem(5)};
      `
      }
      ${breakpoint('l')`
        font-size: ${pxToRem(45)};
        line-height: ${pxToRem(55)};
        letter-spacing: -${pxToRem(1)};
      `}
    `}
  `,
  xl: css<IHeadingProps>`
    ${props => `
      font-size: ${pxToRem(31)};
      line-height: ${pxToRem(39)};
      ${
        props.alignTop &&
        `
        margin-top: -${pxToRem(5)};
      `
      }
      ${breakpoint('l')`
        font-size: ${pxToRem(38)};
        line-height: ${pxToRem(48)};
        letter-spacing: -${pxToRem(0.5)};
     `}
    `}
  `,
  l: css<IHeadingProps>`
    ${props => `
      font-size: ${pxToRem(27)};
      line-height: ${pxToRem(34)};
      ${
        props.alignTop &&
        `
        margin-top: -${pxToRem(5)};
      `
      }
      ${breakpoint('l')`
        font-size: ${pxToRem(32)};
        line-height: ${pxToRem(40)};
        ${
          props.alignTop &&
          `
          margin-top: -${pxToRem(4)};
        `
        }
     `}
    `}
  `,
  m: css<IHeadingProps>`
    ${props => `
      font-size: ${pxToRem(23)};
      line-height: ${pxToRem(29)};
      ${
        props.alignTop &&
        `
        margin-top: -${pxToRem(5)};
      `
      }
      ${breakpoint('l')`
        font-size: ${pxToRem(26)};
        line-height: ${pxToRem(33)};
        ${
          props.alignTop &&
          `
          margin-top: -${pxToRem(3)};
        `
        }
     `}
    `}
  `,
  s: css<IHeadingProps>`
    ${props => `
      font-size: ${pxToRem(20)};
      line-height: ${pxToRem(25)};
      ${
        props.alignTop &&
        `
        margin-top: -${pxToRem(3)};
      `
      }
      ${breakpoint('l')`
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(28)};
     `}
    `}
  `,
  xs: css<IHeadingProps>`
    ${props => `
      font-size: ${pxToRem(17)};
      line-height: ${pxToRem(22)};
      ${
        props.alignTop &&
        `
        margin-top: -${pxToRem(3)};
      `
      }
      ${breakpoint('l')`
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(24)};
        ${
          props.alignTop &&
          `
          margin-top: -${pxToRem(5)};
        `
        }
      `}
    `}
  `,
};

export type IHeadingSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

const Heading = styled.h1<IHeadingProps>`
  ${props => getHeadingStyles(props.size, props.themed)}
`;

export function PageHeading({ className, ...restProps }: any) {
  const headingClass = classNames('heading', className);

  return <Heading {...restProps} className={headingClass} data-test-hook="page-header" />;
}

export function getHeadingStyles(size: IHeadingSize, themed: boolean = false) {
  return css`
    font-size: ${pxToRem(15)};
    margin-bottom: ${pxToRem(10)};
    ${setFont('bold')}
    color: ${(props: any) => (themed ? props.theme.colors.lipstick : 'inherit')};
    ${headingStyles[size]}
  `;
}

export default Heading;
