import { Action, Reducer } from 'redux';
import store from 'store';
import { KnownUserAction } from './';
import IUserState from './contracts/IUserState';

const USER_ADMINISTRATION_STORAGE_KEY = 'user:administration:';

const unloadedState: IUserState = {
  user: null,
  activeAdministration: null,
};

export const userReducer: Reducer<IUserState> = (state: IUserState, incomingAction: Action) => {
  const action = incomingAction as KnownUserAction;

  switch (action.type) {
    case 'REQUEST_USER':
      return state;
    case 'RECEIVE_USER':
      return {
        ...state,
        user: action.user,
      };
    case 'SET_ACTIVE_ADMINISTRATION':
      store.set(getUserAdministrationStorageKey(state.user.id), action.administration);

      return {
        ...state,
        activeAdministration: action.administration,
      };
  }

  return state || unloadedState;
};

export function getUserAdministrationStorageKey(userId: string) {
  return `${USER_ADMINISTRATION_STORAGE_KEY}${userId}`;
}
