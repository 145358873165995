import { get } from '@common/fetch';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { IReceiveClocks, IRequestClocks } from './contracts/Actions';

export { default as IClocksState } from './contracts/IClocksState';

export type KnownClocksAction = IReceiveClocks | IRequestClocks;

export const clocksActions = {
  getAll: (): IAppThunkAction<KnownClocksAction> => async dispatch => {
    const requestPath = 'clocks';

    dispatch({ type: 'REQUEST_CLOCKS' });

    const response = await get(requestPath);

    if (response.ok) {
      dispatch({
        items: await response.json(),
        type: 'RECEIVE_CLOCKS',
      });
    }
  },
};
