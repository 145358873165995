import React from 'react';
import { useSelector } from 'react-redux';
import { IAdministration } from '@models';
import { IApplicationState } from '@store';

type IActiveUserProps = React.HTMLProps<HTMLSpanElement>;

export function ActiveUser({ onClick }: IActiveUserProps) {
  let activeAdministrationName = '';
  const {
    user: { administrations },
    activeAdministration,
  } = useSelector((state: IApplicationState) => state.user);
  if (administrations.length > 0) {
    activeAdministrationName = administrations.filter((administration: IAdministration) => administration.number === activeAdministration?.number)[0]
      .name;
  }

  return <span onClick={onClick}>{`${activeAdministrationName} (${activeAdministration.number})`}</span>;
}

export default React.memo(ActiveUser);
