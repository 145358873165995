import { match, matchPath } from 'react-router';
import { Location } from 'history';

export const HOME_ROUTE = '/';
export const SUPPLY_ROUTE = '/marketplace';
export const ORDER_ROUTE = '/transactions';
export const SETTINGS_ROUTE = '/settings';
export const HELP_ROUTE = '/help/:lang';
export const NOT_AUTHORIZED_ROUTE = '/notauthorized';
export const SUPPLY_LINE_DETAIL_ROUTE_RECOMMENDATION = '/supply-recommendation/:id/details';
export const SUPPLY_LINE_DETAIL_ROUTE = `${SUPPLY_ROUTE}/:id/details`;
export const ORDER_LINE_DETAIL_ROUTE = `${ORDER_ROUTE}/:id/details`;
export const SCAN_ROUTE = '/scan';

export function matchRoute<T>(location: Location, path: string): match<T> {
  return matchPath(location.pathname, { path });
}

export function isMatchingRoute(location: Location, path: string): boolean {
  return !!matchRoute(location, path);
}
