import styled from 'styled-components';
import { pxToRem } from '@styles';

export const Carousel = styled.div`
  display: inline-flex;
  .previous,
  .next {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.colors.wildSand};
    max-width: ${pxToRem(50)};
    width: 16%;
    .icon {
      height: 100%;
      width: 45%;
      right: unset;
    }
  }
  .icon {
    height: 35px;
  }
  .dots {
    position: absolute;
    bottom: ${pxToRem(10)};
    width: 100%;
    text-align: center;
  }
  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    text-decoration: none;
    color: ${props => props.theme.colors.wildSand};
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    padding: 5px;
    margin: 2px;
    border: 1px solid ${props => props.theme.colors.doveGray};
  }
  .dot.active {
    color: ${props => props.theme.colors.lipstick};
  }
`;
