import { get } from '@common/fetch';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { IReceiveBanner, IRequestBanner } from './contracts/Actions';

export { default as IBannerState } from './contracts/IBannerState';

export type KnownBannerAction = IRequestBanner | IReceiveBanner;

export const bannerActions = {
  getBanner: (): IAppThunkAction<KnownBannerAction> => async (dispatch, getState) => {
    const requestPath = 'banners';
    const { path: statePath } = getState().banner;

    if (requestPath !== statePath) {
      dispatch({ type: 'REQUEST_BANNER', path: requestPath });
      const response = await get(requestPath);

      if (response.ok) {
        const banners = await response.json();

        if (banners.length > 0) {
          dispatch({
            item: banners[banners.length - 1],
            type: 'RECEIVE_BANNER',
          });
        }
      }
    }
  },
};
