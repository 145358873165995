import React, { Fragment } from 'react';
import Heading from '@components/shared/Heading';
import { IOrderLine } from '@models';
import Loader from '@components/shared/Loader';
import { Dd, Dl, Dt, Wrapper } from './styles';
import { useTranslation } from 'react-i18next';
import PriceOutput from '@components/shared/PriceOutput';
import { formatDate } from '@components/shared/DateOutput';
import { IconType } from '@enums';
import { OutlineButton } from '@components/shared/Button';

interface IOrdersListProps {
  loading?: boolean;
  orders: IOrderLine[];
}

export default function OrdersList({ loading, orders }: IOrdersListProps) {
  const { t } = useTranslation('home');
  const hasItems = orders.length > 0;

  return (
    <Wrapper>
      <Heading as="h2" size="s" themed alignTop>
        {t('transactions.recent')}
      </Heading>
      {!loading && hasItems && (
        <>
          <Heading as="h3" size="xs" className="uc-first">
            {formatDate(orders[0].createdAt, 'dddd DD MMMM')}
          </Heading>
          <Dl>
            {orders.map(({ id, price, tradeItem: { growerProduct } }) => (
              <Fragment key={id}>
                <Dt className="ellipsify">{growerProduct.vbnProduct.shortName}</Dt>
                <Dd>
                  <PriceOutput price={price} numberOfDigits={2} />
                </Dd>
              </Fragment>
            ))}
          </Dl>
          <OutlineButton to="/transactions" label={t('transactions.moreLink')} icon={{ type: IconType.Arrow, position: 'right', rotation: -90 }} />
        </>
      )}
      {!loading && !hasItems && <span>{t('transactions.noTransactions')}</span>}
      {loading && <Loader />}
    </Wrapper>
  );
}
