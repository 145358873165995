import React from 'react';
import debounce from 'lodash.debounce';
import Input from './Input';

const DEBOUNCE_MS = 300;

interface ITimeProps extends React.HTMLProps<any> {
  onTimeChange?: (value?: string) => void;
  value: string;
}

interface ITimeState extends React.HTMLProps<any> {
  initialValue: string;
  value: string;
}

export default class Time extends React.PureComponent<ITimeProps, ITimeState> {
  state: ITimeState = {
    initialValue: this.props.value,
    value: this.props.value,
  };

  constructor(props: ITimeProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.handleChange = debounce(this.handleChange, DEBOUNCE_MS);
  }

  static getDerivedStateFromProps(nextProps: ITimeProps, state: ITimeState): ITimeState {
    if (nextProps.value !== state.initialValue) {
      return {
        ...state,
        initialValue: nextProps.value,
        value: nextProps.value,
      };
    }

    return state;
  }

  render() {
    const { className } = this.props;

    return (
      <Input
        className={className}
        type="time"
        placeholder="hh:mm"
        pattern="[0-9]{2}:[0-9]{2}"
        value={this.state.value || ''}
        onChange={this.onChange}
      />
    );
  }

  private onChange(event: any) {
    const {
      value,
      validity: { valid: isValidPattern },
    } = event.currentTarget;

    this.setState({ value }, () => {
      this.handleChange(isValidPattern);
    });
  }

  private handleChange(isValidPattern: boolean) {
    if (isValidPattern) {
      const { value } = this.state;
      const { onTimeChange } = this.props;

      if (!value) {
        onTimeChange && onTimeChange();
      } else {
        const split = value.split(':');
        const hour = parseInt(split[0], 10);
        const minute = parseInt(split[1], 10);
        const isValidHour = !isNaN(hour) && split[0].length === 2 && hour >= 0 && hour <= 23;
        const isValidMinute = !isNaN(minute) && split[1].length === 2 && minute >= 0 && minute <= 59;

        if (isValidHour && isValidMinute) {
          onTimeChange && onTimeChange(`${split[0]}:${split[1]}`);
        }
      }
    }
  }
}
