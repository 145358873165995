import React from 'react';
import classNames from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FloatingButtonWrapper } from './styles';
import { OutlineButton } from './';
import Loader from '@components/shared/Loader';

type IFloatingButtonProps = {
  onClick?: () => void;
  className?: string;
  loading?: boolean;
} & WithTranslation;

export function FloatingButton({ t, onClick, className, loading }: IFloatingButtonProps) {
  const blockClass = classNames(className, 'hidden-l hidden-m');

  return (
    <FloatingButtonWrapper className={blockClass}>
      {!loading && <OutlineButton label={t('general:ready')} onClick={onClick} color="white" />}
      {loading && <Loader />}
    </FloatingButtonWrapper>
  );
}

export default withTranslation(['general'])(FloatingButton);
