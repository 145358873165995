import { Action, Reducer } from 'redux';
import deepmerge from 'deepmerge';
import store from 'store';
import includes from 'lodash.includes';
import sortBy from 'lodash.sortby';
import { KnownSupplyFilterAction } from './';
import ISupplyFilterState from './contracts/ISupplyFilterState';
import { MarketPlaceView, SortDirection, SupplySortingField } from '@enums';
import { ISupplyFilter } from '@contracts';
import { IReceiveSupplyFiltersAction } from './contracts/Actions';
import { IQuickFilter } from '@models';
import { getSelectedFiltersForGroup } from '@common/filters';
import { getDefaultDeliveryMoment } from '@common/deliveryMoment';

const STORAGE_KEY_PREFIX = 'supply-filter:';
const STORAGE_KEYS = {
  sorting: `${STORAGE_KEY_PREFIX}sorting`,
  mainGroups: `${STORAGE_KEY_PREFIX}main-groups`,
  deliveryLocation: `${STORAGE_KEY_PREFIX}delivery-location`,
  view: `${STORAGE_KEY_PREFIX}view`,
};

export const unloadedSupplyFilterState: ISupplyFilterState = {
  filters: {
    firstLoad: false,
    loading: false,
    path: '',
    items: [],
  },
  searchSuggestions: {
    loading: false,
    items: [],
  },
  quickFilters: {
    loading: false,
    path: '',
    items: [],
  },
  linkedAccountQuickFilters: {
    loading: false,
    items: [],
  },
  selected: {
    query: '',
    settings: {
      delivery: {
        locationIds: getLocationIds(),
        moment: getDefaultDeliveryMoment(),
        isSelect: false,
      },
      mainGroups: store.get(STORAGE_KEYS.mainGroups) || [1, 2, 3],
    },
    filters: [],
    sorting: store.get(STORAGE_KEYS.sorting) || {
      field: SupplySortingField.ProductName,
      direction: SortDirection.Ascending,
    },
  },
  view: store.get(STORAGE_KEYS.view) || MarketPlaceView.List,
  lastModifiedFilterType: null,
  activeQuickFilter: null,
  queryChanged: false,
};

export const supplyFilterReducer: Reducer<ISupplyFilterState> = (state: ISupplyFilterState, incomingAction: Action) => {
  const action = incomingAction as KnownSupplyFilterAction;

  switch (action.type) {
    case 'REQUEST_SUPPLY_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          firstLoad: !state.filters.path,
          loading: true,
          path: action.path,
        },
      };
    case 'REQUEST_FRESH_SUPPLY_FILTERS':
      return {
        ...state,
        filters: unloadedSupplyFilterState.filters,
      };
    case 'RECEIVE_SUPPLY_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          firstLoad: false,
          loading: false,
          items: getNewFilters(state, action),
        },
      };
    case 'SET_SUPPLY_QUERY':
      return {
        ...state,
        selected: {
          ...state.selected,
          query: action.query,
        },
        queryChanged: true,
      };
    case 'SET_SUPPLY_DELIVERY':
      store.set(STORAGE_KEYS.deliveryLocation, action.delivery.locationIds);

      return {
        ...state,
        selected: {
          ...state.selected,
          settings: {
            ...state.selected.settings,
            delivery: action.delivery,
          },
        },
        lastModifiedFilterType: null,
        queryChanged: false,
      };
    case 'SET_SUPPLY_MAIN_GROUPS':
      store.set(STORAGE_KEYS.mainGroups, action.mainGroups);

      return {
        ...state,
        selected: {
          ...state.selected,
          settings: {
            ...state.selected.settings,
            mainGroups: action.mainGroups,
          },
        },
        lastModifiedFilterType: null,
        queryChanged: false,
      };
    case 'SET_SUPPLY_SORTING':
      store.set(STORAGE_KEYS.sorting, action.sorting);

      return {
        ...state,
        selected: {
          ...state.selected,
          sorting: action.sorting,
        },
      };
    case 'SET_SUPPLY_FILTER':
      const filterContainsValues = action.value.length > 0;
      const currentFilters = state.selected.filters.filter(f => f.type !== action.filterType);

      if (filterContainsValues) {
        currentFilters.push({
          type: action.filterType,
          value: action.value,
        });
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          filters: currentFilters,
        },
        lastModifiedFilterType: action.filterType,
        queryChanged: false,
      };
    case 'REMOVE_ACTIVE_SUPPLY_FILTERS':
      return {
        ...state,
        selected: {
          ...state.selected,
          filters: getSelectedFiltersForGroup(state, action.group, true),
        },
        activeQuickFilter: null,
      };
    case 'REQUEST_QUICK_FILTERS':
      return {
        ...state,
        quickFilters: {
          ...state.quickFilters,
          loading: true,
          path: action.path,
        },
      };
    case 'RECEIVE_QUICK_FILTERS':
      return {
        ...state,
        quickFilters: {
          ...state.quickFilters,
          items: action.filters,
          loading: false,
        },
      };
    case 'SET_ACTIVE_QUICK_FILTER':
      const activeQuickFilter = state.quickFilters.items.filter(q => q.id === action.quickFilterId)[0];

      return {
        ...state,
        selected: {
          ...state.selected,
          filters: activeQuickFilter ? activeQuickFilter.filter : state.selected.filters,
        },
        activeQuickFilter,
        lastModifiedFilterType: null,
        queryChanged: false,
      };
    case 'CREATE_QUICK_FILTER_SUCCEEDED':
      return {
        ...state,
        activeQuickFilter: action.quickFilter,
        quickFilters: {
          ...state.quickFilters,
          items: sortBy([...state.quickFilters.items, action.quickFilter], (q: IQuickFilter) => q.name.toLowerCase()),
        },
      };
    case 'UPDATE_ACTIVE_QUICK_FILTER_SUCCEEDED':
      const newActiveQuickFilter = {
        ...state.activeQuickFilter,
        filter: state.selected.filters,
      };

      return {
        ...state,
        quickFilters: {
          ...state.quickFilters,
          items: state.quickFilters.items.map(q => (q.id === newActiveQuickFilter.id ? newActiveQuickFilter : q)),
        },
        activeQuickFilter: newActiveQuickFilter,
      };
    case 'UPDATE_QUICK_FILTER_SUCCEEDED':
      return {
        ...state,
        quickFilters: {
          ...state.quickFilters,
          items: state.quickFilters.items.map(q => (q.id === action.quickFilter.id ? action.quickFilter : q)),
        },
      };
    case 'SET_SUPPLY_VIEW':
      store.set(STORAGE_KEYS.view, action.view);

      return {
        ...state,
        view: action.view,
      };
    case 'REMOVE_QUICK_FILTER_SUCCEEDED':
      return {
        ...state,
        quickFilters: {
          ...state.quickFilters,
          items: state.quickFilters.items.filter(f => f.id !== action.id),
        },
        activeQuickFilter:
          state.activeQuickFilter && state.activeQuickFilter.id === action.id ? unloadedSupplyFilterState.activeQuickFilter : state.activeQuickFilter,
      };
    case 'SET_SELECTED_SUPPLY_FILTER_STATE':
      return {
        ...state,
        selected: deepmerge(state.selected, action.selection, {
          arrayMerge: (_dest: any, src: any) => src,
        }),
      };
    case 'REQUEST_SUPPLY_SEARCH_SUGGESTIONS':
      return {
        ...state,
        searchSuggestions: {
          ...state.searchSuggestions,
          loading: true,
        },
      };
    case 'RECEIVE_SUPPLY_SEARCH_SUGGESTIONS':
      return {
        ...state,
        searchSuggestions: {
          ...state.searchSuggestions,
          loading: false,
          items: action.suggestions,
        },
      };
  }

  return state || unloadedSupplyFilterState;
};

function getNewFilters(state: ISupplyFilterState, action: IReceiveSupplyFiltersAction): ISupplyFilter[] {
  const sanitizedFilters = sanitizeFilters(action.filters, state);

  return replaceLastModifiedFilter(sanitizedFilters, state);
}

/**
 * Remove options from filters that have a zero count, except if that one is a selected option
 */
function sanitizeFilters(filters: ISupplyFilter[], { selected }: ISupplyFilterState) {
  return filters.reduce((result, filter) => {
    const selectedFilter = selected.filters.filter(f => f.type === filter.type)[0];
    const value = selectedFilter ? selectedFilter.value : [];

    result.push({
      ...filter,
      options: filter.options.filter(option => option.count > 0 || includes(value, option.id)),
    });

    return result;
  }, []);
}

/**
 * Replaces the filter from the specified filters with the one already on the state
 * for the filter that had just been modified by the user. This prevents filter values from
 * being updated for the last modified filter.
 */
function replaceLastModifiedFilter(filters: ISupplyFilter[], state: ISupplyFilterState) {
  return filters.map(filter => {
    if (state.lastModifiedFilterType !== null && filter.type === state.lastModifiedFilterType) {
      const existingActiveFilter = state.selected.filters.filter(f => f.type === filter.type)[0];
      const existingFilter = state.filters.items.filter(f => f.type === filter.type)[0];

      if (!state.queryChanged && existingFilter && existingActiveFilter && existingActiveFilter.value.length > 0) {
        return existingFilter;
      }
    }

    return filter;
  });
}

function getLocationIds() {
  const locationIds = store.get(STORAGE_KEYS.deliveryLocation);

  if (locationIds) {
    return Array.isArray(locationIds) ? locationIds : [locationIds];
  }

  return [];
}
