import React from 'react';
import classNames from 'classnames';
import { InputWrapper, Message, StyledInput } from './styles';

export interface IInputComponentProps {
  error?: string;
  touched?: boolean;
  testHook?: string;
}

type IInputProps = IInputComponentProps & React.HTMLProps<HTMLInputElement>;

interface IInputState {
  touched: boolean;
}

export default class Input extends React.PureComponent<IInputProps> {
  state: IInputState = {
    touched: this.props.touched || false,
  };

  constructor(props: IInputProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  render() {
    const { error, className, ref, as, onChange, onBlur, testHook, ...restProps } = this.props;
    const { touched } = this.state;
    const inputValidated = typeof error !== 'undefined';
    const showError = !!(touched && error);

    const wrapperClass = classNames(
      'input',
      {
        validated: inputValidated,
        error: showError,
      },
      className,
    );

    return (
      <InputWrapper className={wrapperClass}>
        <StyledInput type="text" onChange={this.onChange} onBlur={this.onBlur} {...restProps} data-test-hook={testHook} aria-invalid={showError} />
        {showError && <Message aria-live="polite">{error}</Message>}
      </InputWrapper>
    );
  }

  private onChange(event: any) {
    const { onChange } = this.props;

    this.setState({ touched: true });

    onChange && onChange(event);
  }

  private onBlur(event: any) {
    const { onBlur } = this.props;
    onBlur && onBlur(event);
  }
}
