import { translateEnum } from '@common/enum';

enum OrderStatus {
  Processed = 'Processed',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
}

export function translateOrderStatus(key: OrderStatus, name: string = 'OrderStatus') {
  return translateEnum(name, key);
}

export default OrderStatus;
