import IAppThunkAction from '@store/contracts/IAppThunkAction';
import {
  IReceiveNotificationAction,
  IReceivePushNotificationAction,
  IRemovePushNotificationAction,
  IUpdateNotificationStatusAction,
} from './contracts/Actions';
import { IHubNotification, IPushNotification } from '@models';
import { NotificationStatus } from '@enums';
import { isNewOrderNotification } from '@common/notification';
import { patch } from '@common/fetch';

export { default as INotificationState } from './contracts/INotificationState';

const NOTIFICATION_TIMEOUT_MS = 3000;

export type KnownNotificationAction =
  | IReceiveNotificationAction
  | IReceivePushNotificationAction
  | IRemovePushNotificationAction
  | IUpdateNotificationStatusAction;

export const notificationActions = {
  receiveNotification: (notification: IHubNotification, isPush: boolean = false): IAppThunkAction<KnownNotificationAction> => async (
    dispatch,
    getState,
  ) => {
    dispatch({ type: 'RECEIVE_NOTIFICATION', notification });

    if (isPush) {
      notificationActions.receivePushNotification(notification)(dispatch, getState);
    }
  },
  receivePushNotification: (notification: IPushNotification): IAppThunkAction<KnownNotificationAction> => async (dispatch, getState) => {
    dispatch({
      type: 'RECEIVE_PUSH_NOTIFICATION',
      notification,
    });

    setTimeout(() => {
      notificationActions.removePushNotification(notification)(dispatch, getState);
    }, NOTIFICATION_TIMEOUT_MS);
  },
  removePushNotification: (notification: IPushNotification): IAppThunkAction<KnownNotificationAction> => async dispatch => {
    dispatch({ type: 'REMOVE_PUSH_NOTIFICATION', notification });
  },
  markAllAsRead: (): IAppThunkAction<KnownNotificationAction> => async (_dispatch, getState) => {
    const { notifications } = getState().notifications;
    const notificationIds = notifications
      .filter(notification => notification.id && notification.status === NotificationStatus.New)
      .map(notification => notification.id);

    if (notificationIds.length > 0) {
      await patch('notifications', notificationIds, {
        headers: {
          'x-action': 'read',
        },
      });

      notificationActions.setStatus(notificationIds, NotificationStatus.Read)(_dispatch, getState);
    }
  },
  clearAll: (): IAppThunkAction<KnownNotificationAction> => async (_dispatch, getState) => {
    const { notifications } = getState().notifications;
    const notificationIds = notifications.filter(notification => notification.id).map(notification => notification.id);

    if (notificationIds.length > 0) {
      await patch('notifications', notificationIds, {
        headers: {
          'x-action': 'delete',
        },
      });

      notificationActions.setStatus(notificationIds, NotificationStatus.Deleted)(_dispatch, getState);
    }
  },
  setStatus: (notificationIds: number[], status: NotificationStatus): IAppThunkAction<KnownNotificationAction> => async dispatch => {
    dispatch({ type: 'SET_NOTIFICATION_STATUS', notificationIds, status });
  },
  removeNewOrderNotifications: (): IAppThunkAction<KnownNotificationAction> => async (_dispatch, getState) => {
    const { notifications } = getState().notifications;
    const notificationIds = notifications.filter(notification => isNewOrderNotification(notification)).map(notification => notification.id);

    if (notificationIds.length > 0) {
      await patch('notifications', notificationIds, {
        headers: {
          'x-action': 'delete',
        },
      });

      notificationActions.setStatus(notificationIds, NotificationStatus.Deleted)(_dispatch, getState);
    }
  },
};
