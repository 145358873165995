import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { breakpoint, pxToRem, setFont } from '@styles';

export const Nav = styled.nav`
  height: 100%;
`;

export const Li = styled.li`
  display: flex;
  color: ${props => props.theme.colors.white};
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(40)};
  margin-bottom: ${pxToRem(10)};
  ${breakpoint('m')`
    font-size: ${pxToRem(26)};
    margin-bottom: ${pxToRem(30)};
  `}
  ${breakpoint('l')`
    color: inherit;
    display: inline-table;
    padding-right: ${pxToRem(40)};
    height: inherit;
    font-size: ${pxToRem(18)};
  `}
`;

const linkStyle = css`
  ${props => `
    height: inherit;
    width: 100%;
    text-align: center;
    position: relative;
    ${breakpoint('l')`
      display: table-cell;
      vertical-align: middle;
      border-bottom: 5px solid transparent;
      text-align: left;
    `}
    &.active {
      ${setFont('bold')};
      ${breakpoint('l')`
        color: ${props.theme.colors.lipstick};
        border-color: ${props.theme.colors.lipstick};
      `};
    }
    &:hover {
      ${breakpoint('l')`
        border-color: ${props.theme.colors.lipstick};
      `};
    }
  `}
`;

const disabledStyle = css`
  cursor: default;
  color: ${props => props.theme.colors.silver};
`;

export const InternalLink = styled(NavLink)`
  ${linkStyle}
`;

export const MenuItemText = styled.span`
  position: relative;
  .count {
    position: absolute;
    right: -${pxToRem(23)};
    top: -${pxToRem(8)};
    border-color: ${props => props.theme.colors.white};
    ${breakpoint('m')`
      top: -${pxToRem(2)};
    `}
    ${breakpoint('l')`
      top: -${pxToRem(15)};
      right: -${pxToRem(10)};
    `}
  }
  &.disabled {
    height: inherit;
    width: 100%;
    text-align: center;
    ${breakpoint('l')`
      display: table-cell;
      vertical-align: middle;
    `}
    ${disabledStyle}
  }
`;

export const ButtonWrapper = styled.div`
  ${linkStyle}
  .icon {
    color: ${props => props.theme.colors.laPalma};
    position: absolute;
    top: -${pxToRem(6)};
    ${breakpoint('l')`
      top: ${pxToRem(10)};
    `}
  }
  &.disabled:hover {
    ${breakpoint('l')`
        border-color: transparent;
      `};
  }
  button:disabled {
    ${disabledStyle}
  }
`;
