import i18next from 'i18next';
import { lcFirst } from './string';

export function getEnumValues(enumObject: any, isNumber: boolean = true): typeof enumObject[] {
  return getEnumKeys(enumObject, true).map(key => (isNumber ? Number(key) : key) as any);
}

export function getEnumKeys(enumObject: any, asIndex: boolean = false): string[] {
  return Object.keys(enumObject).filter((key: any) => {
    const isNumeric = isNaN(Number(enumObject[key as any]));

    return (!asIndex && !isNumeric) || (asIndex && isNumeric);
  });
}

export function translateEnum(name: string, key: any) {
  const preparedKey = [name, key].map(s => lcFirst(s as string)).join('.');

  return i18next.t(`enums.${preparedKey}`);
}
