import { Action, Reducer } from 'redux';
import IApiState from './contracts/IApiState';
import { KnownApiAction } from './';

const unloadedState: IApiState = {
  error: null,
};

export const apiReducer: Reducer<IApiState> = (state: IApiState, incomingAction: Action) => {
  const action = incomingAction as KnownApiAction;

  switch (action.type) {
    case 'RECEIVE_API_ERROR':
      return {
        ...state,
        error: action.error,
      };
    case 'REMOVE_API_ERROR':
      return {
        ...state,
        error: unloadedState.error,
      };
  }

  return state || unloadedState;
};
