import React from 'react';
import classNames from 'classnames';
import { Abbr } from './styles';
import TradeInstrument, { translateTradeInstrument } from '@enums/TradeInstrument';

interface ITradeInstrumentIndicatorProps extends React.HTMLProps<HTMLSpanElement> {
  tradeInstrument: TradeInstrument;
  fixedWidth?: boolean;
}

export default function TradeInstrumentIndicator({ tradeInstrument, className, fixedWidth }: ITradeInstrumentIndicatorProps) {
  const title = translateTradeInstrument(tradeInstrument);
  const abbrClass = classNames('trade-instrument-indicator', className, {
    'fixed-width': fixedWidth,
  });

  return (
    <Abbr className={abbrClass} title={title} aria-label={title}>
      {translateTradeInstrument(tradeInstrument, 'tradeInstrumentAbbreviations')}
    </Abbr>
  );
}
