import { createGlobalStyle } from 'styled-components';
import { BASE_FONT_SIZE, breakpoint, BREAKPOINT_GUTTER, pxToRem, setFont } from '.';

const GlobalStyles = createGlobalStyle`
  ${(props: any) => `
    @font-face {
      font-family: 'grotesque';
      src:  url('/fonts/GalanoGrotesque-Regular.woff2') format('woff2'),
            url('/fonts/GalanoGrotesque-Regular.woff') format('woff');
    }
     @font-face {
      font-family: 'grotesque-bold';
       src:  url('/fonts/GalanoGrotesque-Bold.woff2') format('woff2'),
            url('/fonts/GalanoGrotesque-Bold.woff') format('woff');
    }
    @font-face {
      font-family: 'grotesque-thin';
       src:  url('/fonts/GalanoGrotesque-Thin.woff2') format('woff2'),
            url('/fonts/GalanoGrotesque-Thin.woff') format('woff');
    }
    *{
      ${setFont()}
      font-variant-numeric: tabular-nums;
      box-sizing: border-box;
      font-weight: normal;
      &:focus {
        outline: none;
      }
      -webkit-overflow-scrolling: touch; /* Fixes sluggish scrolling on ios */
    }
    html {
      font-size: ${BASE_FONT_SIZE}px;
      &.no-scroll {
        /* Duplicate definition on the overflow needed to prevent scrolling on ios */
        overflow: hidden;
        body {
          overflow: hidden;
        }
      }
    }
    body {
      padding: 0;
      margin: 0;
      color: ${props.theme.colors.mineShaft};
      height: inherit;
      &.using-keyboard {
        *:focus {
          outline-style: dotted;
          outline-width: ${pxToRem(1)};
          outline-color: ${props.theme.colors.silver};
          outline-offset: -${pxToRem(1)};
        }
      }
      &.center-layout {
        overflow-y: scroll;
        &.modal-open {
          overflow-y: hidden;
          ${breakpoint('m')`
            overflow-y: scroll;
          `}
        }
      }
    }
    input, textarea, select {
      font-size: ${pxToRem(BASE_FONT_SIZE)};
      /* Fix for zooming issue on safari ios */
      @supports (-webkit-overflow-scrolling: touch) {
        font-size: 16px;
      }
    }
    input:not([type="checkbox"]) {
      -webkit-appearance: none;
    }
    input:not([type="checkbox"])::-ms-clear {
      display: none;
      width : 0;
      height: 0;
    }
    fieldset {
      border: none;
      padding: 0;
      margin: 0;
      min-width: 0;
    }
    legend {
      padding: 0;
    }
    #app {
      height: inherit;
    }
    main {
      display: block;
    }
    a {
      display: block;
      color: inherit;
      text-decoration: none;
    }
    ul, ol, nav, menu {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    dl, dt, dd {
      margin: 0;
    }
    table{
      border-collapse: collapse;
      border-spacing: 0;
    }
    th, td {
      padding: 0;
      text-align: left;
    }
    p, summary {
      line-height: ${pxToRem(25)};
      margin: 0;
    }
    button {
      font-size: inherit;
      background: none;
      border: none;
      padding: 0;
      color: inherit;
      cursor: pointer;
    }
    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      padding: 0;
       color: inherit;
    }
    label {
      font-family: inherit;
    }
    hr {
      border: 0;
      border-bottom: ${pxToRem(1)} solid ${props.theme.colors.alto};
    }
    svg use {
      fill: transparent;
    }
    .visually-hidden {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px; width: 1px;
      margin: 0; padding: 0; border: 0;
    }
    .hidden-s {
      display: none !important;
      ${breakpoint('m')`
        display: block !important;
      `}
    }
    .hidden-m {
      ${breakpoint('m')`
        display: none !important;
      `}
      ${breakpoint('l')`
        display: block !important;
      `}
    }
    .hidden-l {
      ${breakpoint('l')`
        display: none !important;
      `}
    }
    .nowrap {
      white-space: nowrap;
    }
    .ellipsify {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .text-link {
      color: ${props.theme.colors.lipstick};
      text-decoration: underline;
      transition: color .3s ease-in-out;
      &:disabled {
        color: ${props.theme.colors.doveGray};
      }
      &:not(:disabled):hover {
        color: ${props.theme.colors.redViolet};
      }
    }
    .bold {
      ${setFont('bold')}
    }
    .uc-first {
     text-transform: capitalize;
    }
    .button + .button {
      margin-left: ${pxToRem(10)};
    }
    .bootstrapper-loader {
      text-align: center;
      padding: ${pxToRem(BREAKPOINT_GUTTER.m)};
      .loader {
        margin-bottom: ${pxToRem(30)};
      }
      a {
        color: ${props.theme.colors.redViolet};
      }
    }
  `}

`;

export default GlobalStyles;
