import React from 'react';
import classNames from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import includes from 'lodash.includes';
import TrapFocus from '@components/shared/TrapFocus';
import { BackDrop, Body, Description, Dialog, Footer, Form, Header } from './styles';
import Heading, { IHeadingSize } from '@components/shared/Heading';
import { CloseButton, FillButton, OutlineButton } from '@components/shared/Button';
import RenderOnBreakpoint from '@components/shared/RenderOnBreakpoint';
import { IWithBreakpointProps, withBreakpoint } from '@containers/withBreakpoint';
import { toggleBodyScroll } from '@styles/index';
import { History } from 'history';
import LocationDescriptor = History.LocationDescriptor;

export interface IModalComponentProps {
  title?: string;
  descr?: string;
  closeTo?: LocationDescriptor;
  onCancel?: () => void;
  onReady?: () => void;
  onBoth?: () => void;
  size?: 's' | 'm' | 'l';
  headingSize?: IHeadingSize;
  disabled?: boolean;
  readyLabel?: string;
  cancelLabel?: string;
  important?: boolean;
  showReadyButton?: boolean;
  showCancelButton?: boolean;
  showCloseButton?: boolean;
  fixedContent?: boolean;
  autoClose?: boolean;
  isForm?: boolean;
  reverseButtons?: boolean;
  header?: () => JSX.Element;
  getInstance?: (instance: Modal) => void;
  noBodyScroll?: boolean;
}

type IModalProps = {
  children?: any;
} & WithTranslation &
  IModalComponentProps &
  RouteComponentProps &
  IWithBreakpointProps;

interface IModalState {
  bodyOverflow: boolean;
  windowHeight: number;
}

export class Modal extends React.PureComponent<IModalProps, IModalState> {
  dialogElement: HTMLElement;

  state: IModalState = {
    bodyOverflow: false,
    windowHeight: window.innerHeight,
  };

  constructor(props: IModalProps) {
    super(props);

    this.onCancelClicked = this.onCancelClicked.bind(this);
    this.onReadyClicked = this.onReadyClicked.bind(this);
    this.onBackDropClicked = this.onBackDropClicked.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDialog = this.onDialog.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    const { getInstance } = this.props;

    getInstance && getInstance(this);

    this.handleBodyScroll();

    addEventListener('resize', this.onResize);
  }

  componentDidUpdate() {
    this.handleBodyScroll();
  }

  componentWillUnmount() {
    if (!this.props.noBodyScroll && !includes(document.body.className, 'left-column')) {
      toggleBodyScroll(true);
    }
    removeEventListener('resize', this.onResize);
  }

  render() {
    const {
      t,
      children,
      title,
      descr,
      showReadyButton = true,
      showCancelButton = false,
      showCloseButton = true,
      disabled,
      readyLabel,
      cancelLabel,
      important,
      size = 'm',
      headingSize = 'l',
      fixedContent,
      isForm,
      reverseButtons,
      header,
    } = this.props;
    const { bodyOverflow } = this.state;
    const hasHeaderContent = !!header;
    const overlayClass = classNames({
      'size-s': size === 's',
      'size-l': size === 'l',
      'fixed-content': fixedContent,
    });
    const bodyClass = classNames('body', {
      overflowed: bodyOverflow,
    });

    const buttons = [
      showCancelButton && (
        <OutlineButton
          key="btnCancel"
          label={cancelLabel || t('general:cancel')}
          type="button"
          onClick={this.onCancelClicked}
          testHook="modal-btn-cancel"
        />
      ),
      showReadyButton && (
        <FillButton
          key="btnReady"
          important={important}
          label={readyLabel || t('general:close')}
          type={isForm ? 'submit' : 'button'}
          onClick={!isForm ? this.onReadyClicked : null}
          disabled={disabled}
          testHook="modal-btn-ready"
        />
      ),
    ];

    if (reverseButtons) {
      buttons.reverse();
    }

    const content = (
      <>
        {showCloseButton && <CloseButton onClick={this.onCancelClicked} />}

        {(title || hasHeaderContent) && (
          <Header>
            {title && (
              <Heading as="h2" size={headingSize} themed className="title">
                {title}
              </Heading>
            )}
            {descr && <Description>{descr}</Description>}
            <RenderOnBreakpoint medium={hasHeaderContent} large={hasHeaderContent} render={() => header()} />
          </Header>
        )}

        <Body className={bodyClass}>
          <RenderOnBreakpoint small={hasHeaderContent} render={() => <div className="body-header">{header()}</div>} />
          {children}
        </Body>

        {(showCancelButton || showReadyButton) && <Footer>{buttons}</Footer>}
      </>
    );

    return (
      <TrapFocus>
        {setRef => (
          <BackDrop className={overlayClass} onClick={this.onBackDropClicked} ref={setRef}>
            <Dialog role="dialog" aria-label={title} ref={this.onDialog} style={this.getDialogStyle()} data-test-hook="modal">
              {isForm ? <Form onSubmit={this.onSubmit}>{content}</Form> : content}
            </Dialog>
          </BackDrop>
        )}
      </TrapFocus>
    );
  }

  ready() {
    this.onReadyClicked();
  }

  private getDialogStyle() {
    const { isBreakpointSmall } = this.props;

    if (isBreakpointSmall) {
      const { windowHeight } = this.state;

      return {
        // This fixes an issue on ios where 100% height exceeds the viewport.
        height: windowHeight,
      };
    }

    return null;
  }

  private onDialog(instance: any) {
    if (instance) {
      this.dialogElement = instance;

      this.setBodyOverflow();
    }
  }

  private onResize() {
    this.setBodyOverflow();
    this.setWindowHeight();
  }

  private setBodyOverflow() {
    const body = this.dialogElement.querySelector('.body');
    const hasOverflow = body.scrollHeight > Math.ceil(body.getBoundingClientRect().height);

    this.setState({ bodyOverflow: hasOverflow });
  }

  private setWindowHeight() {
    this.setState({
      windowHeight: window.innerHeight,
    });
  }

  private onBackDropClicked(event: any) {
    const hasSelection = window.getSelection().toString().length > 0;

    if (!hasSelection && this.dialogElement && !this.dialogElement.contains(event.target)) {
      this.onCancelClicked();
    }
  }

  private onCancelClicked() {
    const { onCancel, onBoth } = this.props;

    onCancel && onCancel();
    onBoth && onBoth();

    this.close();
  }

  private onReadyClicked() {
    const { onReady, onBoth, autoClose } = this.props;

    onReady && onReady();
    onBoth && onBoth();

    if (autoClose) {
      this.close();
    }
  }

  private close() {
    const { closeTo, location, history } = this.props;

    if (closeTo) {
      const locationDescriptor =
        typeof closeTo === 'string'
          ? {
              pathname: closeTo,
              search: location.search,
            }
          : closeTo;

      history.push(locationDescriptor);
    }
  }

  private onSubmit(event: any) {
    event.preventDefault();

    this.onReadyClicked();
  }

  private handleBodyScroll() {
    const { isBreakpointSmall, noBodyScroll } = this.props;

    if (!noBodyScroll) {
      toggleBodyScroll(!isBreakpointSmall);
    }
  }
}

export default withRouter(withBreakpoint(withTranslation(['general'])(Modal)));
