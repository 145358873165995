import React from 'react';
import classNames from 'classnames';
import { ButtonsWrapper, InputButton, Wrapper } from './styles';
import { SelectElement } from './selectStyles';
import Icon from '@components/shared/Icon';
import { IconType } from '@enums';

export type ISelectValue = string | number;

export interface ISelectOption {
  value: ISelectValue;
  label: string;
}

interface ISelectProps {
  children?: any;
  onChange?: (event: any) => void;
  value?: number | string;
  className?: string;
  disabled?: boolean;
  testHook?: string;
}

export default function Select({ className, children, onChange, value, disabled, testHook }: ISelectProps) {
  const wrapperClass = classNames('select', className);

  return (
    <Wrapper className={wrapperClass}>
      <SelectElement onChange={onChange} value={value || ''} disabled={disabled} data-test-hook={testHook}>
        {children}
      </SelectElement>
      <ButtonsWrapper clickThrough>
        <InputButton clickThrough aria-hidden tabIndex={-1}>
          <canvas width="1" height="1" />
          <Icon type={IconType.Arrow} />
        </InputButton>
      </ButtonsWrapper>
    </Wrapper>
  );
}
