import { Action, Reducer } from 'redux';
import IEventState from './contracts/IEventState';
import { KnownEventAction } from './';

const unloadedState: IEventState = {
  items: [],
  path: null,
  loading: false,
};

export const eventReducer: Reducer<IEventState> = (state: IEventState, incomingAction: Action) => {
  const action = incomingAction as KnownEventAction;

  switch (action.type) {
    case 'REQUEST_EVENTS':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_EVENTS':
      return {
        ...state,
        items: action.items,
        loading: false,
      };
  }

  return state || unloadedState;
};
