import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import config from '@common/config';
import { LANGUAGE } from '@root/i18n';
import Heading from '@components/shared/Heading';
import { Section, Header, Title, Footer } from '@containers/layout/CenterLayout';
import {
  LeftColumn,
  RightColumn,
  Article,
  ContactBlock,
  ContentBlock,
  DateTimeContainer,
  StatisticsBar,
  Text,
  TitleWrapper,
  FooterContent,
} from './styles';
import { eventActions, bannerActions, IApplicationState, orderActions, statisticsActions, supplyRecommendationActions } from '@store';
import Loader from '@components/shared/Loader';
import Statistics from '@components/home/Statistics';
import { AccountType, IconType } from '@enums';
import Icon from '@components/shared/Icon';
import Image from '@components/shared/Image';
import CurrentTime from '@components/shared/CurrentTime';
import { formatDate } from '@components/shared/DateOutput';
import EventList from '@components/home/EventList';
import OrderList from '@components/home/OrderList';
import { Button, OutlineButton } from '@components/shared/Button';
import BannerBlock from '@components/home/BannerBlock';
import HelpLink from '@components/shared/HelpLink';
import RecommendationList from '@components/home/RecommendationList';

function Home() {
  const dispatch = useDispatch();
  const statistics = useSelector((state: IApplicationState) => state.statistics);
  const { user, activeAdministration } = useSelector((state: IApplicationState) => state.user);
  const event = useSelector((state: IApplicationState) => state.event);
  const banner = useSelector((state: IApplicationState) => state.banner);
  const order = useSelector((state: IApplicationState) => state.order);
  const supplyRecommendation = useSelector((state: IApplicationState) => state.supplyRecommendation);
  const { t } = useTranslation('home');
  const { contactUrl } = config;

  useEffect(() => {
    dispatch(orderActions.getRecent());
    dispatch(bannerActions.getBanner());
    dispatch(eventActions.getToday());
  }, []);

  useEffect(() => {
    dispatch(statisticsActions.getAll());
    dispatch(supplyRecommendationActions.getSupplyRecommendation('home.header', 15));
  }, [activeAdministration]);

  return (
    <Article>
      <Header>
        <TitleWrapper>
          <Title text={t('statistics.today')}>
            <Button border icon={IconType.Refresh} onClick={() => dispatch(statisticsActions.getAll())} title={t('statistics.refresh')} />
          </Title>
          <DateTimeContainer>
            <CurrentTime>
              {now => (
                <>
                  {formatDate(now, 'dddd D MMMM YYYY')}
                  <Icon type={IconType.Clock} className="clock-icon" />
                  {formatDate(now, 'HH:mm')}
                </>
              )}
            </CurrentTime>
          </DateTimeContainer>
        </TitleWrapper>
        <StatisticsBar>
          {!statistics.loading && <Statistics data={statistics.today} />}
          {statistics.loading && <Loader />}
        </StatisticsBar>
      </Header>
      <Section>
        <LeftColumn>
          <Heading as="h2" size="s" themed alignTop>
            {t('recommendations.heading')}
          </Heading>
          {supplyRecommendation && !supplyRecommendation.loading && <RecommendationList supplyRecommendations={supplyRecommendation.items} />}
          {supplyRecommendation && supplyRecommendation.loading && <Loader />}

          {banner.item && (
            <ContentBlock>
              <BannerBlock banner={banner.item} />
            </ContentBlock>
          )}
        </LeftColumn>
        <RightColumn>
          <OrderList loading={order.recent.loading} orders={order.recent.lines} />

          <ContentBlock>
            <Heading as="h2" tabIndex={0} size="s" themed alignTop>
              {t('releaseNotes.releaseNotes')} FloraMondo
            </Heading>
            <HelpLink label={t('releaseNotes.viewAll')} configKey="releaseNotes" target="_blank" showIcon />
          </ContentBlock>

          <ContentBlock>
            <EventList loading={event.loading} events={event.items} />
          </ContentBlock>
        </RightColumn>
      </Section>
      <Footer>
        <FooterContent>
          {user.accountType !== AccountType.External && (
            <ContactBlock>
              <Heading as="h3" className="title" size="m">
                {t('contact.title')}
              </Heading>
              <Text>
                {t('contact.description')}: <a href="tel:+310887898989">+31 (0) 88 - 789 89 89</a>
              </Text>
              <OutlineButton href={contactUrl[LANGUAGE]} label={t('contact.options')} title={t('contact.options')} target="_blank" />
            </ContactBlock>
          )}
          <Image src="/images/rfh-main-logo.png" alt="Logo Royal FloraHolland" />
        </FooterContent>
      </Footer>
    </Article>
  );
}

export default React.memo(Home);
