import styled from 'styled-components';
import { breakpoint, pxToRem, setFont } from '@styles/index';

const FOOTER_HEIGHT = 35;

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  ${breakpoint('m')`
    flex-direction: row;
  `}
`;

export const RecommendationItem = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  background: ${props => props.theme.colors.wildSand};
  min-height: ${pxToRem(95)};
  max-width: ${pxToRem(300)};
  padding: ${pxToRem(15)};
  margin: auto;
  &:not(:first-child) {
    margin-top: ${pxToRem(15)};
  }
  .price {
    ${setFont('bold')};
  }
  .main-photo {
    background: ${props => props.theme.colors.wildSand};
    flex: 0 0 33.3%;
    margin-right: ${pxToRem(10)};
  }
  ${breakpoint('m')`
    display: block;
    padding-bottom: ${pxToRem(FOOTER_HEIGHT)};
    &:not(:first-child) {
      margin: 0 0 0 ${pxToRem(5)};
    }
    .main-photo {
      height: ${pxToRem(300)};
      margin-right: 0;
      ${breakpoint('l')`
        height: ${pxToRem(265.5)};
      `}
  `}
`;

export const Info = styled.div`
  flex: 1;
  overflow: hidden;
  ${breakpoint('m')`
    margin-bottom: ${pxToRem(10)};
    width: auto;
  `}
`;

export const InfoLine = styled.div`
  display: flex;
  align-items: center;
  height: ${pxToRem(20)};
  > *:not(:last-child) {
    margin-right: ${pxToRem(10)};
  }
  .line-select {
    margin-top: ${pxToRem(3)};
    overflow: initial;
  }
  .icon {
    height: 100%;
  }
`;

export const Name = styled.span`
  display: flex;
  overflow: hidden;
  width: 100%;
  white-space: normal;
  line-height: ${pxToRem(20)};
  ${setFont('bold')};
  span {
    display: inline-block;
    font-family: inherit;
  }
`;

export const Footer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: ${pxToRem(FOOTER_HEIGHT)};
  width 100%;
  .price {
    margin-left: 33.3%;
    padding-left: ${pxToRem(15)};
  }
  .text-link {
    padding-right: ${pxToRem(15)};
  }
  ${breakpoint('m')`
    width: auto;
    left: 0;
    margin: 0 ${pxToRem(15)};
    padding: ${pxToRem(10)} 0;
    .price, .text-link {
      margin: 0;
      padding: 0;
    }
  `}
`;

export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
