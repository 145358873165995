import { IHubNotification, IPushNotification } from '@models';
import { NotificationLevel, NotificationStatus, NotificationType } from '@enums';

export function createPushNotification(title: string, level?: NotificationLevel): IPushNotification {
  return {
    title,
    type: NotificationType.General,
    level: level || NotificationLevel.Success,
    status: NotificationStatus.New,
  };
}

export function isGeneralWarningNotification(notification: IHubNotification) {
  return notification.type === NotificationType.General && notification.level === NotificationLevel.Warning;
}

export function isGlobalNotification(notification: IHubNotification) {
  const isOrder = isOrderNotification(notification);

  return !isOrder || (isOrder && isErrorNotification(notification));
}

export function isNewOrderNotification(notification: IHubNotification) {
  return isOrderNotification(notification) && notification.status !== NotificationStatus.Deleted;
}

export function isErrorNotification(notification: IHubNotification) {
  return notification.level === NotificationLevel.Error;
}

export function isOrderNotification(notification: IHubNotification) {
  return notification.type === NotificationType.Order;
}
