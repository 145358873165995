import styled from 'styled-components';
import { pxToRem } from '@styles';

export const NavigationButton = styled.button`
  display: flex;
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  width: ${pxToRem(50)};
  color: inherit;
  opacity: 0.5;
  .icon {
    width: ${pxToRem(25)};
    margin: auto;
  }
  &.previous {
    left: 0;
  }
  &.next {
    right: 0;
    .icon {
      transform: rotate(180deg);
    }
  }
`;
