import styled from 'styled-components';
import { breakpoint, pxToRem, setFont } from '@styles';

export const Wrapper = styled.div`
  > * {
    margin-bottom: ${pxToRem(25)};
  }
`;

export const Table = styled.table`
  width: 100%;
  th,
  td {
    width: 100%;
    display: block;
    ${breakpoint('m')`
      display: table-cell;
      width: auto;
    `}
  }
  th {
    vertical-align: top;
    span {
      ${setFont('bold')}
      display: block;
      width: ${pxToRem(125)};
    }
  }
  tr {
    &:not(:last-child) {
      th {
        ${breakpoint('m')`
          padding-bottom: ${pxToRem(15)};
        `}
      }
      td {
        padding-bottom: ${pxToRem(15)};
      }
    }
  }
  .delivery-locations {
    display: inline-block;
  }
  .quantity {
    width: ${pxToRem(75)};
    margin-right: ${pxToRem(10)};
  }
`;

export const TotalPrice = styled.output`
  ${setFont('bold')}
`;

export const DiscountTable = styled.table`
  display: inline-block;
  th,
  td {
    display: table-cell;
    padding-right: ${pxToRem(15)};
  }
  tr:last-child {
    th,
    td {
      padding-bottom: 0;
    }
  }
  th {
    ${setFont()}
    &::after {
      content: ':';
      display: inline-block;
    }
  }
`;

export const LimitWrapper = styled.div`
  ${props => `
    margin-bottom: ${pxToRem(25)};
    ${breakpoint('m')`
      display: flex;
      justify-content: space-between;
      background: ${props.theme.colors.alabaster};
    `}
    .balance-error {
      bottom: -${pxToRem(25)};
    }
  `}
`;

export const LimitBlock = styled.div`
  ${props => `
    position: relative;
    padding: ${pxToRem(10)};
    margin-bottom: ${pxToRem(10)};
    background: ${props.theme.colors.alabaster};
    ${breakpoint('m')`
      display: inline-block;
      margin-bottom: 0;
      &:not(:first-child) {
        &::before {
          content: "";
          width: ${pxToRem(15)};
          height: 100%;
          background: ${props.theme.colors.white};
          position: absolute;
          left: ${pxToRem(-15)};
          top: 0;
        }
      }
    `}
    .price {
      ${setFont('bold')}
    }
    &.error {
      border-bottom: ${pxToRem(2)} solid ${props.theme.colors.red};
      .price {
        color: ${props.theme.colors.red};
      }
    }
  `}
`;

export const Note = styled.aside`
  &:not(:last-child) {
    margin-bottom: 0;
  }
`;
