import 'whatwg-fetch';
import includes from 'lodash.includes';
import config from './config';
import { LANGUAGE } from '@root/i18n';
import dispatchConnectionError from '@common/dispatchConnectionError';
import { store } from '@root/configureStore';
import Cookies from 'js-cookie';

const DEFAULT_FETCH_TIMEOUT = 3000;
const { api } = config;

type ICustomRequestInit = {
  customErrorHandlers?: number[];
  timeout?: number;
  addUserId?: boolean;
} & RequestInit;

export function get(path: string, init?: ICustomRequestInit) {
  return fetch(getApiUrl(path), init);
}

export function post(path: string, body?: any, init?: ICustomRequestInit) {
  return sendBody(path, body, {
    ...init,
    method: 'POST',
  });
}

export function put(path: string, body: any, init?: ICustomRequestInit) {
  return sendBody(path, body, {
    ...init,
    method: 'PUT',
  });
}

export function patch(path: string, body: any, init?: ICustomRequestInit) {
  return sendBody(path, body, {
    ...init,
    method: 'PATCH',
  });
}

export function remove(path: string, init?: ICustomRequestInit) {
  return fetch(getApiUrl(path), {
    method: 'DELETE',
    ...init,
  });
}

export function getApiUrl(path: string, includeHost: boolean = true, includeLanguage = true) {
  let baseUrl = '';

  if (includeHost) {
    baseUrl += api.host;
  }

  baseUrl += `/${api.path}/${api.version}`;

  if (includeLanguage) {
    baseUrl += `/${LANGUAGE}`;
  }

  return `${baseUrl}/${path}`;
}

export function fetch(url: string, init: ICustomRequestInit = { addUserId: true }): Promise<Response> {
  const headers: { [key: string]: any } = {
    ...init.headers,
  };

  try {
    const token = Cookies.get('oktaToken');
    if (!token) {
      // console.log('No okta token found.');
    }
    headers.Authorization = `Bearer ${token}`;
  } catch (x) {
    // console.log('get okta token exception ', x);
  }

  if (init.addUserId) {
    const { user } = store.getState().user;
    const { lastCookieRefreshMoment } = store.getState().app;

    if (user) {
      headers['X-User-Id'] = user.id;
    }
    if (lastCookieRefreshMoment) {
      headers['X-Last-Cookie-Refresh-Moment'] = lastCookieRefreshMoment;
    }
  }

  return window
    .fetch(url, {
      credentials: 'include', // credentials are included to pass through the cookie
      ...(init as RequestInit),
      headers,
    })
    .then(response => handleErrors(response, init))
    .catch(error => {
      dispatchConnectionError({
        message: error.message,
        url,
      });
      throw error;
    });
}

export function timeoutFetch(url: string, init?: ICustomRequestInit): Promise<Response> {
  let timedOut = false;

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      timedOut = true;
      reject(new Error('Request timed out'));
    }, init.timeout || DEFAULT_FETCH_TIMEOUT);

    fetch(url, init)
      .then(response => {
        clearTimeout(timeout);

        if (!timedOut) {
          resolve(response);
        }
      })
      .catch(err => {
        // Rejection already happened with setTimeout
        if (timedOut) return;

        reject(err);
      });
  });
}

function sendBody(path: string, body: any, init: ICustomRequestInit = {}) {
  return fetch(getApiUrl(path), {
    method: 'POST',
    ...init,
    headers: {
      'Content-Type': 'application/json',
      ...init.headers,
    },
    body: JSON.stringify(body),
  });
}

async function handleErrors(response: Response, init?: ICustomRequestInit) {
  const hasCustomErrorHandler = init && !includes(init.customErrorHandlers, response.status);

  if (!response.ok && (!init || hasCustomErrorHandler)) {
    dispatchConnectionError({
      status: response.status,
      message: await response.text(),
      url: response.url,
    });
  }

  return response;
}
