import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100%;
  display: inline-flex;
  position: relative;
  .previous,
  .next {
    width: 22px;
    .icon {
      height: 35px;
    }
  }
  .dots {
    position: absolute;
    width: 100%;
    left: 0px;
    text-align: center;
  }
  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    text-decoration: none;
    color: ${props => props.theme.colors.wildSand};
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    padding: 5px;
    margin: 2px;
    border: 1px solid ${props => props.theme.colors.doveGray};
  }
  .dot.active {
    color: ${props => props.theme.colors.lipstick};
  }
  &.external-navigation {
    padding: 0px 22px 25px 22px;
    background-color: transparant;
    .dots {
      bottom: -10px;
    }
  }
`;
