import { InputButton, Wrapper } from './styles';
import styled from 'styled-components';
import { pxToRem } from '@styles';

export const SearchWrapper = styled(Wrapper)`
  height: 100%;
  min-height: ${pxToRem(30)};
  input {
    border-right: none;
  }
  &.has-value input {
    padding-right: 63px;
  }
`;

export const ClearButton = styled(InputButton)`
  border-left: none;
  border-right: none;
  border-radius: 0;
`;

export const SearchButton = styled(InputButton)``;
