declare let CONFIG: any;

interface IHelpPageConfig {
  en: string;
  nl: string;
  de: string;
  [key: string]: string;
}

interface IConfig {
  api: {
    host: string;
    path: string;
    version: string;
  };
  okta: {
    issuer: string;
    clientId: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
    pkce: boolean;
  };
  notifications: {
    host: string;
  };
  signalR: {
    retryConnectInMs: number;
    numberOfConnectRetries: number;
  };
  contactUrl: {
    de: string;
    en: string;
    nl: string;
    [key: string]: string;
  };
  env: string;
  version?: string;
  feedbackify: {
    formId: {
      de: number;
      en: number;
      nl: number;
      [key: string]: number;
    };
  };
  help: {
    pages: {
      selectDelivery: IHelpPageConfig;
      marketPlace: IHelpPageConfig;
      [key: string]: IHelpPageConfig;
    };
  };
  scan: {
    license: string;
  };
  analyticsKey: string;
  scanKVV: boolean;
}

const config: IConfig = CONFIG;

export default config;
