import styled from 'styled-components';
import { breakpoint, HEADER_HEIGHT, pxToRem } from '@styles';

const NOTIFICATION_HEADER_HEIGHT = 70;
const LIST_ITEM_PADDING = 20;

export const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: calc(100vh - ${pxToRem(HEADER_HEIGHT.s)});
  ${breakpoint('m')`
    overflow-y: hidden;
    max-height: calc(100vh - ${pxToRem(HEADER_HEIGHT.m)});
  `}
  ${breakpoint('l')`
    max-height: calc(100vh - ${pxToRem(HEADER_HEIGHT.l + 20)}); /* includes small offset to workaround safari vh issue */
  `}
`;

const ContentBlock = styled.div`
  padding: ${pxToRem(25)};
`;

export const Header = styled(ContentBlock)`
  height: ${pxToRem(NOTIFICATION_HEADER_HEIGHT)};
  .title {
    margin: 0;
  }
`;

export const Message = styled(ContentBlock.withComponent('span'))`
  display: block;
`;

export const NotificationList = styled.ul`
  ${breakpoint('m')`
    overflow-y: auto;
    max-height: ${pxToRem(600)};
  `}
`;

export const ListItem = styled.li`
  border-left: ${pxToRem(5)} solid ${props => props.theme.colors.white};
  border-bottom: ${pxToRem(2)} solid ${props => props.theme.colors.white};
  position: relative;
  padding: ${pxToRem(LIST_ITEM_PADDING)};
  padding-left: ${pxToRem(LIST_ITEM_PADDING + 35)};
  &.new {
    background: ${props => props.theme.colors.alabaster};
  }
  &.warning {
    background: ${props => props.theme.colors.schoolBus};
    border-left-color: ${props => props.theme.colors.schoolBus};
  }
  &.error {
    .icon,
    .title {
      color: ${props => props.theme.colors.red};
    }
  }
  &:hover {
    border-left-color: ${props => props.theme.colors.lipstick};
  }
  .icon {
    position: absolute;
    left: ${pxToRem(LIST_ITEM_PADDING)};
    top: ${pxToRem(LIST_ITEM_PADDING)};
  }
  .message {
    margin-bottom: ${pxToRem(10)};
  }
  .date {
    font-size: ${pxToRem(13)};
    color: ${props => props.theme.colors.dustyGray};
  }
`;

export const Footer = styled(ContentBlock)`
  border-top: ${pxToRem(1)} solid ${props => props.theme.colors.silver};
  height: ${pxToRem(NOTIFICATION_HEADER_HEIGHT)};
`;
