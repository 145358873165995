import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Block, BlockList, Wrapper } from './styles';
import StatisticBlock from '@components/home/StatisticBlock';
import Icon from '@components/shared/Icon';
import { useBreakpoint } from '@containers/withBreakpoint';
import { IconType } from '@enums';
import { IStatistics } from '@models';
import { UserConnect } from '@containers/shared/Connect';
import { NavigationButton } from '@components/shared/NavigationButton/styles';

type IStatisticsProps = {
  data: IStatistics;
};

function Statistics({ data }: IStatisticsProps) {
  const [step, setStep] = useState(0);
  const { t } = useTranslation('home');
  const { isBreakpointLarge, isBreakpointSmall } = useBreakpoint();

  const listStyle =
    !isBreakpointLarge && step > 0
      ? {
          transform: `translateX(-${step * 100}%)`,
        }
      : null;

  const getStepSize = () => {
    return isBreakpointSmall ? 1 : 2;
  };

  const blocks = [
    <Block key="block-1">
      <StatisticBlock to="/clock-status" value={data.numberOfActiveClocks} title={t('statistics.clocks')} icon={IconType.AuctionClock} />
    </Block>,
    <UserConnect key="block-2">
      {({ user }) => {
        const hasAccess = user.authorization.account.read;

        return (
          <Block>
            <StatisticBlock
              to={hasAccess ? '/transactions' : ''}
              value={data.turnover}
              title={t('statistics.turnover')}
              isPrice
              className={hasAccess ? '' : 'disabled'}
            />
          </Block>
        );
      }}
    </UserConnect>,
    <UserConnect key="block-3">
      {({ user }) => {
        const hasAccess = user.authorization.account.read;

        return (
          <Block>
            <StatisticBlock
              to={hasAccess ? '/transactions' : ''}
              value={data.numberOfOrders}
              title={t('statistics.transactions')}
              className={hasAccess ? '' : 'disabled'}
            />
          </Block>
        );
      }}
    </UserConnect>,
  ];

  return (
    <Wrapper data-test-hook="statistic-blocks">
      {step > 0 && (
        <NavigationButton className="previous" onClick={() => setStep(step - 1)}>
          <Icon type={IconType.LessThan} />
        </NavigationButton>
      )}
      <BlockList style={listStyle}>{blocks}</BlockList>
      {step < Math.ceil(blocks.length / getStepSize()) - 1 && (
        <NavigationButton className="next" onClick={() => setStep(step + 1)}>
          <Icon type={IconType.LessThan} />
        </NavigationButton>
      )}
    </Wrapper>
  );
}

export default React.memo(Statistics);
