import styled from 'styled-components';
import { pxToRem } from '@styles';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  height: ${pxToRem(20)};
`;

// http://nicolasgallagher.com/canvas-fix-svg-scaling-in-internet-explorer/
export const Canvas = styled.canvas`
  display: block;
  height: 100%;
  visibility: hidden;
`;

export const Svg = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;
