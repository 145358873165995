import React from 'react';

interface ICharacteristicsListItemProps {
  title?: string;
  children?: any;
}

export default function PropertyListItem({ title, children }: ICharacteristicsListItemProps) {
  return (
    <tr>
      <td title={title} tabIndex={0} colSpan={title ? 1 : 2}>
        {title || children}
      </td>
      {title && <td tabIndex={0}>{children}</td>}
    </tr>
  );
}
