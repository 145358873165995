import React from 'react';
import { Content, Wrapper } from './styles';
import Heading from '@components/shared/Heading';
import { useTranslation } from 'react-i18next';
import { FillButton } from '@components/shared/Button';

export default function NotAllowedBrowser() {
  const { t } = useTranslation('general');

  return (
    <Wrapper>
      <img src="/images/main-logo.png" alt="FloraMondo logo" className="logo" />
      <Content>
        <Heading size="xxl" themed>
          {t('notAllowedBrowser.title')}
        </Heading>
        <Heading as="h2" size="xs">
          {t('notAllowedBrowser.description')}
        </Heading>

        {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
        <div dangerouslySetInnerHTML={{ __html: t('notAllowedBrowser.text') }} />

        <FillButton label="www.royalfloraholland.com" href="http://www.royalfloraholland.com" />
      </Content>
    </Wrapper>
  );
}
