import styled from 'styled-components';
import { pxToRem, setFont } from '@styles';

export const Wrapper = styled.div`
  border-radius: 50%;
  color: ${props => props.theme.colors.black};
  border: ${pxToRem(1)} solid ${props => props.theme.colors.black};
  font-size: ${pxToRem(11)};
  ${setFont('bold')}
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  pointer-events: none;
  &.themed {
    border-color: ${props => props.theme.colors.lipstick};
    background-color: ${props => props.theme.colors.lipstick};
    color: ${props => props.theme.colors.white};
  }
`;
