import { appActions } from '@store/app';
import { store } from './../configureStore';

export default function initializeWindowEventListners() {
  window.addEventListener('offline', () => {
    appActions.setOnline(false)(store.dispatch, store.getState);
  });
  window.addEventListener('online', () => {
    appActions.setOnline(true)(store.dispatch, store.getState);
  });
}
