import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState } from '@store';
import { supplyActions } from '@store/supply';
import { ISupplyLine } from '@models';

type ISupplyLineProviderProps = {
  id: string;
  children: (supplyLine: ISupplyLine, loading: boolean) => JSX.Element;
};

function SupplyLineProvider({ id, children }: ISupplyLineProviderProps) {
  const dispatch = useDispatch();
  const {
    detail: { loading, lines },
  } = useSelector((state: IApplicationState) => state.supply);
  const supplyLine = lines[id];

  useEffect(() => {
    dispatch(supplyActions.getSupplyLine(id));
  }, [id]);

  return children(supplyLine, loading);
}

export default React.memo(SupplyLineProvider);
