import styled from 'styled-components';
import { pxToRem } from '@styles';

export const PriceRangeWrapper = styled.div`
  .price-input {
    display: inline-block;
    width: 48%;
    margin-bottom: ${pxToRem(10)};
    &:first-child {
      margin-right: 4%;
    }
  }
`;
