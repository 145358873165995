import { Action, Reducer } from 'redux';
import { KnownSupplyMarkingAction } from './';
import ISupplyMarkingState from './contracts/ISupplyMarkingState';
import store from 'store';

const unloadedState: ISupplyMarkingState = {
  quantity: 0,
  customer: '',
  price: 0,
  maxPrice: 0,
  note: '',
  queue: [],
};

export const supplyMarkingReducer: Reducer<ISupplyMarkingState> = (state: ISupplyMarkingState, incomingAction: Action) => {
  const action = incomingAction as KnownSupplyMarkingAction;

  switch (action.type) {
    case 'REQUEST_MARK':
      return {
        ...state,
        actionInProgress: true,
      };
    case 'MARK_SUCCEEDED':
      return {
        ...state,
        selection: [],
        actionInProgress: false,
      };
    case 'MARK_FAILED':
      return {
        ...state,
        actionInProgress: false,
      };
    case 'ADD_MARK_TO_QUEUE':
      const newQueue = [...state.queue, action.marking];
      store.set('marking-queue', newQueue);

      return {
        ...state,
        queue: newQueue,
      };
    case 'REMOVE_MARK_FROM_QUEUE':
      const filteredQueue = state.queue.filter(marking => marking.id !== action.id);
      store.set('marking-queue', filteredQueue);

      return {
        ...state,
        queue: filteredQueue,
      };
    case 'SET_MARK_QUEUE':
      return {
        ...state,
        queue: [...state.queue, ...action.markings],
      };
  }

  return state || unloadedState;
};
