import React, { Fragment } from 'react';
import classNames from 'classnames';
import includes from 'lodash.includes';
import Heading from '@components/shared/Heading';
import { PropertyValue } from './styles';
import { Article, Body, Header, InfoGroup, SubTitle, SupplierPropertyList, TitleWrapper } from '@components/shared/LineDetails';
import { PropertyList, PropertyListItem } from '@components/shared/PropertyList';
import { useTranslation } from 'react-i18next';
import PriceOutput, { formatPrice } from '@components/shared/PriceOutput';
import { ICharacteristic, ISupplyLine } from '@models';
import ModelDetailMainPhoto from '@components/shared/ModelDetailMainPhoto';
import SupplyLineBuyButton from './SupplyLineBuyButton';
import ColorIndicator from '@components/shared/ColorIndicator';
import { formatDate } from '@components/shared/DateOutput';
import Loader from '@components/shared/Loader';
import { filterDeliveryOptions } from '@common/supply';
import ISupplyDelivery from '@models/ISupplyDelivery';
import SupplyLineTrading from '@containers/shared/SupplyLineTrading';
import TradeInstrument from '@enums/TradeInstrument';
import SupplyLineClockPresalesRequestButton from './SupplyLineClockPresalesRequestButton';

const CERTIFICATION_CODES = ['S97', 'S98'];

interface ISupplyLineDetailProps {
  supplyLine: ISupplyLine;
  compact?: boolean;
  overflow?: boolean;
  loading?: boolean;
  photoPath: string;
  buyPath: string;
  clockPresalesRequestPath?: string;
  onClockPresalesRequest?: (supplyLine: ISupplyLine) => void;
}

function SupplyLineDetail(props: ISupplyLineDetailProps) {
  const { supplyLine, compact, overflow = true, loading, photoPath, buyPath, clockPresalesRequestPath, onClockPresalesRequest } = props;
  const { t } = useTranslation('marketPlace');
  const supplyLineNotfound = !loading && !supplyLine;

  if (loading) {
    return <Loader />;
  }

  if (supplyLineNotfound) {
    return <span>{t('supplyLineDetail.error')}</span>;
  }

  const { price, tradeItem } = supplyLine;
  const { vbnProduct } = tradeItem.growerProduct;
  const isClockSupply = supplyLine.tradeInstrument === TradeInstrument.Clock;
  const articleClass = classNames({ compact, overflow });
  const imageSize = compact ? 125 : 335;

  const getPresentationalCharacteristics = () => {
    return tradeItem.characteristics.filter(c => !includes(CERTIFICATION_CODES, c.code) && !includes(c.code, 'K') && c.code !== 'S62');
  };

  const getCertificationCharacteristics = () => {
    return tradeItem.characteristics.filter(c => includes(CERTIFICATION_CODES, c.code) || includes(c.code, 'K'));
  };

  return (
    <Article className={articleClass} aria-label={tradeItem.growerProduct.name} data-test-hook="supply-line-detail">
      <Header>
        <TitleWrapper>
          <ColorIndicator color={vbnProduct.mainColor.rgb} />
          <Heading as="h2" className="title" size="xs" alignTop>
            {tradeItem.growerProduct.name}
          </Heading>
        </TitleWrapper>

        <SubTitle>{tradeItem.supplier.companyName}</SubTitle>

        <ModelDetailMainPhoto model={supplyLine} className="main-photo" photoPath={photoPath.replace(':id', supplyLine.id)} width={imageSize} />

        <SupplyLineTrading supplyLine={supplyLine}>
          {({ supplyLineId }, isTradable, canRequestClockPresales) => (
            <Fragment>
              {isTradable && <SupplyLineBuyButton to={buyPath.replace(':id', supplyLineId)} className="buy-button" />}
              {canRequestClockPresales && (
                <SupplyLineClockPresalesRequestButton
                  to={clockPresalesRequestPath.replace(':id', supplyLineId)}
                  supplyLine={supplyLine}
                  onClick={onClockPresalesRequest}
                  className="clock-presales-request-button"
                />
              )}
            </Fragment>
          )}
        </SupplyLineTrading>
      </Header>

      <Body>
        <InfoGroup>
          <Heading as="h3">{t('supplyLineDetail.productInformation')}</Heading>
          <PropertyList title={t('supplyLineDetail.productInformation')}>
            <PropertyListItem title={t('supplyLine.name')}>{tradeItem.growerProduct.name}</PropertyListItem>
            <PropertyListItem title={t('supplyLine.number')}>{vbnProduct.code}</PropertyListItem>
            <PropertyListItem title={t('supplyLine.vbnName')}>{vbnProduct.name}</PropertyListItem>
            <PropertyListItem title={t('supplyLine.productGroup')}>{vbnProduct.productGroup.name}</PropertyListItem>
          </PropertyList>
        </InfoGroup>

        <InfoGroup>
          <Heading as="h3">{t(!isClockSupply ? 'supplyLineDetail.priceAndDelivery' : 'supplyLineDetail.delivery')}</Heading>
          <PropertyList title={t('supplyLineDetail.priceAndDelivery')}>
            {!isClockSupply && (
              <PropertyListItem title={t('supplyLine.price')}>
                <PriceOutput price={price} />
              </PropertyListItem>
            )}
            <PropertyListItem title={t('supplyLine.amountAvailable')}>{supplyLine.availablePackages}</PropertyListItem>
            {isClockSupply && (
              <SupplyLineTrading supplyLine={supplyLine}>
                {(tradableSupplyLine, isTradableClockSupply) =>
                  isTradableClockSupply && (
                    <PropertyListItem title={t('supplyLine.clockPresales')}>
                      <>{`${tradableSupplyLine.availablePackages} x ${formatPrice(tradableSupplyLine.price)}`}</>
                    </PropertyListItem>
                  )
                }
              </SupplyLineTrading>
            )}
            <PropertyListItem title={t('supplyLine.load')}>
              {`
                  ${supplyLine.tradeItem.packing.layersPerLoadCarrier} x
                  ${supplyLine.tradeItem.packing.packagesPerLayer} x
                  ${supplyLine.tradeItem.packing.itemsPerPackage}
                  (${supplyLine.tradeItem.packing.package.code})
                  ${supplyLine.tradeItem.packing.loadCarrier.shortName}
                `}
            </PropertyListItem>
            {supplyLine.fulfillment?.deliveryForm?.code && (
              <PropertyListItem title={t('supplyLine.deliveryForm')}>
                {`
                  ${supplyLine.fulfillment.deliveryForm.code}${supplyLine.fulfillment.deliveryForm.letter}
                `}
              </PropertyListItem>
            )}
            {supplyLine.trolleyLocations?.length > 0 && (
              <PropertyListItem title={t('supplyLine.trolleyLocations')}>
                {supplyLine.trolleyLocations.map(location => (
                  <PropertyValue key={location}>{location}</PropertyValue>
                ))}
              </PropertyListItem>
            )}
          </PropertyList>
        </InfoGroup>

        <SupplyLineTrading supplyLine={supplyLine}>
          {({ deliveries }, isTradable) =>
            isTradable && (
              <InfoGroup>
                <Heading as="h3">{t('supplyLine.deliveryMoments')}</Heading>
                <PropertyList title={t('supplyLine.deliveryMoments')}>
                  <PropertyListItem>
                    {filterDeliveryOptions<ISupplyDelivery>(deliveries, d => d.latestDeliveryMoment).map((delivery, index) => (
                      <PropertyValue key={index}>
                        {t('supplyLine.deliveryUntil', {
                          delivery: `${delivery.location} ${formatDate(delivery.latestDeliveryMoment, 'dd HH:mm')}`,
                          until: formatDate(delivery.latestOrderMoment, 'dd HH:mm'),
                        })}
                      </PropertyValue>
                    ))}
                  </PropertyListItem>
                </PropertyList>
              </InfoGroup>
            )
          }
        </SupplyLineTrading>

        {supplyLine.tradeItem.growerProduct.countryOfOrigin && (
          <InfoGroup>
            <Heading as="h3">{t('supplyLineDetail.additionalTradingInformation')}</Heading>
            <PropertyList title={t('supplyLineDetail.additionalTradingInformation')}>
              <PropertyListItem title={t('supplyLine.origin')}>{supplyLine.tradeItem.growerProduct.countryOfOrigin}</PropertyListItem>
            </PropertyList>
          </InfoGroup>
        )}

        <InfoGroup>
          <Heading as="h3">{t('supplyLineDetail.characteristics')}</Heading>
          <PropertyList title={t('supplyLineDetail.characteristics')}>
            {getPresentationalCharacteristics().map((characteristic: ICharacteristic) => (
              <PropertyListItem key={characteristic.code} title={characteristic.name}>
                {characteristic.valueName}
              </PropertyListItem>
            ))}
          </PropertyList>
        </InfoGroup>

        <InfoGroup>
          <Heading as="h3">{t('supplyLineDetail.quality')}</Heading>
          <PropertyList title={t('supplyLineDetail.quality')}>
            <PropertyListItem title={t('supplyLine.qualityCode')}>{tradeItem.qualityCode}</PropertyListItem>
            {getCertificationCharacteristics().map((characteristic: ICharacteristic) => (
              <PropertyListItem key={characteristic.code} title={characteristic.name}>
                {characteristic.valueName}
              </PropertyListItem>
            ))}
          </PropertyList>
        </InfoGroup>

        <InfoGroup>
          <Heading as="h3">{t('supplyLineDetail.supplier')}</Heading>
          <SupplierPropertyList title={t('supplyLineDetail.supplier')} supplier={tradeItem.supplier} />
        </InfoGroup>
      </Body>
    </Article>
  );
}

export default React.memo(SupplyLineDetail);
