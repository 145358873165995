import { translateEnum } from '@common/enum';
import AccountType from '@enums/AccountType';

enum Language {
  NL = 'nl',
  EN = 'en',
  DE = 'de',
}

export function translateLanguage(key: AccountType, name: string = 'Language') {
  return translateEnum(name, key.toLocaleLowerCase());
}

export default Language;
