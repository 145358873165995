import React from 'react';
import classNames from 'classnames';
import { Wrapper } from './styles';
import { LANGUAGE } from '@root/i18n';

const DEFAULT_NUMBER_OF_DIGITS = 3;

interface IPriceOutputProps {
  price?: number;
  tabIndex?: number;
  numberOfDigits?: number;
  className?: string;
  suffixWhenRound?: boolean;
  alternate?: boolean;
}

export default function PriceOutput({
  price,
  tabIndex,
  numberOfDigits = DEFAULT_NUMBER_OF_DIGITS,
  className,
  suffixWhenRound,
  alternate,
}: IPriceOutputProps) {
  const wrapperClass = classNames('price', className, { alternate });

  return (
    <Wrapper tabIndex={tabIndex} className={wrapperClass}>
      {formatPrice(price, numberOfDigits, suffixWhenRound)}
    </Wrapper>
  );
}

export function formatPrice(price: number, numberOfDigits: number = DEFAULT_NUMBER_OF_DIGITS, suffixWhenRound?: boolean) {
  const suffix = suffixWhenRound && LANGUAGE.toLowerCase() === 'nl' && price % 1 === 0 ? ',-' : '';
  const minimumFractionDigits = !suffix ? numberOfDigits : 0;
  const result = new Intl.NumberFormat(LANGUAGE, { style: 'currency', currency: 'EUR', minimumFractionDigits }).format(price);

  return `${result}${suffix}`;
}
