import { Action, Reducer } from 'redux';
import { KnownHelpAction } from './';
import IHelpState from '@store/help/contracts/IHelpState';

const unloadedState: IHelpState = {
  pages: {
    all: {
      items: [],
      loading: false,
    },
    detail: {
      items: {},
      loading: false,
    },
  },
  questions: {
    items: {},
    loading: false,
  },
  search: {
    loading: false,
    path: '',
    items: [],
  },
};

export const helpReducer: Reducer<IHelpState> = (state: IHelpState, incomingAction: Action) => {
  const action = incomingAction as KnownHelpAction;

  switch (action.type) {
    case 'REQUEST_HELP_PAGES':
      return {
        ...state,
        pages: {
          ...state.pages,
          all: {
            ...state.pages.all,
            loading: true,
          },
        },
      };
    case 'RECEIVE_HELP_PAGES':
      return {
        ...state,
        pages: {
          ...state.pages,
          all: {
            ...state.pages.all,
            items: action.pages,
            loading: false,
          },
          detail: unloadedState.pages.detail,
        },
      };
    case 'REQUEST_HELP_DETAIL_PAGE':
      return {
        ...state,
        pages: {
          ...state.pages,
          detail: {
            ...state.pages.detail,
            loading: true,
          },
        },
      };
    case 'RECEIVE_HELP_DETAIL_PAGE':
      const { page } = action;

      return {
        ...state,
        pages: {
          ...state.pages,
          detail: {
            ...state.pages.detail,
            items: {
              ...state.pages.detail.items,
              [page.id]: page,
            },
            loading: false,
          },
        },
      };
    case 'REQUEST_HELP_QUESTIONS':
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: true,
        },
      };
    case 'RECEIVE_HELP_QUESTIONS':
      return {
        ...state,
        questions: {
          ...state.questions,
          items: {
            ...state.questions.items,
            [action.pageId]: action.questions,
          },
          loading: false,
        },
      };
    case 'REQUEST_SEARCH_HELP_PAGES':
      return {
        ...state,
        search: {
          ...state.search,
          loading: true,
          path: action.path,
        },
      };
    case 'RECEIVE_SEARCH_HELP_PAGES':
      return {
        ...state,
        search: {
          ...state.search,
          loading: false,
          items: action.pages,
        },
      };
  }

  return state || unloadedState;
};
