import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';
import { fillButtonStyles } from './styles';
import BaseButton, { IBaseButtonProps } from './BaseButton';

export type IFillButtonProps = {
  important?: boolean;
  color?: 'black' | 'white';
} & IBaseButtonProps;

const FillButton = styled(({ className, important, ...restProps }: any) => (
  <BaseButton className={classNames(className, { important })} {...restProps} />
))`
  ${fillButtonStyles}
`;

export default FillButton;
