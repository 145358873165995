import { IUser } from '@models';
import qs from 'query-string';
import * as signalR from '@aspnet/signalr';
import config from '@common/config';
import initializeNotifications from './notification';
import reset from '@common/reset';

const { notifications } = config;

export let connection: signalR.HubConnection;
let numberOfConnectionRetries: number = 0;

export async function initialize(user?: IUser) {
  if (!user) return;

  const hubUrl = `${notifications.host}/hubs/notification?${qs.stringify({
    // Only expose the data we need
    user: JSON.stringify({
      id: user.id,
      name: user.name,
      language: user.language,
      accountType: user.accountType,
    }),
  })}`;

  // @ts-ignore
  if (window.signalrMock) {
    // @ts-ignore
    connection = window.signalrMock;
  } else {
    connection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    await startConnection(hubUrl);
  }

  initializeNotifications();

  connection.onclose(async () => {
    await startConnection(hubUrl);
  });
}

async function startConnection(hubUrl: string) {
  try {
    await connection.start();
  } catch {
    if (++numberOfConnectionRetries >= config.signalR.numberOfConnectRetries && navigator.onLine) {
      reset();
    } else {
      setTimeout(async () => {
        await startConnection(hubUrl);
      }, config.signalR.retryConnectInMs);
    }
  }
}
