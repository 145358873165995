enum IconType {
  Arrow,
  AuctionClock,
  Bell,
  Calendar,
  Check,
  Clock,
  Cross,
  Edit,
  Error,
  Figure,
  Filter,
  FilterFlowers,
  FilterHousePlants,
  FilterGardenPlants,
  Gear,
  Globe,
  Grid,
  Hamburger,
  Info,
  LessThan,
  List,
  Magnifier,
  MagnifierMinus,
  MagnifierPlus,
  Message,
  MultipleImages,
  NoColor,
  PhotoCamera,
  QuestionMark,
  Refresh,
  Star,
  TrashCan,
  Trolley,
  Truck,
  VbnPotSize,
  VbnPlantHeight,
  VbnPlantDiameter,
  VbnThickness,
  VbnInflorenscencesPerPot,
  VbnFlowerClustersPerPot,
  VbnStemsPerPlant,
  VbnBulbsPerPot,
  VbnStemLength,
  VbnWeight,
  VbnFlowerBuds,
  VbnFlowerDiameter,
  VbnLengthOfSpathe,
  VbnLengthOfInflorescence,
  VbnLeafDiameter,
  VbnBundleWeight,
  VbnFruitDiameter,
  VbnBulbSize,
  VbnProductColor,
  VbnProductMaterial,
  VbnMaterialHeight,
  VbnMaterialDiameter,
  VbnStemsPerBunch,
  VbnQuality,
  VbnCountryOfOrigin,
  VbnDefault,
  VbnMaturityStage,
  Warning,
  VbnPackage,
}

export default IconType;
