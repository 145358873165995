import React from 'react';
import classNames from 'classnames';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import * as H from 'history';

interface IAdditionalLocationProps {
  pathname: string;
  search?: boolean | H.Search;
  relative?: boolean;
  replace?: string;
  hash?: string;
  state?: any;
}

export type ILocationDescriptor = string | IAdditionalLocationProps;

interface ILinkProps extends React.HTMLProps<any> {
  to: string | ILocationDescriptor;
  themed?: boolean;
  testHook?: string;
  excludeAutoFocus?: boolean;
  disabled?: boolean;
}

export default function CustomLink({ children, className, to, onClick, themed, excludeAutoFocus, disabled, testHook }: ILinkProps) {
  const linkClass = classNames(className, {
    'text-link': themed,
  });

  return (
    <Route>
      {({ location }: RouteComponentProps) => {
        const locationDescriptor =
          typeof to === 'string'
            ? {
                pathname: to,
              }
            : {
                pathname: getPathName(to),
                search: to.search === true ? location.search : to.search,
                hash: to.hash,
                state: to.state,
              };

        return (
          <Link
            onClick={e => {
              disabled ? e.preventDefault() : onClick && onClick(e);
            }}
            to={locationDescriptor as any}
            className={linkClass}
            data-exclude-auto-focus={excludeAutoFocus}
            data-test-hook={testHook}
          >
            {children}
          </Link>
        );
      }}
    </Route>
  );
}

function getPathName(to: IAdditionalLocationProps) {
  if (to.relative || to.replace) {
    const basePath = to.replace ? location.pathname.split(to.replace)[0] : location.pathname;

    return `${basePath}/${to.pathname}`;
  }

  return to.pathname;
}
