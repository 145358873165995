import { Action, Reducer } from 'redux';
import { KnownStatisticsAction } from './';
import IStatisticsState from './contracts/IStatisticsState';

const unloadedState: IStatisticsState = {
  today: {
    numberOfActiveClocks: 0,
    numberOfOrders: 0,
    turnover: 0,
  },
  loading: false,
};

export const statisticsReducer: Reducer<IStatisticsState> = (state: IStatisticsState, incomingAction: Action) => {
  const action = incomingAction as KnownStatisticsAction;

  switch (action.type) {
    case 'REQUEST_STATISTICS':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_STATISTICS':
      return {
        ...state,
        today: action.statistics,
        loading: false,
      };
  }

  return state || unloadedState;
};
