import { Action, Reducer } from 'redux';
import deepmerge from 'deepmerge';
import store from 'store';
import IOrderFilterState from './contracts/IOrderFilterState';
import { KnownOrderFilterAction } from './';
import { OrderSortingField, SortDirection, OrderGroupingField, OrderGroupingSelect } from '@enums';

const STORAGE_KEY_PREFIX = 'order-filter:';
const STORAGE_KEYS = {
  mainGroups: `${STORAGE_KEY_PREFIX}main-groups`,
  sorting: `${STORAGE_KEY_PREFIX}sorting`,
  grouping: `${STORAGE_KEY_PREFIX}grouping`,
};

export const unloadedOrderFilterState: IOrderFilterState = {
  filters: {
    firstLoad: false,
    loading: false,
    path: '',
    items: [],
  },
  selected: {
    settings: {
      mainGroups: store.get(STORAGE_KEYS.mainGroups) || [1, 2, 3],
    },
    filters: [],
    sorting: store.get(STORAGE_KEYS.sorting) || {
      field: OrderSortingField.TransactionMoment,
      direction: SortDirection.Descending,
    },
    grouping: store.get(STORAGE_KEYS.grouping) || {
      key: OrderGroupingSelect.TransactionMomentDesc,
      field: OrderGroupingField.TransactionMoment,
      direction: SortDirection.Descending,
    },
  },
};

export const orderFilterReducer: Reducer<IOrderFilterState> = (state: IOrderFilterState, incomingAction: Action) => {
  const action = incomingAction as KnownOrderFilterAction;

  switch (action.type) {
    case 'REQUEST_ORDER_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          firstLoad: !state.filters.path,
          loading: true,
          path: action.path,
        },
      };
    case 'RECEIVE_ORDER_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          firstLoad: false,
          loading: false,
          items: action.filters,
        },
      };
    case 'SET_ORDER_MAIN_GROUPS':
      store.set(STORAGE_KEYS.mainGroups, action.mainGroups);

      return {
        ...state,
        selected: {
          ...state.selected,
          settings: {
            ...state.selected.settings,
            mainGroups: action.mainGroups,
          },
        },
      };
    case 'SET_ORDER_FILTER':
      const newActiveFilters = state.selected.filters.filter(f => f.type !== action.filterType);

      newActiveFilters.push({
        type: action.filterType,
        value: action.value,
      });

      return {
        ...state,
        selected: {
          ...state.selected,
          filters: newActiveFilters,
        },
      };
    case 'REMOVE_ACTIVE_ORDER_FILTERS':
      return {
        ...state,
        selected: {
          ...state.selected,
          filters: unloadedOrderFilterState.selected.filters,
        },
      };
    case 'SET_ORDER_SORTING':
      store.set(STORAGE_KEYS.sorting, action.sorting);

      return {
        ...state,
        selected: {
          ...state.selected,
          sorting: action.sorting,
        },
      };
    case 'SET_SELECTED_ORDER_FILTER_STATE':
      return {
        ...state,
        selected: deepmerge(state.selected, action.selection, {
          arrayMerge: (_dest: any, src: any) => src,
        }),
      };
    case 'SET_ORDER_GROUPING':
      store.set(STORAGE_KEYS.grouping, action.grouping);

      return {
        ...state,
        selected: {
          ...state.selected,
          grouping: action.grouping,
        },
      };
  }

  return state || unloadedOrderFilterState;
};
