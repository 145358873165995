import qs from 'query-string';

export function parseUrl(url: string) {
  const anchor = document.createElement('a');

  anchor.href = url;

  return anchor;
}

export function getPathName(url: string) {
  const anchor = parseUrl(url);
  let result = anchor.pathname;

  if (result[0] !== '/') {
    result = `/${result}`; // Fix IE11
  }

  return result;
}

export function removeFromSearch(search: string, props: string[]) {
  const searchObj = qs.parse(search);

  props.forEach(prop => {
    delete searchObj[prop];
  });

  return qs.stringify(searchObj);
}

export function addToSearch(search: string, obj: any) {
  const searchObj = { ...qs.parse(search), ...obj };

  return qs.stringify(searchObj);
}
