import React from 'react';
import { useTranslation } from 'react-i18next';
import { InternalLink, Li, MenuItemText, Nav } from './styles';
import CountLabel from '@components/app/CountLabel';
import { useBreakpoint } from '@containers/withBreakpoint';
import { IWithAuthorizationProps, withAuthorization } from '@containers/withAuthorization';
import { UserConnect } from '@containers/shared/Connect';
import { IUser } from '@models/index';

interface IMenuItem {
  id: string;
  path: (user?: IUser) => string;
  title: string;
  exact: boolean;
  getCount: (props?: INavigationProps) => number;
}

const MENU_ITEMS: IMenuItem[] = [
  {
    id: 'home',
    path: () => '/',
    title: 'menu.home',
    exact: true,
    getCount: () => 0,
  },
  {
    id: 'marketplace',
    path: () => '/marketplace',
    title: 'menu.marketplace',
    exact: false,
    getCount: () => 0,
  },
  {
    id: 'transactions',
    path: () => '/transactions',
    title: 'menu.transactions',
    exact: false,
    getCount: ({ newOrderCount = 0 }) => newOrderCount,
  },
  {
    id: 'scan',
    path: () => '/scan/scanner',
    title: 'menu.scan',
    exact: false,
    getCount: () => 0,
  },
  {
    id: 'help',
    path: user => `/help/${user.language.toLowerCase()}`,
    title: 'menu.help',
    exact: false,
    getCount: () => 0,
  },
];

interface INavigationProps extends IWithAuthorizationProps {
  hidden?: boolean;
  newOrderCount?: number;
}

export function Navigation(props: INavigationProps) {
  const { hidden, authorizedForPath } = props;
  const { t } = useTranslation();
  const { isBreakpointLarge } = useBreakpoint();
  const filteredMenuItems = isBreakpointLarge ? MENU_ITEMS.filter(item => item.id !== 'scan') : MENU_ITEMS;

  return (
    <UserConnect>
      {({ user }) => {
        return (
          <Nav role="menubar" hidden={hidden}>
            {filteredMenuItems.map(item => {
              const hasAccess = authorizedForPath(item.path(user));

              return (
                item && (
                  <Li role="menuitem" key={item.id}>
                    {hasAccess ? (
                      <InternalLink exact={item.exact} to={item.path(user)} data-test-hook={`menu-link-${item.id}`}>
                        <MenuItemText>
                          {t(item.title)}
                          {<CountLabel count={item.getCount(props)} themed={isBreakpointLarge} />}
                        </MenuItemText>
                      </InternalLink>
                    ) : (
                      <MenuItemText className="disabled">{t(item.title)}</MenuItemText>
                    )}
                  </Li>
                )
              );
            })}
          </Nav>
        );
      }}
    </UserConnect>
  );
}

export default withAuthorization(Navigation);
