import React from 'react';
import { Block, Data, Title } from './styles';
import PriceOutput from '@components/shared/PriceOutput';
import { ILocationDescriptor } from '@components/shared/Link';
import Icon from '@components/shared/Icon';
import { IconType } from '@enums/index';

interface IStatisticsProps {
  value: number;
  title: string;
  isPrice?: boolean;
  icon?: IconType;
  to: ILocationDescriptor;
  className?: string;
}

export default function StatisticBlock({ value, title, isPrice, to, icon, className }: IStatisticsProps) {
  return (
    <Block to={to as any} className={className}>
      <span className="visually-hidden">{title}</span>
      <Data>
        {isPrice && <PriceOutput price={value} numberOfDigits={2} suffixWhenRound />}
        {!isPrice && value}
      </Data>
      <Title className="ellipsify" aria-hidden>
        {title}
      </Title>
      {icon && <Icon type={icon} />}
    </Block>
  );
}
