import { fetch, getApiUrl } from '@common/fetch';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import {
  IRequestHelpPagesAction,
  IReceiveHelpPagesAction,
  IRequestHelpDetailPageAction,
  IReceiveHelpDetailPageAction,
  IRequestHelpQuestionsAction,
  IReceiveHelpQuestionsAction,
  IRequestSearchHelpPagesAction,
  IReceiveSearchHelpPagesAction,
} from '@store/help/contracts/Actions';
import { Language } from '@enums/index';

export { default as IHelpState } from './contracts/IHelpState';

export type KnownHelpAction =
  | IRequestHelpPagesAction
  | IReceiveHelpPagesAction
  | IRequestHelpDetailPageAction
  | IReceiveHelpDetailPageAction
  | IRequestHelpQuestionsAction
  | IReceiveHelpQuestionsAction
  | IRequestSearchHelpPagesAction
  | IReceiveSearchHelpPagesAction;

export const helpActions = {
  getPages: (language: Language): IAppThunkAction<KnownHelpAction> => async dispatch => {
    dispatch({ type: 'REQUEST_HELP_PAGES' });

    const url = getApiUrl(`${language}/pages`, true, false);
    const response = await fetch(url);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_HELP_PAGES',
        pages: await response.json(),
      });
    }
  },
  getPageDetails: (language: Language, id: string): IAppThunkAction<KnownHelpAction> => async dispatch => {
    dispatch({ type: 'REQUEST_HELP_DETAIL_PAGE' });

    const url = getApiUrl(`${language}/pages/${id}`, true, false);
    const response = await fetch(url);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_HELP_DETAIL_PAGE',
        page: await response.json(),
      });
    }
  },
  getQuestions: (language: Language, pageId: string): IAppThunkAction<KnownHelpAction> => async dispatch => {
    dispatch({ type: 'REQUEST_HELP_QUESTIONS' });

    const url = getApiUrl(`${language}/questions?pageId=${pageId}`, true, false);
    const response = await fetch(url);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_HELP_QUESTIONS',
        questions: await response.json(),
        pageId,
      });
    }
  },
  searchPages: (language: Language, query: string): IAppThunkAction<KnownHelpAction> => async (dispatch, getState) => {
    const {
      search: { path: statePath },
    } = getState().help;
    const path = `${language}/pages?query=${query}&select=body`;

    if (path !== statePath) {
      dispatch({ type: 'REQUEST_SEARCH_HELP_PAGES', path });

      if (query.trim() !== '') {
        const url = getApiUrl(path, true, false);
        const response = await fetch(url);

        if (response.ok) {
          dispatch({
            type: 'RECEIVE_SEARCH_HELP_PAGES',
            pages: await response.json(),
          });
        }
      } else {
        dispatch({
          type: 'RECEIVE_SEARCH_HELP_PAGES',
          pages: [],
        });
      }
    }
  },
};
