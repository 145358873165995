import { NOT_FOUND } from 'http-status-codes';
import { get } from '@common/fetch';
import { ISupplyLine } from '@models';
import { getActiveAdministrationNumber } from '@store/user';

export default async function getSupplyLine(id: string): Promise<ISupplyLine> {
  const response = await get(`supply/${id}?administrationNumber=${getActiveAdministrationNumber()}`, {
    customErrorHandlers: [NOT_FOUND],
  });

  if (response.ok) {
    return (await response.json()) as ISupplyLine;
  }

  return null;
}
