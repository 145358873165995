export function lcFirst(value: string): string {
  return value.charAt(0).toLowerCase() + value.slice(1);
}

export function ellipsify(value: string, max: number = 30) {
  const trimmed = value.substr(0, max);

  if (value.length > trimmed.length) {
    return `${trimmed.trim()}...`;
  }

  return value;
}
