enum SupplySortingField {
  ProductName = 'growerProduct.vbnProduct.shortName',
  GrowerProductName = 'growerProduct.name',
  SupplierName = 'supplier.name',
  Price = 'price',
  CreationMoment = 'createdAt',
  TrolleyLocations = 'trolleylocations',
}

export default SupplySortingField;
