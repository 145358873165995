import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/de';
import { IUser } from '@models';
import config from '@common/config';

const FALLBACK_LANGUAGE = 'en';
export let LANGUAGE = FALLBACK_LANGUAGE;

/* Needed to ensure language is set correctly for tests */
moment.locale(LANGUAGE);

export function initializeI18n(user?: IUser) {
  LANGUAGE = user && user.language ? user.language.toString().toLowerCase() : FALLBACK_LANGUAGE;

  moment.locale(LANGUAGE);

  i18n
    .use(Backend as any)
    .use(initReactI18next)
    .init({
      lng: LANGUAGE,
      fallbackLng: FALLBACK_LANGUAGE,
      ns: ['general'],
      defaultNS: 'general',
      debug: config.env === 'loc',
      react: {
        wait: true,
      },
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.${config.version}.json`,
      },
    });
}
