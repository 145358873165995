import React from 'react';
import { Body, Indicator, Input, Label, Wrapper } from './checkboxStyles';
import classNames from 'classnames';
import { IconType } from '@enums';
import Icon from '@components/shared/Icon';

type ICheckboxValue = string | number;

interface ICheckboxProps {
  label?: string | (() => JSX.Element);
  value?: ICheckboxValue;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (checked: boolean, value: ICheckboxValue) => void;
  onClick?: (event: any) => void;
  tabIndex?: number;
  wrapLabel?: boolean;
  testHook?: string;
}

export default class Checkbox extends React.PureComponent<ICheckboxProps> {
  constructor(props: ICheckboxProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  render() {
    const { label, value, checked, disabled, className, tabIndex, onClick, wrapLabel, testHook = 'checkbox' } = this.props;
    const wrapperClass = classNames('ellipsify', className, {
      disabled,
    });

    return (
      <Wrapper className={wrapperClass} onClick={onClick} data-test-hook={testHook}>
        <Input
          type="checkbox"
          className="visually-hidden"
          checked={checked}
          disabled={disabled}
          value={value}
          onChange={this.onChange}
          tabIndex={tabIndex}
        />
        <Body aria-hidden wrapLabel={wrapLabel}>
          <Indicator className="indicator">
            <Icon type={IconType.Check} className="icon-check-mark" />
          </Indicator>
          {label && <Label className="ellipsify">{typeof label === 'function' ? label() : label}</Label>}
        </Body>
      </Wrapper>
    );
  }

  private onChange(event: any) {
    const { onChange, value } = this.props;
    const castedValue = typeof value === 'number' ? parseInt(event.currentTarget.value, 10) : value;

    onChange(event.currentTarget.checked, castedValue);
  }
}
