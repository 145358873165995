import { Action, Reducer } from 'redux';
import includes from 'lodash.includes';
import store from 'store';
import { KnownSupplyAction } from './';
import ISupplyState from './contracts/ISupplyState';
import { SUPPLY_COLUMN_LAYOUT_DEFAULT, getInitialColumnLayout, SUPPLY_COLUMN_LAYOUT_BREAKPOINT_SMALL } from '@common/columnLayout';
import { ISupplyLine } from '@models/index';
import { getBreakpoints } from '@containers/withBreakpoint';

const STORAGE_KEY_PREFIX = 'supply:';
const STORAGE_KEYS = {
  columnLayout: `${STORAGE_KEY_PREFIX}column-layout`,
};

const unloadedState: ISupplyState = {
  all: {
    path: null,
    lines: [],
    totalAmount: 0,
    nextLink: '',
    loading: false,
    loadingNext: false,
  },
  detail: {
    loading: false,
    lines: {},
  },
  selection: [],
  actionInProgress: false,
  columnLayout: getInitialColumnLayout(
    STORAGE_KEYS.columnLayout,
    getBreakpoints().isBreakpointSmall ? SUPPLY_COLUMN_LAYOUT_BREAKPOINT_SMALL : SUPPLY_COLUMN_LAYOUT_DEFAULT,
  ),
  requestedClockPresales: [],
  selectedDeliveryForm: {
    loading: false,
    code: null,
    supplier: null,
    supply: [],
  },
};

export const supplyReducer: Reducer<ISupplyState> = (state: ISupplyState, incomingAction: Action) => {
  const action = incomingAction as KnownSupplyAction;

  switch (action.type) {
    case 'REQUEST_SUPPLY':
      return {
        ...state,
        all: {
          ...state.all,
          loading: !action.isNext ? true : state.all.loading,
          loadingNext: action.isNext,
          path: action.path,
        },
      };
    case 'RECEIVE_SUPPLY':
      const newLines = action.isNext ? concatSupplyLines(state.all.lines, action.supply) : action.supply;
      const selectionWithoutFilteredLines = state.selection.filter(s =>
        includes(
          newLines.map(l => l.id),
          s.id,
        ),
      );

      return {
        ...state,
        all: {
          ...state.all,
          lines: newLines,
          totalAmount: action.supplyAmount,
          nextLink: (action.supplyLink && action.supplyLink.next && action.supplyLink.next.url) || '',
          loading: false,
          loadingNext: false,
        },
        selection: selectionWithoutFilteredLines,
      };
    case 'REQUEST_FRESH_SUPPLY':
      return {
        ...state,
        all: unloadedState.all,
        detail: unloadedState.detail,
      };
    case 'REQUEST_SUPPLY_LINE':
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
        },
      };
    case 'RECEIVE_SUPPLY_LINE':
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          lines: {
            ...state.detail.lines,
            [action.supplyLine.id]: action.supplyLine,
          },
        },
      };
    case 'RECEIVE_SUPPLY_LINE_FAILED':
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
        },
      };
    case 'SET_SUPPLY_COLUMN_LAYOUT':
      store.set(STORAGE_KEYS.columnLayout, action.columnLayout);

      return {
        ...state,
        columnLayout: action.columnLayout,
      };
    case 'TOGGLE_SELECTED_SUPPLY_LINE':
      return {
        ...state,
        selection: action.selected ? [...state.selection, action.supplyLine] : state.selection.filter(l => l.id !== action.supplyLine.id),
      };
    case 'CLEAR_SELECTED_SUPPLY_LINES':
      return {
        ...state,
        selection: unloadedState.selection,
      };
    case 'RECEIVE_ORDER_CONDITIONS_FAILED':
      const parentSupplyLine = state.all.lines.filter(l => l.clockPresales && l.clockPresales.supplyLineId === action.supplyLineId)[0];
      const newParentSupplyLine: ISupplyLine = parentSupplyLine
        ? {
            ...parentSupplyLine,
            clockPresales: null,
          }
        : null;

      return {
        ...state,
        all: {
          ...state.all,
          lines: state.all.lines.reduce((result, line) => {
            if (parentSupplyLine && line.id === parentSupplyLine.id) {
              result.push(newParentSupplyLine);
            } else if (line.id !== action.supplyLineId) {
              result.push(line);
            }

            return result;
          }, []),
        },
        detail: {
          ...state.detail,
          lines: Object.keys(state.detail.lines).reduce((accumulator: { [key: string]: ISupplyLine }, lineId) => {
            if (parentSupplyLine && lineId === parentSupplyLine.id) {
              accumulator[lineId] = newParentSupplyLine;
            } else if (lineId !== action.supplyLineId) {
              accumulator[lineId] = state.detail.lines[lineId];
            }

            return accumulator;
          }, {}),
        },
      };
    case 'REQUEST_CLOCK_PRESALES':
      const requestedClockPresales = [...state.requestedClockPresales];
      requestedClockPresales.push(action.supplyLineId);

      return {
        ...state,
        requestedClockPresales,
      };
    case 'REQUEST_CLOCK_PRESALES_FAILED':
      const requestedClockPresalesRollback = state.requestedClockPresales.filter(id => id !== action.supplyLineId);

      return {
        ...state,
        requestedClockPresales: requestedClockPresalesRollback,
      };
    case 'REQUEST_DELIVERY_FORM_SUPPLY':
      return {
        ...state,
        selectedDeliveryForm: {
          ...state.selectedDeliveryForm,
          loading: true,
        },
      };
    case 'RECEIVE_DELIVERY_FORM_SUPPLY':
      const { supply } = action;
      const firstSupply = supply[0];

      return {
        ...state,
        selectedDeliveryForm: {
          loading: false,
          code: firstSupply && firstSupply.fulfillment.deliveryForm.code,
          supplier: firstSupply && firstSupply.tradeItem.supplier.name,
          supply,
        },
      };
    case 'RECEIVE_DELIVERY_FORM_SUPPLY_FAILED':
      return {
        ...state,
        selectedDeliveryForm: {
          ...unloadedState.selectedDeliveryForm,
        },
      };
    case 'RESET_DELIVERY_FORM_SUPPLY':
      return {
        ...state,
        selectedDeliveryForm: {
          ...unloadedState.selectedDeliveryForm,
        },
      };
  }

  return state || unloadedState;
};

const concatSupplyLines = (currentLines: ISupplyLine[], incomingLines: ISupplyLine[]) => {
  const oldLines = [...currentLines];
  const newLines = [...incomingLines];

  incomingLines.forEach((incomingLine, index) => {
    const oldLineIndex = oldLines.findIndex(oldLine => incomingLine.id === oldLine.id);
    if (oldLineIndex > -1) {
      const oldLine = oldLines[oldLineIndex];
      const updatedLine = { ...oldLine, deliveries: oldLine.deliveries.concat(incomingLine.deliveries) };
      oldLines.splice(oldLineIndex, 1);
      newLines[index] = updatedLine;
    }
  });

  return oldLines.concat(newLines);
};
