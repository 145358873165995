import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { clocksActions, IApplicationState } from '@store/index';
import Modal from '@components/shared/Modal';
import ClockStatusList from '@components/home/ClockStatusList';
import Loader from '@components/shared/Loader';

function ClockStatusDialog() {
  const dispatch = useDispatch();
  const { items, loading } = useSelector((state: IApplicationState) => state.clocks);
  const { t } = useTranslation('home');

  useEffect(() => {
    dispatch(clocksActions.getAll());
  }, []);

  return (
    <Modal title={t('clockStatus.title')} closeTo="/" autoClose>
      {loading && <Loader />}
      {!loading && <ClockStatusList clocks={items} />}
    </Modal>
  );
}

export default React.memo(ClockStatusDialog);
