import styled from 'styled-components';
import { pxToRem, setFont } from '@styles';

export const Abbr = styled.abbr`
  display: inline-block;
  color: ${props => props.theme.colors.laPalma};
  ${setFont('bold')};
  text-decoration: none;
  &.fixed-width {
    width: ${pxToRem(40)};
    margin-right: ${pxToRem(10)};
  }
`;
