import { get } from '@common/fetch';
import { getActiveAdministrationNumber } from '@store/user';
import { IOrderLine } from '@models';

export default async function getOrderLine(id: string): Promise<IOrderLine> {
  const response = await get(`orders/${id}?administrationNumber=${getActiveAdministrationNumber()}`);

  if (response.ok) {
    return (await response.json()) as IOrderLine;
  }

  return null;
}
