import styled, { css } from 'styled-components';
import { IButtonProps } from './Button';
import { IFillButtonProps } from './FillButton';
import { IOutlineButtonProps } from './OutlineButton';
import { pxToRem, setFont } from '@styles';

const genericButtonStyles = css`
  overflow: hidden;
  transition-property: none;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  line-height: ${pxToRem(15)};
  .label {
    font-family: inherit;
  }
  &.has-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    .icon {
      display: block;
      height: ${pxToRem(13)};
    }
  }
  &.no-label {
    .icon-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      .icon {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        height: auto;
      }
    }
  }
  &.has-label.has-icon {
    .label {
      margin-right: ${pxToRem(10)};
      &:last-child {
        margin-right: 0;
        margin-left: ${pxToRem(10)};
      }
    }
  }
`;

const BUTTON_HEIGHT = 25;

// Fixes min-height issue icw flexbox center for ie11
function applyMinHeightFix(minHeight: any = 'inherit') {
  return css`
    &:after {
      content: '';
      min-height: ${minHeight};
      font-size: 0;
    }
  `;
}

export const buttonStyles = css<IButtonProps>`
  ${genericButtonStyles}
  height: ${pxToRem(BUTTON_HEIGHT)};
  padding: 0 ${pxToRem(5)};
  transition-property: color, border-color;
  color: inherit;
  &.has-label {
    ${applyMinHeightFix()}
    padding: 0 ${pxToRem(10)};
    &:not(.bordered) {
      padding: 0;
    }
  }
  &.bordered {
    min-height: ${pxToRem(BUTTON_HEIGHT)};
    border-radius: ${pxToRem(5)};
    border: ${pxToRem(1)} solid ${props => props.theme.colors.silver};
    &.no-label {
      color: ${props => props.theme.colors.dustyGray};
    }
  }
  &.no-label {
    width: ${pxToRem(BUTTON_HEIGHT)};
  }
  &.disabled {
    color: ${props => props.theme.colors.silver};
  }
  &:not(.disabled) {
    @media (hover: hover) {
      &:hover:not(.no-hover) {
        color: ${props => props.theme.colors.lipstick};
        border-color: ${props => props.theme.colors.lipstick};
      }
    }
    &.active {
      color: ${props => props.theme.colors.lipstick};
      border-color: ${props => props.theme.colors.lipstick};
    }
  }
  &.button-close {
    width: ${pxToRem(44)};
    height: ${pxToRem(44)};
    padding: ${pxToRem(10)};
  }
`;

const FILL_BUTTON_MIN_HEIGHT = 40;
const FILL_BUTTON_VERTICAL_PADDING = 8;

const genericFillButtonStyles = css`
  ${genericButtonStyles}
  ${setFont('bold')}
  border-radius: ${pxToRem(20)};
  min-height: ${pxToRem(FILL_BUTTON_MIN_HEIGHT)};
  padding: ${pxToRem(FILL_BUTTON_VERTICAL_PADDING)} ${pxToRem(20)};
  ${applyMinHeightFix(pxToRem(FILL_BUTTON_MIN_HEIGHT - 2 * FILL_BUTTON_VERTICAL_PADDING))}
  &.has-label {
    min-width: ${pxToRem(100)};
    min-height: ${pxToRem(BUTTON_HEIGHT)};
  }
  &.disabled,
  &.important.disabled {
    background-color: ${props => props.theme.colors.alto};
    color: ${props => props.theme.colors.dustyGray};
  }
  &.has-label.has-icon {
    .icon-wrapper {
      margin-left: -${pxToRem(5)};
      &:last-child {
        margin-left: 0;
        margin-right: -${pxToRem(5)};
      }
    }
  }
`;

export const fillButtonStyles = css<IFillButtonProps>`
  ${genericFillButtonStyles}
  background: ${props => props.theme.colors.lipstick};
  color: ${props => (props.color === 'white' ? props.theme.colors.lipstick : props.theme.colors.white)};
  background-color: ${props => (props.color === 'white' ? props.theme.colors.white : props.theme.colors.lipstick)};
  transition-property: color, background-color;
  &.important {
    background: ${props => props.theme.colors.schoolBus};
    color: ${props => props.theme.colors.black};
  }
  &:not(.disabled) {
    &:hover {
      background-color: ${props => props.theme.colors.redViolet};
      color: ${props => props.theme.colors.white};
    }
  }
`;

const OUTLINE_BUTTON_BORDER_WIDTH = 1;

export const outlineButtonStyles = css<IOutlineButtonProps>`
  ${genericFillButtonStyles}
  color: ${props => (props.color === 'white' ? props.theme.colors.white : props.theme.colors.mineShaft)};
  transition-property: color, border, background-color;
  &:not(.disabled) {
    border: ${pxToRem(OUTLINE_BUTTON_BORDER_WIDTH)} solid
      ${props => (props.color === 'white' ? props.theme.colors.white : props.theme.colors.mineShaft)};
    ${applyMinHeightFix(pxToRem(FILL_BUTTON_MIN_HEIGHT - 2 * FILL_BUTTON_VERTICAL_PADDING - 2 * OUTLINE_BUTTON_BORDER_WIDTH))}
    &:hover {
      color: ${props => (props.color === 'white' ? props.theme.colors.white : props.theme.colors.redViolet)};
      border-color: ${props => (props.color === 'white' ? props.theme.colors.white : props.theme.colors.redViolet)};
      background-color: ${props => (props.color === 'white' ? props.theme.colors.redViolet : 'none')};
    }
  }
`;

export const FLOATING_BUTTON_HEIGHT = 60;

export const FloatingButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${pxToRem(FLOATING_BUTTON_HEIGHT)};
  background-color: ${props => props.theme.colors.lipstick};
  padding: ${pxToRem(10)} ${pxToRem(15)};
  color: ${props => props.theme.colors.white};
  .button {
    width: 100%;
  }
`;
