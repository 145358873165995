import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, withRouter } from 'react-router';
import { IWithAuthorizationProps, withAuthorization } from '@containers/withAuthorization';
import { NOT_AUTHORIZED_ROUTE } from '@root/routes';

type IAuthenticatedRouteProps = RouteProps & IWithAuthorizationProps & RouteComponentProps;

function AuthenticatedRoute(props: IAuthenticatedRouteProps) {
  const { path, authorizedForPath } = props;

  return authorizedForPath(path) ? (
    <Route {...props} />
  ) : (
    <Route path={path} render={() => <Redirect to={`${NOT_AUTHORIZED_ROUTE}?path=${path}`} />} />
  );
}

export default withRouter(withAuthorization(AuthenticatedRoute));
