import React from 'react';
import classNames from 'classnames';
import includes from 'lodash.includes';
import Input, { IInputComponentProps } from './Input';

export interface IInputNumberComponentProps extends IInputComponentProps {
  decimals?: number;
  onNumberChange?: (number?: string) => void;
}

type IInputNumberProps = IInputNumberComponentProps & React.HTMLProps<HTMLInputElement>;

export default class InputNumber extends React.PureComponent<IInputNumberProps> {
  constructor(props: IInputNumberProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  render() {
    const { value, min, max, step = 'any', disabled, className, required, error, decimals, touched, testHook } = this.props;
    const inputClass = classNames('input-number', className, {
      'no-decimals': !decimals,
    });

    return (
      <Input
        value={value}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onKeyPress={this.onKeyPress}
        className={inputClass}
        required={required}
        error={error}
        touched={touched}
        type="number"
        testHook={testHook}
      />
    );
  }

  private onKeyPress(event: any) {
    const { charCode } = event;
    const isReturnChar = charCode === 13;

    if (isReturnChar) return;

    const { decimals, min } = this.props;
    const numericMin = parseFloat(min as string);

    const isNumeric = charCode >= 48 && charCode <= 57;
    const isNegativeChar = charCode === 45;
    const isDecimalChar = includes([44, 46], charCode);

    let isAllowed = isNumeric;

    if (!isNumeric) {
      if (decimals > 0) {
        isAllowed = isDecimalChar;
      } else if (!isNaN(numericMin) && numericMin < 0) {
        isAllowed = isNegativeChar;
      }
    }

    if (!isAllowed) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private onChange(event: any) {
    const { decimals, min } = this.props;
    const { value } = event.currentTarget;
    const valueSplit = value.split('.');
    const numericMin = parseFloat(min as string);

    let newValue = value;

    if (valueSplit[1]) {
      const newDecimals = valueSplit[1].substr(0, decimals);
      newValue = newDecimals ? `${valueSplit[0]}.${newDecimals}` : valueSplit[0];
    }

    if (!isNaN(numericMin) && numericMin >= 0) {
      newValue = newValue.replace(/-/gi, '');
    }

    this.onNumberChange(newValue);
  }

  private onBlur(event: any) {
    const { value } = event.currentTarget;
    const { onBlur } = this.props;
    const numericValue = parseFloat(value);
    const hasNumericValue = !isNaN(numericValue);

    let newValue = value;

    if (hasNumericValue) {
      const { min, max } = this.props;
      const numericMin = parseFloat(min as string);
      const numericMax = parseFloat(max as string);

      if (!isNaN(numericMin) && newValue < numericMin) {
        newValue = min;
      }

      if (!isNaN(numericMax) && newValue > numericMax) {
        newValue = max;
      }
    }

    this.onNumberChange(newValue);
    onBlur && onBlur(event);
  }

  private onNumberChange(value: any) {
    const { onNumberChange } = this.props;
    onNumberChange && onNumberChange(value);
  }
}
