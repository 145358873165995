enum OrderFilterType {
  MainGroup = 'MainGroup',
  Account = 'Account',
  DeliveryLocation = 'DeliveryLocation',
  TradeInstrument = 'TradeInstrument',
  PeriodStart = 'PeriodStart',
  PeriodEnd = 'PeriodEnd',
}

export default OrderFilterType;
