import React from 'react';
import classNames from 'classnames';
import Date from './Date';
import Time from './Time';
import { DateTimeWrapper } from './dateTimeStyles';

interface IDateTimeProps extends React.HTMLProps<any> {
  onDateTimeChange?: (value?: string) => void;
  value: string;
  minValue?: string;
  maxValue?: string;
  showTimeInput?: boolean;
  dateOffset?: number;
  testHook?: string;
}

export default class DateTime extends React.PureComponent<IDateTimeProps> {
  constructor(props: IDateTimeProps) {
    super(props);

    this.onDateChanged = this.onDateChanged.bind(this);
    this.onTimeChanged = this.onTimeChanged.bind(this);
  }

  render() {
    const { showTimeInput = true, minValue, maxValue, dateOffset, testHook } = this.props;
    const values = this.getValues();
    const wrapperClass = classNames({
      'with-time': showTimeInput,
    });

    return (
      <DateTimeWrapper className={wrapperClass}>
        <Date
          className="date"
          value={values[0] || ''}
          offset={dateOffset}
          minValue={minValue}
          maxValue={maxValue}
          onDateChange={this.onDateChanged}
          testHook={testHook}
        />
        {showTimeInput && <Time className="time" value={values[1] || ''} onTimeChange={this.onTimeChanged} />}
      </DateTimeWrapper>
    );
  }

  private onDateChanged(value: string) {
    const { onDateTimeChange } = this.props;
    const timeValue = this.getValues()[1];
    const dateTimeValue = value ? `${value} ${timeValue || '00:00'}` : '';

    onDateTimeChange && onDateTimeChange(dateTimeValue);
  }

  private onTimeChanged(value: string) {
    const { onDateTimeChange } = this.props;
    const dateValue = this.getValues()[0];
    const dateTimeValue = value && dateValue ? `${dateValue} ${value}` : dateValue ? `${dateValue} 00:00` : '';

    onDateTimeChange && onDateTimeChange(dateTimeValue);
  }

  private getValues() {
    const { value } = this.props;

    return value ? value.split(' ') : [];
  }
}
