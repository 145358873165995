import { translateEnum } from '@common/enum';

enum WebServiceFilterPolicy {
  Allow = 'Allow',
  Deny = 'Deny',
}

export function translateWebServiceFilterPolicy(key: WebServiceFilterPolicy, name: string = 'WebServiceFilterPolicy') {
  return translateEnum(name, key);
}

export default WebServiceFilterPolicy;
