import styled from 'styled-components';
import { pxToRem } from '@styles';

export const HtmlOutputStyle = styled.div`
  p {
    margin-bottom: ${pxToRem(10)};
  }
  a {
    display: inline-block;
    color: ${props => props.theme.colors.lipstick};
  }
`;
