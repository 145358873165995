import { IOrderGrouping } from '@contracts';
import { translateEnum } from '@common/enum';
import OrderGroupingField from './OrderGroupingField';
import SortDirection from './SortDirection';

enum OrderGroupingSelect {
  TransactionMomentAsc = 'TransactionMomentAsc',
  TransactionMomentDesc = 'TransactionMomentDesc',
  DeliveryMomentAsc = 'DeliveryMomentAsc',
  DeliveryMomentDesc = 'DeliveryMomentDesc',
}

export const orderGroupingMapper: Record<OrderGroupingSelect, IOrderGrouping> = {
  [OrderGroupingSelect.TransactionMomentAsc]: {
    key: OrderGroupingSelect.TransactionMomentAsc,
    field: OrderGroupingField.TransactionMoment,
    direction: SortDirection.Ascending,
  },
  [OrderGroupingSelect.TransactionMomentDesc]: {
    key: OrderGroupingSelect.TransactionMomentDesc,
    field: OrderGroupingField.TransactionMoment,
    direction: SortDirection.Descending,
  },
  [OrderGroupingSelect.DeliveryMomentAsc]: {
    key: OrderGroupingSelect.DeliveryMomentAsc,
    field: OrderGroupingField.DeliveryMoment,
    direction: SortDirection.Ascending,
  },
  [OrderGroupingSelect.DeliveryMomentDesc]: {
    key: OrderGroupingSelect.DeliveryMomentDesc,
    field: OrderGroupingField.DeliveryMoment,
    direction: SortDirection.Descending,
  },
};

export function translateOrderGroupingSelect(key: OrderGroupingSelect) {
  return translateEnum('OrderGroupingSelect', key);
}

export default OrderGroupingSelect;
