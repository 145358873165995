import { IOrder, IOrderConditions, ISupplyLine } from '@models';
import { store } from '@root/configureStore';
import i18next from 'i18next';

export interface IOrderValidationResult {
  isSuccess: boolean;
  error?: {
    quantity?: string;
    balance?: string;
  };
}

export default class OrderHelper {
  constructor(private orderConditions: IOrderConditions) {}

  private static getSupplyLine(id: string): ISupplyLine {
    return store.getState().supply.detail.lines[id];
  }

  getTotalPrice(order: IOrder) {
    const supplyLine = OrderHelper.getSupplyLine(order.supplyLineId);
    const { orderFee = 0 } = this.orderConditions;

    if (supplyLine) {
      const {
        tradeItem: {
          packing: { itemsPerPackage },
        },
      } = supplyLine;
      const { quantity } = order;
      const selectedDeliveryOption = this.getSelectedDeliveryOption(order);

      if (selectedDeliveryOption) {
        return selectedDeliveryOption.calculatedPricePerItem * (itemsPerPackage * quantity) + orderFee;
      }
    }

    return 0;
  }

  validate(order: IOrder): IOrderValidationResult {
    const { availableQuantity, orderLimit, dailyOrderLimit } = this.orderConditions;
    const result: IOrderValidationResult = { isSuccess: true, error: {} };
    const selectedDeliveryOption = this.getSelectedDeliveryOption(order);

    if (!order.quantity) {
      result.isSuccess = false;
      result.error.quantity = i18next.t('marketPlace:order.validation.quantityRequired');
    } else if (order.quantity > availableQuantity) {
      result.isSuccess = false;
      result.error.quantity = i18next.t('marketPlace:order.validation.quantityExceedsMax');
    } else if (order.quantity < selectedDeliveryOption.minimumQuantity) {
      result.isSuccess = order.quantity >= 1 && !!order.quantity && !selectedDeliveryOption.isMinimumQuantityRequired;
      result.error.quantity = i18next.t('marketPlace:order.validation.quantityTooLow');
    } else if (order.quantity % selectedDeliveryOption.quantityStep !== 0) {
      result.isSuccess = false;
      result.error.quantity = i18next.t('marketPlace:order.validation.quantityNotEqualToQuantityStep');
    }

    if ((dailyOrderLimit || dailyOrderLimit === 0) && this.getBalance(order) < 0) {
      result.isSuccess = false;
      result.error.balance = i18next.t('marketPlace:order.validation.insufficientBalance');
    } else if ((orderLimit || orderLimit === 0) && this.getBalance(order) < 0) {
      result.isSuccess = false;
      result.error.balance = i18next.t('marketPlace:order.validation.insufficientBalance');
    }

    return result;
  }

  getBalance(order: IOrder) {
    const { orderLimit, dailyOrderLimit, currentOrderTotal } = this.orderConditions;
    const currentLimit = orderLimit || orderLimit === 0 ? orderLimit : dailyOrderLimit - currentOrderTotal;

    return currentLimit - this.getTotalPrice(order);
  }

  getSelectedDeliveryOption(order: IOrder) {
    const { deliveryOptions } = this.orderConditions;
    const { deliveryId } = order;

    return deliveryOptions.filter(l => l.id === deliveryId)[0];
  }
}
