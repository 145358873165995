import styled from 'styled-components';
import { breakpoint, pxToRem, setFont } from '@styles';

const IMAGE_WIDTH = {
  s: 100,
  m: 160,
};

export const Body = styled.div`
  position: relative;
  .image {
    width: ${pxToRem(IMAGE_WIDTH.s)};
    float: right;
  }
  .accordion {
    margin-bottom: ${pxToRem(30)};
  }
  ${breakpoint('m')`
    padding-right: ${pxToRem(IMAGE_WIDTH.m + 2 * 70)};
    .image {
      position absolute;
      right: ${pxToRem(70)};
      width: ${pxToRem(160)};
    }
  `}
`;

export const Intro = styled.p`
  padding-right: ${pxToRem(IMAGE_WIDTH.s + 15)};
  margin-bottom: ${pxToRem(30)};
  ${breakpoint('m')`
    padding-right: 0;
  `}
`;

export const Details = styled.table`
  th,
  td {
    padding-bottom: ${pxToRem(5)};
  }
  th {
    ${setFont('bold')}
    padding-right: ${pxToRem(15)};
    vertical-align: top;
  }
  td {
    word-break: break-all;
  }
`;

export const Steps = styled.ol`
  list-style-type: decimal;
  list-style-position: inside;
  > li {
    margin-bottom: ${pxToRem(5)};
    span {
      padding-left: ${pxToRem(10)};
    }
  }
`;
