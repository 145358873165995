import React, { useState, useMemo } from 'react';
import { IClock } from '@models/index';
import ClockLocation, { translateClockLocation } from '@enums/ClockLocation';
import { Select } from '@components/shared/Input';
import uniqBy from 'lodash.uniqby';
import { SelectLabel, Table } from './styles';
import { useTranslation } from 'react-i18next';

interface IClockStatusListProps {
  clocks: IClock[];
}

export default function ClockStatusList({ clocks }: IClockStatusListProps) {
  const [selectedLocation, setLocation] = useState(ClockLocation.Aalsmeer);
  const { t } = useTranslation(['home', 'general']);
  const filteredClocks = clocks.filter(c => c.location === selectedLocation);
  const locations = useMemo(() => uniqBy(clocks, 'location').map(c => c.location), [clocks]);

  return (
    <div>
      <SelectLabel>
        <span>{t('general:location')}:</span>
        <Select value={selectedLocation} onChange={event => setLocation(event.currentTarget.value)}>
          {locations.map(location => (
            <option key={location}>{translateClockLocation(location)}</option>
          ))}
        </Select>
      </SelectLabel>
      <Table>
        <thead>
          <tr>
            <th>{t('clockStatus.name')}</th>
            <th>{t('clockStatus.description')}</th>
            <th>{t('clockStatus.loadCarriers')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredClocks.map(clock => (
            <tr key={clock.name}>
              <td>{clock.name}</td>
              <td>{clock.statusDescription}</td>
              <td>{clock.numberOfLoadCarriers}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
