import { get } from '@common/fetch';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { IReceiveDeliveryLocations, IRequestDeliveryLocations } from './contracts/Actions';
import { IDeliveryLocation } from '@models';
import { getActiveAdministrationNumber } from '@store/user';

export { default as IDeliveryLocationState } from './contracts/IDeliveryLocationState';

export type KnownDeliveryLocationAction = IRequestDeliveryLocations | IReceiveDeliveryLocations;

export const deliveryLocationActions = {
  getAll: (): IAppThunkAction<KnownDeliveryLocationAction> => async (dispatch, getState) => {
    const { locations: currentLocations } = getState().deliveryLocation;

    if (currentLocations.length <= 0) {
      dispatch({ type: 'REQUEST_DELIVERY_LOCATIONS' });

      const response = await get(`delivery-locations?administrationNumber=${getActiveAdministrationNumber()}`);

      if (response.ok) {
        dispatch({
          type: 'RECEIVE_DELIVERY_LOCATIONS',
          locations: (await response.json()) as IDeliveryLocation[],
        });
      }
    }
  },
};
