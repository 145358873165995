import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState, userActions } from '@store';
import { getEnumValues } from '@common/enum';
import { Language } from '@enums';
import { translateLanguage } from '@enums/Language';
import { Select } from '@components/shared/Input';

export function LanguageSelectContainer() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: IApplicationState) => state.user);

  const onLanguageChanged = (event: React.FormEvent<HTMLSelectElement>) => {
    dispatch(userActions.setLanguage(event.currentTarget.value as Language));
  };

  return (
    <Select value={user.language} onChange={onLanguageChanged}>
      {getEnumValues(Language, false).map(language => (
        <option key={language} value={language}>
          {translateLanguage(language)}
        </option>
      ))}
    </Select>
  );
}

export default React.memo(LanguageSelectContainer);
