import i18next from 'i18next';
import { get, patch, post, put, remove } from '@common/fetch';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import {
  IReceiveAccountAction,
  IReceiveAccountConfigurationAction,
  IReceiveAccountsAction,
  IReceiveBuyerCardsAction,
  IReceiveOrderConfigurationsAction,
  IReceiveSetDefaultDeliveryLocationAction,
  IReceiveUpdateAccountConfigurationAction,
  IReceiveUpdateAccountConfigurationFailedAction,
  IReceiveUpdateOrderConfigurationsAction,
  IRequestAccountAction,
  IRequestAccountConfigurationAction,
  IRequestAccountsAction,
  IRequestBuyerCardsAction,
  IRequestOrderConfigurationsAction,
  IRequestSetDefaultDeliveryLocationAction,
  IRequestUpdateAccountConfigurationAction,
  IRequestUpdateOrderConfigurationsAction,
  IRequestFilterSetAction,
  IReceiveFilterSetAction,
  ICreateFilterSetSucceededAction,
  IUpdateFilterSetStartAction,
  IUpdateFilterSetRequestedAction,
  IUpdateFilterSetSucceededAction,
  IRequestRemoveFilterSetSucceededAction,
  IUpdateFilterSetFailedAction,
  IRequestSuppliersAction,
  IReceiveSuppliersAction,
  IRequestCropGroupsAction,
  IReceiveCropGroupsAction,
  IRequestProductGroupsAction,
  IReceiveProductGroupsAction,
  IRequestProducerGroupsAction,
  IReceiveProducerGroupsAction,
  IReceiveInsightAction,
  IRequestInsightAction,
  IRequestSupplyFilterSetsAction,
  IReceiveSupplyFilterSetsAction,
  ICreateSupplyFilterSetAction,
  IUpdateSupplyFilterSetStartAction,
  IUpdateSupplyFilterSetRequestedAction,
  IUpdateSupplyFilterSetSucceededAction,
  IUpdateSupplyFilterSetFailedAction,
  IRemoveSupplyFilterSetAction,
  IRequestSetSupplyFilterSetAction,
  IRequestSupplyFilterSetAction,
  ISetSupplyFilterSetSucceededAction,
  ISetSupplyFilterSetFailedAction,
  IResetFilterSetErrors,
} from './contracts/Actions';
import parseLink from 'parse-link-header';
import IUserState from '@store/user/contracts/IUserState';
import { IAccountConfiguration, IFilterSet, IOrderConfiguration, IWebServiceFilter } from '@models';
import { KnownNotificationAction, notificationActions } from '@store/notification';
import { createPushNotification } from '@common/notification';
import { PRECONDITION_REQUIRED } from 'http-status-codes';
import { AccountType, Location } from '@enums';
import qs from 'query-string';
import { store } from '@root/configureStore';
import IApplicationState from '@store/contracts/IApplicationState';
import WebServiceFilterType, { TYPE_TO_ITEMS } from '@enums/WebServiceFilterType';

export { default as ISettingsState } from './contracts/ISettingsState';

export type KnownSettingsAction =
  | KnownNotificationAction
  | IRequestAccountsAction
  | IReceiveAccountsAction
  | IRequestAccountAction
  | IReceiveAccountAction
  | IRequestOrderConfigurationsAction
  | IReceiveOrderConfigurationsAction
  | IRequestBuyerCardsAction
  | IReceiveBuyerCardsAction
  | IRequestUpdateOrderConfigurationsAction
  | IReceiveUpdateOrderConfigurationsAction
  | IRequestAccountConfigurationAction
  | IReceiveAccountConfigurationAction
  | IRequestUpdateAccountConfigurationAction
  | IReceiveUpdateAccountConfigurationAction
  | IReceiveUpdateAccountConfigurationFailedAction
  | IRequestSetDefaultDeliveryLocationAction
  | IReceiveSetDefaultDeliveryLocationAction
  | IRequestSetSupplyFilterSetAction
  | ISetSupplyFilterSetSucceededAction
  | ISetSupplyFilterSetFailedAction
  | IRequestFilterSetAction
  | IReceiveFilterSetAction
  | IRequestSupplyFilterSetAction
  | IRequestRemoveFilterSetSucceededAction
  | ICreateFilterSetSucceededAction
  | IUpdateFilterSetStartAction
  | IUpdateFilterSetRequestedAction
  | IUpdateFilterSetSucceededAction
  | IUpdateFilterSetFailedAction
  | IRequestSuppliersAction
  | IReceiveSuppliersAction
  | IRequestCropGroupsAction
  | IReceiveCropGroupsAction
  | IRequestProductGroupsAction
  | IReceiveProductGroupsAction
  | IRequestProducerGroupsAction
  | IReceiveProducerGroupsAction
  | IRequestInsightAction
  | IReceiveInsightAction
  | IRequestSupplyFilterSetsAction
  | IReceiveSupplyFilterSetsAction
  | ICreateSupplyFilterSetAction
  | IUpdateSupplyFilterSetStartAction
  | IUpdateSupplyFilterSetRequestedAction
  | IUpdateSupplyFilterSetSucceededAction
  | IUpdateSupplyFilterSetFailedAction
  | IRemoveSupplyFilterSetAction
  | IResetFilterSetErrors;

export const settingsActions = {
  getAccounts: (types: AccountType[] = []): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    const {
      accounts: { path: statePath },
    } = getState().settings;

    const query = { types };
    const path = `${appendAdministrationNumber('accounts', getState)}&${qs.stringify(query)}`;

    if (path !== statePath) {
      dispatch({ type: 'REQUEST_ACCOUNTS', path });

      const response = await get(path);

      if (response.ok) {
        const linkHeader = parseLink(response.headers.get('Link'));
        const manageAccountsUrl = linkHeader ? linkHeader.more : null;

        dispatch({
          type: 'RECEIVE_ACCOUNTS',
          accounts: await response.json(),
          manageAccountsUrl: (manageAccountsUrl && manageAccountsUrl.url) || null,
        });
      }
    }
  },
  getAccount: (accountId: string): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    const path = appendAdministrationNumber(`accounts/${accountId}`, getState);

    dispatch({ type: 'REQUEST_ACCOUNT' });

    const response = await get(path);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_ACCOUNT',
        account: await response.json(),
      });
    }
  },
  getFilterSets: (accountId: string): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    const administrationNumber = store.getState().user.activeAdministration.number;
    const path = `accounts/${accountId}/filter-sets?administrationNumber=${administrationNumber}`;

    dispatch({ type: 'REQUEST_FILTER_SETS' });

    const response = await get(path);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_FILTER_SETS',
        filterSets: await response.json(),
      });
    }
  },
  removeFilterSet: (accountId: string, filterSet: IFilterSet): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    const response = await remove(`accounts/${accountId}/filter-sets/${filterSet.id}`);

    if (response.ok) {
      const notification = createPushNotification(i18next.t('settings:account.filterSet.delete.saveSucceeded', { name: filterSet.name }));

      notificationActions.receivePushNotification(notification)(dispatch, getState);
      dispatch({ type: 'REMOVE_FILTER_SET_SUCCEEDED', id: filterSet.id });
    }
  },
  getOrderConfigurations: (accountId: string): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    const path = `accounts/${accountId}/order-configurations`;

    dispatch({ type: 'REQUEST_ORDER_CONFIGURATIONS' });

    const response = await get(path);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_ORDER_CONFIGURATIONS',
        configurations: await response.json(),
      });
    }
  },
  getBuyerCards: (administrationNumber: number): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    const path = `buyer-cards?administrationNumber=${administrationNumber}`;

    dispatch({ type: 'REQUEST_BUYER_CARDS' });

    const response = await get(path);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_BUYER_CARDS',
        cards: await response.json(),
        administrationNumber,
      });
    }
  },
  updateOrderConfigurations: (accountId: string, configurations: IOrderConfiguration[]): IAppThunkAction<KnownSettingsAction> => async (
    dispatch,
    getState,
  ) => {
    const path = `accounts/${accountId}/order-configurations`;
    const administration = configurations[0].administration;

    dispatch({ type: 'REQUEST_UPDATE_ORDER_CONFIGURATIONS', administration });

    const response = await put(path, configurations);

    if (response.ok) {
      const notification = createPushNotification(
        i18next.t('settings:account.orderConfiguration.saveSucceeded', {
          name: administration.name,
          number: administration.number,
        }),
      );

      notificationActions.receivePushNotification(notification)(dispatch, getState);

      dispatch({
        type: 'RECEIVE_UPDATE_ORDER_CONFIGURATIONS',
        configurations,
      });
    }
  },
  getAccountConfigurations: (accountId: string): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    const path = `accounts/${accountId}/account-configurations`;

    dispatch({ type: 'REQUEST_ACCOUNT_CONFIGURATION' });

    const response = await get(path);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_ACCOUNT_CONFIGURATION',
        configuration: await response.json(),
      });
    }
  },
  updateAccountConfiguration: (accountId: string, configuration: IAccountConfiguration): IAppThunkAction<KnownSettingsAction> => async (
    dispatch,
    getState,
  ) => {
    const path = `accounts/${accountId}/account-configurations/${configuration.id}`;

    dispatch({ type: 'REQUEST_UPDATE_ACCOUNT_CONFIGURATION' });

    const response = await put(path, configuration, {
      customErrorHandlers: [PRECONDITION_REQUIRED],
    });

    if (response.ok) {
      const notification = createPushNotification(i18next.t('settings:account.accountConfiguration.saveSucceeded', { accountId }));

      notificationActions.receivePushNotification(notification)(dispatch, getState);

      dispatch({ type: 'RECEIVE_UPDATE_ACCOUNT_CONFIGURATION' });
    } else if (response.status === PRECONDITION_REQUIRED) {
      dispatch({ type: 'RECEIVE_UPDATE_ACCOUNT_CONFIGURATION_FAILED', errors: await response.json() });
    }
  },
  setDefaultDeliveryLocation: (accountId: string, location: Location): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    dispatch({ type: 'REQUEST_SET_DEFAULT_DELIVERY_LOCATION' });

    const path = appendAdministrationNumber(`accounts/${accountId}`, getState);

    const response = await patch(path, [
      {
        op: 'replace',
        path: '/defaultDeliveryLocation',
        value: location,
      },
    ]);

    if (response.ok) {
      const notification = createPushNotification(i18next.t('settings:linkedAccount.deliveryLocation.saveSucceeded', { accountId }));

      notificationActions.receivePushNotification(notification)(dispatch, getState);

      dispatch({
        type: 'RECEIVE_SET_DEFAULT_DELIVERY_LOCATION',
        location,
        accountId,
      });
    }
  },
  setSupplyFilterSet: (accountId: string, id: number): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    dispatch({ type: 'REQUEST_SET_SUPPLY_FILTER_SET' });

    const path = `accounts/${accountId}/supply-filter-set`;
    const response = await put(path, id);

    if (response.ok) {
      const notification = createPushNotification(i18next.t('settings:account.accountConfiguration.supplyFilter.saveSucceeded', { accountId }));

      notificationActions.receivePushNotification(notification)(dispatch, getState);
      dispatch({ type: 'SET_SUPPLY_FILTER_SET_SUCCEEDED' });
    } else {
      dispatch({ type: 'SET_SUPPLY_FILTER_SET_FAILED' });
    }
  },
  createFilterSet: (accountId: string, name: string): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    const response = await post(`accounts/${accountId}/filter-sets`, { name });

    if (response.ok) {
      const notification = createPushNotification(i18next.t('settings:account.filter.modify.saveAsSucceeded', { name }));

      dispatch({ type: 'CREATE_FILTER_SET_SUCCEEDED', filterSet: await response.json() });

      notificationActions.receivePushNotification(notification)(dispatch, getState);
    }
  },
  updateFilterSetName: (accountId: string, id: number, name: string): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    await updateFilterSet(id, accountId, 'replace', '/name', name, () => ({ name }))(dispatch, getState);
  },
  startFilterUpdate: (): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    dispatch({ type: 'UPDATE_FILTER_SET_START' });
  },
  updateFilter: <T extends IWebServiceFilter>(
    accountId: string,
    filterSetId: number,
    filter: T,
    isSupplyFilter?: boolean,
  ): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    if (isSupplyFilter) {
      if (TYPE_TO_ITEMS[filter.type](filter).length > 0) {
        const filterSet = getSupplyFilterSet(filterSetId, getState);
        const filterIndex = getFilterIndex(filterSet, filter.type);

        await updateSupplyFilterSet(filterSetId, accountId, 'replace', '/filters/', filter, () => ({
          filters: Object.assign([], filterSet.filters, { [filterIndex]: filter }),
        }))(dispatch, getState);
      } else {
        dispatch({ type: 'UPDATE_SUPPLY_FILTER_SET_FAILED', errors: [i18next.t('settings:account.filter.filterForm.blankOptions')] });
      }
    } else {
      const filterSet = getFilterSet(filterSetId, getState);
      const filterIndex = getFilterIndex(filterSet, filter.type);

      await updateFilterSet(filterSetId, accountId, 'replace', '/filters/', filter, () => ({
        filters: Object.assign([], filterSet.filters, { [filterIndex]: filter }),
      }))(dispatch, getState);
    }
  },
  addFilter: <T extends IWebServiceFilter>(
    accountId: string,
    filterSetId: number,
    filter: T,
    isSupplyFilter?: boolean,
  ): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    if (isSupplyFilter) {
      if (TYPE_TO_ITEMS[filter.type](filter).length > 0) {
        const filterSet = getSupplyFilterSet(filterSetId, getState);
        await updateSupplyFilterSet(filterSetId, accountId, 'add', '/filters/-', filter, () => ({
          filters: [...filterSet.filters, filter],
        }))(dispatch, getState);
      } else {
        dispatch({ type: 'UPDATE_SUPPLY_FILTER_SET_FAILED', errors: [i18next.t('settings:account.filter.filterForm.blankOptions')] });
      }
    } else {
      const filterSet = getFilterSet(filterSetId, getState);

      await updateFilterSet(filterSetId, accountId, 'add', '/filters/-', filter, () => ({
        filters: [...filterSet.filters, filter],
      }))(dispatch, getState);
    }
  },
  removeFilter: <T extends IWebServiceFilter>(
    accountId: string,
    filterSetId: number,
    type: WebServiceFilterType,
    filter: T,
    isSupplyFilter?: boolean,
  ): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    if (isSupplyFilter) {
      const filterSet = getSupplyFilterSet(filterSetId, getState);
      await updateSupplyFilterSet(filterSetId, accountId, 'remove', '/filters/', filter, () => ({
        filters: filterSet.filters.filter(f => f.type !== type),
      }))(dispatch, getState);
    } else {
      const filterSet = getFilterSet(filterSetId, getState);
      await updateFilterSet(filterSetId, accountId, 'remove', '/filters/', filter, () => ({
        filters: filterSet.filters.filter(f => f.type !== type),
      }))(dispatch, getState);
    }
  },
  getSuppliers: (query: string): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    dispatch({ type: 'REQUEST_SUPPLIERS' });

    const response = await get(`suppliers?query=${query}`);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_SUPPLIERS',
        suppliers: await response.json(),
      });
    }
  },
  getCropGroups: (query: string): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    dispatch({ type: 'REQUEST_CROP_GROUPS' });

    const response = await get(`crop-groups?query=${query}`);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_CROP_GROUPS',
        cropGroups: await response.json(),
      });
    }
  },
  getProductGroups: (query: string): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    dispatch({ type: 'REQUEST_PRODUCT_GROUPS' });

    const response = await get(`product-groups?query=${query}`);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_PRODUCT_GROUPS',
        productGroups: await response.json(),
      });
    }
  },
  getProducerGroups: (): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    dispatch({ type: 'REQUEST_PRODUCER_GROUPS' });

    const response = await get('producer-groups');

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_PRODUCER_GROUPS',
        producerGroups: await response.json(),
      });
    }
  },
  getInsight: (accountId: string): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    dispatch({ type: 'REQUEST_INSIGHT' });
    const path = `accounts/${accountId}/vmp-interface-insight`;
    const response = await get(path);
    const resp = await response.json();

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_INSIGHT',
        insight: resp,
      });
    }
  },
  getSupplyFilterSets: (accountId: string): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    const path = `accounts/${accountId}/supply-filter-sets`;

    dispatch({ type: 'REQUEST_SUPPLY_FILTER_SETS' });

    const response = await get(path);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_SUPPLY_FILTER_SETS',
        supplyFilterSets: await response.json(),
      });
    }
  },
  createSupplyFilterSet: (accountId: string, name: string): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    const response = await post(`accounts/${accountId}/supply-filter-sets`, { name });

    if (response.ok) {
      const notification = createPushNotification(i18next.t('settings:account.supplyFilterSet.modify.saveAsSucceeded', { name }));

      dispatch({ type: 'CREATE_SUPPLY_FILTER_SET', supplyFilterSet: await response.json() });

      notificationActions.receivePushNotification(notification)(dispatch, getState);
    }
  },
  updateSupplyFilterSetName: (accountId: string, id: number, name: string): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    await updateSupplyFilterSet(id, accountId, 'replace', '/name', name, () => ({ name }))(dispatch, getState);
  },
  removeSupplyFilterSet: (accountId: string, filterSet: IFilterSet): IAppThunkAction<KnownSettingsAction> => async (dispatch, getState) => {
    const response = await remove(`accounts/${accountId}/supply-filter-sets/${filterSet.id}`);

    if (response.ok) {
      const notification = createPushNotification(i18next.t('settings:account.supplyFilterSet.deleteSucceeded', { name: filterSet.name }));

      notificationActions.receivePushNotification(notification)(dispatch, getState);

      dispatch({ type: 'REMOVE_SUPPLY_FILTER_SET', id: filterSet.id });
    }
  },
  startSupplyFilterUpdate: (): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    dispatch({ type: 'UPDATE_SUPPLY_FILTER_SET_START' });
  },
  resetFilterSetErrors: (): IAppThunkAction<KnownSettingsAction> => async dispatch => {
    dispatch({ type: 'RESET_FILTER_SET_ERRORS' });
  },
};

function appendAdministrationNumber(path: string, getState: any) {
  const { activeAdministration } = getState().user as IUserState;

  return `${path}?administrationNumber=${activeAdministration.number}`;
}

function getFilterSet(id: number, getState: () => IApplicationState) {
  const { items } = getState().settings.filterSets;

  return items.filter(i => i.id === id)[0];
}

function getFilterIndex(filterSet: IFilterSet, type: WebServiceFilterType) {
  return filterSet.filters.indexOf(filterSet.filters.filter(f => f.type === type)[0]);
}

function updateFilterSet(id: number, accountId: string, operation: string, path: string, value: any, callback: () => any) {
  return async (dispatch: (action: KnownSettingsAction) => void, getState: () => IApplicationState) => {
    const { settings, user } = getState();
    const url = `accounts/${accountId}/filter-sets/${id}?administrationNumber=${user.activeAdministration.number}`;

    dispatch({ type: 'UPDATE_FILTER_SET_REQUESTED' });

    const response = await patch(
      url,
      [
        {
          op: operation,
          path,
          value,
        },
      ],
      {
        customErrorHandlers: [PRECONDITION_REQUIRED],
      },
    );

    if (response.ok) {
      const currentFilterSet = settings.filterSets.items.filter(f => f.id === id)[0];
      const updatedFilterSet = {
        ...currentFilterSet,
        ...callback(),
      };

      if (operation === 'remove') {
        const notification = createPushNotification(i18next.t('settings:account.filter.delete.saveSucceeded', { name: updatedFilterSet.name }));

        notificationActions.receivePushNotification(notification)(dispatch, getState);
      } else {
        const notification = createPushNotification(i18next.t('settings:account.filter.modify.saveSucceeded', { name: updatedFilterSet.name }));

        notificationActions.receivePushNotification(notification)(dispatch, getState);
      }

      dispatch({ type: 'UPDATE_FILTER_SET_SUCCEEDED', currentFilterSet, updatedFilterSet });
    } else if (response.status === PRECONDITION_REQUIRED) {
      dispatch({ type: 'UPDATE_FILTER_SET_FAILED', errors: await response.json() });
    }
  };
}

function getSupplyFilterSet(id: number, getState: () => IApplicationState) {
  const { items } = getState().settings.supplyFilterSets;

  return items.filter(i => i.id === id)[0];
}

function updateSupplyFilterSet(id: number, accountId: string, operation: string, path: string, value: any, callback: () => any) {
  return async (dispatch: (action: KnownSettingsAction) => void, getState: () => IApplicationState) => {
    const { settings } = getState();
    const url = `accounts/${accountId}/supply-filter-sets/${id}`;

    dispatch({ type: 'UPDATE_SUPPLY_FILTER_SET_REQUESTED' });

    const response = await patch(
      url,
      [
        {
          op: operation,
          path,
          value,
          accountId,
        },
      ],
      {
        customErrorHandlers: [PRECONDITION_REQUIRED],
      },
    );

    if (response.ok) {
      const currentFilterSet = settings.supplyFilterSets.items.filter(f => f.id === id)[0];
      const updatedFilterSet = {
        ...currentFilterSet,
        ...callback(),
      };

      if (operation === 'remove') {
        const notification = createPushNotification(
          i18next.t('settings:account.supplyFilterSet.deleteFilter.saveSucceeded', { name: updatedFilterSet.name }),
        );

        notificationActions.receivePushNotification(notification)(dispatch, getState);
      } else {
        const notification = createPushNotification(
          i18next.t('settings:account.supplyFilterSet.modify.saveSucceeded', { name: updatedFilterSet.name }),
        );

        notificationActions.receivePushNotification(notification)(dispatch, getState);
      }

      dispatch({ type: 'UPDATE_SUPPLY_FILTER_SET_SUCCEEDED', currentFilterSet, updatedFilterSet });
    } else if (response.status === PRECONDITION_REQUIRED) {
      dispatch({ type: 'UPDATE_SUPPLY_FILTER_SET_FAILED', errors: await response.json() });
    }
  };
}
