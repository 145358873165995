import styled from 'styled-components';

export const Picture = styled.picture`
  display: inline-flex;
  align-items: start;
  width: 100%;
`;

export const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  &.fit {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    margin: auto;
  }
  &.rounded {
    border-radius: 50%;
  }
`;
