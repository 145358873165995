import React from 'react';
import { PropertyListWrapper } from './styles';

interface IPropertyListProps {
  title?: string;
  children?: any;
}

export default function PropertyList({ title, children }: IPropertyListProps) {
  return (
    <PropertyListWrapper tabIndex={0} aria-label={title}>
      <tbody>{children}</tbody>
    </PropertyListWrapper>
  );
}
