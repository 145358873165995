import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Modal, { IModalComponentProps } from '@components/shared/Modal';
import Image from '@components/shared/Image';
import Heading from '@components/shared/Heading';
import { IApiError } from '@models';
import { Body, Details, Intro, Steps } from './styles';
import DateOutput from '@components/shared/DateOutput';
import Accordion from '@components/layout/Accordion';

type IErrorDialog = {
  error: IApiError;
  onClose?: () => void;
  showButtons?: boolean;
} & WithTranslation &
  IModalComponentProps;

export function ErrorDialog({ t, error, onClose, showButtons = true, ...restProps }: IErrorDialog) {
  return (
    <Modal {...restProps} size="l" title={t('error.http.title')} onBoth={onClose} showReadyButton={showButtons}>
      <Heading as="h3" size="m">
        {t('error.http.subtitle')}
      </Heading>

      <Body>
        <Image src="/images/fmo-error.svg" aria-hidden />

        <Intro>{t('error.http.intro')}</Intro>

        <Accordion title={t('error.http.details')}>
          <Details>
            <tbody>
              <tr>
                <th>{t('error.http.dateTime')}:</th>
                <td>
                  <DateOutput />
                </td>
              </tr>
              {error.url && (
                <tr>
                  <th>{t('error.http.url')}:</th>
                  <td>{error.url}</td>
                </tr>
              )}
              {error.status && (
                <tr>
                  <th>{t('error.http.statusCode')}:</th>
                  <td>{error.status}</td>
                </tr>
              )}
              {error.message && (
                <tr>
                  <th>{t('error.http.message')}:</th>
                  <td>{error.message}</td>
                </tr>
              )}
            </tbody>
          </Details>
        </Accordion>

        <Heading as="h4" size="xs">
          {t('error.http.steps')}
        </Heading>
        <Steps>
          <li>
            <span>{t('error.http.refreshPage')}</span>
          </li>
          <li>
            <span>{t('error.http.tryAgain')}</span>
          </li>
          <li>
            <span>{t('error.http.contactSupport')}</span>
          </li>
        </Steps>
      </Body>
    </Modal>
  );
}

export default withTranslation()(ErrorDialog);
