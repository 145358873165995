import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { IApplicationState, ISupplyState } from '@store';
import { ToastGroup } from '@components/app/Toast';
import { supplyActions } from '@store/supply';
import SupplyActionToast from '@containers/supply/SupplyActionToast';
import { isMatchingRoute, SUPPLY_ROUTE } from '@root/routes';

type IActionToastGroupProps = ISupplyState & typeof supplyActions & RouteComponentProps;

export class ActionToastGroup extends React.PureComponent<IActionToastGroupProps> {
  render() {
    return <ToastGroup>{this.getSupplyActionToast()}</ToastGroup>;
  }

  private getSupplyActionToast() {
    const { selection, location, actionInProgress } = this.props;
    const hasSupplySelection = selection.length > 0;
    const isSupplyRoute = isMatchingRoute(location, SUPPLY_ROUTE);

    if (hasSupplySelection && isSupplyRoute) {
      return <SupplyActionToast selection={selection} inProgress={actionInProgress} />;
    }

    return null;
  }
}

export default withRouter(connect((state: IApplicationState) => state.supply, supplyActions)(ActionToastGroup));
