import React from 'react';
import classNames from 'classnames';
import { Canvas, Svg, Wrapper } from './styles';
import { IconType } from '@enums';
import config from '@common/config';

interface IIconProps {
  title?: string;
  className?: string;
  type: IconType;
  rotate?: number;
  onClick?: () => void;
}

export default function Icon({ title, className, type, rotate, onClick }: IIconProps) {
  const wrapperClass = classNames('icon', className);
  const wrapperStyle = {
    [!!rotate && 'transform']: `rotate(${rotate}deg) translate3d(0, 0, 0)`,
  };

  return (
    <Wrapper className={wrapperClass} style={wrapperStyle} onClick={onClick}>
      <Canvas height="1" width="1" />
      <Svg xmlns="http://www.w3.org/2000/svg">
        {title && <title>{title}</title>}
        <use xlinkHref={`/images/sprite.${config.version}.svg#${IconType[type].toLowerCase()}`} />
      </Svg>
    </Wrapper>
  );
}
