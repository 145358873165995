import { IApiError } from '@models/index';
import { KnownApiAction } from '@store/api';
import { store } from '@root/configureStore';
import { useSelector } from 'react-redux';
import { IApplicationState } from '../store';

export default function dispatchConnectionError(error: IApiError) {
  const app = useSelector((state: IApplicationState) => state.app);

  if (!app.suppressError) {
    const action: KnownApiAction = {
      type: 'RECEIVE_API_ERROR',
      error,
    };

    store.dispatch(action);
  }
}
