import styled from 'styled-components';
import { pxToRem, rgbToRgba } from '@styles';

const INDICATOR_SIZE = 15;

export const Wrapper = styled.label`
  display: inline-block;
  min-height: ${pxToRem(20)};
  &:not(.disabled):hover input:not(:checked) + div .icon-check-mark {
    border-color: ${props => props.theme.colors.doveGray};
  }
  .using-keyboard &:focus-within {
    .indicator::before {
      content: '';
      position: absolute;
      width: ${pxToRem(INDICATOR_SIZE + 2)};
      height: ${pxToRem(INDICATOR_SIZE + 2)};
      top: -${pxToRem(2)};
      left: -${pxToRem(2)};
      border: ${pxToRem(1)} dotted ${props => props.theme.colors.silver};
    }
  }
`;

export const Input = styled.input`
  &:checked + div .icon-check-mark {
    background-color: ${props => props.theme.colors.lipstick};
    border-color: ${props => props.theme.colors.lipstick};
  }
  .disabled &:checked + div .icon-check-mark {
    background-color: ${props => rgbToRgba(props.theme.colors.doveGray, 0.3)};
    border-color: transparent;
  }
`;

export const Indicator = styled.div`
  position: relative;
  width: ${pxToRem(INDICATOR_SIZE)};
  height: ${pxToRem(INDICATOR_SIZE)};
  display: inline-block;
  background: ${props => props.theme.colors.white};
  margin-top: ${pxToRem(-1)};
  .icon-check-mark {
    width: inherit;
    height: inherit;
    border: ${pxToRem(2)} solid ${props => props.theme.colors.silver};
    color: ${props => props.theme.colors.white};
  }
`;

export const Label = styled.span`
  display: inline-flex;
  margin-left: ${pxToRem(8)};
`;

interface IBodyProps {
  wrapLabel: boolean;
}

export const Body = styled.div<IBodyProps>`
  display: flex;
  align-items: center;
  ${props =>
    props.wrapLabel
      ? `
    align-items: flex-start;
    ${Indicator} {
      margin-top: ${pxToRem(3)};
    }
    ${Label} {
      white-space: initial;
      line-height: ${pxToRem(22)};
    }
  `
      : null}
`;
