import { get } from '@common/fetch';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { IReceiveNews, IRequestNews } from './contracts/Actions';
import parseLink from 'parse-link-header';

export { default as INewsState } from './contracts/INewsState';

export type KnownNewsAction = IRequestNews | IReceiveNews;

export const newsActions = {
  getAll: (): IAppThunkAction<KnownNewsAction> => async dispatch => {
    const requestPath = 'news';

    dispatch({ type: 'REQUEST_NEWS' });

    const response = await get(requestPath);

    if (response.ok) {
      const linkHeader = parseLink(response.headers.get('Link'));
      const parsedMoreNewsLink = linkHeader ? linkHeader.more : null;

      dispatch({
        items: await response.json(),
        type: 'RECEIVE_NEWS',
        moreNewsUrl: (parsedMoreNewsLink && parsedMoreNewsLink.url) || '/',
      });
    }
  },
};
