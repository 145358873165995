import qs from 'query-string';
import { SortDirection, SupplyFilterType } from '@enums';
import ISelectedSupplyFilter from '@store/supply-filter/contracts/ISelectedSupplyFilter';
import { getActiveAdministrationNumber } from '@store/user';
import ISupplyFilterState from '@store/supply-filter/contracts/ISupplyFilterState';

const FILTER_TYPE_TO_QUERY_PROP: { [key: string]: string } = {
  [SupplyFilterType.TradeInstrument]: 'tradeInstruments',
  [SupplyFilterType.CropGroup]: 'cropGroups',
  [SupplyFilterType.ProductGroup]: 'productGroups',
  [SupplyFilterType.Product]: 'products',
  [SupplyFilterType.IsSpecific]: 'isSpecificItems',
  [SupplyFilterType.Supplier]: 'suppliers',
  [SupplyFilterType.CountryOfOrigin]: 'countryOfOrigins',
  [SupplyFilterType.Certification]: 'certifications',
  [SupplyFilterType.Quality]: 'qualities',
  [SupplyFilterType.MaturityStage]: 'maturityStages',
  [SupplyFilterType.MinimumStemsPerPlant]: 'minimumStemsPerPlants',
  [SupplyFilterType.PotMaterial]: 'potMaterials',
  [SupplyFilterType.PlantShape]: 'plantShapes',
  [SupplyFilterType.Colour]: 'colours',
  [SupplyFilterType.Package]: 'packages',
  [SupplyFilterType.AuctionClock]: 'auctionClocks',
  [SupplyFilterType.Price]: 'priceRange',
  [SupplyFilterType.ProducerGroup]: 'producerGroups',
  [SupplyFilterType.PotSize]: 'potSizes',
  [SupplyFilterType.LengthOfFlowerStem]: 'lengthOfFlowerStems',
  [SupplyFilterType.PlantHeight]: 'plantHeights',
  [SupplyFilterType.Weight]: 'weights',
  [SupplyFilterType.DeliveryFormBarcode]: 'deliveryFormBarcodes',
  [SupplyFilterType.StagingArea]: 'stagingAreas',
};

export interface ISupplyQueryProps {
  query?: string;
  deliveryLocationIds?: number[];
  mainGroups?: number[];
  deliveryMoment?: string;
  sort?: string;
  limit?: number;
  offset?: number;

  [key: string]: any;
}

interface ISupplyUrlInclude {
  sorting?: boolean;
  query?: boolean;
}

interface IAdditionalProps {
  [key: string]: string | number;
}

export default function getSupplyUrl(
  path: string,
  getState: any,
  additionalProps: IAdditionalProps = {},
  include: ISupplyUrlInclude = {
    sorting: true,
    query: true,
  },
) {
  const queryProps: ISupplyQueryProps = {
    offset: 0,
    limit: 50,
    ...additionalProps,
  };
  const {
    selected: {
      query,
      settings: { delivery, mainGroups },
      sorting,
      filters,
    },
  } = getState().supplyFilter as ISupplyFilterState;

  if (include.query && query) {
    queryProps.query = query;
  }

  if (delivery.locationIds) {
    queryProps.deliveryLocationIds = [...delivery.locationIds];
  }

  if (delivery.moment) {
    queryProps.deliveryMoment = delivery.moment;
  }

  if (delivery.isSelect) {
    queryProps.isSelectDelivery = delivery.isSelect;
  }

  if (mainGroups) {
    queryProps.mainGroups = mainGroups;
  }

  if (filters) {
    filters.forEach((filter: ISelectedSupplyFilter) => {
      queryProps[FILTER_TYPE_TO_QUERY_PROP[filter.type]] = filter.value;
    });
  }

  if (include.sorting && sorting) {
    const direction = sorting.direction === SortDirection.Ascending ? '+' : '-';

    queryProps.sort = `${direction}${sorting.field}`;
  }

  const queryPropsAsString = qs.stringify(queryProps);

  return `${path}?administrationNumber=${getActiveAdministrationNumber()}${queryPropsAsString.length > 0 ? `&${queryPropsAsString}` : ''}`;
}
