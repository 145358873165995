import React from 'react';
import Search from './Search';
import { Wrapper, Suggestions, ListItem } from './autoCompleteStyles';
import { OutlineButton } from '@components/shared/Button';
import { IconType } from '@enums/index';
import HtmlOutput from '@components/shared/HtmlOutput';
import { WithTranslation, withTranslation } from 'react-i18next';
import Loader from '@components/shared/Loader';
import escapeRegexString from 'escape-regex-string';

export interface IAutoCompleteValue {
  value: any;
  label: string;
}

interface IAutoCompleteProps extends WithTranslation {
  query?: string;
  placeholder?: string;
  items?: IAutoCompleteValue[];
  loading?: boolean;
  manualSelect?: boolean;
  onQueryChange?: (value: string) => void;
  onItemSelect?: (item: IAutoCompleteValue) => void;
}

interface IAutoCompleteState {
  activeItem: IAutoCompleteValue;
}

export class AutoComplete extends React.PureComponent<IAutoCompleteProps, IAutoCompleteState> {
  state: IAutoCompleteState = {
    activeItem: null,
  };

  constructor(props: IAutoCompleteProps) {
    super(props);

    this.onQueryChanged = this.onQueryChanged.bind(this);
    this.onAddClicked = this.onAddClicked.bind(this);
  }

  render() {
    const { t, manualSelect, loading, query, placeholder, items } = this.props;
    const { activeItem } = this.state;
    const hasItems = items && items.length > 0;
    const showSuggestions = query && !activeItem && (loading || hasItems);

    return (
      <Wrapper className="input-auto-complete">
        <Search value={query} placeholder={placeholder} onChange={this.onQueryChanged} />

        {manualSelect && (
          <OutlineButton label={t('general:add')} icon={{ type: IconType.Cross, rotation: -45 }} onClick={this.onAddClicked} disabled={!activeItem} />
        )}

        {showSuggestions && (
          <Suggestions isLoading={loading} className="suggestion-list">
            {loading && <Loader />}
            {!loading && (
              <ol>
                {items.map((item, index) => (
                  <ListItem key={index} onClick={this.onItemClicked.bind(this, item)}>
                    <HtmlOutput html={this.highlightQueryInLabel(item.label, query)} />
                  </ListItem>
                ))}
              </ol>
            )}
          </Suggestions>
        )}
      </Wrapper>
    );
  }

  private highlightQueryInLabel(label: string, query: string) {
    const findQueryRegex = new RegExp(`(${escapeRegexString(query)})`, 'ig');

    return label.replace(findQueryRegex, '<span>$1</span>');
  }

  private onQueryChanged(query: string) {
    const { onQueryChange } = this.props;

    this.setState(
      {
        activeItem: null,
      },
      () => onQueryChange(query),
    );
  }

  private onItemClicked(item: IAutoCompleteValue) {
    const { manualSelect, onQueryChange } = this.props;

    this.setState(
      {
        activeItem: item,
      },
      () => {
        onQueryChange(this.state.activeItem.label);

        if (!manualSelect) {
          this.passSelectedItem();
        }
      },
    );
  }

  private onAddClicked() {
    this.passSelectedItem();
  }

  private passSelectedItem() {
    const { manualSelect, onQueryChange, onItemSelect } = this.props;
    const { activeItem } = this.state;

    if (manualSelect) {
      this.setState(
        {
          activeItem: null,
        },
        () => {
          onQueryChange('');
          finish();
        },
      );
    } else {
      finish();
    }

    function finish() {
      onItemSelect && onItemSelect(activeItem);
    }
  }
}

export default withTranslation(['general'])(AutoComplete);
