import { IReceiveApiError, IRemoveApiError } from './contracts/Actions';
import IAppThunkAction from '@store/contracts/IAppThunkAction';

export { default as IApiState } from './contracts/IApiState';

export type KnownApiAction = IReceiveApiError | IRemoveApiError;

export const apiActions = {
  clearError: (): IAppThunkAction<KnownApiAction> => async dispatch => {
    dispatch({ type: 'REMOVE_API_ERROR' });
  },
};
