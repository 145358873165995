import React from 'react';
import debounce from 'lodash.debounce';
import moment from 'moment';
import Input from './Input';
import Icon from '@components/shared/Icon';
import { IconType } from '@enums';
import { ButtonsWrapper, InputButton, Wrapper } from './styles';

const DEBOUNCE_MS = 300;
const OUTPUT_FORMAT = 'YYYY-MM-DD';
const BROWSER_FORMATS: { [key: string]: string } = {
  date: 'YYYY-MM-DD',
  text: 'DD-MM-YYYY',
};

interface IDateProps extends React.HTMLProps<any> {
  onDateChange?: (value?: string) => void;
  value: string;
  minValue?: string;
  maxValue?: string;
  offset?: number;
  testHook?: string;
}

interface IDateState extends React.HTMLProps<any> {
  initialValue: string;
  value: string;
}

export default class Date extends React.PureComponent<IDateProps, IDateState> {
  element: HTMLInputElement = null;
  hasDateSupport: boolean = false;

  state: IDateState = {
    initialValue: this.props.value,
    value: this.props.value,
  };

  constructor(props: IDateProps) {
    super(props);

    this.onInputElement = this.onInputElement.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChange = debounce(this.handleChange, DEBOUNCE_MS);
  }

  static getDerivedStateFromProps(nextProps: IDateProps, state: IDateState): IDateState {
    const nextDate = nextProps.value;

    if (nextDate !== state.initialValue) {
      return {
        ...state,
        initialValue: nextDate,
        value: nextDate,
      };
    }

    return state;
  }

  componentDidMount() {
    this.forceUpdate();
  }

  render() {
    // Only render the actual component if we have determined it's type
    if (this.element) {
      const { className, testHook } = this.props;

      return (
        <Wrapper>
          <Input
            className={className}
            type="date"
            placeholder="dd-mm-yyyy"
            pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
            value={this.getInputValue() || ''}
            onChange={this.onChange}
            testHook={testHook}
          />
          <ButtonsWrapper clickThrough>
            <InputButton clickThrough aria-hidden tabIndex={-1} webkitOnly>
              <canvas width="1" height="1" />
              <Icon type={IconType.Calendar} />
            </InputButton>
          </ButtonsWrapper>
        </Wrapper>
      );
    }

    // Render a basic input of type date to determine date support
    return <input style={{ display: 'none' }} type="date" ref={this.onInputElement} />;
  }

  private onInputElement(instance: HTMLInputElement) {
    if (instance) {
      this.element = instance;
      this.hasDateSupport = instance.type.toLowerCase() === 'date';
    }
  }

  private onChange(event: any) {
    const {
      value,
      validity: { valid: isValidPattern },
    } = event.currentTarget;

    this.setStateValue(value, isValidPattern);
  }

  private handleChange(isValidPattern: boolean) {
    if (isValidPattern) {
      const { value } = this.state;
      const { onDateChange, minValue, maxValue } = this.props;

      if (!value) {
        onDateChange && onDateChange();
      } else {
        const date = moment(value, OUTPUT_FORMAT).startOf('day');

        if (date.isValid()) {
          const minValueDate = moment(minValue && minValue.split(' ')[0], OUTPUT_FORMAT);
          const isBeforeMinDate = value && minValue && minValueDate.diff(date) > 0;
          const maxValueDate = moment(maxValue && maxValue.split(' ')[0], OUTPUT_FORMAT);
          const isPastMaxValue = value && maxValue && maxValueDate.diff(date) < 0;

          if (isBeforeMinDate) {
            this.setStateValue(minValueDate.format(OUTPUT_FORMAT));
          } else if (isPastMaxValue) {
            this.setStateValue(maxValueDate.format(OUTPUT_FORMAT));
          } else {
            onDateChange && onDateChange(date.format(OUTPUT_FORMAT));
          }
        }
      }
    }
  }

  private setStateValue(value: string, isValidPattern: boolean = true) {
    this.setState(
      {
        value: this.getNewStateValue(value),
      },
      () => this.handleChange(isValidPattern),
    );
  }

  private getNewStateValue(value: string) {
    const { offset = 0 } = this.props;

    if (value) {
      const browserFormat = this.getBrowserFormat();
      const dateValue = moment(value, browserFormat, true).add(offset, 'days');

      if (dateValue.isValid()) {
        return dateValue.format(OUTPUT_FORMAT);
      }
    }

    return value;
  }

  private getInputValue() {
    const { value } = this.state;
    const { offset = 0 } = this.props;

    if (value) {
      const browserFormat = this.getBrowserFormat();
      const dateValue = moment(value, OUTPUT_FORMAT, true).subtract(offset, 'days');

      if (dateValue.isValid()) {
        return dateValue.format(browserFormat);
      }
    }

    return value;
  }

  /*
  Gets correct format in case date type is supported and browser falls back to text
   */
  private getBrowserFormat() {
    return this.hasDateSupport ? BROWSER_FORMATS.date : BROWSER_FORMATS.text;
  }
}
