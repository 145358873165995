import React from 'react';
import { Medium, Small, Wrapper } from './styles';
import classNames from 'classnames';

interface ILoaderProps extends React.HTMLProps<HTMLDivElement> {
  iconSize?: 's' | 'm' | 'l';
  inline?: boolean;
}

/**
 * This component uses hardcoded SVG objects instead of the SVG sprite because of the animations.
 * The SVGSpritemapPlugin we use to create the sprite does not play well with the keyframes specified to enable the animation.
 * Therefor the SVG is now embedded in the component and styled with styled components to get the animations
 * with automatic addition of any browser prefixes out of the box.
 */
/* eslint-disable max-len */
export default function Loader({ iconSize = 's', inline, className }: ILoaderProps) {
  const wrapperClass = classNames(className, 'loader', { inline });
  const mediumLoaderClass = classNames({
    'size-l': iconSize === 'l',
  });

  return (
    <Wrapper className={wrapperClass}>
      {iconSize === 's' && (
        <Small xmlns="http://www.w3.org/2000/svg" width="49px" height="11px" viewBox="0 0 49 11">
          <ellipse
            rx="5.5"
            ry="5.5"
            fill="currentColor"
            stroke="none"
            strokeWidth="0"
            fillRule="evenodd"
            opacity="0"
            strokeOpacity="0.9"
            fillOpacity="0.9"
            transform="translate(0,0) translate(43.5,5.5)"
            style={{ animation: 'Bol-3_o 1.4s linear infinite both' }}
          />
          <ellipse
            rx="5.5"
            ry="5.5"
            fill="currentColor"
            stroke="none"
            strokeWidth="0"
            fillRule="evenodd"
            opacity="0"
            strokeOpacity="0.9"
            fillOpacity="0.9"
            transform="translate(0,0) translate(24.5,5.5)"
            style={{ animation: 'Bol-2_o 1.4s linear infinite both' }}
          />
          <ellipse
            rx="5.5"
            ry="5.5"
            fill="currentColor"
            stroke="none"
            strokeWidth="0"
            fillRule="evenodd"
            opacity="0"
            strokeOpacity="0.9"
            fillOpacity="0.9"
            transform="translate(0,0) translate(5.5,5.5)"
            style={{ animation: 'Bol-1_o 1.4s linear infinite both' }}
          />
        </Small>
      )}
      {iconSize !== 's' && (
        <Medium className={mediumLoaderClass} xmlns="http://www.w3.org/2000/svg" width="61px" height="75px" viewBox="0 0 61 75">
          <g className="el_OvLBgWP-PH" fillRule="evenodd">
            <g className="el_vRS-Y4MUVV" fillRule="nonzero" fill="currentColor">
              <g className="el_bT6kRkPRUf">
                <path
                  d="M9.63226147,22.3632258 L10.5145399,21.2679931 L11.8868826,21.4895772 C25.3148483,23.6577117 34.2793293,30.1637189 38.4373776,40.9352902 C42.5961393,51.7087091 40.4501896,62.5106669 32.1867251,73.0268952 L31.3010939,74.1539648 L29.9036708,73.9223532 C17.1254486,71.8044658 8.15533539,65.5690521 3.25695246,55.2829348 C-1.73252343,44.8055314 0.492778057,33.7086929 9.63226147,22.3632258 Z M7.62859372,53.1136602 C11.6224024,61.5002615 18.7590276,66.6975716 29.2701494,68.7643166 C35.7573555,59.9735365 37.2505946,51.3953886 33.9145857,42.7533396 C30.569924,34.0888754 23.5370292,28.7386454 12.5119654,26.6385662 C5.24155535,36.1557172 3.70311071,44.870536 7.62859372,53.1136602 Z"
                  className="el_6zh40Dh_c3"
                />
                <path
                  d="M50.1762635,21.4539703 L51.5497292,21.2679106 L52.4030108,22.3800684 C60.7521179,33.2622012 62.9545457,44.2098388 58.8083462,54.9327241 C54.6614356,65.6574488 45.8969601,72.0346766 32.8609105,73.9653136 L31.4637808,74.1722283 L30.5988891,73.0353932 C22.6902048,62.6400217 20.3033246,51.88548 23.5859932,41.0030688 C26.9297082,29.9182825 35.9485926,23.3813546 50.1762635,21.4539703 Z M28.2203625,42.4527177 C25.5438974,51.3254966 27.2853365,60.0539275 33.5745233,68.841286 C44.1650298,66.936118 50.9713113,61.7256375 54.2978139,53.1226493 C57.6329445,44.4973471 56.0690822,35.7294576 49.4272516,26.5617419 C37.7813804,28.4530747 30.8510388,33.7317335 28.2203625,42.4527177 Z"
                  className="el_E764c05Uer"
                />
                <polygon className="el_91fWNOmFfL" points="13.1548235 18.3394117 8.02947371 10.9156334 12.1297535 8.59659925 17.2561601 16.0203775" />
                <polygon className="el_UwyESxxd0Z" points="20.0507815 15.4738787 16.6794922 4.16328466 21.3815536 2.86553308 24.7528428 14.1761271" />
                <polygon className="el_Bep0pf6tOO" points="28.2128502 13.7545588 32.8261935 13.7545588 32.8261935 0 28.2128502 0" />
                <polygon className="el_ysT2kS2Z1n" points="41.5653764 15.4738787 36.8628688 14.1761271 40.2337117 2.86553308 44.9362194 4.16328466" />
                <polygon className="el_pECwKYcLRK" points="48.7807821 18.3394117 44.9362194 16.0203775 49.7416751 8.59659925 53.5862379 10.9156334" />
              </g>
            </g>
          </g>
        </Medium>
      )}
    </Wrapper>
  );
}
