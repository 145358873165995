import { translateEnum } from '@common/enum';

enum Location {
  Aalsmeer = 'Aalsmeer',
  Bleiswijk = 'Bleiswijk',
  Eelde = 'Eelde',
  Naaldwijk = 'Naaldwijk',
  RheinMaas = 'RheinMaas',
  Rijnsburg = 'Rijnsburg',
  AddisAbabaAirport = 'AddisAbabaAirport',
  BogotaAirport = 'BogotaAirport',
  NairobiAirport = 'NairobiAirport',
  QuitoAirport = 'QuitoAirport',
  TelAvivAirport = 'TelAvivAirport',
  Nursery = 'Nursery',
}

export function translateLocation(key: Location, name: string = 'Location') {
  return translateEnum(name, key);
}

export function getLocation(gln: number) {
  switch (gln) {
    case 8714231208754:
      return 'AM';
    case 8714231208747:
      return 'NW';
    case 8714231208730:
      return 'RB';
    case 8714231208693:
      return 'ED';
    case 8714231208723:
      return 'BW';
    case 8713783843772:
      return 'RM';
    case 8714231208761:
      return 'AF';
    case 8718288046703:
      return 'NBO';
    case 8718288046918:
      return 'ADD';
    case 8718288046925:
      return 'UIO';
    case 8718288046932:
      return 'BOG';
    case 8718288046949:
      return 'TLV';
    default:
      return '';
  }
}

export default Location;
