import { css } from 'styled-components';
import { IColorIndicatorComponentProps } from './';
import { lightness, pxToRem } from '@styles';

const COLOR_SIZE = 13;
const COLOR_BORDER_WIDTH = 1;

export const styles = css<IColorIndicatorComponentProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 ${pxToRem(COLOR_SIZE)};
  width: ${pxToRem(COLOR_SIZE)};
  height: ${pxToRem(COLOR_SIZE)};
  .icon {
    width: ${pxToRem(COLOR_SIZE)};
    height: ${pxToRem(COLOR_SIZE)};
  }
  ${props =>
    !!props.color &&
    `
    background-color: ${props.color};
    border-radius: 50%;
    ${
      lightness(props.color) > 245
        ? `
      border: ${pxToRem(COLOR_BORDER_WIDTH)} solid ${props.theme.colors.mineShaft};
    `
        : ''
    }
  `}
`;
