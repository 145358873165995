import { Action, Reducer } from 'redux';
import { KnownSettingsAction } from './';
import ISettingsState from './contracts/ISettingsState';

const unloadedState: ISettingsState = {
  accounts: {
    loading: false,
    path: null,
    items: [],
    manageAccountsUrl: null,
  },
  orderConfigurations: {
    loading: false,
    items: [],
    updating: [],
  },
  buyerCards: {
    loading: false,
    items: {},
  },
  accountConfiguration: {
    loading: false,
    item: null,
    updating: false,
    errors: [],
  },
  filterSets: {
    initialized: false,
    loading: false,
    handling: false,
    updating: false,
    items: [],
    errors: [],
  },
  defaultDeliveryLocation: {
    updating: false,
  },
  supplyFilterSet: {
    updating: false,
  },
  suppliers: {
    loading: false,
    items: [],
  },
  cropGroups: {
    loading: false,
    items: [],
  },
  productGroups: {
    loading: false,
    items: [],
  },
  producerGroups: {
    loading: false,
    items: [],
  },
  insight: {
    loading: false,
    info: {
      databaseGetMoment: null,
      isRealTimeFilterActive: false,
      isRecalculationActive: false,
      latestGetSupplyMoment: null,
      latestSentSupplyLineDateTime: null,
      supplierSupplyLines: [],
    },
  },
  supplyFilterSets: {
    initialized: false,
    loading: false,
    handling: false,
    updating: false,
    items: [],
    errors: [],
  },
};

export const settingsReducer: Reducer<ISettingsState> = (state: ISettingsState, incomingAction: Action) => {
  const action = incomingAction as KnownSettingsAction;

  switch (action.type) {
    case 'REQUEST_ACCOUNTS':
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: true,
          path: action.path,
        },
      };
    case 'RECEIVE_ACCOUNTS':
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: false,
          items: action.accounts,
          manageAccountsUrl: action.manageAccountsUrl,
        },
      };
    case 'RECEIVE_ACCOUNT':
      return {
        ...state,
        accounts: {
          ...state.accounts,
          items: [...state.accounts.items, action.account],
        },
      };
    case 'REQUEST_ORDER_CONFIGURATIONS':
      return {
        ...state,
        orderConfigurations: {
          ...state.orderConfigurations,
          loading: true,
        },
      };
    case 'RECEIVE_ORDER_CONFIGURATIONS':
      return {
        ...state,
        orderConfigurations: {
          ...state.orderConfigurations,
          loading: false,
          items: action.configurations,
        },
      };
    case 'REQUEST_FILTER_SETS':
      return {
        ...state,
        filterSets: {
          ...state.filterSets,
          loading: true,
        },
      };
    case 'RECEIVE_FILTER_SETS':
      return {
        ...state,
        filterSets: {
          ...state.filterSets,
          initialized: true,
          loading: false,
          items: action.filterSets,
        },
      };
    case 'CREATE_FILTER_SET_SUCCEEDED':
      return {
        ...state,
        filterSets: {
          ...state.filterSets,
          items: [...state.filterSets.items, action.filterSet],
        },
      };
    case 'UPDATE_FILTER_SET_START':
      return {
        ...state,
        filterSets: {
          ...state.filterSets,
          handling: true,
          errors: unloadedState.filterSets.errors,
        },
      };
    case 'UPDATE_FILTER_SET_REQUESTED':
      return {
        ...state,
        filterSets: {
          ...state.filterSets,
          updating: true,
        },
      };
    case 'UPDATE_FILTER_SET_SUCCEEDED':
      const { items } = state.filterSets;
      const newitems = items.slice();

      newitems.splice(newitems.indexOf(action.currentFilterSet), 1, action.updatedFilterSet);

      return {
        ...state,
        filterSets: {
          ...state.filterSets,
          handling: false,
          updating: false,
          items: newitems,
          errors: unloadedState.filterSets.errors,
        },
      };
    case 'UPDATE_FILTER_SET_FAILED':
      return {
        ...state,
        filterSets: {
          ...state.filterSets,
          updating: false,
          errors: action.errors,
        },
      };
    case 'REMOVE_FILTER_SET_SUCCEEDED':
      return {
        ...state,
        filterSets: {
          ...state.filterSets,
          items: state.filterSets.items.filter(f => f.id !== action.id),
        },
      };
    case 'REQUEST_BUYER_CARDS':
      return {
        ...state,
        buyerCards: {
          ...state.buyerCards,
          loading: true,
        },
      };
    case 'RECEIVE_BUYER_CARDS':
      return {
        ...state,
        buyerCards: {
          ...state.buyerCards,
          loading: false,
          items: {
            ...state.buyerCards.items,
            [action.administrationNumber]: action.cards,
          },
        },
      };
    case 'REQUEST_UPDATE_ORDER_CONFIGURATIONS':
      return {
        ...state,
        orderConfigurations: {
          ...state.orderConfigurations,
          updating: [...state.orderConfigurations.updating, action.administration.number],
        },
      };
    case 'RECEIVE_UPDATE_ORDER_CONFIGURATIONS':
      const administration = action.configurations[0].administration;

      return {
        ...state,
        orderConfigurations: {
          ...state.orderConfigurations,
          updating: state.orderConfigurations.updating.filter(id => id !== administration.number),
          items: [...state.orderConfigurations.items.filter(i => i.administration.number !== administration.number), ...action.configurations],
        },
      };
    case 'REQUEST_ACCOUNT_CONFIGURATION':
      return {
        ...state,
        accountConfiguration: {
          ...state.accountConfiguration,
          loading: true,
        },
      };
    case 'RECEIVE_ACCOUNT_CONFIGURATION':
      return {
        ...state,
        accountConfiguration: {
          ...state.accountConfiguration,
          loading: false,
          item: action.configuration,
        },
      };
    case 'REQUEST_UPDATE_ACCOUNT_CONFIGURATION':
      return {
        ...state,
        accountConfiguration: {
          ...state.accountConfiguration,
          updating: true,
          errors: [],
        },
      };
    case 'RECEIVE_UPDATE_ACCOUNT_CONFIGURATION':
      return {
        ...state,
        accountConfiguration: {
          ...state.accountConfiguration,
          updating: false,
        },
      };
    case 'RECEIVE_UPDATE_ACCOUNT_CONFIGURATION_FAILED':
      return {
        ...state,
        accountConfiguration: {
          ...state.accountConfiguration,
          updating: false,
          errors: action.errors,
        },
      };
    case 'REQUEST_SET_DEFAULT_DELIVERY_LOCATION':
      return {
        ...state,
        defaultDeliveryLocation: {
          ...state.defaultDeliveryLocation,
          updating: true,
        },
      };
    case 'RECEIVE_SET_DEFAULT_DELIVERY_LOCATION':
      return {
        ...state,
        defaultDeliveryLocation: {
          ...state.defaultDeliveryLocation,
          updating: false,
        },
        accounts: {
          ...state.accounts,
          items: state.accounts.items.map(account => {
            const updatedAccount = { ...account };
            if (updatedAccount.id === action.accountId) {
              updatedAccount.defaultDeliveryLocation = action.location;
            }

            return updatedAccount;
          }),
        },
      };
    case 'RECEIVE_SUPPLY_FILTER_SETS':
      return {
        ...state,
        supplyFilterSets: {
          ...state.supplyFilterSets,
          initialized: true,
          loading: false,
          items: action.supplyFilterSets,
        },
      };
    case 'REQUEST_SUPPLIERS':
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loading: true,
        },
      };
    case 'RECEIVE_SUPPLIERS':
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loading: false,
          items: action.suppliers,
        },
      };
    case 'REQUEST_CROP_GROUPS':
      return {
        ...state,
        cropGroups: {
          ...state.cropGroups,
          loading: true,
        },
      };
    case 'RECEIVE_CROP_GROUPS':
      return {
        ...state,
        cropGroups: {
          ...state.cropGroups,
          loading: false,
          items: action.cropGroups,
        },
      };
    case 'REQUEST_PRODUCT_GROUPS':
      return {
        ...state,
        productGroups: {
          ...state.productGroups,
          loading: true,
        },
      };
    case 'RECEIVE_PRODUCT_GROUPS':
      return {
        ...state,
        productGroups: {
          ...state.productGroups,
          loading: false,
          items: action.productGroups,
        },
      };
    case 'REQUEST_PRODUCER_GROUPS':
      return {
        ...state,
        producerGroups: {
          ...state.producerGroups,
          loading: true,
        },
      };
    case 'RECEIVE_PRODUCER_GROUPS':
      return {
        ...state,
        producerGroups: {
          ...state.producerGroups,
          loading: false,
          items: action.producerGroups,
        },
      };
    case 'REQUEST_INSIGHT':
      return {
        ...state,
        insight: {
          ...state.insight,
          loading: true,
        },
      };
    case 'RECEIVE_INSIGHT':
      return {
        ...state,
        insight: {
          loading: false,
          info: action.insight,
        },
      };
    case 'REQUEST_SUPPLY_FILTER_SETS':
      return {
        ...state,
        supplyFilterSets: {
          ...state.supplyFilterSets,
          loading: true,
        },
      };
    case 'CREATE_SUPPLY_FILTER_SET':
      return {
        ...state,
        supplyFilterSets: {
          ...state.supplyFilterSets,
          items: [...state.supplyFilterSets.items, action.supplyFilterSet],
        },
      };
    case 'UPDATE_SUPPLY_FILTER_SET_START':
      return {
        ...state,
        supplyFilterSets: {
          ...state.supplyFilterSets,
          handling: true,
          errors: unloadedState.supplyFilterSets.errors,
        },
      };
    case 'UPDATE_SUPPLY_FILTER_SET_REQUESTED':
      return {
        ...state,
        supplyFilterSets: {
          ...state.supplyFilterSets,
          updating: true,
        },
      };
    case 'UPDATE_SUPPLY_FILTER_SET_SUCCEEDED':
      const { items: supplyFilters } = state.supplyFilterSets;
      const newFilters = supplyFilters.slice();

      newFilters.splice(newFilters.indexOf(action.currentFilterSet), 1, action.updatedFilterSet);

      return {
        ...state,
        supplyFilterSets: {
          ...state.supplyFilterSets,
          handling: false,
          updating: false,
          items: newFilters,
          errors: unloadedState.supplyFilterSets.errors,
        },
      };
    case 'UPDATE_SUPPLY_FILTER_SET_FAILED':
      return {
        ...state,
        supplyFilterSets: {
          ...state.supplyFilterSets,
          updating: false,
          errors: action.errors,
        },
      };
    case 'REMOVE_SUPPLY_FILTER_SET':
      return {
        ...state,
        supplyFilterSets: {
          ...state.supplyFilterSets,
          items: state.supplyFilterSets.items.filter(f => f.id !== action.id),
        },
      };
    case 'REQUEST_SET_SUPPLY_FILTER_SET':
      return {
        ...state,
        supplyFilterSet: {
          updating: true,
        },
      };
    case 'SET_SUPPLY_FILTER_SET_SUCCEEDED':
    case 'SET_SUPPLY_FILTER_SET_FAILED':
      return {
        ...state,
        supplyFilterSet: {
          updating: false,
        },
      };
    case 'RESET_FILTER_SET_ERRORS':
      return {
        ...state,
        supplyFilterSets: {
          ...state.supplyFilterSets,
          errors: [],
        },
        filterSets: {
          ...state.filterSets,
          errors: [],
        },
      };
  }

  return state || unloadedState;
};
