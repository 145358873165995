import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationState } from '@store';
import { supplyActions } from '@store/supply';
import ModelDetailPhotosDialog from '@components/shared/ModelDetailPhotosDialog';

type ISupplyLinePhotosContainerProps = {
  closeTo?: string;
};

function SupplyLinePhotosContainer({ closeTo }: ISupplyLinePhotosContainerProps) {
  const dispatch = useDispatch();
  const {
    detail: { lines },
  } = useSelector(({ supply }: IApplicationState) => supply);
  const { id } = useParams();
  const supplyLine = lines[id];
  const closeToPath = closeTo.replace(':id', id);

  useEffect(() => {
    dispatch(supplyActions.getSupplyLine(id));
  }, []);

  return <ModelDetailPhotosDialog type="supplyLine" model={supplyLine} closeTo={closeToPath} />;
}

export default React.memo(SupplyLinePhotosContainer);
