import { ISupplyDelivery, ISupplyLine } from '@models/index';
import { TradeInstrument } from '@enums/index';
import moment from 'moment';

interface ITradableSupplyLine {
  supplyLineId: string;
  price: number;
  availablePackages: number;
  deliveries: ISupplyDelivery[];
}

interface ISupplyLineTradingProps {
  children: (tradableSupplyLine: ITradableSupplyLine, isTradable: boolean, canRequestClockPresales: boolean) => JSX.Element;
  supplyLine: ISupplyLine;
  preventDefault?: boolean;
}

export default function SupplyLineTrading({ children, supplyLine, preventDefault }: ISupplyLineTradingProps) {
  const { id, tradeInstrument, clockPresales } = supplyLine;
  const isClockSupply = tradeInstrument === TradeInstrument.Clock;
  const isTradable = isSupplyLineTradable();
  const isTradableClockSupply = isClockSupply && isTradable;
  const supplyLineId = isTradableClockSupply ? clockPresales.supplyLineId : id;
  const price = isTradableClockSupply ? clockPresales.price : supplyLine.price;
  const availablePackages = isTradableClockSupply ? clockPresales.availablePackages : supplyLine.availablePackages;
  const deliveries = isTradableClockSupply ? [clockPresales.delivery] : supplyLine.deliveries;
  const canRequestClockPresales = !!(!isTradable && clockPresales && clockPresales.supplierOrganizationId);

  if (preventDefault || isTradable || canRequestClockPresales) {
    return children(
      {
        supplyLineId,
        price,
        availablePackages,
        deliveries,
      },
      !!isTradable,
      canRequestClockPresales,
    );
  }

  return null;

  function isSupplyLineTradable() {
    if (!isClockSupply) {
      return true;
    }

    if (clockPresales) {
      const firstOrderMoment = moment(clockPresales.delivery.firstOrderMoment);
      const latestOrderMoment = moment(clockPresales.delivery.latestOrderMoment);

      return moment().diff(firstOrderMoment) > 0 && moment().diff(latestOrderMoment) < 0;
    }

    return false;
  }
}
