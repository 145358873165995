import styled from 'styled-components';
import { breakpoint, pxToRem } from '@styles';

const COMPACT_HEADER_PHOTO_WIDTH = 125;

export const Article = styled.article`
  width: 100%;
  &:not(.overflow) {
    ${breakpoint('m')`
      display: flex;
      flex-direction: column;
    `}
  }
`;

export const Header = styled.header`
  font-size: ${pxToRem(15)};
  margin-bottom: ${pxToRem(15)};
  .main-photo {
    margin-bottom: ${pxToRem(15)};
    border: ${pxToRem(1)} solid ${props => props.theme.colors.wildSand};
  }
  .compact & {
    padding-left: ${pxToRem(COMPACT_HEADER_PHOTO_WIDTH + 15)};
    min-height: ${pxToRem(COMPACT_HEADER_PHOTO_WIDTH)};
    position: relative;
    .main-photo {
      position: absolute;
      top: 0;
      left: 0;
      width: ${pxToRem(COMPACT_HEADER_PHOTO_WIDTH)};
      height: 100%;
      .image {
        height: 100%;
      }
    }
    .buy-button {
      position: absolute;
      bottom: 0;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  .title {
    display: inline-block;
    margin-left: ${pxToRem(10)};
  }
`;

export const SubTitle = styled.span`
  display: none;
  margin-left: ${pxToRem(23)};
  .compact & {
    display: block;
  }
`;

export const Body = styled.div`
  overflow-y: auto;
  width: 100%;
`;

export const InfoGroup = styled.div`
  margin-bottom: ${pxToRem(15)};
`;

export const PropertyListWrapper = styled.table`
  line-height: ${pxToRem(25)};
  width: 100%;
  td {
    word-break: break-all; /* Fix horizontal scroll in IE */
    word-break: break-word;
    hyphens: auto;
    vertical-align: top;
    width: 50%;
    &:first-child:not(:only-child) {
      min-width: ${pxToRem(150)};
      padding-right: ${pxToRem(15)};
    }
  }
`;
