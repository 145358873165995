import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IEvent } from '@models';
import DateOutput from '@components/shared/DateOutput';
import Loader from '@components/shared/Loader';
import Heading from '@components/shared/Heading';
import { Dd, Dl, Dt } from './styles';

interface IEventListProps {
  loading?: boolean;
  events: IEvent[];
}

export default function EventList({ loading, events }: IEventListProps) {
  const { t } = useTranslation('home');
  const hasItems = events.length > 0;

  return (
    <div>
      <Heading as="h2" size="s" themed alignTop>
        {t('events.title')}
      </Heading>
      {!loading && hasItems && (
        <Dl>
          {events.map(event => (
            <Fragment key={event.id}>
              <Dt className="ellipsify">
                <DateOutput date={event.startDate} format="ddd DD MMMM" />
              </Dt>
              <Dd className="ellipsify">
                <LinkWrapper event={event}>{event.name}</LinkWrapper>
              </Dd>
            </Fragment>
          ))}
        </Dl>
      )}
      {!loading && !hasItems && <span>{t('events.noEvents')}</span>}
      {loading && <Loader />}
    </div>
  );
}

interface ILinkWrapperProps extends React.HTMLProps<HTMLDivElement> {
  event: IEvent;
}

function LinkWrapper({ children, event }: ILinkWrapperProps): any {
  return event.url ? (
    <a href={event.url} title={event.name} target="_blank" rel="noopener noreferrer" className="text-link">
      {children}
    </a>
  ) : (
    <span title={event.name}>{children}</span>
  );
}
