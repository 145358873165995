import styled from 'styled-components';
import ActivityIndicator from '@components/shared/ActivityIndicator';
import { breakpoint, BREAKPOINT_GUTTER, HEADER_HEIGHT, pxToRem } from '@styles';

const LAYOUT_ANIMATION_DURATION = 300;

export const MenuWrapper = styled.div`
  ${props => `
    width: 100%;
    height: ${pxToRem(HEADER_HEIGHT.s)};
    background: url('/images/main-background.jpg');
    background-size: cover;
    position: fixed;
    left: 0;
    right: 0;
    will-change: height;
    transition: height ${LAYOUT_ANIMATION_DURATION}ms;
    z-index: 100;
    ${breakpoint('m')`
      height: ${pxToRem(HEADER_HEIGHT.m)};
    `}
    ${breakpoint('l')`
      position: absolute;
      border-bottom: ${pxToRem(1)} solid ${props.theme.colors.alto};
      background: ${props.theme.colors.white};
      height: ${pxToRem(HEADER_HEIGHT.l)};
    `}
     &.open {
      height: 100vh;
      ${breakpoint('l')`
        height: auto;
      `};
    }
    .tooltip-menu {
      top: ${pxToRem(HEADER_HEIGHT.s)};
      color: ${props.theme.colors.mineShaft};
      width: 100vw;
      margin-left: -${pxToRem(BREAKPOINT_GUTTER.s)};
      z-index: 101;
      left: 0;
      &::before {
        left: auto;
      }
      ${breakpoint('m')`
        width: ${pxToRem(300)};
        right: -${pxToRem(15)};
        top: ${pxToRem(HEADER_HEIGHT.m)};
        left: auto;
      `}
      ${breakpoint('l')`
        top: ${pxToRem(HEADER_HEIGHT.l)};
        left: ${pxToRem(25)};
      `}
      &.notification {
        &::before {
          right: ${pxToRem(95)};
          ${breakpoint('l')`
            left: ${pxToRem(12)};
          `}
          ${breakpoint('m')`
            right: ${pxToRem(72)};
          `}
        }
      }
      &.user {
        &::before {
          right: ${pxToRem(43)};
          ${breakpoint('l')`
            left: ${pxToRem(62)};
          `}
          ${breakpoint('m')`
            right: ${pxToRem(23)};
          `}
        }
      }
    }
  `}
`;

export const InnerWrapper = styled.div`
  position: relative;
  margin: 0 ${pxToRem(BREAKPOINT_GUTTER.s)};
  height: ${pxToRem(HEADER_HEIGHT.s)};
  ${breakpoint('m')`
    margin: 0 ${pxToRem(BREAKPOINT_GUTTER.m)};
    height: ${pxToRem(HEADER_HEIGHT.m)};
  `}
  ${breakpoint('l')`
    margin: 0;
    height: inherit;
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    padding: 0 ${pxToRem(BREAKPOINT_GUTTER.l)};
    .center-layout & {
      padding: 0;
      justify-content: center;
      width: ${pxToRem(1024)};
      margin: 0 auto;
    }
  `}
`;

export const Bar = styled.div`
  position: relative;
  color: ${props => props.theme.colors.white};
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon-button {
    width: ${pxToRem(50)};
    height: ${pxToRem(50)};
    padding: ${pxToRem(10)};
    margin: 0;
  }
`;

export const ToggleButtonsWrapper = styled.div`
  margin-left: -${pxToRem(10)};
`;

export const UserNavigationWrapper = styled.div`
  ${props => `
    margin-right: -${pxToRem(10)};
    ${breakpoint('l')`
      color: ${props.theme.colors.mineShaft};
      height: 100%;
      display: flex;
      align-items: center;
    `}
  `}
`;

export const NotificationButtonWrapper = styled.div`
  ${props => `
    display: inline-block;
    position: relative;
    .button {
      &.has-new-notifications {
        svg, use {
          color: ${props.theme.colors.white};
          fill: ${props.theme.colors.white};
          ${breakpoint('l')`
            color: ${props.theme.colors.lipstick};
            fill: ${props.theme.colors.lipstick};
          `}
        }
      }
      &.has-general-warning {
        svg, use {
          color: ${props.theme.colors.schoolBus};
          fill: ${props.theme.colors.schoolBus};
        }
      }
    }
    .count {
      color: ${props.theme.colors.black};
      position: absolute;
      top: ${pxToRem(3)};
      right: ${pxToRem(6)};
      z-index: 1;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  margin-top: ${pxToRem(10)};
  overflow: hidden;
  ${breakpoint('m')`
    margin-top: ${pxToRem(30)};
  `}
  ${breakpoint('l')`
    display: block;
    flex: 1;
    position: relative;
    width: auto;
    font-size: ${pxToRem(18)};
    top: 0;
    margin: 0;
    height: inherit;
  `}
  .open & {
    transition: height ${LAYOUT_ANIMATION_DURATION}ms;
    height: 100vh;
  }
`;

export const Logo = styled.div`
  background: url('/images/logo-small.png');
  background-size: cover;
  width: ${pxToRem(33)};
  height: ${pxToRem(40)};
  margin-left: -${pxToRem(17)};
  position: absolute;
  top: ${pxToRem(10)};
  left: 50%;
  ${breakpoint('m')`
    width: ${pxToRem(130)};
    height: ${pxToRem(60)};
    margin-left: -${pxToRem(65)};
    background-image: url('/images/logo-medium.png');
  top: ${pxToRem(20)};
  `}
  ${breakpoint('l')`
    background-image: url('/images/logo-medium-color.png');
    position: relative;
    left: 0;
    top: 0;
    margin: 0 ${pxToRem(BREAKPOINT_GUTTER.l)} 0 0 ;
    .center-layout & {
      display: none;
    }
  `}
`;

export const HamburgerActivityIndicator = styled(ActivityIndicator)`
  &::before {
    background-color: ${props => props.theme.colors.white};
    top: ${pxToRem(10)};
    right: ${pxToRem(5)};
  }
`;
