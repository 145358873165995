import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import TradeInstrument, { translateTradeInstrument } from '@enums/TradeInstrument';
import { IDeliveryOption, IOrder, IOrderConditions, ISupplyLine } from '@models';
import { InputNumber, Message } from '@components/shared/Input';
import { DiscountTable, LimitBlock, LimitWrapper, Note, Table, TotalPrice, Wrapper } from './styles';
import PriceOutput, { formatPrice } from '@components/shared/PriceOutput';
import DeliveryGrid from '@components/supply/DeliveryGrid';
import OrderHelper, { IOrderValidationResult } from '@common/OrderHelper';
import { IDeliverySetting } from '@contracts';
import Accordion from '@components/layout/Accordion';
import { filterDeliveryOptions } from '@common/supply';
import { UserConnect } from '@containers/shared/Connect';
import { AccountType } from '@enums';

type IOrderFormProps = {
  supplyLine: ISupplyLine;
  conditions: IOrderConditions;
  delivery?: IDeliverySetting;
  order?: IOrder;
  onChange?: (value: IOrder, result: IOrderValidationResult) => void;
  postRecommendationEvent?: (event: string, supplylineID: string) => void;
  isInstantOrder?: boolean;
};

function OrderForm(props: IOrderFormProps) {
  const { supplyLine, conditions, delivery, onChange, postRecommendationEvent, isInstantOrder } = props;
  const orderHelper = new OrderHelper(conditions);

  const getActiveDeliveryOption = (): IDeliveryOption => {
    const { deliveryOptions } = conditions;
    let options = deliveryOptions;

    if (delivery) {
      if (location) {
        options = deliveryOptions.filter(o => delivery.locationIds.includes(o.delivery.location.id));
        options = options.length > 0 ? options : deliveryOptions;
      }

      if (delivery.moment) {
        const momentOptions = options.filter(o => moment(o.delivery.moment).isSame(delivery.moment));
        options = momentOptions.length > 0 ? momentOptions : options;
      }
    }

    return options[0];
  };

  const createNewOrder = (): IOrder => {
    const activeDeliveryOption = getActiveDeliveryOption();

    return {
      supplyLineId: supplyLine.id,
      bbsSupplyId: conditions.bbsSupplyId,
      quantity: activeDeliveryOption.minimumQuantity,
      deliveryId: activeDeliveryOption.id,
      hasBarcodeScanOrigin: window.location.href.includes('/scan'),
    };
  };

  const [order, setOrder] = useState(props.order || createNewOrder());
  const [result, setResult] = useState(orderHelper.validate(order));
  const { t } = useTranslation(['marketPlace', 'general']);
  const { quantity, deliveryId } = order;

  useEffect(() => {
    onOrderChange();
  }, [order]);

  useEffect(() => {
    onChange && onChange(order, result);
  }, [result]);

  const hasOrderLimit = conditions.orderLimit || conditions.orderLimit === 0;
  const hasDailyOrderLimit = conditions.dailyOrderLimit || conditions.dailyOrderLimit === 0;
  const selectedDeliveryLocation = orderHelper.getSelectedDeliveryOption(order);
  const hasDiscountProperties = !!(
    conditions.priceDiscountPerItem ||
    conditions.priceSupplementPerItem ||
    selectedDeliveryLocation.priceSupplementPerItem
  );
  const balance = orderHelper.getBalance(order);
  const balanceClass = classNames({
    error: balance < 0,
  });

  if (postRecommendationEvent) {
    postRecommendationEvent('viewed-order-form', supplyLine.id);
  }

  const getDeliveryOptions = () => filterDeliveryOptions<IDeliveryOption>(conditions.deliveryOptions, o => o.delivery.moment);

  const onDeliveryChanged = (deliveryOption: IDeliveryOption) => {
    setOrder({ ...order, deliveryId: deliveryOption.id });
  };

  const onQuantityChanged = (newQuantity: string) => {
    const parsedQuantity = newQuantity || newQuantity === '0' ? parseFloat(newQuantity) : null;
    setOrder({ ...order, quantity: parsedQuantity });
  };

  const onOrderChange = () => {
    setResult(orderHelper.validate(order));
  };

  if (!isInstantOrder || !result.isSuccess) {
    return (
      <Wrapper>
        <Table>
          <tbody>
            <tr>
              <th>
                <span>{t('supplyLine.tradeInstrument')}</span>
              </th>
              <td>{translateTradeInstrument(supplyLine.tradeInstrument)}</td>
            </tr>
            <tr>
              <th>
                <span>{t('supplyLine.vbnName')}</span>
              </th>
              <td>{supplyLine.tradeItem.growerProduct.vbnProduct.shortName}</td>
            </tr>
            <tr>
              <th>
                <span>{t('supplyLine.supplier')}</span>
              </th>
              <td>{supplyLine.tradeItem.supplier.name}</td>
            </tr>
            <tr>
              <th>
                <span>{t('order.delivery')}</span>
              </th>
              <td>
                <DeliveryGrid options={getDeliveryOptions()} value={deliveryId} onChange={onDeliveryChanged} />
              </td>
            </tr>
            {!!selectedDeliveryLocation.minimumQuantity && (
              <tr>
                <th>
                  <span>{t('order.minimumQuantity')}</span>
                </th>
                <td data-test-hook="minimum-quantity">{selectedDeliveryLocation.minimumQuantity}</td>
              </tr>
            )}
            {!!selectedDeliveryLocation.quantityStep && (
              <tr>
                <th>
                  <span>{t('order.quantityStep')}</span>
                </th>
                <td data-test-hook="quantity-step">{selectedDeliveryLocation.quantityStep}</td>
              </tr>
            )}
            <tr>
              <th>
                <span>{t('order.quantity')}</span>
              </th>
              <td>
                <InputNumber
                  error={result.error.quantity || null}
                  touched={!!(isInstantOrder && result.error.quantity)}
                  min={selectedDeliveryLocation.isMinimumQuantityRequired ? selectedDeliveryLocation.minimumQuantity : 1}
                  value={quantity || quantity === 0 ? quantity : ''}
                  step={selectedDeliveryLocation.quantityStep}
                  required
                  onNumberChange={onQuantityChanged}
                  className="quantity"
                  testHook="quantity"
                />
                {`${conditions.availableQuantity} ${t('order.available').toLowerCase()}`}
              </td>
            </tr>
            <tr>
              <th>
                <span>{t('order.pricePerItem')}</span>
              </th>
              <td>
                <PriceOutput price={selectedDeliveryLocation.calculatedPricePerItem} />
              </td>
            </tr>
            {conditions.showPriceDetails && hasDiscountProperties && (
              <tr>
                <td colSpan={2}>
                  <Accordion title={t('order.discountsAndSurcharges')}>
                    <DiscountTable>
                      <tbody>
                        {!!conditions.priceDiscountPerItem && (
                          <tr>
                            <th>{t('order.discountPerItem')}</th>
                            <td className="nowrap">
                              <PriceOutput price={conditions.priceDiscountPerItem} />
                              {!!conditions.percentualDiscountPerItem && ` (${conditions.percentualDiscountPerItem}%)`}
                            </td>
                          </tr>
                        )}
                        {!!conditions.priceSupplementPerItem && (
                          <tr>
                            <th>{t('order.priceSupplementPerItem')}</th>
                            <td className="nowrap">
                              <PriceOutput price={conditions.priceSupplementPerItem} />
                            </td>
                          </tr>
                        )}
                        {!!selectedDeliveryLocation.priceSupplementPerItem && (
                          <tr>
                            <th>{t('order.deliveryLocationPriceSupplementPerItem')}</th>
                            <td className="nowrap">
                              <PriceOutput price={selectedDeliveryLocation.priceSupplementPerItem} />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </DiscountTable>
                  </Accordion>
                </td>
              </tr>
            )}
            {(conditions.orderFee || conditions.orderFee === 0) && (
              <tr>
                <th>
                  <span>{t('order.orderFee')}</span>
                </th>
                <td>
                  <PriceOutput price={conditions.orderFee} />
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={2}>
                <hr />
              </td>
            </tr>
            <tr>
              <th>
                <span>{t('order.totalPrice')}</span>
              </th>
              <td>
                <TotalPrice>{formatPrice(orderHelper.getTotalPrice(order), 2)}</TotalPrice>
                {` (${quantity || 0}
                  x ${supplyLine.tradeItem.packing.itemsPerPackage}
                  x ${formatPrice(selectedDeliveryLocation.calculatedPricePerItem)})
                  ${conditions.orderFee ? ` + ${formatPrice(conditions.orderFee)}` : ''}
              `}
              </td>
            </tr>
          </tbody>
        </Table>

        {(hasOrderLimit || hasDailyOrderLimit) && (
          <LimitWrapper>
            {hasOrderLimit ? (
              <LimitBlock>
                {t('order.orderLimit')}: <PriceOutput price={conditions.orderLimit} suffixWhenRound numberOfDigits={2} />
              </LimitBlock>
            ) : (
              <LimitBlock>
                {t('order.dailyOrderLimit')}: <PriceOutput price={conditions.dailyOrderLimit} suffixWhenRound numberOfDigits={2} />
              </LimitBlock>
            )}
            <LimitBlock className={balanceClass}>
              {t('order.remaining')}: <PriceOutput price={balance > 0 ? balance : 0} suffixWhenRound numberOfDigits={2} />
              <Message className="balance-error">{result.error.balance}</Message>
            </LimitBlock>
          </LimitWrapper>
        )}

        {!conditions.isCancellable && (
          <Note role="alert">
            <span className="bold">{t('general:note')}:</span> {t('order.notCancellable')}
          </Note>
        )}

        {!selectedDeliveryLocation.isMinimumQuantityRequired && order.quantity < selectedDeliveryLocation.minimumQuantity && (
          <Note role="alert">
            <span className="bold">{t('general:note')}:</span> {t('order.minimumQuantityNotRequired')}
          </Note>
        )}

        <UserConnect>
          {({ user }) =>
            user.accountType === AccountType.External &&
            supplyLine.tradeInstrument === TradeInstrument.ClockPresales &&
            !conditions.orderFee &&
            conditions.orderFee !== 0 && (
              <Note role="alert">
                <span className="bold">{t('general:note')}:</span> {t('order.ecaTransactionFee')}
              </Note>
            )
          }
        </UserConnect>
      </Wrapper>
    );
  }

  return null;
}

export default React.memo(OrderForm);
