import React from 'react';
import { PriceRangeWrapper } from './priceRangeStyles';
import Price from './Price';

interface IPriceRangeProps {
  value: string[];
  min?: number;
  max?: number;
  decimals?: number;
  onChange?: (value: string[]) => void;
}

interface IPriceRangeState {
  initialValue: string[];
  value: string[];
}

export default class PriceRange extends React.PureComponent<IPriceRangeProps, IPriceRangeState> {
  state: IPriceRangeState = {
    initialValue: this.props.value,
    value: this.props.value,
  };

  static getDerivedStateFromProps(nextProps: IPriceRangeProps, state: IPriceRangeState): IPriceRangeState {
    if (nextProps.value !== state.initialValue) {
      return {
        ...state,
        initialValue: nextProps.value,
        value: nextProps.value,
      };
    }

    return state;
  }

  render() {
    const { min, max, decimals } = this.props;
    const { value } = this.state;

    return (
      <PriceRangeWrapper>
        <Price
          className="price-input"
          value={value[0]}
          min={min}
          max={value[1]}
          decimals={decimals}
          onPriceChange={this.onInputChanged.bind(this, 0)}
          onBlur={this.onInputBlur.bind(this, 0)}
        />
        <Price
          className="price-input"
          value={value[1]}
          min={value[0]}
          max={max}
          decimals={decimals}
          onPriceChange={this.onInputChanged.bind(this, 1)}
          onBlur={this.onInputBlur.bind(this, 1)}
        />
      </PriceRangeWrapper>
    );
  }

  private onInputChanged(index: number, price: string) {
    this.setState((currentState: IPriceRangeState) => {
      const newValue = currentState.value.slice(0);
      newValue[index] = price.toString();

      return {
        value: newValue,
      };
    });
  }

  private onInputBlur(index: number) {
    this.onChange(index);
  }

  private onChange(index?: number) {
    const { onChange } = this.props;
    const { value } = this.state;
    const newValue = value.slice(0);

    if (typeof index !== 'undefined') {
      const numericMinValue = parseFloat(newValue[0]);
      const numericMaxValue = parseFloat(newValue[1]);

      // Make sure min does not exceed max and vice-versa
      if (index === 0) {
        newValue[0] = isNaN(numericMinValue) || numericMinValue < numericMaxValue ? newValue[0] : newValue[1];
      } else if (index === 1) {
        newValue[1] = isNaN(numericMaxValue) || numericMaxValue > numericMinValue ? newValue[1] : newValue[0];
      }
    }

    onChange && onChange(newValue);
  }
}
