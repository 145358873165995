import styled from 'styled-components';
import { pxToRem } from '@styles';

export const Wrapper = styled.div`
  text-align: center;
  &.inline {
    display: inline-block;
  }
`;

export const Small = styled.svg`
  @keyframes Bol-3_o {
    0% {
      opacity: 0;
    }
    14.2857% {
      opacity: 0;
    }
    28.5714% {
      opacity: 0;
    }
    42.8571% {
      opacity: 1;
    }
    57.1429% {
      opacity: 1;
    }
    71.4286% {
      opacity: 1;
    }
    85.7143% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes Bol-2_o {
    0% {
      opacity: 0;
    }
    14.2857% {
      opacity: 0;
    }
    28.5714% {
      opacity: 1;
    }
    42.8571% {
      opacity: 1;
    }
    57.1429% {
      opacity: 1;
    }
    71.4286% {
      opacity: 1;
    }
    85.7143% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes Bol-1_o {
    0% {
      opacity: 0;
    }
    14.2857% {
      opacity: 1;
    }
    28.5714% {
      opacity: 1;
    }
    42.8571% {
      opacity: 1;
    }
    57.1429% {
      opacity: 1;
    }
    71.4286% {
      opacity: 0;
    }
    85.7143% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const Medium = styled.svg`
  @keyframes el_pECwKYcLRK_Animation {
    0% {
      opacity: 0;
    }
    42.22% {
      opacity: 0;
    }
    47.78% {
      opacity: 1;
    }
    83.33% {
      opacity: 1;
    }
    87.78% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes el_ysT2kS2Z1n_Animation {
    0% {
      opacity: 0;
    }
    38.89% {
      opacity: 0;
    }
    44.44% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    85.56% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes el_Bep0pf6tOO_Animation {
    0% {
      opacity: 0;
    }
    35.56% {
      opacity: 0;
    }
    41.11% {
      opacity: 1;
    }
    77.78% {
      opacity: 1;
    }
    82.22% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes el_UwyESxxd0Z_Animation {
    0% {
      opacity: 0;
    }
    32.22% {
      opacity: 0;
    }
    37.78% {
      opacity: 1;
    }
    74.44% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes el_91fWNOmFfL_Animation {
    0% {
      opacity: 0;
    }
    28.89% {
      opacity: 0;
    }
    34.44% {
      opacity: 1;
    }
    72.22% {
      opacity: 1;
    }
    76.67% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes el_E764c05Uer_Animation {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    28.89% {
      opacity: 1;
    }
    85.56% {
      opacity: 1;
    }
    92.22% {
      opacity: 1;
    }
    96.67% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes el_6zh40Dh_c3_Animation {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    28.89% {
      opacity: 1;
    }
    85.56% {
      opacity: 1;
    }
    92.22% {
      opacity: 0;
    }
    96.67% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  * {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
  }
  .el_OvLBgWP-PH {
    stroke: none;
    stroke-width: 1;
    fill: none;
  }
  .el_vRS-Y4MUVV {
    transform: translate(-30px, -17px);
  }
  .el_bT6kRkPRUf {
    transform: translate(30px, 17px);
  }
  .el_6zh40Dh_c3 {
    animation-name: el_6zh40Dh_c3_Animation;
  }
  .el_E764c05Uer {
    animation-name: el_E764c05Uer_Animation;
  }
  .el_91fWNOmFfL {
    animation-name: el_91fWNOmFfL_Animation;
  }
  .el_UwyESxxd0Z {
    animation-name: el_UwyESxxd0Z_Animation;
  }
  .el_Bep0pf6tOO {
    animation-name: el_Bep0pf6tOO_Animation;
  }
  .el_ysT2kS2Z1n {
    animation-name: el_ysT2kS2Z1n_Animation;
  }
  .el_pECwKYcLRK {
    animation-name: el_pECwKYcLRK_Animation;
  }
  &.size-l {
    color: ${props => props.theme.colors.lipstick};
    width: ${pxToRem(120)};
    height: ${pxToRem(154)};
  }
`;
