import styled from 'styled-components';
import { pxToRem, setFont } from '@styles';

const dtWidth = 155;
const dtPadding = 20;

export const Dl = styled.dl`
  display: flex;
  flex-flow: row wrap;
  line-height: ${pxToRem(30)};
`;

export const Dt = styled.dt`
  width: ${pxToRem(dtWidth)};
  padding-right: ${pxToRem(dtPadding)};
`;

export const Dd = styled.dd`
  ${setFont('bold')}
  width: calc(100% - ${pxToRem(dtWidth + dtPadding)});
  > a,
  > span {
    font-family: inherit;
  }
`;
