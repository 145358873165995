import styled from 'styled-components';
import { pxToRem, rgbToRgba } from '@styles';

const INDICATOR_SIZE = 20;

export const Wrapper = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  &:hover:not(.disabled) {
    input:not(:checked) + .indicator {
      border-color: ${props => props.theme.colors.doveGray};
    }
  }
  &.disabled {
    color: ${props => rgbToRgba(props.theme.colors.doveGray, 0.3)};
  }
  .using-keyboard &:focus-within {
    .indicator::before {
      content: '';
      position: absolute;
      width: ${pxToRem(INDICATOR_SIZE + 1)};
      height: ${pxToRem(INDICATOR_SIZE + 1)};
      top: -${pxToRem(1)};
      left: -${pxToRem(1)};
      border: ${pxToRem(1)} dotted ${props => props.theme.colors.silver};
    }
  }
`;

export const StyledRadioInput = styled.input`
  &:checked {
    + .indicator {
      border: ${pxToRem(7)} solid ${props => props.theme.colors.lipstick};
    }
  }
  .disabled &:checked {
    + .indicator {
      border-color: ${props => rgbToRgba(props.theme.colors.doveGray, 0.3)};
    }
  }
`;

export const Indicator = styled.span`
  height: ${pxToRem(INDICATOR_SIZE)};
  width: ${pxToRem(INDICATOR_SIZE)};
  border: ${pxToRem(2)} solid ${props => props.theme.colors.silver};
  border-radius: 50%;
  margin-right: ${pxToRem(5)};
`;

export const Label = styled.span`
  overflow: hidden;
`;
