import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IOrder } from '@models';
import { IApplicationState, orderActions, supplyRecommendationActions } from '@store';
import Modal, { Modal as ModalType } from '@components/shared/Modal';
import OrderForm from '@components/supply/OrderForm';
import Loader from '@components/shared/Loader';
import { removeFromSearch } from '@common/url';
import { IOrderValidationResult } from '@common/OrderHelper';
import includes from 'lodash.includes';
import qs from 'query-string';

interface IOrderParam {
  quantity: number;
}

type IOrderDialogContainerProps = {
  closeTo: string;
};

function OrderDialogContainer({ closeTo }: IOrderDialogContainerProps) {
  const dispatch = useDispatch();
  const order = useSelector((state: IApplicationState) => state.order);
  const {
    conditions: { data, supplyLine, error },
  } = order;
  const {
    selected: {
      settings: { delivery },
    },
  } = useSelector((state: IApplicationState) => state.supplyFilter);
  const location = useLocation();

  const getInstantOrderParam = (): IOrderParam => {
    const orderQuery = qs.parse(location.search).order;

    if (orderQuery) {
      return JSON.parse(orderQuery) as IOrderParam;
    }

    return null;
  };

  const instantOrderParam = getInstantOrderParam();
  const [orderState, setOrderState] = useState<IOrder>(null);
  const [result, setResult] = useState(null);
  const [isInstantOrder, setIsInstantOrder] = useState(!!instantOrderParam);
  const [modalInstance, setModalInstance] = useState<ModalType>(null);
  const { id } = useParams();
  const { t } = useTranslation('general');
  const hasError = result && !result.isSuccess;

  const isDataLoaded = () => supplyLine && data;

  const dataLoaded = isDataLoaded();
  const closeToPath = closeTo.replace(':id', id);

  const getModalInstance = (instance: ModalType) => {
    setModalInstance(instance);
  };

  const getInstantOrder = () => {
    const { locationIds } = delivery;

    const deliveryId =
      locationIds.length > 0 ? data.deliveryOptions.filter(d => locationIds.includes(d.delivery.location.id))[0].id : data.deliveryOptions[0].id;

    return {
      supplyLineId: supplyLine.id,
      bbsSupplyId: data.bbsSupplyId,
      deliveryId,
      quantity: instantOrderParam.quantity,
    };
  };

  const onOrderChanged = (changedOrder: IOrder, validationResult: IOrderValidationResult) => {
    setOrderState(changedOrder);
    setResult(validationResult);
  };

  useEffect(() => {
    if (isInstantOrder && result && result.isSuccess) {
      setIsInstantOrder(false);
      modalInstance.ready();
    }
  }, [result]);

  const onReady = () => {
    dispatch(orderActions.createOrder(orderState));

    if (includes(location.pathname, 'recommendation')) {
      dispatch(supplyRecommendationActions.createRecommendationEvent('order-request', id));
    }
  };

  useEffect(() => {
    dispatch(orderActions.getConditions(id));

    if (includes(location.pathname, 'recommendation')) {
      dispatch(supplyRecommendationActions.createRecommendationEvent('viewed-order-dialog', id));
    }

    return () => {
      dispatch(orderActions.resetOrderConditions());
    };
  }, []);

  return (
    <Modal
      title={t('buy')}
      isForm
      readyLabel={t('buy')}
      important
      onReady={onReady}
      showReadyButton={!isInstantOrder || hasError}
      showCancelButton
      getInstance={getModalInstance}
      disabled={!dataLoaded || hasError}
      closeTo={{ pathname: closeToPath, search: removeFromSearch(location.search, ['order']) }}
      autoClose
    >
      {dataLoaded && (
        <OrderForm
          supplyLine={supplyLine}
          conditions={data}
          delivery={delivery}
          isInstantOrder={!!isInstantOrder}
          order={isInstantOrder ? getInstantOrder() : null}
          onChange={onOrderChanged}
        />
      )}
      {!error && !dataLoaded && <Loader />}
      {error && <span>{error}</span>}
    </Modal>
  );
}

export default React.memo(OrderDialogContainer);
