import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '@components/shared/Modal';
import SupplyLineProvider from '@containers/supply/SupplyLineProvider';
import SupplyLineDetail from '@components/supply/SupplyLineDetail';
import { useLocation, useParams } from 'react-router-dom';
import { supplyRecommendationActions } from '@store';
import includes from 'lodash.includes';

type ISupplyLineDetailsDialogProps = {
  photoPath: string;
  buyPath: string;
};

function SupplyLineDetailsDialog({ photoPath, buyPath }: ISupplyLineDetailsDialogProps) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    if (includes(pathname, 'recommendation')) {
      dispatch(supplyRecommendationActions.createRecommendationEvent('viewed-details', id));
    }
  }, []);

  return (
    <Modal closeTo="/" fixedContent autoClose showReadyButton={false}>
      <SupplyLineProvider id={id}>
        {(supplyLine, loading) => (
          <SupplyLineDetail supplyLine={supplyLine} compact overflow={false} loading={loading} photoPath={photoPath} buyPath={buyPath} />
        )}
      </SupplyLineProvider>
    </Modal>
  );
}

export default React.memo(SupplyLineDetailsDialog);
