import { Action, Reducer } from 'redux';
import { KnownDeliveryLocationAction } from './';
import IDeliveryLocationState from './contracts/IDeliveryLocationState';

const unloadedState: IDeliveryLocationState = { locations: [], loading: true };

export const deliveryLocationReducer: Reducer<IDeliveryLocationState> = (state: IDeliveryLocationState, incomingAction: Action) => {
  const action = incomingAction as KnownDeliveryLocationAction;

  switch (action.type) {
    case 'REQUEST_DELIVERY_LOCATIONS':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_DELIVERY_LOCATIONS':
      return {
        ...state,
        locations: action.locations,
        loading: false,
      };
  }

  return state || unloadedState;
};
