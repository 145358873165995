import React from 'react';
import classNames from 'classnames';
import { IconType } from '@enums';
import { styles } from './styles';
import Icon from '@components/shared/Icon';
import styled from 'styled-components';

export interface IColorIndicatorComponentProps {
  color: string;
}

type IColorIndicatorProps = IColorIndicatorComponentProps & React.HTMLProps<HTMLDivElement>;

export function ColorIndicator({ color, tabIndex, className }: IColorIndicatorProps) {
  const hasColor = !!color;
  const wrapperClass = classNames('color', className);

  return (
    <div tabIndex={tabIndex} color={color} className={wrapperClass}>
      {!hasColor && <Icon type={IconType.NoColor} />}
    </div>
  );
}

export default styled(ColorIndicator)`
  ${styles}
`;
