import { translateEnum } from '@common/enum';

enum ClockLocation {
  Aalsmeer = 'Aalsmeer',
  Bleiswijk = 'Bleiswijk',
  Eelde = 'Eelde',
  Naaldwijk = 'Naaldwijk',
  RheinMaas = 'RheinMaas',
  Rijnsburg = 'Rijnsburg',
  Concern = 'Concern',
}

export function translateClockLocation(key: ClockLocation, name: string = 'ClockLocation') {
  return translateEnum(name, key);
}

export default ClockLocation;
