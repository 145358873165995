import styled from 'styled-components';
import { breakpoint, BREAKPOINT_GUTTER, pxToRem } from '@styles';

export const Wrapper = styled.article`
  text-align: center;
  padding: ${pxToRem(BREAKPOINT_GUTTER.s)};
  ${breakpoint('m')`
    padding: ${pxToRem(BREAKPOINT_GUTTER.m)};
  `}
  ${breakpoint('l')`
    padding: ${pxToRem(BREAKPOINT_GUTTER.l)};
  `}
  .logo {
    position: relative;
    width: 100%;
    max-width: ${pxToRem(280)};
    margin-bottom: ${pxToRem(50)};
    ${breakpoint('m')`
      width: auto;
    `}
  }
`;

export const Content = styled.section`
  background-color: ${props => props.theme.colors.wildSand};
  padding: ${pxToRem(15)};
  max-width: ${pxToRem(900)};
  margin: 0 auto;
  text-align: left;
  padding: ${pxToRem(BREAKPOINT_GUTTER.s)};
  ${breakpoint('m')`
    padding: ${pxToRem(BREAKPOINT_GUTTER.m)};
  `}
  ${breakpoint('l')`
    padding: ${pxToRem(BREAKPOINT_GUTTER.l)};
  `}
  p {
    margin-bottom: ${pxToRem(15)};
  }
`;
