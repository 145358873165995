import React, { RefObject } from 'react';
import { ActionToastWrapper } from './styles';
import { Button } from '@components/shared/Button';
import { IconType } from '@enums/index';
import classNames from 'classnames';

interface IActionToastProps extends React.HTMLProps<HTMLDivElement> {
  hideVisibilityButton?: boolean;
}

function ActionToast({ children, className, hideVisibilityButton }: IActionToastProps) {
  const element: RefObject<HTMLElement> = React.createRef();
  const onToggleClick = () => {
    element.current.classList.toggle('toast-enter-done');
    element.current.classList.toggle('toast-exit-done');
  };
  const wrapperClass = classNames('toast', className);

  return (
    <ActionToastWrapper role="alert" aria-live="assertive" className={wrapperClass} ref={element}>
      {!hideVisibilityButton && <Button className="btn-toggle-visibility" icon={IconType.Arrow} onClick={onToggleClick} noHover />}
      {children}
    </ActionToastWrapper>
  );
}

export default React.memo(ActionToast);
