import React from 'react';
import classNames from 'classnames';
import { Wrapper } from './styles';

interface ICountLabelProps extends React.HTMLProps<HTMLDivElement> {
  count?: number;
  themed?: boolean;
}

export default function CountLabel({ count = 0, themed, className }: ICountLabelProps) {
  const limitedCount = count > 99 ? 99 : count;
  const wrapperClass = classNames(className, 'count', {
    themed,
  });

  if (limitedCount > 0) {
    return <Wrapper className={wrapperClass}>{limitedCount}</Wrapper>;
  }

  return null;
}
