import styled from 'styled-components';
import { pxToRem } from '@styles';

interface IActivityIndicatorProps {
  show?: boolean;
}

const ActivityIndicator = styled.div<IActivityIndicatorProps>`
  position: relative;
  ${props =>
    props.show &&
    `
    &::before {
      content: '';
      width: ${pxToRem(12)};
      height: ${pxToRem(12)};
      background-color: ${props.theme.colors.black};
      display: block;
      border-radius: 50%;
      position: absolute;
      top: -${pxToRem(2)};
      right: 0;
      z-index: 1;
    }
  `}
`;

export default ActivityIndicator;
