import React, { Suspense } from 'react';
import { Main, RightColumnWrapper } from './styles';

interface IRightColumnProps {
  children?: any;
}

export default function RightColumn({ children }: IRightColumnProps) {
  return (
    <RightColumnWrapper>
      <Main>
        <Suspense fallback={null}>{children}</Suspense>
      </Main>
    </RightColumnWrapper>
  );
}
