import { supplyReducer } from './supply/reducer';
import { supplyFilterReducer } from './supply-filter/reducer';
import { userReducer } from './user/reducer';
import { deliveryLocationReducer } from './delivery-location/reducer';
import { newsReducer } from './news/reducer';
import { orderReducer } from './order/reducer';
import { statisticsReducer } from './statistics/reducer';
import { notificationReducer } from './notification/reducer';
import { apiReducer } from './api/reducer';
import { orderFilterReducer } from './order-filter/reducer';
import { eventReducer } from './event/reducer';
import { settingsReducer } from './settings/reducer';
import { appReducer } from './app/reducer';
import { koaReducer } from './koa/reducer';
import { helpReducer } from './help/reducer';
import { bannerReducer } from './banner/reducer';
import { clocksReducer } from './clocks/reducer';
import { supplyRecommendationReducer } from './supply-recommendation/reducer';
import { supplyMarkingReducer } from './supply-marking/reducer';

export { default as IApplicationState } from './contracts/IApplicationState';
export { IApiState, apiActions } from './api';
export { IAppState, appActions } from './app';
export { IBannerState, bannerActions } from './banner';
export { IClocksState, clocksActions } from './clocks';
export { IDeliveryLocationState, deliveryLocationActions } from './delivery-location';
export { IEventState, eventActions } from './event';
export { IHelpState, helpActions } from './help';
export { IKoaState, koaActions } from './koa';
export { INewsState, newsActions } from './news';
export { INotificationState, notificationActions } from './notification';
export { IOrderState, orderActions } from './order';
export { IOrderFilterState, orderFilterActions } from './order-filter';
export { ISettingsState, settingsActions } from './settings';
export { IStatisticsState, statisticsActions } from './statistics';
export { ISupplyState, supplyActions } from './supply';
export { ISupplyFilterState, supplyFilterActions } from './supply-filter';
export { ISupplyMarkingState, IQueueMarking, supplyMarkingActions } from './supply-marking';
export { ISupplyRecommendationsState, supplyRecommendationActions } from './supply-recommendation';
export { IUserState, userActions } from './user';

export const reducers = {
  api: apiReducer,
  app: appReducer,
  banner: bannerReducer,
  clocks: clocksReducer,
  deliveryLocation: deliveryLocationReducer,
  event: eventReducer,
  help: helpReducer,
  koa: koaReducer,
  news: newsReducer,
  notifications: notificationReducer,
  order: orderReducer,
  orderFilter: orderFilterReducer,
  settings: settingsReducer,
  statistics: statisticsReducer,
  supply: supplyReducer,
  supplyFilter: supplyFilterReducer,
  supplyRecommendation: supplyRecommendationReducer,
  supplyMarking: supplyMarkingReducer,
  user: userReducer,
};
