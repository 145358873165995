import { translateEnum } from '@common/enum';

enum OrderColumnType {
  ProductName = 'ProductName',
  GrowerProductName = 'GrowerProductName',
  TransactionMoment = 'TransactionMoment',
  DeliveryMoment = 'DeliveryMoment',
  DeliveryLocation = 'DeliveryLocation',
  Supplier = 'Supplier',
  Account = 'Account',
  Quantity = 'Quantity',
  PricePerItem = 'PricePerItem',
  Price = 'Price',
  CustomerPricePerItem = 'CustomerPricePerItem',
  CustomerPrice = 'CustomerPrice',
  Cancel = 'Cancel',
  OrderFee = 'OrderFee',
  OrderFeeCustomer = 'OrderFeeCustomer',
  PackageCode = 'PackageCode',
}

export function translateOrderColumnType(key: OrderColumnType, name: string = 'OrderColumnType') {
  return translateEnum(name, key);
}

export default OrderColumnType;
