import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';
import { buttonStyles } from './styles';
import BaseButton, { IBaseButtonProps } from './BaseButton';

export type IButtonProps = {
  border?: boolean;
} & IBaseButtonProps;

const Button = styled(({ className, border, active, ...restProps }: any) => (
  <BaseButton className={classNames(className, { bordered: border, active })} {...restProps} />
))`
  ${buttonStyles}
`;

export default Button;
