import styled from 'styled-components';
import { breakpoint, pxToRem } from '@styles';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: inherit;
  ${breakpoint('l')`
    .next, .previous{
     display: none;
    }
  `}
`;

export const BlockList = styled.ul`
  transition: transform 0.3s ease-in-out;
  height: inherit;
  white-space: nowrap;
`;

export const Block = styled.li`
  display: inline-block;
  width: 100%;
  height: inherit;
  padding: 0 ${pxToRem(50)};
  &:first-child {
    padding-left: 0;
  }
  ${breakpoint('m')`
    width: 50%;
    padding: 0 ${pxToRem(50)} 0 0;
    &:nth-child(odd):not(:first-child) {
      padding-left: ${pxToRem(50)};
    }
  `}
  ${breakpoint('l')`
    width: ${pxToRem(275)};
    padding: 0;
    margin-right: ${pxToRem(50)};
    &:nth-child(odd):not(:first-child) {
      padding: 0;
    }
  `}
`;
