import styled from 'styled-components';
import { pxToRem } from '@styles';

export const Header = styled.button`
  width: 100%;
  display: inline-flex;
  align-items: center;
  .icon {
    width: ${pxToRem(13)};
    margin-right: ${pxToRem(10)};
  }
  .heading {
    margin: 0;
  }
`;

export const Body = styled.div`
  margin-top: ${pxToRem(5)};
  padding: ${pxToRem(15)};
`;
