import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OutlineButton } from '@components/shared/Button';
import Loader from '@components/shared/Loader';
import { ISupplyLine } from '@models';
import MarkButtons from '@containers/supply/MarkButtons';
import { IApplicationState, supplyActions } from '@store';
import { ToastWrapper } from './styles';
import { useBreakpoint } from '../../withBreakpoint';

type IActionToastGroupProps = {
  selection: ISupplyLine[];
  inProgress: boolean;
};

function SupplyActionToast({ selection, inProgress }: IActionToastGroupProps) {
  const dispatch = useDispatch();
  const { sideBar } = useSelector(({ app }: IApplicationState) => app);
  const { isBreakpointSmall } = useBreakpoint();
  const { t } = useTranslation(['marketPlace', 'general']);
  const onCancelClicked = () => dispatch(supplyActions.clearSelectedSupplyLines());
  const isSideBarOpenWithBreakpointSmall = sideBar.isOpen && isBreakpointSmall;
  // the init state of toast-enter-done acts a litlle strange, when we don't set this here.
  const toastWrapperClassName = isSideBarOpenWithBreakpointSmall ? 'is-side-bar-open-with-breakpoint-small toast-enter-done' : 'toast-enter-done';

  return (
    <ToastWrapper className={toastWrapperClassName} hideVisibilityButton={isSideBarOpenWithBreakpointSmall}>
      <span className="hidden-s">{t('actions.selectionCount', { count: selection.length })}</span>
      <div className="buttons">
        {!inProgress && (
          <>
            {!isSideBarOpenWithBreakpointSmall && <OutlineButton label={t('general:cancel')} onClick={onCancelClicked} color="white" />}
            <MarkButtons selection={selection} />
          </>
        )}
        {inProgress && <Loader />}
      </div>
    </ToastWrapper>
  );
}

export default React.memo(SupplyActionToast);
