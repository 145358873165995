import styled from 'styled-components';
import { breakpoint, HEADER_HEIGHT, pxToRem } from '@styles';

export const Wrapper = styled.aside`
  max-height: calc(100vh - ${pxToRem(HEADER_HEIGHT.s)});
  ${breakpoint('m')`
    max-height: calc(100vh - ${pxToRem(HEADER_HEIGHT.m)});
  `}
  ${breakpoint('l')`
    max-height: calc(100vh - ${pxToRem(HEADER_HEIGHT.l)});
  `}
`;

export const InnerWrapper = styled.div`
  overflow-y: auto;
`;

interface IBLockProps {
  noPadding?: boolean;
}

export const Block = styled.div<IBLockProps>`
  padding: ${pxToRem(30)};
  &:not(:last-child) {
    border-bottom: ${pxToRem(1)} solid ${props => props.theme.colors.silver};
  }
  > .button {
    margin: 0;
    width: 100%;
    &.back-to-old {
      margin-bottom: ${pxToRem(20)};
    }
    &.log-out {
      margin-left: 0;
    }
  }
  ${props =>
    props.noPadding
      ? `
    padding: 0;
  `
      : null}
`;

export const InputLabel = styled.label`
  display: block;
  margin-top: ${pxToRem(15)};
`;

export const Code = styled.span`
  display: block;
  color: ${props => props.theme.colors.doveGray};
`;
