import styled, { css } from 'styled-components';
import { breakpoint, pxToRem, rgbToRgba, setFont } from '@styles';
import { BACKDROP_Z_INDEX } from '@components/shared/Modal/styles';

export const ACTION_TOAST_HEIGHT = 60;
export const NOTIFICATION_TOAST_HEIGHT = 50;
export const TOAST_ANIMATION_DURATION = 300;
const TOGGLE_BUTTON_HEIGHT = 44;
const TOGGLE_BUTTON_OFFSET = 15;

export const toastStyles = css`
  display: flex;
  align-items: center;
  width: 100vw;
  background: ${props => props.theme.colors.doveGray};
  z-index: 200;
  color: ${props => props.theme.colors.white};
  padding: 0 ${pxToRem(15)};
  ${breakpoint('m')`
    padding: 0 ${pxToRem(30)};
  `}
  ${setFont('bold')}
`;

export const ActionToastWrapper = styled.aside`
  ${toastStyles}
  z-index: ${BACKDROP_Z_INDEX - 1};
  background-color: ${props => props.theme.colors.lipstick};
  color: ${props => props.theme.colors.white};
  span {
    font-family: inherit;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .button {
      width: 42%;
      white-space: nowrap;
      .icon {
        display: none;
      }
    }
    ${breakpoint('m')`
       margin-left: auto;
       width: auto;
       .button {
          width: auto;
          .icon {
            display: block;
          }
       }
    `}
  }
  .btn-toggle-visibility {
    position: absolute;
    top: -${pxToRem(TOGGLE_BUTTON_OFFSET)};
    background: ${props => props.theme.colors.lipstick};
    border-radius: 50%;
    width: ${pxToRem(TOGGLE_BUTTON_HEIGHT)};
    height: ${pxToRem(TOGGLE_BUTTON_HEIGHT)};
    padding: ${pxToRem(11)};
    left: 50%;
    margin-left: -${pxToRem(TOGGLE_BUTTON_HEIGHT / 2)};
    transition: transform ${TOAST_ANIMATION_DURATION}ms ease-in;
    .toast-exit-done& {
      transform: translateY(-${pxToRem(TOGGLE_BUTTON_HEIGHT - TOGGLE_BUTTON_OFFSET)}) rotate(180deg);
    }
  }
  ${getAnimationStyles(ACTION_TOAST_HEIGHT)}
`;

export const NotificationToastWrapper = styled.aside`
  ${toastStyles}
  font-size: ${pxToRem(18)};
  &.warning {
    background: ${props => props.theme.colors.schoolBus};
    color: ${props => props.theme.colors.black};
  }
  &.success {
    background: ${props => props.theme.colors.laPalma};
  }
  &.error {
    background: ${props => props.theme.colors.red};
  }
  > .icon {
    margin-right: 15px;
  }
  .button-close {
    margin-left: auto;
    width: ${pxToRem(35)};
  }
  ${getAnimationStyles(NOTIFICATION_TOAST_HEIGHT)}
`;

export const Label = styled.label`
  width: calc(100% - ${pxToRem(75)});
`;

function getAnimationStyles(height: number) {
  return css`
    height: ${pxToRem(height)};
    transition: transform ${TOAST_ANIMATION_DURATION}ms ease-in;
    &.toast {
      position: fixed;
      bottom: -${pxToRem(height)};
      box-shadow: 0 0 ${pxToRem(15)} 0 ${props => rgbToRgba(props.theme.colors.black, 0.3)};
    }
    &.toast-enter-active {
      transform: translateY(-${pxToRem(height)});
    }
    &.toast-enter-done,
    &.toast-exit {
      transform: translateY(-${pxToRem(height)});
    }
    &.toast-enter-done {
      &:not(:first-child) {
        box-shadow: none;
      }
    }
    &.toast-exit-active {
      transform: translateY(${pxToRem(height)});
    }
  `;
}
