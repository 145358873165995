import React from 'react';
import classNames from 'classnames';
import { Label, NotificationToastWrapper } from './styles';
import { CloseButton } from '@components/shared/Button';
import { IconType, NotificationLevel } from '@enums';
import Icon from '@components/shared/Icon';
import { IPushNotification } from '@models';

const NOTIFICATION_LEVEL_TO_ICON_TYPE = {
  [NotificationLevel.Info]: IconType.Info,
  [NotificationLevel.Success]: IconType.Check,
  [NotificationLevel.Warning]: IconType.Warning,
  [NotificationLevel.Error]: IconType.Error,
};

interface INotificationToastProps {
  notification: IPushNotification;
  onClose?: (notification: IPushNotification) => void;
}

export default function NotificationToast({ notification, onClose }: INotificationToastProps) {
  const notificationClass = classNames('toast', 'notification', notification.level.toLowerCase());

  return (
    <NotificationToastWrapper role="alert" aria-live="assertive" className={notificationClass}>
      <Icon type={NOTIFICATION_LEVEL_TO_ICON_TYPE[notification.level]} />
      <Label className="ellipsify">{notification.title}</Label>
      <CloseButton
        onClick={() => {
          onClose && onClose(notification);
        }}
        noHover
      />
    </NotificationToastWrapper>
  );
}
