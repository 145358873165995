import styled from 'styled-components';
import { pxToRem, setFont } from '@styles';

const ddWidth = 50;
const dtPadding = 20;

export const Wrapper = styled.div`
  margin-bottom: 30px;
`;

export const Dl = styled.dl`
  display: flex;
  flex-flow: row wrap;
  line-height: ${pxToRem(35)};
  margin-bottom: ${pxToRem(15)};
`;

export const Dt = styled.dt`
  width: calc(100% - ${pxToRem(ddWidth + dtPadding)});
  padding-right: ${pxToRem(dtPadding)};
  border-bottom: ${pxToRem(1)} solid ${props => props.theme.colors.alto};
`;

export const Dd = styled.dd`
  ${setFont('bold')}
  width: ${pxToRem(ddWidth + dtPadding)};
  border-bottom: ${pxToRem(1)} solid ${props => props.theme.colors.alto};
`;
