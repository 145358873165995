import styled from 'styled-components';
import { pxToRem, rgbToRgba, setFont } from '@styles/index';

const VISIBLE_ITEMS = 6;
const ITEM_HEIGHT = 40;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  .input-search {
    width: 100%;
    + .button {
      margin-left: ${pxToRem(10)};
    }
  }
  .button {
    min-height: ${pxToRem(30)};
    min-width: ${pxToRem(135)};
    height: ${pxToRem(30)};
  }
`;

interface ISuggestionsProps {
  isLoading: boolean;
}

export const Suggestions = styled.div<ISuggestionsProps>`
  position: absolute;
  z-index: 2;
  background: ${props => props.theme.colors.white};
  width: calc(100% - ${pxToRem(10)});
  height: ${pxToRem(VISIBLE_ITEMS * ITEM_HEIGHT)};
  overflow-y: scroll;
  top: ${pxToRem(30)};
  border: ${pxToRem(1)} solid ${props => props.theme.colors.alto};
  box-shadow: ${pxToRem(2)} ${pxToRem(2)} ${pxToRem(5)} ${props => rgbToRgba(props.theme.colors.black, 0.15)};
  ${props =>
    props.isLoading
      ? `
    padding: ${pxToRem(15)};
  `
      : null}
`;

export const ListItem = styled.li`
  height: ${pxToRem(ITEM_HEIGHT)};
  border-bottom: ${pxToRem(1)} solid ${props => props.theme.colors.alto};
  display: flex;
  align-items: center;
  padding: ${pxToRem(10)};
  &:hover {
    background: ${props => props.theme.colors.alabaster};
  }
  span {
    ${setFont('bold')}
  }
`;
