import { translateEnum } from '@common/enum';

enum WebServiceFilterType {
  CancelableSupply = 'CancelableSupply',
  TradeInstrument = 'TradeInstrument',
  DeliveryLocation = 'DeliveryLocation',
  Delivery = 'Delivery',
  MinimalNumberOfPackages = 'MinimalNumberOfPackages',
  MainGroup = 'MainGroup',
  Supplier = 'Supplier',
  ProducerGroup = 'ProducerGroup',
  Quality = 'Quality',
  CropGroup = 'CropGroup',
  ProductGroup = 'ProductGroup',
  SelectSupply = 'SelectSupply',
}

export enum SupplyFilterType {
  MainGroup = 'MainGroup',
  DeliveryLocation = 'DeliveryLocation',
  Supplier = 'Supplier',
  ProducerGroup = 'ProducerGroup',
  Quality = 'Quality',
  CropGroup = 'CropGroup',
  ProductGroup = 'ProductGroup',
}

export function translateWebServiceFilterType(key: WebServiceFilterType, plural: boolean = false, name: string = 'WebServiceFilterType') {
  return translateEnum(name, `${key}${plural ? '_plural' : ''}`);
}

export const TYPE_TO_ITEMS: { [key: string]: (filter: any) => any[] } = {
  [WebServiceFilterType.CropGroup]: filter => filter.cropGroups,
  [WebServiceFilterType.DeliveryLocation]: filter => filter.deliveryLocations,
  [WebServiceFilterType.MainGroup]: filter => filter.mainGroups,
  [WebServiceFilterType.ProducerGroup]: filter => filter.producerGroups,
  [WebServiceFilterType.ProductGroup]: filter => filter.productGroups,
  [WebServiceFilterType.Quality]: filter => filter.qualities,
  [WebServiceFilterType.Supplier]: filter => filter.suppliers,
};

export default WebServiceFilterType;
