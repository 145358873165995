import styled from 'styled-components';
import { pxToRem } from '@styles';

export const Strong = styled.p`
  font-weight: bold;
`;

export const Wrapper = styled.section`
  .icon {
    width: ${pxToRem(80)}
    height: ${pxToRem(80)}
    display: inline-block;
  }
`;

export const Content = styled.section`
  display: inline-block;
  width: 80%;
  margin-left: ${pxToRem(20)};
`;
