import { css } from 'styled-components';

export const BASE_FONT_SIZE = 15;

export const theme = {
  colors: {
    white: 'rgb(255, 255, 255)',
    red: 'rgb(255, 0, 0)',
    lipstick: 'rgb(175, 0, 80)',
    redViolet: 'rgb(225, 20, 130)',
    laPalma: 'rgb(30, 175, 18)',
    schoolBus: 'rgb(255, 217, 0)',

    // Gray tones
    black: 'rgb(0, 0, 0)',
    mineShaft: 'rgb(34, 34, 34)',
    alabaster: 'rgb(248, 248, 248)',
    alto: 'rgb(221, 221, 221)',
    dustyGray: 'rgb(153, 153, 153)',
    silver: 'rgb(204, 204, 204)',
    doveGray: 'rgb(102, 102, 102)',
    wildSand: 'rgb(246, 246, 246)',
  },
};

export const BREAKPOINT_GUTTER: any = {
  s: 20,
  m: 30,
  l: 40,
};

export const HEADER_HEIGHT: any = {
  s: 60,
  m: 100,
  l: 80,
};

export const BREAKPOINTS_IN_PX: any = {
  m: 768,
  l: 1024,
  xl: 1280,
};

export const MAX_VIEWPORT_WIDTH = 1920;

export const BREAKPOINTS: any = {
  m: `(min-width: ${BREAKPOINTS_IN_PX.m}px)`,
  l: `(min-width: ${BREAKPOINTS_IN_PX.l}px)`,
  xl: `(min-width: ${BREAKPOINTS_IN_PX.xl}px)`,
};

export function pxToRem(numberOfPixels: number): string {
  return `${numberOfPixels / BASE_FONT_SIZE}rem`;
}

export function isBreakpoint(size: string) {
  const query = getMediaQueryString(size);

  return matchMedia(query).matches;
}

export function breakpoint(size: string) {
  return (args: TemplateStringsArray, ...interpolations: any[]) =>
    css`
      @media ${getMediaQueryString(size)} {
        ${css(args, ...interpolations)}
      }
    `.join('');
}

export function rgbToRgba(color: string = 'rgb(0,0,0)', opacity: number = 1) {
  return `rgba(${getRgb(color).join(',')}, ${opacity})`;
}

export function setFont(weight?: 'bold' | 'thin') {
  return `
    font-family: grotesque${weight ? `-${weight}` : ''}, arial;
  `;
}

export function lightness(color: string) {
  const rgb = getRgb(color);
  const max = Math.max.apply(Math, rgb);
  const min = Math.min.apply(Math, rgb);

  return (max + min) / 2;
}

let scrollY = 0;

export function toggleBodyScroll(enable: boolean = true) {
  const { classList } = document.documentElement;

  if (enable) {
    classList.remove('no-scroll');
    window.scrollTo(0, scrollY);
  } else {
    scrollY = window.scrollY;
    classList.add('no-scroll');
  }
}

export function webkitOnly() {
  return (args: TemplateStringsArray, ...interpolations: any[]) =>
    css`
      @supports (-webkit-appearance: none) {
        ${css(args, ...interpolations)}
      }
    `.join('');
}

function getMediaQueryString(size: string) {
  return `only screen and ${BREAKPOINTS[size] || ''}`;
}

function getRgb(color: string) {
  return color.match(/\d{1,3}|\d{2}?/g);
}
