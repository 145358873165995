import sortBy from 'lodash.sortby';
import store from 'store';
import { IColumnLayoutItem } from '@contracts';
import { OrderColumnType, SupplyColumnType } from '@enums';

export const SUPPLY_COLUMN_LAYOUT_DEFAULT: IColumnLayoutItem[] = [
  { type: SupplyColumnType.MarkSelected, position: 1, visible: false, fixed: true },
  { type: SupplyColumnType.ProductName, position: 2, visible: true, fixed: false },
  { type: SupplyColumnType.GrowerProductName, position: 3, visible: false, fixed: false },
  { type: SupplyColumnType.SelectDelivery, position: 4, visible: true, fixed: false },
  { type: SupplyColumnType.Supplier, position: 5, visible: true, fixed: false },
  { type: SupplyColumnType.Price, position: 6, visible: true, fixed: false },
  { type: SupplyColumnType.ClockPresales, position: 7, visible: false, fixed: false },
  { type: SupplyColumnType.AvailablePackages, position: 8, visible: false, fixed: false },
  { type: SupplyColumnType.Characteristics, position: 9, visible: true, fixed: false },
  { type: SupplyColumnType.Load, position: 10, visible: true, fixed: false },
  { type: SupplyColumnType.DeliveryForm, position: 11, visible: false, fixed: false },
  { type: SupplyColumnType.CreationDate, position: 12, visible: false, fixed: false },
  { type: SupplyColumnType.Deliveries, position: 13, visible: false, fixed: false },
  { type: SupplyColumnType.TrolleyLocations, position: 14, visible: false, fixed: false },
  { type: SupplyColumnType.InstantBuy, position: 15, visible: false, fixed: false },
];

export const SUPPLY_COLUMN_LAYOUT_BREAKPOINT_SMALL: IColumnLayoutItem[] = [
  { type: SupplyColumnType.MarkSelected, position: 1, visible: true, fixed: true },
  { type: SupplyColumnType.InstantBuy, position: 2, visible: true, fixed: false },
  { type: SupplyColumnType.ClockPresales, position: 3, visible: true, fixed: false },
  { type: SupplyColumnType.ProductName, position: 4, visible: true, fixed: false },
  { type: SupplyColumnType.GrowerProductName, position: 5, visible: false, fixed: false },
  { type: SupplyColumnType.SelectDelivery, position: 6, visible: true, fixed: false },
  { type: SupplyColumnType.Supplier, position: 7, visible: true, fixed: false },
  { type: SupplyColumnType.Price, position: 8, visible: true, fixed: false },
  { type: SupplyColumnType.AvailablePackages, position: 9, visible: false, fixed: false },
  { type: SupplyColumnType.Characteristics, position: 10, visible: true, fixed: false },
  { type: SupplyColumnType.Load, position: 11, visible: true, fixed: false },
  { type: SupplyColumnType.DeliveryForm, position: 12, visible: false, fixed: false },
  { type: SupplyColumnType.CreationDate, position: 13, visible: false, fixed: false },
  { type: SupplyColumnType.Deliveries, position: 14, visible: false, fixed: false },
  { type: SupplyColumnType.TrolleyLocations, position: 15, visible: false, fixed: false },
];

export const ORDER_COLUMN_LAYOUT_DEFAULT: IColumnLayoutItem[] = [
  { type: OrderColumnType.ProductName, position: 1, visible: true, fixed: false },
  { type: OrderColumnType.GrowerProductName, position: 2, visible: false, fixed: false },
  { type: OrderColumnType.TransactionMoment, position: 3, visible: true, fixed: false },
  { type: OrderColumnType.DeliveryMoment, position: 4, visible: true, fixed: false },
  { type: OrderColumnType.DeliveryLocation, position: 5, visible: true, fixed: false },
  { type: OrderColumnType.Supplier, position: 6, visible: true, fixed: false },
  { type: OrderColumnType.Account, position: 7, visible: true, fixed: false },
  { type: OrderColumnType.Quantity, position: 8, visible: true, fixed: false },
  { type: OrderColumnType.PackageCode, position: 9, visible: false, fixed: false },
  { type: OrderColumnType.PricePerItem, position: 10, visible: true, fixed: false },
  { type: OrderColumnType.OrderFee, position: 11, visible: false, fixed: false },
  { type: OrderColumnType.Price, position: 12, visible: true, fixed: false },
  { type: OrderColumnType.CustomerPricePerItem, position: 13, visible: false, fixed: false },
  { type: OrderColumnType.OrderFeeCustomer, position: 14, visible: false, fixed: false },
  { type: OrderColumnType.CustomerPrice, position: 15, visible: false, fixed: false },
  { type: OrderColumnType.Cancel, position: 16, visible: true, fixed: false },
];

export function getInitialColumnLayout(key: string, defaultLayout: IColumnLayoutItem[]) {
  const storedLayout = store.get(key) as IColumnLayoutItem[];

  if (storedLayout) {
    return sortLayout(
      defaultLayout.map(defaultColumn => {
        const storedColumn = storedLayout.filter(c => c.type === defaultColumn.type)[0];

        if (storedColumn) {
          return {
            ...storedColumn,
            position: defaultColumn.fixed ? defaultColumn.position : storedColumn.position,
            fixed: defaultColumn.fixed,
          };
        }

        return defaultColumn;
      }),
    );
  }

  return defaultLayout;
}

export function sortLayout(layout: IColumnLayoutItem[]): IColumnLayoutItem[] {
  return sortBy(layout, ['position', '-fixed']).map((c, index) => ({ ...c, position: index + 1 }));
}
