import React, { useState, useEffect } from 'react';
import Tooltip from './Tooltip';

interface ITooltipProviderProps {
  children: (element: typeof React.Component, setRef: (instance: Element) => void) => JSX.Element;
}

interface ITooltipProviderState {
  show: boolean;
}

export function useTooltipProvider() {
  let element: Element;
  const [show, setShow] = useState(false);
  const tooltipElement = show ? Tooltip : (): any => null;

  const setRef = (instance: Element) => {
    if (instance) {
      element = instance;
      element.addEventListener('mouseenter', onMouseEnter);
      element.addEventListener('mouseleave', onMouseLeave);
    }
  };

  const onMouseEnter = () => {
    setShow(true);
  };

  const onMouseLeave = () => {
    setShow(false);
  };

  const onDocumentScroll = () => {
    setShow(false);
  };

  useEffect(() => {
    addEventListener('scroll', onDocumentScroll);

    return () => {
      element.removeEventListener('mouseenter', onMouseEnter);
      element.removeEventListener('mouseleave', onMouseLeave);
      removeEventListener('scroll', onDocumentScroll);
    };
  }, []);

  return { tooltipElement, setRef };
}

export default class TooltipProvider extends React.PureComponent<ITooltipProviderProps, ITooltipProviderState> {
  element: Element;

  state: ITooltipProviderState = {
    show: false,
  };

  constructor(props: ITooltipProviderProps) {
    super(props);

    this.setRef = this.setRef.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onDocumentScroll = this.onDocumentScroll.bind(this);
  }

  componentDidMount() {
    addEventListener('scroll', this.onDocumentScroll);
  }

  componentWillUnmount() {
    this.element.removeEventListener('mouseenter', this.onMouseEnter);
    this.element.removeEventListener('mouseleave', this.onMouseLeave);
    removeEventListener('scroll', this.onDocumentScroll);
  }

  render() {
    const { children } = this.props;
    const { show } = this.state;
    const tooltipElement = show ? Tooltip : (): any => null;

    return children(tooltipElement as any, this.setRef);
  }

  setRef(instance: Element) {
    if (instance) {
      this.element = instance;
      this.element.addEventListener('mouseenter', this.onMouseEnter);
      this.element.addEventListener('mouseleave', this.onMouseLeave);
    }
  }

  private onMouseEnter() {
    this.setState({
      show: true,
    });
  }

  private onMouseLeave() {
    this.setState({
      show: false,
    });
  }

  private onDocumentScroll() {
    this.setState({
      show: false,
    });
  }
}
