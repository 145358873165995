import { translateEnum } from '@common/enum';

enum AccountType {
  Main = 'Main',
  Sub = 'Sub',
  External = 'External',
  Process = 'Process',
  RfhEmployee = 'RfhEmployee',
  Koa = 'Koa',
}

export function translateAccountType(key: AccountType, name: string = 'AccountType') {
  return translateEnum(name, key);
}

export default AccountType;
