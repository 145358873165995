import React, { useState } from 'react';
import Icon from '@components/shared/Icon';
import { IconType } from '@enums';
import classNames from 'classnames';
import { NavigationButton } from '../NavigationButton/styles';
import { Wrapper } from './styles';

interface ICarouselProps extends React.HTMLProps<HTMLDivElement> {
  children: JSX.Element[];
  onIndexChange?: (value: number) => void;
  visibleItems?: number;
}

export default function Carousel(props: ICarouselProps) {
  const { children, onIndexChange, visibleItems = 1 } = props;
  const [index, setIndex] = useState(0);
  const visibleIndices = children.length < visibleItems ? children.length : visibleItems;

  return (
    <Wrapper className="external-navigation">
      {children.slice(index, index + visibleIndices)}
      {index > 0 && (
        <NavigationButton className="previous" onClick={event => onClick(event, index - 1)}>
          <Icon type={IconType.LessThan} />
        </NavigationButton>
      )}
      {index < children.length - visibleIndices && (
        <NavigationButton className="next" onClick={event => onClick(event, index + 1)}>
          <Icon type={IconType.LessThan} />
        </NavigationButton>
      )}

      {children.length > visibleItems && (
        <div className="dots">
          {Array.apply(null, Array(children.length)).map((_x: any, dotIndex: number) => (
            <span
              key={dotIndex}
              className={classNames('dot', { active: dotIndex >= index && dotIndex <= index - 1 + visibleIndices })}
              onClick={event => onClick(event, dotIndex)}
            />
          ))}
        </div>
      )}
    </Wrapper>
  );

  function onClick(event: React.MouseEvent, value: number) {
    event.preventDefault();
    const lastVisualStartValue = children.length - visibleIndices;
    const valueToApply = value > lastVisualStartValue ? lastVisualStartValue : value;
    setIndex(valueToApply);
    onIndexChange(valueToApply);
  }
}
