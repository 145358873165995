import React from 'react';
import { IBanner } from '@models';
import { Img } from '../../shared/Image/styles';

interface IEventListProps extends React.HTMLProps<HTMLDivElement> {
  banner: IBanner;
}

export default function BannerBlock({ banner }: IEventListProps) {
  const bannerImage = <Img src={banner.imageUrl} alt={banner.title} title={banner.title} />;

  return (
    <>
      {!banner.linkUrl && bannerImage}
      {banner.linkUrl && <a href={banner.linkUrl}>{bannerImage}</a>}
    </>
  );
}
