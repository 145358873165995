import React from 'react';
import { IWithBreakpointProps, withBreakpoint } from '@containers/withBreakpoint';

const KEY_CODE_TAB = 9;
const FOCUSABLE_SELECTOR = ['input', 'select', 'textarea', 'button', '[href]', '[tabindex]'];

interface ITrapFocusProps extends IWithBreakpointProps {
  children: (setRef: any) => JSX.Element;
  disabled?: boolean;
  focusOnRender?: boolean;
}

interface ITrapFocusState {
  isInteractedWith: boolean;
}

export class TrapFocus extends React.PureComponent<ITrapFocusProps, ITrapFocusState> {
  state: ITrapFocusState = {
    isInteractedWith: false,
  };

  element: any;

  focusableElements: any[];

  constructor(props: ITrapFocusProps) {
    super(props);

    this.onElement = this.onElement.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
  }

  static getDerivedStateFromProps(nextProps: ITrapFocusProps, currentState: ITrapFocusState): ITrapFocusState {
    if (nextProps.disabled) {
      return {
        ...currentState,
        isInteractedWith: false,
      };
    }

    return currentState;
  }

  componentDidMount() {
    this.setFocusableElements();

    if (this.shouldFocus()) {
      this.focusInside();
    }
  }

  componentDidUpdate() {
    this.setFocusableElements();

    if (this.shouldFocus()) {
      this.focusInside();
    }
  }

  componentWillUnmount() {
    this.element.removeEventListener('keydown', this.onKeyDown);
    this.element.removeEventListener('mousedown', this.onMouseDown);
  }

  render() {
    const { children } = this.props;

    return children(this.onElement);
  }

  private shouldFocus() {
    const { focusOnRender = true, disabled, isBreakpointLarge } = this.props;
    const { isInteractedWith } = this.state;

    return !disabled && focusOnRender && !isInteractedWith && isBreakpointLarge;
  }

  private setFocusableElements() {
    if (this.element) {
      this.focusableElements = this.element.querySelectorAll(`${FOCUSABLE_SELECTOR.join(':not(:disabled), ')}:not(:disabled)`);
    }
  }

  private onElement(instance: any) {
    if (!this.element && instance) {
      this.element = instance;
      this.element.addEventListener('keydown', this.onKeyDown);
      this.element.addEventListener('mousedown', this.onMouseDown);
    }
  }

  private focusInside() {
    for (let i = 0, y = this.focusableElements.length; i < y; i++) {
      const element = this.focusableElements[i];

      if (element.dataset.excludeAutoFocus !== 'true') {
        element.focus();
        break;
      }
    }
  }

  private onKeyDown(event: any) {
    this.setInteraction(() => {
      const { disabled } = this.props;

      if (!disabled) {
        const firstFocusable = this.focusableElements[0];
        const lastFocusable = this.focusableElements[this.focusableElements.length - 1];

        if (event.key === 'Tab' || event.keyCode === KEY_CODE_TAB) {
          if (event.shiftKey) {
            /* shift + tab */ if (document.activeElement === firstFocusable) {
              firstFocusable.focus();
              event.preventDefault();
            }
          } else if (document.activeElement === lastFocusable) {
            /* tab */ lastFocusable.focus();
            event.preventDefault();
          }
        }
      }
    });
  }

  private onMouseDown() {
    this.setInteraction();
  }

  private setInteraction(callback?: () => void) {
    const { disabled } = this.props;

    if (!disabled) {
      this.setState({ isInteractedWith: true }, callback);
    }
  }
}

export default withBreakpoint(TrapFocus);
