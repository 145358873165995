import React from 'react';
import ErrorDialog from '@components/shared/ErrorDialog';
import { connect } from 'react-redux';
import { apiActions, IApiState, IApplicationState } from '@store';
import { Route, RouteComponentProps } from 'react-router';

type IErrorDialogContainerProps = {
  showButtons?: boolean;
} & IApiState &
  typeof apiActions;

export class ErrorDialogContainer extends React.PureComponent<IErrorDialogContainerProps> {
  constructor(props: IErrorDialogContainerProps) {
    super(props);

    this.onDialogClosed = this.onDialogClosed.bind(this);
  }

  render() {
    const { error, showButtons = true } = this.props;

    if (error) {
      return (
        <Route>
          {({ location }: RouteComponentProps) => {
            const pathSplit = location.pathname.substr(1).split('/');
            const mainRoutePath = pathSplit.length > 1 && pathSplit[1].includes('account') ? `/${pathSplit[0]}/accounts` : `/${pathSplit[0]}`;
            const closeTo = showButtons ? mainRoutePath : null;

            return <ErrorDialog error={error} onClose={this.onDialogClosed} closeTo={closeTo} autoClose showButtons={showButtons} />;
          }}
        </Route>
      );
    }

    return null;
  }

  private onDialogClosed() {
    const { clearError, showButtons = true } = this.props;

    showButtons && clearError();
  }
}

export default connect((state: IApplicationState) => state.api, apiActions)(ErrorDialogContainer);
