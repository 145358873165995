import React from 'react';
import moment from 'moment';
import Select, { ISelectValue } from '@components/shared/Input/Select';

interface IHourSelectProps {
  value: ISelectValue;
  disabled?: boolean;
  valueFormat?: string;
  visualFormat?: string;
  onFormatOption?: (index: number, label: string) => string;
  onChange?: (value: string) => void;
  optional?: boolean;
}

export default function HourSelect(props: IHourSelectProps) {
  const { value, disabled, onChange } = props;

  return (
    <Select
      onChange={(event: React.FormEvent<HTMLSelectElement>) => onChange && onChange(event.currentTarget.value)}
      value={value}
      disabled={disabled}
    >
      {getTimeOptions(props)}
    </Select>
  );
}

function getTimeOptions(props: IHourSelectProps) {
  const { optional, valueFormat = 'HH:mm', visualFormat = 'HH:mm', onFormatOption } = props;
  const now = moment().startOf('day');
  const result = [];

  if (optional) {
    result.push(<option key={'null'} value={null} />);
  }

  for (let i = 0, y = 24; i < y; i++) {
    let label = now.format(visualFormat);
    const value = now.format(valueFormat);

    if (onFormatOption) {
      label = onFormatOption(i, label);
    }

    result.push(
      <option key={value} value={value}>
        {label}
      </option>,
    );

    now.add(1, 'hour');
  }

  return result;
}
