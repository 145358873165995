import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { IApplicationState } from '@store';
import { LeftColumnContent, LeftColumnWrapper } from './styles';
import TrapFocus from '@components/shared/TrapFocus';
import { CloseButton, FloatingButton } from '@components/shared/Button';
import { useBreakpoint } from '../../withBreakpoint';

interface ILeftColumnProps extends React.HTMLProps<HTMLDivElement> {
  isOpen: boolean;
  trapFocus?: boolean;
  onClose: () => void;
}

export default function LeftColumn({ children, trapFocus, onClose }: ILeftColumnProps) {
  const loading = useSelector(({ orderFilter, supplyFilter }: IApplicationState) => orderFilter.filters.loading || supplyFilter.filters.loading);
  const { selection } = useSelector(({ supply }: IApplicationState) => supply);
  const { isBreakpointSmall } = useBreakpoint();
  const hasSupplySelectionWithBreakpointSmall = selection.length > 0 && isBreakpointSmall;
  const floatingButtonClassName = hasSupplySelectionWithBreakpointSmall ? 'has-supply-selection-with-breakpoint-small' : '';

  return (
    <TrapFocus disabled={!trapFocus}>
      {setRef => (
        <LeftColumnWrapper ref={setRef}>
          <LeftColumnContent>
            <CloseButton onClick={onClose} className="close-button" />
            <Suspense fallback={null}>{children}</Suspense>
          </LeftColumnContent>
          <FloatingButton onClick={onClose} loading={loading} className={floatingButtonClassName} />
        </LeftColumnWrapper>
      )}
    </TrapFocus>
  );
}
