import React from 'react';
import moment, { Moment } from 'moment';

const DEFAULT_FORMAT = 'DD-MM-YYYY HH:mm';

type DateInput = string | Moment;

interface IDateOutputProps {
  date?: DateInput;
  format?: string;
}

export default function DateOutput({ date, format }: IDateOutputProps) {
  return <>{formatDate(date, format)}</>;
}

export function formatDate(date?: DateInput, format: string = DEFAULT_FORMAT) {
  return moment(date).format(format);
}
