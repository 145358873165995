import React from 'react';
import moment, { Moment } from 'moment';

interface ICurrentTimeProps {
  interval?: number;
  children: (now: Moment) => JSX.Element;
}

interface ICurrentTimeState {
  now: Moment;
}

export default class CurrentTime extends React.Component<ICurrentTimeProps, ICurrentTimeState> {
  intervalToken: any;

  state: ICurrentTimeState = {
    now: moment(),
  };

  componentDidMount() {
    const { interval = 60000 } = this.props;

    this.intervalToken = setInterval(this.refreshNow.bind(this), interval);
  }

  componentDidUpdate() {
    const { now } = this.state;
    const languageChanged = moment().locale() !== now.locale();

    if (languageChanged) {
      this.refreshNow();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalToken);
  }

  render() {
    const { children } = this.props;
    const { now } = this.state;

    return children(now);
  }

  private refreshNow() {
    this.setState({
      now: moment(),
    });
  }
}
