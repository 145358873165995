import { patch, get } from '@common/fetch';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { IRecommendationEventAction, IRequestSupplyRecommendationsAction, IReceiveSupplyRecommendationsAction } from './contracts/Actions';
import { getActiveAdministrationNumber } from '@store/user';

export type KnownSupplyRecommendationEventAction =
  | IRequestSupplyRecommendationsAction
  | IReceiveSupplyRecommendationsAction
  | IRecommendationEventAction;

export { default as ISupplyRecommendationsState } from './contracts/ISupplyRecommendationsState';

export const supplyRecommendationActions = {
  getSupplyRecommendation: (position: string, limit: number = 50): IAppThunkAction<KnownSupplyRecommendationEventAction> => async dispatch => {
    const activeAdministrationNumber = getActiveAdministrationNumber();

    if (activeAdministrationNumber) {
      const path = `supply/recommendations?administrationNumber=${activeAdministrationNumber}&position=${position}&limit=${limit}`;
      dispatch({ type: 'REQUEST_SUPPLY_RECOMMENDATIONS' });

      const response = await get(path);

      if (response.ok) {
        dispatch({
          items: await response.json(),
          type: 'RECEIVE_SUPPLY_RECOMMENDATIONS',
        });
      }
    }
  },
  createRecommendationEvent: (event: string, supplyLineId: string): IAppThunkAction<KnownSupplyRecommendationEventAction> => async (
    dispatch,
    getState,
  ) => {
    const { user } = getState().user;

    if (user.authorization.supplyRecommendation.write) {
      const path = `supply/recommendations?event=${event}&supplylineId=${supplyLineId}`;
      const { items } = getState().supplyRecommendation;

      items.map(async supplyRecommendation => {
        if (supplyRecommendation.supplyLine.id === supplyLineId) {
          dispatch({ type: 'RECOMMENDATION_EVENT', event, supplyRecommendation });
          await patch(path, supplyRecommendation);
        }
      });
    }
  },
};
