import React from 'react';
import PropertyListItem from '../PropertyList/PropertyListItem';
import PropertyList from '../PropertyList/PropertyList';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ISupplier } from '@models';
import { AccountType } from '@enums';
import { UserConnect } from '@containers/shared/Connect';

type ISupplierPropertyListProps = {
  title: string;
  supplier: ISupplier;
} & WithTranslation;

export function SupplierPropertyList({ t, title, supplier }: ISupplierPropertyListProps) {
  return (
    <PropertyList title={title}>
      <PropertyListItem title={t('supplierDetails.name')}>{supplier.name}</PropertyListItem>
      {supplier.companyName && <PropertyListItem title={t('supplierDetails.companyName')}>{supplier.companyName}</PropertyListItem>}
      {supplier.administrationNumber && (
        <PropertyListItem title={t('supplierDetails.administrationNumber')}>{supplier.administrationNumber}</PropertyListItem>
      )}

      <UserConnect>
        {({ user }) =>
          user.accountType !== AccountType.External && (
            <>
              {supplier.address && <PropertyListItem title={t('supplierDetails.address')}>{supplier.address}</PropertyListItem>}
              {supplier.postalCode && <PropertyListItem title={t('supplierDetails.postalCode')}>{supplier.postalCode}</PropertyListItem>}
              {supplier.city && <PropertyListItem title={t('supplierDetails.city')}>{supplier.city}</PropertyListItem>}
              {!!supplier.websiteUrl && (
                <PropertyListItem title={t('supplierDetails.websiteUrl')}>
                  <a href={supplier.websiteUrl} className="text-link" target="_blank" rel="noopener noreferrer">
                    {supplier.websiteUrl}
                  </a>
                </PropertyListItem>
              )}
              {!!supplier.phoneNumber && (
                <PropertyListItem title={t('supplierDetails.phoneNumber')}>
                  <a href={`tel:${supplier.phoneNumber}`} className="text-link">
                    {supplier.phoneNumber}
                  </a>
                </PropertyListItem>
              )}
              {!!supplier.email && (
                <PropertyListItem title={t('supplierDetails.email')}>
                  <a href={`mailto:${supplier.email}`} className="text-link">
                    {supplier.email}
                  </a>
                </PropertyListItem>
              )}
            </>
          )
        }
      </UserConnect>
    </PropertyList>
  );
}

export default withTranslation()(SupplierPropertyList);
