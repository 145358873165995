import React, { useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import sortBy from 'lodash.sortby';
import { Footer, Header, ListItem, Message, NotificationList, Wrapper } from './styles';
import Heading from '@components/shared/Heading';
import { useTranslation } from 'react-i18next';
import Icon from '@components/shared/Icon';
import { IHubNotification } from '@models';
import DateOutput from '@components/shared/DateOutput';
import NotificationLevel from '@enums/NotificationLevel';
import IconType from '@enums/IconType';
import NotificationType from '@enums/NotificationType';
import HtmlOutput from '@components/shared/HtmlOutput';

type INotificationMenuProps = {
  notifications: IHubNotification[];
  onClear?: () => void;
};

function NotificationMenu({ notifications, onClear }: INotificationMenuProps) {
  const { t } = useTranslation();

  /* Gets notifications sorted on creation date but with general warnings always on top */
  const getNotifications = () => {
    const generalWarning = notifications.filter(n => n.type === NotificationType.General && n.level === NotificationLevel.Warning)[0];
    let result = notifications.filter(n => n !== generalWarning);

    result = sortBy(result, (n: IHubNotification) => -moment(n.createdAt));

    if (generalWarning) {
      result.unshift(generalWarning);
    }

    return result;
  };

  const sortedNotifications = useMemo(getNotifications, [notifications]);
  const hasNotifications = sortedNotifications.length > 0;

  return (
    <Wrapper>
      <Header>
        <Heading as="h5" size="s" className="title">
          {t('menu.notifications')}
        </Heading>
      </Header>
      {!hasNotifications && <Message>{t('notification.noNotifications')}</Message>}
      {hasNotifications && (
        <NotificationList>
          {notifications.map((notification: IHubNotification) => {
            const itemClass = classNames(notification.status.toLowerCase(), notification.level.toLowerCase());
            const isWarning = notification.level === NotificationLevel.Warning;

            return (
              <ListItem key={notification.id} className={itemClass}>
                <Icon type={isWarning ? IconType.Warning : IconType.Bell} />
                <Heading as="h6" size="xs" className="title">
                  {notification.title}
                </Heading>
                <section className="message">
                  <HtmlOutput html={notification.message} />
                </section>
                <time dateTime={notification.createdAt} className="date">
                  <DateOutput date={notification.createdAt} format="DD MMMM YYYY - HH:mm" />
                </time>
              </ListItem>
            );
          })}
        </NotificationList>
      )}
      <Footer>
        <button
          className="text-link"
          onClick={() => {
            onClear && onClear();
          }}
          data-test-hook="btn-notifications-clear-all"
        >
          {t('notification.clearAll')}
        </button>
      </Footer>
    </Wrapper>
  );
}

export default React.memo(NotificationMenu);
