import React from 'react';
import { Prefix, PrefixWrapper } from './prefixStyles';
import InputNumber from './InputNumber';

type IPriceProps = {
  onPriceChange?: (price: string) => void;
  decimals?: number;
} & React.HTMLProps<HTMLInputElement>;

export default class Price extends React.PureComponent<IPriceProps> {
  render() {
    const { className, value, min, max, disabled, onPriceChange, onBlur, decimals } = this.props;

    return (
      <PrefixWrapper className={className}>
        <InputNumber value={value} min={min} max={max} disabled={disabled} decimals={decimals} onBlur={onBlur} onNumberChange={onPriceChange} />
        <Prefix char="€" />
      </PrefixWrapper>
    );
  }
}
