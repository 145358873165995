import { StyledInput } from './styles';
import styled from 'styled-components';
import { pxToRem } from '@styles';

export const SelectElement = styled(StyledInput.withComponent('select'))`
  background-color: ${props => props.theme.colors.white};
  padding-right: ${pxToRem(40)};
  appearance: none;
  &::-ms-expand {
    display: none; /* hide the default arrow in ie10 and ie11 */
  }
  line-height: 175%; /* Fix text center issue in FF */
`;
