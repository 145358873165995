import { get } from '@common/fetch';
import { getActiveAdministrationNumber } from '@store/user';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { IReceiveStatistics, IRequestStatistics } from './contracts/Actions';

export { default as IStatisticsState } from './contracts/IStatisticsState';

export type KnownStatisticsAction = IRequestStatistics | IReceiveStatistics;

export const statisticsActions = {
  getAll: (): IAppThunkAction<KnownStatisticsAction> => async dispatch => {
    const activeAdministrationNumber = getActiveAdministrationNumber();

    if (activeAdministrationNumber) {
      dispatch({ type: 'REQUEST_STATISTICS' });

      const response = await get(`statistics/today?administrationNumber=${activeAdministrationNumber}`);

      if (response.ok) {
        dispatch({
          type: 'RECEIVE_STATISTICS',
          statistics: await response.json(),
        });
      }
    }
  },
};
