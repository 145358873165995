import i18next from 'i18next';
import {
  ICreateQuickFilterSucceededAction,
  IReceiveQuickFiltersAction,
  IReceiveSupplyFiltersAction,
  IReceiveSupplySearchSuggestionsAction,
  IRemoveActiveSupplyFiltersAction,
  IRemoveQuickFilterSucceededAction,
  IRequestFreshSupplyFiltersAction,
  IRequestQuickFiltersAction,
  IRequestSupplyFiltersAction,
  IRequestSupplySearchSuggestionsAction,
  ISetActiveQuickFilterAction,
  ISetSelectedSupplyFilterStateAction,
  ISetSupplyDeliveryAction,
  ISetSupplyFilterAction,
  ISetSupplyMainGroupsAction,
  ISetSupplyQueryAction,
  ISetSupplySortingAction,
  ISetSupplyViewAction,
  IUpdateActiveQuickFilterSucceededAction,
  IUpdateQuickFilterSucceededAction,
} from './contracts/Actions';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { IDeliverySetting, ISupplyFilter, ISupplySorting } from '@contracts';
import { IFilterOptionId } from '@contracts/IFilterOption';
import { get, patch, post, remove } from '@common/fetch';
import { MarketPlaceView, SupplyFilterGroup, SupplyFilterType } from '@enums';
import getSupplyUrl from '@store/supply/getSupplyUrl';
import { IQuickFilter } from '@models';
import { KnownNotificationAction, notificationActions } from '@store/notification';
import { ISelectedSupplyFilterState } from './contracts/ISupplyFilterState';
import { createPushNotification } from '@common/notification';
import { getSelectedFiltersForGroup } from '@common/filters';

export { default as ISupplyFilterState } from './contracts/ISupplyFilterState';

export type KnownSupplyFilterAction =
  | ISetSupplyQueryAction
  | ISetSupplyDeliveryAction
  | ISetSupplySortingAction
  | ISetSupplyFilterAction
  | ISetSupplyMainGroupsAction
  | IRemoveActiveSupplyFiltersAction
  | IRequestSupplyFiltersAction
  | IReceiveSupplyFiltersAction
  | IRequestFreshSupplyFiltersAction
  | IRequestQuickFiltersAction
  | IReceiveQuickFiltersAction
  | ISetActiveQuickFilterAction
  | ICreateQuickFilterSucceededAction
  | IUpdateActiveQuickFilterSucceededAction
  | IUpdateQuickFilterSucceededAction
  | KnownNotificationAction
  | ISetSupplyViewAction
  | IRemoveQuickFilterSucceededAction
  | ISetSelectedSupplyFilterStateAction
  | IRequestSupplySearchSuggestionsAction
  | IReceiveSupplySearchSuggestionsAction;

export const supplyFilterActions = {
  getAll: (fresh: boolean, query?: string): IAppThunkAction<KnownSupplyFilterAction> => async (dispatch, getState) => {
    if (fresh) {
      dispatch({ type: 'REQUEST_FRESH_SUPPLY_FILTERS' });
    }

    const {
      filters: { path: statePath },
    } = getState().supplyFilter;
    const requestPath = getSupplyUrl('supply/filters', getState, { query }, { sorting: false });

    if (requestPath !== statePath) {
      dispatch({ path: requestPath, type: 'REQUEST_SUPPLY_FILTERS' });

      const response = await get(requestPath);

      if (response.ok) {
        dispatch({
          type: 'RECEIVE_SUPPLY_FILTERS',
          filters: (await response.json()) as ISupplyFilter[],
        });
      }
    }
  },
  getSearchSuggestions: (query: string): IAppThunkAction<KnownSupplyFilterAction> => async (dispatch, getState) => {
    dispatch({ type: 'REQUEST_SUPPLY_SEARCH_SUGGESTIONS' });

    const supplyUrl = getSupplyUrl('supply/search-suggestions', getState, { query }, { sorting: false, query: false });
    const response = await get(supplyUrl);

    if (response.ok) {
      dispatch({
        type: 'RECEIVE_SUPPLY_SEARCH_SUGGESTIONS',
        suggestions: await response.json(),
      });
    }
  },
  setQuery: (query?: string): IAppThunkAction<KnownSupplyFilterAction> => dispatch => {
    dispatch({ query, type: 'SET_SUPPLY_QUERY' });
  },
  setDelivery: (delivery: IDeliverySetting): IAppThunkAction<KnownSupplyFilterAction> => dispatch => {
    dispatch({ delivery, type: 'SET_SUPPLY_DELIVERY' });
  },
  setMainGroups: (mainGroups: number[]): IAppThunkAction<KnownSupplyFilterAction> => dispatch => {
    dispatch({ mainGroups, type: 'SET_SUPPLY_MAIN_GROUPS' });
  },
  setSorting: (sorting: ISupplySorting): IAppThunkAction<KnownSupplyFilterAction> => dispatch => {
    dispatch({ sorting, type: 'SET_SUPPLY_SORTING' });
  },
  setFilter: (filterType: SupplyFilterType, value: IFilterOptionId[]): IAppThunkAction<KnownSupplyFilterAction> => dispatch => {
    dispatch({ filterType, value, type: 'SET_SUPPLY_FILTER' });
  },
  setView: (view: MarketPlaceView): IAppThunkAction<KnownSupplyFilterAction> => dispatch => {
    dispatch({ view, type: 'SET_SUPPLY_VIEW' });
  },
  setSelectedState: (selection: ISelectedSupplyFilterState): IAppThunkAction<KnownSupplyFilterAction> => dispatch => {
    dispatch({ selection, type: 'SET_SELECTED_SUPPLY_FILTER_STATE' });
  },
  removeActiveFilters: (group: SupplyFilterGroup): IAppThunkAction<KnownSupplyFilterAction> => async dispatch => {
    dispatch({ type: 'REMOVE_ACTIVE_SUPPLY_FILTERS', group });
  },
  getQuickFilters: (fresh: boolean = false): IAppThunkAction<KnownSupplyFilterAction> => async (dispatch, getState) => {
    const {
      quickFilters: { path: statePath },
    } = getState().supplyFilter;
    const requestPath = `quick-filters?sort=${encodeURIComponent('+name')}`;

    if (fresh || requestPath !== statePath) {
      dispatch({ type: 'REQUEST_QUICK_FILTERS', path: requestPath });

      const response = await get(requestPath);

      if (response.ok) {
        dispatch({
          type: 'RECEIVE_QUICK_FILTERS',
          filters: await response.json(),
        });
      }
    }
  },
  setActiveQuickFilter: (id: number): IAppThunkAction<KnownSupplyFilterAction> => async dispatch => {
    dispatch({ type: 'SET_ACTIVE_QUICK_FILTER', quickFilterId: id });
  },
  createQuickFilter: (name: string): IAppThunkAction<KnownSupplyFilterAction> => async (dispatch, getState) => {
    const supplyFilterState = getState().supplyFilter;
    const filter: IQuickFilter = {
      name,
      filter: getSelectedFiltersForGroup(supplyFilterState, SupplyFilterGroup.General),
    };

    const response = await post('quick-filters', filter);

    if (response.ok) {
      const notification = createPushNotification(i18next.t('marketPlace:quickFilters.saveAsSucceeded', { name }));

      dispatch({ type: 'CREATE_QUICK_FILTER_SUCCEEDED', quickFilter: await response.json() });

      notificationActions.receivePushNotification(notification)(dispatch, getState);
    }
  },
  updateActiveQuickFilter: (): IAppThunkAction<KnownSupplyFilterAction> => async (dispatch, getState) => {
    const supplyFilterState = getState().supplyFilter;
    const { activeQuickFilter } = supplyFilterState;

    const response = await patch(`quick-filters/${activeQuickFilter.id}`, [
      {
        op: 'replace',
        path: '/filter',
        value: getSelectedFiltersForGroup(supplyFilterState, SupplyFilterGroup.General),
      },
    ]);

    if (response.ok) {
      const notification = createPushNotification(i18next.t('marketPlace:quickFilters.saveSucceeded', { name: activeQuickFilter.name }));

      notificationActions.receivePushNotification(notification)(dispatch, getState);

      dispatch({ type: 'UPDATE_ACTIVE_QUICK_FILTER_SUCCEEDED' });
    }
  },
  updateQuickFilter: (quickFilter: IQuickFilter, prop: string): IAppThunkAction<KnownSupplyFilterAction> => async (dispatch, getState) => {
    const response = await patch(`quick-filters/${quickFilter.id}`, [
      {
        op: 'replace',
        path: `/${prop}`,
        value: quickFilter[prop],
      },
    ]);

    if (response.ok) {
      const notification = createPushNotification(i18next.t('settings:account.quickFilters.modify.saveSucceeded', { name: quickFilter.name }));

      notificationActions.receivePushNotification(notification)(dispatch, getState);

      dispatch({ type: 'UPDATE_QUICK_FILTER_SUCCEEDED', quickFilter });
    }
  },
  removeQuickFilter: (quickFilter: IQuickFilter): IAppThunkAction<KnownSupplyFilterAction> => async (dispatch, getState) => {
    const response = await remove(`quick-filters/${quickFilter.id}`);

    if (response.ok) {
      const notification = createPushNotification(i18next.t('settings:account.quickFilters.deleteSucceeded', { name: quickFilter.name }));

      notificationActions.receivePushNotification(notification)(dispatch, getState);

      dispatch({ type: 'REMOVE_QUICK_FILTER_SUCCEEDED', id: quickFilter.id });
    }
  },
};
