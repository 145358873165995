import { Action, Reducer } from 'redux';
import store from 'store';
import { KnownOrderAction } from './';
import IOrderState from './contracts/IOrderState';
import { ORDER_COLUMN_LAYOUT_DEFAULT, getInitialColumnLayout } from '@common/columnLayout';

const STORAGE_KEY_PREFIX = 'order:';
const STORAGE_KEYS = {
  columnLayout: `${STORAGE_KEY_PREFIX}column-layout`,
};

const unloadedState: IOrderState = {
  all: {
    lines: [],
    loading: false,
    path: '',
  },
  detail: {
    lines: {},
  },
  recent: {
    lines: [],
    loading: false,
  },
  conditions: {
    data: null,
    supplyLine: null,
    loading: false,
    error: '',
  },
  pendingCancellationOrderIds: [],
  columnLayout: getInitialColumnLayout(STORAGE_KEYS.columnLayout, ORDER_COLUMN_LAYOUT_DEFAULT),
};

export const orderReducer: Reducer<IOrderState> = (state: IOrderState, incomingAction: Action) => {
  const action = incomingAction as KnownOrderAction;

  switch (action.type) {
    case 'REQUEST_ORDERS':
      return {
        ...state,
        all: {
          ...state.all,
          loading: true,
          path: action.path,
        },
      };
    case 'RECEIVE_ORDERS':
      return {
        ...state,
        all: {
          ...state.all,
          lines: action.orderLines,
          loading: false,
        },
      };
    case 'REQUEST_ORDER_LINE':
      return state;
    case 'RECEIVE_ORDER_LINE':
      return {
        ...state,
        detail: {
          ...state.detail,
          lines: {
            ...state.detail.lines,
            [action.orderLine.id]: action.orderLine,
          },
        },
      };
    case 'REQUEST_FRESH_ORDERS':
      return {
        ...state,
        all: unloadedState.all,
      };
    case 'REQUEST_RECENT_ORDERS':
      return {
        ...state,
        recent: {
          ...state.recent,
          loading: true,
        },
      };
    case 'RECEIVE_RECENT_ORDERS':
      return {
        ...state,
        recent: {
          ...state.recent,
          lines: action.orderLines,
          loading: false,
        },
      };
    case 'REQUEST_ORDER_CONDITIONS':
      return {
        ...state,
        conditions: unloadedState.conditions,
      };
    case 'RECEIVE_ORDER_CONDITIONS':
      return {
        ...state,
        conditions: {
          ...state.conditions,
          data: action.conditions,
          supplyLine: action.supplyLine,
          loading: false,
        },
      };
    case 'RECEIVE_ORDER_CONDITIONS_FAILED':
      return {
        ...state,
        conditions: {
          ...state.conditions,
          loading: false,
          error: action.error,
          supplyLine: null,
        },
      };
    case 'RESET_ORDER_CONDITIONS':
      return {
        ...state,
        conditions: unloadedState.conditions,
      };
    case 'REQUEST_ORDER_CANCELLATION':
      return {
        ...state,
        pendingCancellationOrderIds: [...state.pendingCancellationOrderIds, action.orderId],
      };
    case 'ORDER_CANCELLATION_RESULT':
      return {
        ...state,
        pendingCancellationOrderIds: state.pendingCancellationOrderIds.filter(id => id !== action.orderId),
        all: {
          ...state.all,
          lines: state.all.lines.map(line => (line.id === action.orderId ? action.orderLine : line)),
        },
        detail: {
          ...state.detail,
          lines: {
            ...state.detail.lines,
            [action.orderId]: action.orderLine,
          },
        },
      };
    case 'SET_ORDER_COLUMN_LAYOUT':
      store.set(STORAGE_KEYS.columnLayout, action.columnLayout);

      return {
        ...state,
        columnLayout: action.columnLayout,
      };
  }

  return state || unloadedState;
};
