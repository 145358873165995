import React, { useState, useEffect } from 'react';
import { isBreakpoint } from '@styles';

export interface IWithBreakpointProps {
  currentBreakpoint: string;
  isBreakpointSmall: boolean;
  isBreakpointMedium: boolean;
  isBreakpointLarge: boolean;
}

type WithoutPrefilled<T extends IWithBreakpointProps> = Pick<T, Exclude<keyof T, keyof IWithBreakpointProps>>;

export function withBreakpoint<P extends IWithBreakpointProps>(WrappedComponent: React.ComponentType<P>): React.ComponentClass<WithoutPrefilled<P>> {
  return class WithBreakpoint extends React.PureComponent<WithoutPrefilled<P>> {
    state: IWithBreakpointProps = getBreakpoints();

    constructor(props: WithoutPrefilled<P>) {
      super(props);

      this.updateBreakpoint = this.updateBreakpoint.bind(this);
    }

    componentDidMount() {
      addEventListener('resize', this.updateBreakpoint);
    }

    componentWillUnmount() {
      removeEventListener('resize', this.updateBreakpoint);
    }

    render(): JSX.Element {
      return <WrappedComponent {...(this.props as any)} {...this.state} />;
    }

    private updateBreakpoint() {
      this.setState(getBreakpoints());
    }
  };
}

export function useBreakpoint() {
  const [breakpoints, updateBreakpoint] = useState(getBreakpoints());

  useEffect(() => {
    function handleResize() {
      const nextBreakpoints = getBreakpoints();
      if (breakpoints.currentBreakpoint !== nextBreakpoints.currentBreakpoint) {
        updateBreakpoint(nextBreakpoints);
      }
    }

    addEventListener('resize', handleResize);

    return () => {
      removeEventListener('resize', handleResize);
    };
  }, [breakpoints.currentBreakpoint]);

  return breakpoints;
}

export function getBreakpoints() {
  const isMedium = isBreakpoint('m');
  const isLarge = isBreakpoint('l');
  const isBreakpointSmall = !isMedium && !isLarge;
  const isBreakpointMedium = isMedium && !isLarge;
  const isBreakpointLarge = isLarge;

  return {
    isBreakpointSmall,
    isBreakpointMedium,
    isBreakpointLarge,
    currentBreakpoint: (isBreakpointMedium && 'm') || (isBreakpointLarge && 'l') || 's',
  };
}
