import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IApplicationState, userActions } from '@store';
import { Select, InputNumber } from '@components/shared/Input';
import { IAdministration } from '@models';
import debounce from 'lodash.debounce';

export function AdministrationSelectContainer() {
  const MAX_ADMINISTRATION_NUMBER = 9999999;
  const dispatch = useDispatch();
  const {
    user: { administrations, authorization },
    activeAdministration,
  } = useSelector((state: IApplicationState) => state.user);
  const [activeAdministrationNumber, setAdministrationNumber] = useState(activeAdministration ? activeAdministration.number : '');

  const onAdministrationChanged = (event: React.FormEvent<HTMLSelectElement>) => {
    dispatch(userActions.setActiveAdministration(parseInt(event.currentTarget.value, 10)));
  };

  const debounceAdministrationInputChanged = useCallback(
    debounce((administrationNumber: number) => {
      dispatch(userActions.setActiveAdministration(administrationNumber));
    }, 500),
    [],
  );

  const onAdministrationInputChanged = (number: string) => {
    const administrationNumber = parseInt(number, 10);
    if (administrationNumber < MAX_ADMINISTRATION_NUMBER || number === '') {
      setAdministrationNumber(number);
      debounceAdministrationInputChanged(isNaN(administrationNumber) ? 0 : administrationNumber);
    }
  };

  if (authorization.useAnyAdministration.write) {
    return (
      <InputNumber
        value={activeAdministrationNumber === 0 ? '' : activeAdministrationNumber}
        min={0}
        max={MAX_ADMINISTRATION_NUMBER}
        onNumberChange={onAdministrationInputChanged}
      />
    );
  }

  if (activeAdministration) {
    return (
      <Select onChange={onAdministrationChanged} value={activeAdministration.number}>
        {administrations.map((administration: IAdministration) => (
          <option key={administration.number} value={administration.number}>{`${administration.name} (${administration.number})`}</option>
        ))}
      </Select>
    );
  }

  return <Select disabled />;
}

export default React.memo(AdministrationSelectContainer);
