import { get, post } from '@common/fetch';
import i18next from 'i18next';
import qs from 'query-string';
import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { getActiveAdministrationNumber } from '@store/user';
import {
  IMarkSucceededAction,
  IRequestMarkAction,
  IMarkFailedAction,
  IAddMarkToQueueAction,
  IPostQueueAction,
  IRemoveMarkFromQueueAction,
  ISetMarkQueue,
} from './contracts/Actions';
import { ISupplyLine, IUpdateSupplyLinesReport } from '@models';
import { getDefaultDeliveryMoment } from '@common/deliveryMoment';
import { createPushNotification } from '@common/notification';
import { KnownNotificationAction, notificationActions } from '../notification';
import ISupplyMarkingState from './contracts/ISupplyMarkingState';
import IQueueMarking from './contracts/IQueueMarking';

export type KnownSupplyMarkingAction =
  | KnownNotificationAction
  | IRequestMarkAction
  | IMarkSucceededAction
  | IMarkFailedAction
  | IAddMarkToQueueAction
  | IPostQueueAction
  | IRemoveMarkFromQueueAction
  | ISetMarkQueue;

export { default as ISupplyMarkingState } from './contracts/ISupplyMarkingState';
export { default as IQueueMarking } from './contracts/IQueueMarking';

interface IMarkingBody {
  supplyLine: ISupplyLine;
  markingDetail: ISupplyMarkingState;
}

interface ISupplyQueryProps {
  query?: string;
  deliveryLocationIds?: number[];
  mainGroups?: number[];
  deliveryMoment?: string;
  sort?: string;
  limit?: number;
  offset?: number;

  [key: string]: any;
}

export const supplyMarkingActions = {
  markCurrentSelection: (supplyMarking?: ISupplyMarkingState): IAppThunkAction<KnownSupplyMarkingAction> => async (dispatch, getState) => {
    const { user, supply, koa } = getState();
    const query = {
      accounts: koa.selection.map(a => a.id),
      hasBarcodeScanOrigin: window.location.href.includes('/scan'),
    };
    const path = `supply/markings?administrationNumber=${user.activeAdministration.number}&${qs.stringify(query)}`;
    const body = supply.selection.map(s => ({
      supplyLine: s,
      markingDetail: supplyMarking ?? ({} as ISupplyMarkingState),
    }));
    postMarking(path, body)(dispatch, getState);
  },
  markSelectedSupply: (supply: ISupplyLine[]): IAppThunkAction<KnownSupplyMarkingAction> => async (dispatch, getState) => {
    const { user, koa } = getState();
    const query = {
      accounts: koa.selection.map(a => a.id),
      hasBarcodeScanOrigin: window.location.href.includes('/scan'),
    };
    const path = `supply/markings?administrationNumber=${user.activeAdministration.number}&${qs.stringify(query)}`;
    const body = supply.map(supplyLine => ({
      supplyLine,
      markingDetail: {} as ISupplyMarkingState,
    }));
    postMarking(path, body)(dispatch, getState);
  },
  addMarkQueue: (marking: IQueueMarking): IAppThunkAction<KnownSupplyMarkingAction> => async dispatch => {
    dispatch({ type: 'ADD_MARK_TO_QUEUE', marking });
  },
  setMarkQueue: (markings: IQueueMarking[]): IAppThunkAction<KnownSupplyMarkingAction> => async dispatch => {
    dispatch({ type: 'SET_MARK_QUEUE', markings });
  },
  postMarkQueue: (): IAppThunkAction<KnownSupplyMarkingAction> => async (dispatch, getState) => {
    dispatch({ type: 'POST_QUEUE' });
    const { supplyMarking } = getState();
    supplyMarking.queue.forEach(marking => postQueueMarking(marking)(dispatch, getState));
  },
};

function postQueueMarking(marking: IQueueMarking): IAppThunkAction<KnownSupplyMarkingAction> {
  return async dispatch => {
    const queryProps: ISupplyQueryProps = {
      deliveryFormBarcodes: marking.barcode,
      deliveryMoment: getDefaultDeliveryMoment(),
      mainGroups: [1, 2, 3],
      hasBarcodeScanOrigin: window.location.href.includes('/scan'),
    };
    const getSupplyPath = `supply?administrationNumber=${getActiveAdministrationNumber()}&${qs.stringify(queryProps)}`;
    const supplyResponse = await get(getSupplyPath);

    if (supplyResponse.ok) {
      const supply: ISupplyLine[] = await supplyResponse.json();
      const selectedSupply: ISupplyLine[] = supply.filter(supplyLine => marking.letters.includes(supplyLine.fulfillment.deliveryForm.letter));

      if (selectedSupply.length === 0) {
        dispatch({ type: 'REMOVE_MARK_FROM_QUEUE', id: marking.id });

        return;
      }

      const query = {
        accounts: marking.buyers,
        hasBarcodeScanOrigin: window.location.href.includes('/scan'),
      };
      const path = `supply/markings?administrationNumber=${getActiveAdministrationNumber()}&${qs.stringify(query)}`;
      const body = selectedSupply.map(supplyLine => ({ supplyLine, markingDetail: {} as ISupplyMarkingState }));

      const markingResponse = await post(path, body);

      if (markingResponse.ok) {
        dispatch({ type: 'REMOVE_MARK_FROM_QUEUE', id: marking.id });
      }
    }
  };
}

function postMarking(path: string, body: IMarkingBody[]): IAppThunkAction<KnownSupplyMarkingAction> {
  return async (dispatch, getState) => {
    dispatch({ type: 'REQUEST_MARK' });

    const response = await post(path, body);

    if (response.ok) {
      const report = (await response.json()) as IUpdateSupplyLinesReport;
      const notification = createPushNotification(
        i18next.t('general:actions.markSucceeded', {
          count: report.numberOfUpdatedSupplyLines,
        }),
      );

      dispatch({ type: 'MARK_SUCCEEDED' });
      notificationActions.receivePushNotification(notification)(dispatch, getState);
    } else {
      dispatch({ type: 'MARK_FAILED' });
    }
  };
}
