import IAppThunkAction from '@store/contracts/IAppThunkAction';
import { get } from '@common/fetch';
import getOrderUrl from '@store/order/getOrderUrl';
import {
  IReceiveOrderFiltersAction,
  IRemoveActiveOrderFiltersAction,
  IRequestOrderFiltersAction,
  ISetOrderFilterAction,
  ISetOrderSortingAction,
  ISetSelectedOrderFilterStateAction,
  ISetOrderMainGroupsAction,
  ISetOrderGroupingAction,
} from './contracts/Actions';
import { OrderFilterType } from '@enums';
import { IOrderFilter, IOrderSorting, IOrderGrouping } from '@contracts';
import { IFilterOptionId } from '@contracts/IFilterOption';
import { ISelectedOrderFilterState } from './contracts/IOrderFilterState';
import { getActiveAdministrationNumber } from '@store/user';

export { default as IOrderFilterState } from './contracts/IOrderFilterState';

export type KnownOrderFilterAction =
  | IRequestOrderFiltersAction
  | IReceiveOrderFiltersAction
  | ISetOrderFilterAction
  | IRemoveActiveOrderFiltersAction
  | ISetOrderSortingAction
  | ISetSelectedOrderFilterStateAction
  | ISetOrderMainGroupsAction
  | ISetOrderGroupingAction;

export const orderFilterActions = {
  getAllFilters: (): IAppThunkAction<KnownOrderFilterAction> => async (dispatch, getState) => {
    const activeAdministrationNumber = getActiveAdministrationNumber();

    if (activeAdministrationNumber) {
      const {
        filters: { path: statePath },
      } = getState().orderFilter;
      const requestPath = getOrderUrl('orders/filters', getState().orderFilter, activeAdministrationNumber);

      if (requestPath !== statePath) {
        dispatch({ path: requestPath, type: 'REQUEST_ORDER_FILTERS' });

        const response = await get(requestPath);

        if (response.ok) {
          dispatch({
            type: 'RECEIVE_ORDER_FILTERS',
            filters: (await response.json()) as IOrderFilter[],
          });
        }
      }
    }
  },
  setMainGroups: (mainGroups: number[]): IAppThunkAction<KnownOrderFilterAction> => dispatch => {
    dispatch({ mainGroups, type: 'SET_ORDER_MAIN_GROUPS' });
  },
  setFilter: (filterType: OrderFilterType, value: IFilterOptionId[]): IAppThunkAction<KnownOrderFilterAction> => dispatch => {
    dispatch({ filterType, value, type: 'SET_ORDER_FILTER' });
  },
  removeActiveFilters: (): IAppThunkAction<KnownOrderFilterAction> => async dispatch => {
    dispatch({ type: 'REMOVE_ACTIVE_ORDER_FILTERS' });
  },
  setSorting: (sorting: IOrderSorting): IAppThunkAction<KnownOrderFilterAction> => dispatch => {
    dispatch({ type: 'SET_ORDER_SORTING', sorting });
  },
  setSelectedState: (selection: ISelectedOrderFilterState): IAppThunkAction<KnownOrderFilterAction> => dispatch => {
    dispatch({ selection, type: 'SET_SELECTED_ORDER_FILTER_STATE' });
  },
  setGrouping: (grouping: IOrderGrouping): IAppThunkAction<KnownOrderFilterAction> => dispatch => {
    dispatch({ type: 'SET_ORDER_GROUPING', grouping });
  },
};
